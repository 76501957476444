import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Contact } from 'models/Institutional';
import { fetchContactsRequest } from 'store/ducks/institutionalContact/actions';
import { PageHeader } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { ContactActionSidebar, ContactDataTable } from './components';
import * as S from './ContactPage.styles';
import { contactInitialState } from './ContactPage.utils';

const ContactPage = () => {
  const dispatch = useDispatch();
  const { contacts } = useSelector(
    (state: ApplicationState) => state.institutionalContact
  );

  const [selected, setSelected] = useState<Contact>(contactInitialState);
  const [mode, setMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchContactsRequest());
  }, [dispatch]);

  const handleDoubleClick = (contact: Contact) => {
    setSelected(contact);
    setMode('OPEN_VIEW');
  };

  const handleCloseClick = () => {
    setSelected(contactInitialState);
    setMode('CLOSE');
  };

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <ContactActionSidebar
          mode={mode}
          contact={selected}
          onCancelPress={handleCloseClick}
        />

        <S.Container>
          <PageHeader title="Institucional - Contato" />

          <ContactDataTable data={contacts} onPressItem={handleDoubleClick} />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { ContactPage };
