import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  justify-content: left;
  padding: 0px;
  font-size: 14px;
  flex-wrap: wrap;

  .qa-card {
    width: 24%;
    background-color: ${({ theme }) => theme.section.bg};
    box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
    border-radius: 6px;
    padding: 15px;
    margin: 5px;
    cursor: pointer;

    &__id, &__answer, &__isCorrect {
      margin: 12px auto 12px;
    }

    &__label {
      color: ${({ theme }) => theme.text.secondary};
      font-size: 12px;
      text-transform: uppercase;
    }

    &__content {
      color: ${({ theme }) => theme.text.primary};
      font-size: 18px;
    }
  }
`;
