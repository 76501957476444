import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { mainMenu } from 'components/Navigator/Navigator.utils';
import { ReactComponent as PlaylegisLogoYellow } from 'assets/playlegis-logo-yellow.svg';
import * as S from './ForbiddenPage.styles';

const ForbiddenPage = () => {
  const [redirect, setRedirect] = useState(false);
  const [counter, setCounter] = useState(5);

  const [pageToRedirect, setPageToRedirect] = useState('/');

  useEffect(() => {
    const cookies = Cookies.get(btoa('AuthAccess'));

    if (cookies) {
      const pathId = JSON.parse(cookies).map((p: string) => atob(p))[0];

      const page =
        mainMenu
          .map((item) => item.links.find(({ id }) => id === pathId))
          .filter((item) => item !== undefined)[0]?.to || '';

      setPageToRedirect(page);
    }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  setTimeout(() => {
    setRedirect(true);
  }, 5000);

  if (redirect) {
    return <Navigate to={pageToRedirect} />;
  }

  return (
    <S.Container>
      <PlaylegisLogoYellow />
      <h1>Acesso negado</h1>
      <h2>
        {`Você não tem permissão para acessar essa página e será direcionado em ${counter} segundos...`}
      </h2>
      <Link to="/">Página inicial</Link>
    </S.Container>
  );
};

export { ForbiddenPage };
