import { useEffect, useState } from 'react';
import { FaArrowLeft, FaPlus, FaFilter } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Filters } from 'models/Filters';
import StudentRequest from 'models/StudentRequest';
import {
  fetchStudentsReqRequest,
  sendStudentMessageRequest,
  updateStudentReqRequest
} from 'store/ducks/studentRequest/actions';
import { PageHeader, Button } from 'components';
import { filterInitialState } from 'helpers/filters';
import QueryBuilder from 'helpers/queryBuilder';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { StudentsDisplayMode } from 'helpers/types/StudentsDisplayMode';
import {
  StudentRequestActionSidebar,
  StudentRequestContactSidebar,
  StudentRequestDataTable,
  StudentRequestFilterSidebar,
  StudentRequestInformationTable
} from './components';
import * as S from './StudentRequestPage.styles';

const studentRequestInitialState: StudentRequest = {
  id: 0,
  hubId: '',
  comments: '',
  files: [
    {
      url: '',
      fileName: ''
    }
  ],
  status: 'PENDING',
  subscriptionId: 0,
  reasonRepprove: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  userName: '',
  userEmail: ''
};

const initialFilters = {
  startsIn: new Date(Date.now() - 3.154e10).toISOString(),
  endsIn: new Date(Date.now()).toISOString(),
  status: 'ALL'
};

const StudentRequestPage = () => {
  const dispatch = useDispatch();

  const studentRequests = useSelector(
    (state: ApplicationState) => state.studentRequest.all
  );

  const [approved, setApproved] = useState<StudentRequest[]>([]);
  const [disapproved, setDisapproved] = useState<StudentRequest[]>([]);
  const [pending, setPending] = useState<StudentRequest[]>([]);

  const [selectedStudent, setSelectedStudent] = useState<StudentRequest>(
    studentRequestInitialState
  );
  const [selectedFilters, setSelectedFilters] =
    useState<Filters>(filterInitialState);

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');
  const [contactDrawerMode, setContactDrawerMode] =
    useState<DrawerMode>('CLOSE');
  const [filterDrawerMode, setFilterDrawerMode] = useState<DrawerMode>('CLOSE');
  const [displayMode, setDisplayMode] =
    useState<StudentsDisplayMode>('ALL_STUDENTS');

  useEffect(() => {
    setApproved(studentRequests.filter((req) => req.status === 'APPROVED'));
    setDisapproved(
      studentRequests.filter((req) => req.status === 'DISAPPROVED')
    );
    setPending(studentRequests.filter((req) => req.status === 'PENDING'));
  }, [studentRequests]);

  useEffect(() => {
    dispatch(fetchStudentsReqRequest(initialFilters));
  }, [dispatch]);

  // Handlers de estado
  const handleSavePressed = (student: StudentRequest) => {
    const serializedFilters = QueryBuilder.qobj(selectedFilters as any);

    dispatch(updateStudentReqRequest(student, serializedFilters as any));
  };

  const handleApplyPressed = (filters: Filters) => {
    const serializedFilters = QueryBuilder.qobj(filters as any);

    dispatch(fetchStudentsReqRequest(serializedFilters as any));
    setSelectedFilters(filters);
  };

  const handleCleanPress = () => {
    dispatch(fetchStudentsReqRequest(initialFilters));
    setSelectedFilters({ ...filterInitialState });
    setFilterDrawerMode('CLOSE');
  };

  // Handlers de botões e ações
  const handleNavigation = () => {
    switch (displayMode) {
      case 'ONE_STUDENT':
        setDisplayMode('ALL_STUDENTS');
        break;
      default:
        break;
    }
  };

  const handleFilterPressed = () => {
    setFilterDrawerMode('OPEN_FILTER');
  };

  const handleEditPressed = (student: StudentRequest) => {
    setSelectedStudent(student);
    setDrawerMode('OPEN_EDIT');
  };

  const handleDoubleClick = (data: any) => {
    switch (displayMode) {
      case 'ALL_STUDENTS':
        setSelectedStudent(data);
        setDisplayMode('ONE_STUDENT');
        break;
      default:
        break;
    }
  };

  const handleContactClick = (student: StudentRequest) => {
    setSelectedStudent(student);
    setContactDrawerMode('OPEN_ADD');
  };

  const handleContactSendClick = (student: StudentRequest, message: string) => {
    dispatch(sendStudentMessageRequest(student, message));
    setSelectedStudent(studentRequestInitialState);
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={() => handleNavigation()}
      disabled={displayMode === 'ALL_STUDENTS'}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  const getActionButtons = () => (
    <S.Row>
      <Button
        type="button"
        severity="primary"
        size="big"
        onClick={handleFilterPressed}
        disabled={displayMode === 'ONE_STUDENT'}
      >
        <FaFilter />
        Filtrar
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <StudentRequestActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedStudentRequest={selectedStudent}
          onConfirmPress={handleSavePressed}
        />

        <StudentRequestContactSidebar
          mode={contactDrawerMode}
          setMode={setContactDrawerMode}
          selectedStudentRequest={selectedStudent}
          onConfirmPress={handleContactSendClick}
        />

        <StudentRequestFilterSidebar
          mode={filterDrawerMode}
          setMode={setFilterDrawerMode}
          selectedFilters={selectedFilters}
          onConfirmPress={handleApplyPressed}
          onCleanPress={handleCleanPress}
        />

        <S.Container>
          <PageHeader
            title="Estudantes"
            breadcrumbsBottomComponent={getBackButton()}
            actionsComponent={getActionButtons()}
          />

          {displayMode === 'ALL_STUDENTS' ? (
            <>
              <S.DataTableTitle>Solicitações pendentes</S.DataTableTitle>
              <StudentRequestDataTable
                data={pending}
                onPressItem={handleEditPressed}
                onContactClick={handleContactClick}
                onDoubleClick={handleDoubleClick}
                requestStatus="PENDING"
              />

              <S.DataTableTitle>Solicitações aprovadas</S.DataTableTitle>
              <StudentRequestDataTable
                data={approved}
                onPressItem={handleEditPressed}
                onContactClick={handleContactClick}
                onDoubleClick={handleDoubleClick}
                requestStatus="APPROVED"
              />

              <S.DataTableTitle>Solicitações negadas</S.DataTableTitle>
              <StudentRequestDataTable
                data={disapproved}
                onPressItem={handleEditPressed}
                onContactClick={handleContactClick}
                onDoubleClick={handleDoubleClick}
                requestStatus="DISAPPROVED"
              />
            </>
          ) : (
            <StudentRequestInformationTable data={selectedStudent} />
          )}
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { StudentRequestPage };
