import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptionsRequest } from 'store/ducks/subscriptions/actions';
import { ActivatableDateInterval, Button, DateInterval } from 'components';
import { Select } from 'components/Form';
import dateUtils from 'helpers/date';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './SubscriptionsReportFilterBar.styles';
import {
  SubscriptionFilterStatus,
  statusOptions
} from './SubscriptionsReportFilterBar.utils';

export const SubscriptionFilterBar = () => {
  const dispatch = useDispatch();

  const plans = useSelector((state: ApplicationState) => state.plan.plans);
  const institutions = useSelector(
    (state: ApplicationState) => state.teachingInstitution.all
  );

  const { getInitialEndDate, getInitialStartDate, convertDateToString } =
    dateUtils;

  const [useFilterDate, setUseDateFilter] = useState(false);
  const [selectedStatus, setSelectedStatus] =
    useState<SubscriptionFilterStatus>('');
  const [selectedPlanId, setSelectedPlanId] = useState<number>(0);
  const [selectedTeachingInstitutionId, setSelectedTeachingInstitutionId] =
    useState<number>(0);
  const [startsIn, setStartsIn] = useState(
    convertDateToString(getInitialStartDate())
  );
  const [endsIn, setEndsIn] = useState(
    convertDateToString(getInitialEndDate())
  );

  const queryBuilder = () => {
    const query = [];

    if (useFilterDate && startsIn && endsIn) {
      query.push(`startsIn=${startsIn}&endsIn=${endsIn}`);
    }

    if (selectedPlanId) {
      query.push(`planId=${selectedPlanId}`);
    }

    if (selectedStatus) {
      query.push(`status=${selectedStatus}`);
    }

    if (selectedTeachingInstitutionId) {
      query.push(`teachingInstitutionId=${selectedTeachingInstitutionId}`);
    }

    return query.reduce(
      (prev, current) =>
        prev ? prev.concat('&'.concat(current)) : prev.concat(current),
      ''
    );
  };

  const search = () => {
    const query = queryBuilder();
    dispatch(fetchSubscriptionsRequest(query));
  };

  const getPlanOptions = () => [{ id: null, name: 'Todos' }, ...plans];
  const getInstitutionOptions = () => [
    { id: null, name: 'Todos' },
    ...institutions
  ];

  return (
    <S.FilterContainer>
      <S.FilterElementsContainer>
        <S.CalendarWrapper>
          <ActivatableDateInterval
            startsIn={startsIn}
            onStartsInChange={setStartsIn}
            endsIn={endsIn}
            onEndsInChange={setEndsIn}
            disable={!useFilterDate}
            containerDirection="row"
            isActive={useFilterDate}
            onActiveChange={setUseDateFilter}
            label="Filtrar por data:"
          />
        </S.CalendarWrapper>

        <S.DropdownWrapper>
          <Select
            label="Status"
            name="subscriptionStatus"
            placeholder="Todos"
            data={statusOptions}
            dataId="id"
            dataValue="value"
            selected={selectedStatus}
            onSelection={setSelectedStatus}
          />
        </S.DropdownWrapper>

        <S.DropdownWrapper>
          <Select
            label="Plano"
            name="subscriptionPlan"
            placeholder="Todos"
            data={getPlanOptions()}
            dataId="id"
            dataValue="name"
            selected={selectedPlanId}
            onSelection={setSelectedPlanId}
          />
        </S.DropdownWrapper>

        <S.DropdownWrapper>
          <Select
            label="Instituição de ensino"
            name="institution"
            placeholder="Todas"
            data={getInstitutionOptions()}
            dataId="id"
            dataValue="name"
            selected={selectedTeachingInstitutionId}
            onSelection={setSelectedTeachingInstitutionId}
            searchable
            searchPlaceholder="Buscar"
          />
        </S.DropdownWrapper>
      </S.FilterElementsContainer>

      <Button type="button" severity="confirm" onClick={search}>
        <FaSearch />
        Filtrar
      </Button>
    </S.FilterContainer>
  );
};
