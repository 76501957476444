import { CSVLink } from 'react-csv';
import styled from 'styled-components';

export const CsvLinkWrapper = styled(CSVLink)`
  display: flex;
  align-items: center;
  justify-items: center;
  color: #fff;
  font-size: 12px;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.2s, color 0.2s;

  i {
    margin-right: 4px;
  }

  &.btn-primary {
    color: ${({ theme }) => theme.btn.primary.color};
    background-color: ${({ theme }) => theme.btn.primary.bg};
  }

  &.btn-secondary {
    color: ${({ theme }) => theme.btn.secondary.color};
    background-color: ${({ theme }) => theme.btn.secondary.bg};
  }

  &.btn-confirm {
    background-color: ${({ theme }) => theme.green};
  }

  &.btn-warning {
    background-color: ${({ theme }) => theme.red};
  }

  &.btn-info {
    color: ${({ theme }) => theme.btn.info.color};
    background-color: ${({ theme }) => theme.btn.info.bg};

    &:hover {
      color: ${({ theme }) => theme.btn.info.hover};
    }
  }

  &.btn-primary,
  &.btn-secondary,
  &.btn-confirm,
  &.btn-warning {
    &:hover {
      color: #fff;
      background-color: ${({ theme }) => theme.blueLighter};
    }
  }

  &.btn-close {
    color: ${({ theme }) => theme.btn.primary.bg};
    padding: 0px;
    font-size: 24px;

    &:hover {
      color: ${({ theme }) => theme.blueLighter};
    }
  }

  &.big {
    padding: 12px 24px;
    font-size: 14px;

    svg {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  &.noMargin {
    svg {
      margin: 0px;
    }
  }

  &:disabled,
  &:disabled:hover {
    color: ${({ theme }) => theme.btn.disabled.color};
    background-color: ${({ theme }) => theme.btn.disabled.bg};
  }
`;
