import { Reducer } from 'redux';
import LoginResponse, { LoginInfo } from 'models/Login';
import { LoginTypes, LoginState } from './types';

export type Action = {
    type: LoginTypes;
    payload: {
      info: LoginInfo;
      data: LoginResponse;
      navigate: any;
    }
};

export const INITIAL_STATE: LoginState = {
  info: {
    email: '',
    password: '',
  },
  data: {
    token: '',
    expireIn: new Date(),
    admin: false,
  },
};

const reducer: Reducer<LoginState, Action> = (
  state: LoginState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case LoginTypes.LOGIN_REQUEST: {
    return {
      ...state,
      info: action.payload.info,
    };
  }
  case LoginTypes.LOGIN_SUCCESS: {
    return {
      ...state,
      data: action.payload.data,
    };
  }
  default: {
    return state;
  }
  }
};

export default reducer;
