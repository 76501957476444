import styled from 'styled-components';

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;

  h2 {
    flex-grow: 2;
    text-align: center;
    font-family: Mulish, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  button {
    flex-grow: 0;
  }
`;
