import styled from 'styled-components';
import { ContentLayout } from 'components';
import { H1, H3 } from 'components/Styled/Text';
import * as Breakpoint from 'styles/breakpoints';

export const ContainerFluid = styled.div`
  ${Breakpoint.Fluid};
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Container = styled(ContentLayout)``;

export const Row = styled.div`
  display: flex;
  height: auto;

  &.headerRow {
    margin-bottom: 32px;
  }

  &.buttonGroup {
    align-items: flex-end;
    flex-grow: 1;

    a:disabled {
      display: none;
    }

    a:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1024px) {
    &.dashboard-page__financial {
      flex-direction: column;
    }
  }

  @media screen and (min-width: 1025px) {
    &.dashboard-page__financial {
      .card__card-column:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
