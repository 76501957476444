import { NewsArticle } from 'models/Institutional';

export const articleInitialState: NewsArticle = {
  id: 0,
  title: '',
  description: '',
  coverFilename: '',
  coverUrl: '',
  publish: false,
  createdAt: new Date(),
  updatedAt: new Date(),
}