import styled from 'styled-components';
import { ContentLayout } from 'components';
import { H1 } from 'components/Styled/Text';
import * as Breakpoint from 'styles/breakpoints';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &.addButtonColumn {
    align-items: flex-end;
  }

  &.buttonGroup {
    flex-grow: 1;
    align-items: flex-end;

    button:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const ContainerFluid = styled.div`
  ${Breakpoint.Fluid};
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  height: 100%;

  &.headerRow {
    margin-bottom: 32px;
  }

  &.noHeight {
    height: auto;
  }
`;

export const Container = styled(ContentLayout)``;

export const Title = styled(H1)``;
