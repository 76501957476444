import { useState } from 'react';
import { FaSave, FaTimes, FaSync, FaFilePdf } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';
import FileService from 'services/FileService';
import * as Yup from 'yup';
import { showToast } from 'store/ducks/toast/actions';
import { Button, ProgressUpload, Drawer } from 'components';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './SubscriberImportSidebar.styles';

interface SubscriberImportSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;

  onCancelPress?: () => void;
}

const SubscriberImportSidebar = (props: SubscriberImportSidebarProps) => {
  const { mode, setMode, onCancelPress } = props;

  const dispatch = useDispatch();

  const [file, setFile] = useState<File | null>(null);
  const [visibleProgressBar, setVisibleProgressBar] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const handleConfirmPress = () => {
    try {
      if (file !== null) {
        setVisibleProgressBar(true);

        new FileService()
          .importSubscribersFromCsv(file, setProgressValue)
          .then(() => {
            setMode('CLOSE');
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => {
            setVisibleProgressBar(false);
          });
      } else {
        setMode('CLOSE');
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  const onUploadSingleFile = (e: any) => {
    setFile(e.files[0]);
  };

  const handleChangeChooseLabel = () => {
    if (file !== null) {
      return file.name;
    }
    return 'Adicionar arquivo';
  };

  return (
    <>
      <ProgressUpload
        progressValue={progressValue}
        visibleProgressBar={visibleProgressBar}
      />

      <Drawer
        title="Importar assinantes"
        drawerMode={mode}
        onBackDropPress={handleCancelPress}
      >
        <S.ImportWrapper>
          <S.FileUploadWrapper>
            <FileUpload
              mode="basic"
              name="FileUpload"
              customUpload
              accept="*/*"
              uploadHandler={onUploadSingleFile}
              chooseOptions={{
                icon: 'pi pi-replay',
                label: handleChangeChooseLabel()
              }}
              auto
            />
          </S.FileUploadWrapper>

          <S.DownloadButton
            title="Modelo de arquivo para importação"
            href="/files/modelo-importacao.csv"
            download
          >
            <FaFilePdf />
            Baixar modelo de arquivo
          </S.DownloadButton>

          <S.ButtonGroup>
            <Button
              type="button"
              size="big"
              severity="confirm"
              onClick={handleConfirmPress}
            >
              <FaSave />
              Salvar
            </Button>

            <Button
              type="button"
              size="big"
              severity="danger"
              onClick={handleCancelPress}
            >
              <FaTimes />
              Cancelar
            </Button>
          </S.ButtonGroup>
        </S.ImportWrapper>
      </Drawer>
    </>
  );
};

SubscriberImportSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { SubscriberImportSidebar };
