export enum StoreModule {
  ANALYTICS = 'ANALYTICS',
  AUDIO = 'AUDIO',
  CURRENTFOLDER = 'CURRENTFOLDER',
  DIALOG = 'DIALOG',
  FOLDER = 'FOLDER',
  GROWL = 'GROWL',
  INSTITUTIONALABOUTUS = 'INSTITUTIONALABOUTUS',
  INSTITUTIONALCONTACT = 'INSTITUTIONALCONTACT',
  INSTITUTIONALFAQ = 'INSTITUTIONALFAQ',
  INSTITUTIONALHOME = 'INSTITUTIONALHOME',
  INSTITUTIONALNEWS = 'INSTITUTIONALNEWS',
  INSTITUTIONALTERMS = 'INSTITUTIONALTERMS',
  LOADING = 'LOADING',
  LOGIN = 'LOGIN',
  LOGS = 'LOGS',
  NAVIGATOR = 'NAVIGATOR',
  PLAN = 'PLAN',
  PLAYLIST = 'PLAYLIST',
  PLAYLISTCATEGORY = 'PLAYLISTCATEGORY',
  PROMOTION = 'PROMOTION',
  QUIZALTERNATIVES = 'QUIZALTERNATIVES',
  QUIZQUESTIONS = 'QUIZQUESTIONS',
  QUIZZES = 'QUIZZES',
  STUDENTREQUEST = 'STUDENTREQUEST',
  SUBSCRIBER = 'SUBSCRIBER',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  SUPERCATEGORY = 'SUPERCATEGORY',
  TEACHINGINSTITUTION = 'TEACHINGINSTITUTION',
  THEME = 'THEME',
  TRASH = 'TRASH',
  USERADMIN = 'USERADMIN',
  USERAPP = 'USERAPP'
}
