import { FormEvent, useEffect, useRef, useState } from 'react';
import { FaCamera } from 'react-icons/fa';
import classNames from 'classnames';
import { InputSwitch } from 'primereact/inputswitch';
import { NewsArticle } from 'models/Institutional';
import { RichTextEditor, Switch } from 'components';
import { InputText } from 'components/Form';
import * as S from './NewsEditor.styles';

interface NewsEditorProps {
  article: NewsArticle;
  setArticle: React.Dispatch<React.SetStateAction<NewsArticle>>;
}

const NewsEditor = (props: NewsEditorProps) => {
  const { article, setArticle } = props;

  const [publishLabel, setPublishLabel] = useState('Publicar');
  const [cover, setCover] = useState<File>();
  const [preview, setPreview] = useState<string>('');

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (cover) {
      setPreview(URL.createObjectURL(cover));
      setArticle((a) => ({
        ...a,
        cover
      }));
    }
  }, [cover]);

  useEffect(() => {
    if (article.coverUrl && !cover) {
      setPreview(article.coverUrl);
      setCover(undefined);
    }
  }, [article.coverUrl, cover]);

  useEffect(() => {
    if (article.publish) {
      setPublishLabel('Publicar');
    } else {
      setPublishLabel('Rascunho');
    }
  }, [article.publish]);

  const onUploadSingleFile = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setCover(e.currentTarget.files[0]);
    }
  };

  const handleSwitchChange = () => {
    setArticle((a) => ({
      ...a,
      publish: !a.publish
    }));
  };

  return (
    <S.EditorWrapper>
      <S.FormGroup>
        <S.Fieldset>
          <label htmlFor="articleTitle">Título</label>
          <InputText
            id="articleTitle"
            name="articleTitle"
            title="Título do artigo"
            value={article.title}
            onChange={(e) => setArticle({ ...article, title: e.target.value })}
          />
        </S.Fieldset>
      </S.FormGroup>

      <S.FormGroup>
        <S.Fieldset>
          <label htmlFor="articleDescription">Texto</label>
          <RichTextEditor
            id="articleDescription"
            value={article.description}
            onTextChange={(ev) =>
              setArticle({
                ...article,
                description: ev.htmlValue || ''
              })
            }
            style={{ height: '320px' }}
          />
        </S.Fieldset>

        <S.FormGroup $column>
          <S.CoverFieldset>
            <h2>Capa</h2>

            <S.CoverInput>
              <label
                className={classNames('article-cover__label', {
                  'has-cover': preview !== ''
                })}
                htmlFor="articleCover"
                title="Clique para trocar a capa"
              >
                <FaCamera />
                <input
                  id="articleCover"
                  type="file"
                  accept="image/*"
                  onChange={onUploadSingleFile}
                  ref={fileInputRef}
                  required
                />
              </label>

              <div
                className="article-cover__preview"
                style={{
                  backgroundImage: `url(${preview})`
                }}
              />
            </S.CoverInput>
          </S.CoverFieldset>

          <S.Fieldset>
            <label>Visibilidade</label>

            <Switch
              id="publishToggler"
              leftSideLabel={publishLabel}
              checked={article.publish}
              onCheck={handleSwitchChange}
            />
          </S.Fieldset>
        </S.FormGroup>
      </S.FormGroup>
    </S.EditorWrapper>
  );
};

export { NewsEditor };
