import styled from 'styled-components';
import { DataTableLayout } from 'components';

export const DataTableLayoutWrapper = styled(DataTableLayout)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export { ActionButtons } from 'components/DataTableLayout/DataTableLayout.styles';
