import * as yup from 'yup';
import Audio from 'models/Audio';

const audioSchema = yup.object().shape({
  title: yup.string().required('O campo "NOME" é obrigatório!'),
  description: yup.string().required('O campo "DESCRIÇÃO" é obrigatório!'),
  transcription: yup.string().required('O campo "TRANSCRIÇÃO" é obrigatório!'),
});

export default (audio:Audio) => audioSchema.validateSync(audio, { abortEarly: false });
