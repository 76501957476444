import classNames from 'classnames';
import * as S from './ExportPdfButton.styles';
import { ExportPdfButtonProps } from './interfaces/ExportPdfButtonProps';

const ExportPdfButton = (props: ExportPdfButtonProps) => {
  const { document, fileName, disabled, big, dataLoading } = props;

  return (
    <S.PdfDownloadLink
      className={classNames({
        'btn-disabled': disabled,
        'btn-small': !big,
        'btn-big': big
      })}
      document={document}
      fileName={`${fileName}.pdf`}
    >
      {({ loading }) =>
        loading || dataLoading ? (
          <>
            <i className="pi pi-spin pi-spinner" />
            Carregando...
          </>
        ) : (
          <>
            <i className="pi pi-file-pdf" />
            Exportar PDF
          </>
        )
      }
    </S.PdfDownloadLink>
  );
};

ExportPdfButton.defaultProps = {
  fileName: 'generatedPdf',
  disabled: false,
  big: false
};

export { ExportPdfButton };
