import { DiscountValueTypeEnum } from 'helpers/enum/DiscountValueTypeEnum';
import { PromotionState } from './types';

const get7DaysAhead = () => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  return date;
};

export const PROMOTION_PLAN_INITIAL_STATE: PromotionState = {
  promotions: [],
  promotion: {
    id: 0,
    discount: 0,
    discountType: DiscountValueTypeEnum.PERCENT,
    startsIn: new Date(),
    endsIn: get7DaysAhead(),
    promoPeriod: 0,
    status: false,
    usedQuantity: 0,
    totalQuantity: 0,
    promoCode: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    name: '',
    description: ''
  }
};
