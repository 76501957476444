import { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import PlaylistCategory from 'models/PlaylistCategory';
import SuperCategory from 'models/SuperCategory';
import { fetchSuperCategoryRequest } from 'store/ducks/superCategory/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, InputRadio, InputTextarea, Select } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import categoryValidate from 'helpers/validation/category';
import * as S from './CategoryActionSidebar.styles';

interface CategoryActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  category: PlaylistCategory;
  onConfirmPress?: (category: PlaylistCategory) => void;
  onCancelPress?: () => void;
}

const CategoryActionSidebar = (props: CategoryActionSidebarProps) => {
  const { mode, setMode, onConfirmPress, onCancelPress } = props;
  const [category, setCategory] = useState<PlaylistCategory>(props.category);

  const dispatch = useDispatch();

  const superCategory = useSelector(
    (state: ApplicationState) => state.superCategory.data
  );

  const [superCategorySelected, setSuperCategorySelected] =
    useState<SuperCategory>();

  useEffect(() => {
    setCategory(props.category);
  }, [props.category]);

  useEffect(() => {
    dispatch(fetchSuperCategoryRequest());
  }, [dispatch]);

  useEffect(() => {
    if (superCategory.length > 0) {
      setSuperCategorySelected(
        superCategory.find((item) => item.id === category.superCategoryId) ||
          superCategory[0]
      );
      setCategory({
        ...category,
        superCategoryId:
          superCategory.find((item) => item.id === category.superCategoryId)
            ?.id || superCategory[0].id
      });
    }
  }, [category.superCategoryId, superCategory]); // eslint-disable-line

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar nova categoria';
        break;
      case 'OPEN_EDIT':
        title = 'Editar categoria';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      categoryValidate(category);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((err_, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: err_.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    if (onConfirmPress) {
      onConfirmPress(category);
    }

    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) {
      onCancelPress();
    }

    setMode('CLOSE');
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Nome"
          name="name"
          type="text"
          value={category.name}
          onChange={(e) =>
            setCategory({
              ...category,
              name: e.target.value
            })
          }
        />

        <InputTextarea
          label="Descrição"
          name="description"
          rows={5}
          cols={30}
          value={category.description}
          onChange={(e) =>
            setCategory({
              ...category,
              description: e.currentTarget.value
            })
          }
        />

        <Select
          label="Categoria principal"
          name="superCategory"
          placeholder="Selecione a categoria principal"
          data={superCategory}
          dataId="id"
          dataValue="name"
          selected={superCategorySelected?.id || 0}
          onSelection={(id) => {
            setSuperCategorySelected(id);
            setCategory({ ...category, superCategoryId: id || 0 });
          }}
          disabled={category.id !== 0}
        />

        <S.InputRadioGroup>
          <span className="radioGroupLabel">Mostrar na Home</span>

          <InputRadio
            label="Sim"
            name="showInHome"
            value="yes"
            onClick={() =>
              setCategory({
                ...category,
                showInHome: true
              })
            }
            checked={category.showInHome === true}
          />

          <InputRadio
            label="Não"
            name="showInHome"
            value="no"
            onClick={() =>
              setCategory({
                ...category,
                showInHome: false
              })
            }
            checked={category.showInHome === false}
          />
        </S.InputRadioGroup>

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
            label="Salvar"
            icon="pi pi-save"
            className="p-button-outlined"
          />

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
            label="Cancelar"
            icon="pi pi-times"
            className="p-button-outlined"
          />
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

CategoryActionSidebar.defaultProps = {
  onConfirmPress: () => false,
  onCancelPress: () => false
};

export { CategoryActionSidebar };
