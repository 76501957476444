import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    margin-bottom: 32px;
  }

  h1 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Mulish', sans-serif;
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 18px;
  }

  h2 {
    font-size: 16px;
    font-family: 'Mulish', sans-serif;
    color: ${({ theme }) => theme.text.secondary};
    margin-bottom: 32px;
  }

  a {
    border: 1px solid ${({ theme }) => theme.table.border};
    padding: 10px 30px;
    border-radius: 5px;
    transition: all 0.3s;

    &:hover {
      background: ${({ theme }) => theme.yellow};
      color: ${({ theme }) => theme.text.primary};
      border-color: ${({ theme }) => theme.yellow};
    }
  }
`;
