import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Subscription from 'models/Subscription';
import { DataTableLayout } from 'components';
import { DataTableColumnSelector } from 'components/DataTableColumnSelector/DataTableColumnSelector';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import money from 'helpers/money';
import ApplicationState from 'helpers/types/ApplicationState';

const columns: ColumnProps[] = [
  { field: 'cpf', header: 'CPF' },
  { field: 'userEmail', header: 'Email' },
  { field: 'phone', header: 'Telefone' },
  { field: 'planName', header: 'Plano' },
  { field: 'value', header: 'Valor do plano' }
];

const SubscriptionDataTable = () => {
  const { subscriptions } = useSelector(
    (state: ApplicationState) => state.subscriptions
  );

  const [selectedColumns, setSelectedColumns] =
    useState<ColumnProps[]>(columns);

  const handleTranslateSubscriptionStatus = (input: string) => {
    switch (input) {
      case 'ENABLED':
        return 'Ativa';
      case 'CANCELED':
        return 'Cancelada';
      case 'IN_TEST':
        return 'Em teste';
      case 'OVERDUE':
        return 'Pagamento em atraso';
      default:
        return '-';
    }
  };

  const getSubscriptionStatusBody = (subscription: Subscription) => (
    <span>{handleTranslateSubscriptionStatus(subscription.status)}</span>
  );

  const getCreatedAtBody = (subscription: Subscription) => (
    <span>{moment(subscription.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const getUserEmailBody = (subscription: Subscription) =>
    subscription.userEmail;

  const getUserPlanValueBody = (subscription: any) => money(subscription.value);

  const selectableColumns = useMemo(
    () =>
      selectedColumns.map((column) => {
        let props: ColumnProps;

        switch (column.field) {
          case 'cpf':
            props = {
              filter: true,
              filterPlaceholder: 'Pesquisar',
              sortable: true
            };
            break;
          case 'userEmail':
            props = {
              filter: true,
              filterPlaceholder: 'Pesquisar',
              body: getUserEmailBody,
              sortable: true
            };
            break;
          case 'phone':
            props = {
              filter: true,
              filterPlaceholder: 'Pesquisar',
              sortable: true
            };
            break;
          case 'planName':
            props = {
              sortable: true
            };
            break;
          case 'value':
            props = {
              sortable: true,
              body: getUserPlanValueBody
            };
            break;
          default:
            props = {};
            break;
        }

        return (
          <Column
            key={column.field}
            field={column.field}
            header={column.header}
            {...props}
          />
        );
      }),
    [selectedColumns]
  );

  const dataTableHeader = useMemo(
    () => (
      <DataTableColumnSelector
        options={columns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
    ),
    [selectedColumns]
  );

  return (
    <DataTableLayout>
      <DataTable
        header={dataTableHeader}
        value={subscriptions}
        sortField="userName"
        sortOrder={1}
        selectionMode="single"
        filterDisplay="row"
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={subscriptions.length}
        rowsPerPageOptions={[10, 15, 20]}
        resizableColumns
        columnResizeMode="fit"
      >
        <Column
          field="userName"
          header="Cliente"
          filter
          filterPlaceholder="Buscar"
          sortable
        />

        {selectableColumns}

        <Column
          field="subscriptionStatus"
          header="Status"
          body={getSubscriptionStatusBody}
          sortable
        />

        <Column
          field="createdAt"
          header="Cadastrado em"
          body={getCreatedAtBody}
          sortable
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { SubscriptionDataTable };
