import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import { fetchSubscriptionsSuccess, fetchSubscriptionsError } from './actions';

export function* fetchSubscription(action: any) {
  try {
    yield put(addLoadingModule(StoreModule.SUBSCRIPTIONS));

    const { query } = action.payload;

    const response: ConnectorResponse = yield call(
      Connector().get,
      `/analytics/all-subscription${query ? `?${query}` : ''}`
    );

    yield put(fetchSubscriptionsSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Assinaturas',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchSubscriptionsError());
  } finally {
    yield put(removeLoadingModule(StoreModule.SUBSCRIPTIONS));
  }
}
