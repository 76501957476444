import styled from 'styled-components';

export const DateIntervalContainer = styled.div`
  display: flex;
  /* width: 100%; */
  justify-content: center;
  align-items: center;

  input {
    &:first-of-type {
      margin-left: 10px;
    }

    &:last-of-type {
      margin-right: 10px;
    }
  }

  input {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text.primary};
    outline: 0;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    &:focus {
      box-shadow: 0;
    }
  }
`;

export const DateIntervalMiddleTerm = styled.span`
  margin: 0px 8px;
  font-size: 14px;
`;
