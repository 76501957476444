import { Reducer } from 'redux';
import { PromotionTypes, PromotionState, PromotionAction } from './types';
import { PROMOTION_PLAN_INITIAL_STATE } from './utils';

const reducer: Reducer<PromotionState, PromotionAction> = (
  state: PromotionState = PROMOTION_PLAN_INITIAL_STATE,
  action: PromotionAction
) => {
  switch (action.type) {
    case PromotionTypes.FETCH_ALL_PROMOTIONS_REQUEST: {
      return state;
    }

    case PromotionTypes.FETCH_ALL_PROMOTIONS_SUCCESS: {
      return {
        ...state,
        promotions: action.payload.promotions
      };
    }

    case PromotionTypes.CREATE_PROMOTION_REQUEST: {
      return {
        ...state,
        promotion: action.payload.promotion
      };
    }

    case PromotionTypes.EDIT_PROMOTION_REQUEST: {
      return {
        ...state,
        promotion: action.payload.promotion
      };
    }

    case PromotionTypes.DELETE_PROMOTION_REQUEST: {
      return {
        ...state,
        promotionId: action.payload.promotionId
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
