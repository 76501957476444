import { FaTimesCircle, FaFileAudio } from 'react-icons/fa';
import { FileDragAndDropListProps } from '../../interfaces/FileDragAndDropListProps';
import * as S from './FileDragAndDropList.styles';

const FileDragAndDropList = (props: FileDragAndDropListProps) => {
  const { audios, onRemovePress } = props;

  return (
    <S.FileDragAndropListContainer
      className={audios.length > 0 ? 'isFilled' : 'isEmpty'}
    >
      {audios.map((file, index) => (
        <li key={file.name + index.toString()}>
          <i>
            <FaFileAudio />
          </i>
          <div>
            <span className="title">{file.name}</span>
            <span className="size">{`${file.size / 1000} kb`}</span>
          </div>
          <button type="button" onClick={() => onRemovePress(file)}>
            <FaTimesCircle />
          </button>
        </li>
      ))}
    </S.FileDragAndropListContainer>
  );
};

export { FileDragAndDropList };
