import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FaCloudUploadAlt } from 'react-icons/fa';
import classNames from 'classnames';
import { FileDragAndDropTargetBoxProps } from 'components/FileDragAndDrop/interfaces/FileDragAndDropTargetBoxProps';
import * as S from './FileDragAndDropTargetBox.styles';

const FileDragAndDropTargetBox = (props: FileDragAndDropTargetBoxProps) => {
  const { onDrop } = props;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(_, monitor) {
      if (onDrop) {
        onDrop(props, monitor);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  const getLabel = () =>
    isActive ? 'Solte para carregar' : 'Arraste os audios';

  return (
    <S.FileDragAndDropTargetBoxContainer
      ref={drop}
      className={classNames({
        isActive
      })}
    >
      <FaCloudUploadAlt className="dndIcon" />
      <span className="dndLabel">{getLabel()}</span>
      <span>Máx. 120 arquivos</span>
    </S.FileDragAndDropTargetBoxContainer>
  );
};

export { FileDragAndDropTargetBox };
