import Subscription from 'models/Subscription';

const getTranslatedSubscriptionStatus = (status: string) => {
  switch (status) {
    case 'CANCELED':
      return 'Cancelada';
    case 'ENABLED':
      return 'Ativa';
    case 'IN_TEST':
      return 'Em teste';
    case 'OVERDUE':
      return 'Pagamento atrasado';
    default:
      return 'Ativa';
  }
};

const getTranslatedFields = (subscriptions: Subscription[]) =>
  subscriptions.map((item) => ({
    Usuário: item.userName,
    CPF: item.cpf,
    Email: item.userEmail,
    Telefone: item.phone,
    Plano: item.planName,
    ValorDoPlano: item.value,
    DataDeCriação: item.createdAt,
    StatusDaAssinatura: getTranslatedSubscriptionStatus(item.status)
  }));

export { getTranslatedFields, getTranslatedSubscriptionStatus };
