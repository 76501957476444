import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.table.border};
  border-radius: 4px;
  margin-top: 18px;
`;

export const Timer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Buttons = styled.div`
  display: flex;
  flex-grow: 0;

  button:not(:last-child) {
    margin-right: 12px;
  }
`;
