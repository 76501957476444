import { MultiSelectChangeParams } from 'primereact/multiselect';
import { MultiSelect } from '../MultiSelect/MultiSelect';
import { DataTableColumnSelectorProps } from './interfaces/DataTableColumnSelectorProps';

const DataTableColumnSelector = (props: DataTableColumnSelectorProps) => {
  const { options, selectedColumns, setSelectedColumns } = props;

  const handleChange = (event: MultiSelectChangeParams) => {
    const selectedColumns = event.value;

    const orderedSelectedColumns = options.filter((column: any) =>
      selectedColumns.some(
        (selectedColumn: any) => selectedColumn.field === column.field
      )
    );

    setSelectedColumns(orderedSelectedColumns);
  };

  return (
    <MultiSelect
      label="Colunas vísíveis:"
      placeholder="Selecione as colunas"
      emptySelection="Selecionar colunas"
      options={options}
      optionLabel="header"
      onChange={handleChange}
      value={selectedColumns}
      labelDirection="side"
    />
  );
};

export { DataTableColumnSelector };
