import { StoreModule } from 'helpers/enum/StoreModule';

export enum LoadingTypes {
  ADD_LOADING_MODULE = '@loading/ADD_LOADING_MODULE',
  REMOVE_LOADING_MODULE = '@loading/REMOVE_LOADING_MODULE'
}
export interface LoadingState {
  readonly modules: StoreModule[];
}

export type LoadingAction = {
  type: LoadingTypes;
  payload: LoadingState & {
    latestModule: StoreModule;
  };
};
