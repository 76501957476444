import { Banner, Differential, Testimonial } from 'models/Institutional';

export enum InstitutionalHomeTypes {
  FETCH_HOME_DATA = '@institutionalHome/FETCH_HOME_DATA',

  TESTIMONIAL_CREATE = '@institutionalHome/TESTIMONIAL_CREATE',
  TESTIMONIAL_EDIT = '@institutionalHome/TESTIMONIAL_EDIT',
  TESTIMONIAL_REMOVE = '@institutionalHome/TESTIMONIAL_REMOVE',

  DIFFERENTIAL_CREATE = '@institutionalHome/DIFFERENTIAL_CREATE',
  DIFFERENTIAL_EDIT = '@institutionalHome/DIFFERENTIAL_EDIT',
  DIFFERENTIAL_REMOVE = '@institutionalHome/DIFFERENTIAL_REMOVE',

  BANNER_EDIT = '@institutionalHome/BANNER_EDIT',

  FETCH_REQUEST_SUCCESS = '@institutionalHome/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_ERROR = '@institutionalHome/FETCH_REQUEST_ERROR',

  HOME_REQUEST_SUCCESS = '@institutionalHome/HOME_REQUEST_SUCCESS',
  HOME_REQUEST_ERROR = '@institutionalHome/HOME_REQUEST_ERROR',
}

export interface InstitutionalHomeState {
  readonly banner: Banner;
  readonly differential: Differential;
  readonly testimonial: Testimonial;
  readonly differentials: Differential[];
  readonly testimonials: Testimonial[];
}

export type InstitutionalHomeAction = {
  type: InstitutionalHomeTypes;
  payload: {
    banner: Banner;
    differential: Differential;
    testimonial: Testimonial;
    differentials: Differential[];
    testimonials: Testimonial[];
  }
};
