import { UserAdmin } from 'models/User';

export enum UserAdminTypes {
  FETCH_USERS_ADMIN_REQUEST = '@user/FETCH_USERS_ADMIN_REQUEST',
  FETCH_USERS_ADMIN_SUCCESS = '@user/FETCH_USERS_ADMIN_SUCCESS',
  FETCH_USERS_ADMIN_ERROR = '@user/FETCH_USERS_ADMIN_ERROR',

  FETCH_ONE_USER_ADMIN_REQUEST = '@user/FETCH_ONE_USER_ADMIN_REQUEST',
  FETCH_ONE_USER_ADMIN_SUCCESS = '@user/FETCH_ONE_USER_ADMIN_SUCCESS',
  FETCH_ONE_USER_ADMIN_ERROR = '@user/FETCH_ONE_USER_ADMIN_ERROR',

  CREATE_USER_ADMIN_REQUEST = '@user/CREATE_USER_ADMIN_REQUEST',
  CREATE_USER_ADMIN_SUCCESS = '@user/CREATE_USER_ADMIN_SUCCESS',
  CREATE_USER_ADMIN_ERROR = '@user/CREATE_USER_ADMIN_ERROR',

  UPDATE_USER_ADMIN_REQUEST = '@user/UPDATE_USER_ADMIN_REQUEST',
  UPDATE_USER_ADMIN_SUCCESS = '@user/UPDATE_USER_ADMIN_SUCCESS',
  UPDATE_USER_ADMIN_ERROR = '@user/UPDATE_USER_ADMIN_ERROR',

  DELETE_USER_ADMIN_REQUEST = '@user/DELETE_USER_ADMIN_REQUEST',
  DELETE_USER_ADMIN_SUCCESS = '@user/DELETE_USER_ADMIN_SUCCESS',
  DELETE_USER_ADMIN_ERROR = '@user/DELETE_USER_ADMIN_ERROR',
}

export interface UserAdminState {
  readonly id: number;
  readonly one: UserAdmin;
  readonly all: UserAdmin[];
}
