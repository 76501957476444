import styled from 'styled-components';

export const EditorWrapper = styled.div`
  padding: 16px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.section.bg};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
`;

export const FormGroup = styled.div<{ $column?: boolean }>`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 32px;
  }

  ${({ $column }) =>
    $column &&
    `
    flex-direction: column;
    margin-left: 32px;
  `}
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  width: 100%;

  label:first-child {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
`;

export const CoverFieldset = styled(Fieldset)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 32px;
  width: fit-content;

  h2 {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
`;

export const CoverInput = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .article-cover__label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 180px;
    position: absolute;
    top: 0;
    margin-bottom: 12px;
    text-align: center;
    background-color: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    input {
      display: none;
    }

    svg {
      font-size: 20px;
      color: gray;
      transition: all 0.3s;
    }

    &:hover {
      background-color: gray;

      svg {
        opacity: 0.6;
        color: white;
      }
    }

    &.has-cover {
      opacity: 0;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .article-cover__preview {
    width: 300px;
    height: 180px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${({ theme }) => theme.background};
    transition: all 0.3s;
  }
`;
