import { DateInterval } from '../DateInterval/DateInterval';
import { Checkbox } from '../Form';
import * as S from './ActivatableDateInterval.styles';
import { ActivatableDateIntervalProps } from './interfaces/ActivatableDateIntervalProps';

const ActivatableDateInterval = (props: ActivatableDateIntervalProps) => {
  const {
    startsIn,
    endsIn,
    onStartsInChange,
    onEndsInChange,
    isActive,
    onActiveChange,
    disable,
    label,
    containerDirection
  } = props;

  return (
    <S.ActivatableDateIntervalContainer
      $flexDirection={containerDirection || 'row'}
    >
      <S.CheckboxWrapper
        className="activatable-date-interval__checkbox"
        $flexDirection={containerDirection || 'row'}
      >
        <Checkbox
          checked={isActive}
          onChange={(e) => onActiveChange(e.checked)}
        />

        <S.CheckboxLabel>{label}</S.CheckboxLabel>
      </S.CheckboxWrapper>

      <DateInterval
        startsIn={startsIn}
        onStartsInChange={onStartsInChange}
        endsIn={endsIn}
        onEndsInChange={onEndsInChange}
        disable={!isActive || disable}
      />
    </S.ActivatableDateIntervalContainer>
  );
};

export { ActivatableDateInterval };
