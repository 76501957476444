import styled from 'styled-components';

export const DialogWrapper = styled.div`
  .p-dialog {
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    min-width: 200px;
  }

  .p-dialog .p-dialog-titlebar {
    border-top: 1px solid ${({ theme }) => theme.dialog.border};
    border-bottom: 1px solid ${({ theme }) => theme.dialog.border};
    background-color: ${({ theme }) => theme.dialog.header.bg};
    color: ${({ theme }) => theme.dialog.header.color};
    padding: 8px;
    font-weight: 700;
    border-bottom: 0 none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    text-align: center;
    font-size: 14px;
    font-family: 'Mulish';
  }

  .p-dialog .p-dialog-titlebar .p-dialog-title {
    margin: 0;
    float: none;
    font-weight: 700;
  }

  .p-dialog .p-dialog-content {
    background-color: ${({ theme }) => theme.dialog.content.bg};
    color: ${({ theme }) => theme.dialog.content.color};
    border-top: 1px solid ${({ theme }) => theme.dialog.border};
    border-bottom: 1px solid ${({ theme }) => theme.dialog.border};
    padding: 1em;
  }

  .p-dialog-title {
    text-align: center;
    font-size: 14px;
    font-family: 'Mulish';
  }

  .p-progressbar {
    top: 0;
    border: 0 none;
    border-radius: 25px;
    height: 24px;
    background-color: ${({ theme }) => theme.progressBar.bg};
    transition: none;
  }

  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: ${({ theme }) => theme.progressBar.status};
    transition: none;
  }

  .p-progressbar .p-progressbar-label {
    margin-top: 0;
    font-family: Mulish;
    font-size: 14px;
    color: ${({ theme }) => theme.progressBar.color};
    line-height: 24px;
  }
`;
