import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Quiz from 'models/Quiz';
import { showDialog } from 'store/ducks/dialog/actions';
import * as QuizSagas from 'store/ducks/quizzes/actions';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import filterMatch from 'helpers/filterMatch';
import * as S from './QuizPlaylistDataTable.styles';

interface QuizPlaylistDataTableProps {
  data: Quiz[];
  onPressItem: (quiz: Quiz) => void;
  onDoubleClick: (quiz: Quiz) => void;
}

const QuizPlaylistDataTable = (props: QuizPlaylistDataTableProps) => {
  const { data, onPressItem, onDoubleClick } = props;

  const dispatch = useDispatch();

  const handleRemovePressed = (quiz: Quiz) => {
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover quiz',
        message: `Deseja reamente remover o quiz "${quiz.name}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () => dispatch(QuizSagas.deleteQuizRequest(quiz))
      })
    );
  };

  const handleRowDoubleClick = (quiz: Quiz) => {
    onDoubleClick(quiz);
  };

  const getActionButtons = (quiz: Quiz) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(quiz)}
        severity="confirm"
        className="p-button-success"
        icon="pi pi-pencil"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleRemovePressed(quiz)}
        severity="danger"
        className="p-button-danger"
        icon="pi pi-trash"
      >
        <FaTrash />
      </Button>
    </S.ActionButtons>
  );

  const updatedAtBody = (quiz: Quiz) => (
    <span>{moment(quiz.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const createdAtBody = (quiz: Quiz) => (
    <span>{moment(quiz.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        value={data}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        filterDisplay="row"
      >
        <Column
          field="id"
          header="ID"
          bodyClassName="p-table-center"
          style={{ width: '60px' }}
          sortable
        />
        <Column
          field="name"
          header="Nome"
          filter
          filterHeaderClassName="p-filter-single"
          filterPlaceholder="Buscar"
          sortable
        />
        <Column field="description" header="Descrição" />
        <Column
          header="Criado em"
          sortable
          body={createdAtBody}
          style={{ width: '200px' }}
          sortField="createdAt"
        />
        <Column
          header="Atualizado em"
          sortable
          body={updatedAtBody}
          style={{ width: '200px' }}
          sortField="updatedAt"
        />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          body={getActionButtons}
          headerClassName="isCentered"
          bodyClassName="actionsColumn isCentered"
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { QuizPlaylistDataTable };
