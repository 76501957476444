import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import { fetchContactsSuccess, fetchContactsError } from './actions';

export function* fetchContacts() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALCONTACT));

    const { data }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/contacts'
    );

    yield put(fetchContactsSuccess(data));
  } catch (err: any) {
    yield put(fetchContactsError());
    yield put(
      showToast({
        summary: 'Institucional - Contato',
        detail: 'Falha ao carregar questões',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALCONTACT));
  }
}
