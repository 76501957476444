import { Editor } from 'primereact/editor';
import { RichTextEditorProps } from './interfaces/RichTextEditor';
import * as S from './RichTextEditor.styles';
import { editorFormats, editorModules } from './RichTextEditor.utils';

const RichTextEditor = ({ ref, ...props }: RichTextEditorProps) => (
  <S.RichTextEditorContainer>
    <Editor
      modules={editorModules}
      formats={editorFormats}
      theme="snow"
      {...props}
    />
  </S.RichTextEditorContainer>
);

export { RichTextEditor };
