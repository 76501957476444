import { call, put } from 'redux-saga/effects';
import Plan from 'models/Plan';
import Promotion from 'models/Promotion';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { fetchPlanRequest } from '../plan/actions';
import { showToast } from '../toast/actions';
import {
  createPromotionSuccess,
  createPromotionError,
  editPromotionSuccess,
  editPromotionError,
  fetchPromotionsRequest,
  deletePromotionSuccess,
  deletePromotionError,
  fetchPromotionsError,
  fetchPromotionsSuccess
} from './actions';
import { PromotionAction } from './types';

export function* fetchAllPromotions() {
  try {
    yield put(addLoadingModule(StoreModule.PROMOTION));

    const response: ConnectorResponse<Promotion[]> = yield call(
      Connector().get,
      'promotions/list'
    );

    yield put(fetchPromotionsSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Promoção',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchPromotionsError());
  } finally {
    yield put(removeLoadingModule(StoreModule.PROMOTION));
  }
}

export function* createPromotion(action: PromotionAction) {
  try {
    const { promotion, planId } = action.payload;

    const newPromotion = {
      discount: promotion.discount,
      discountType: promotion.discountType,
      name: promotion.name,
      description: promotion.description,
      startsIn: promotion.startsIn,
      endsIn: promotion.endsIn,
      promoPeriod: promotion.promoPeriod,
      totalQuantity:
        promotion.totalQuantity === 0 ? null : promotion.totalQuantity,
      promoCode: promotion.promoCode
    };

    const response: ConnectorResponse<Promotion> = yield call(
      Connector().post,
      'promotions',
      newPromotion
    );

    const linkPromotionToPlanData = {
      planId,
      promotionIds: [response.data.id]
    };

    yield call(
      Connector(true).put,
      'plans/add-promotion',
      linkPromotionToPlanData
    );

    yield put(createPromotionSuccess());
    yield put(fetchPromotionsRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Promoção',
        detail: 'Falha ao criar',
        severity: 'error'
      })
    );
    yield put(createPromotionError());
  }
}

export function* editPromotion(action: PromotionAction) {
  try {
    const { promotion } = action.payload;

    const updatedPromotion = {
      id: promotion.id,
      discount: promotion.discount,
      discountType: promotion.discountType,
      name: promotion.name,
      description: promotion.description,
      startsIn: promotion.startsIn,
      endsIn: promotion.endsIn,
      promoPeriod: promotion.promoPeriod,
      totalQuantity: promotion.totalQuantity,
      promoCode: promotion.promoCode,
      status: promotion.status
    };

    yield call(Connector().put, 'promotions', updatedPromotion);

    yield put(editPromotionSuccess());
    yield put(fetchPromotionsRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Promoção',
        detail: 'Falha ao atualizar',
        severity: 'error'
      })
    );
    yield put(editPromotionError());
  }
}

export function* deletePromotion(action: PromotionAction) {
  try {
    const { promotionId } = action.payload;

    yield call(Connector().delete, `promotions?id=${promotionId}`);

    yield put(deletePromotionSuccess());
    yield put(fetchPromotionsRequest());
  } catch (err: any) {
    yield put(deletePromotionError());
    yield put(
      showToast({
        summary: 'Promoção',
        detail: 'Falha ao remover',
        severity: 'error'
      })
    );
  }
}
