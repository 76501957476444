import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate
} from 'react-router-dom';
import { NavigatorLayout } from 'components';
import {
  AdminLogsPage,
  CategoryPage,
  DashboardPage,
  FileManagerPage,
  ForbiddenPage,
  HomePage,
  InstitutionalHomePage,
  InstitutionalTermsPage,
  InstitutionalNewsPage,
  InstitutionalFaqPage,
  InstitutionalContactPage,
  InstitutionalAboutUsPage,
  LoginPage,
  NotFoundPage,
  PlanPage,
  PlaylistPage,
  PromotionPage,
  QuizPage,
  StudentRequestPage,
  SubscriberPage,
  SubscriptionsReportPage,
  TeachingInstitutionsPage,
  TrashPage,
  UsagePage,
  UserAdminPage,
  UserAppPage,
  UserLogsPage
} from 'pages';
import { Examples } from 'pages/Examples/Examples';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';
import AuthRoute from './components/AuthRoute';

const Router = () => (
  <BrowserRouter>
    <Routes>
      {process.env.NODE_ENV === 'development' && (
        <Route path="/examples" element={<Examples />} />
      )}

      <Route path={PUBLIC_ROUTES.LOGIN} element={<AuthRoute type="guest" />}>
        <Route path={PUBLIC_ROUTES.LOGIN} element={<LoginPage />} />
      </Route>

      <Route path={PUBLIC_ROUTES.LOGOUT} element={<AuthRoute type="guest" />}>
        <Route
          path={PUBLIC_ROUTES.LOGOUT}
          element={<LoginPage type="logout" />}
        />
      </Route>

      <Route
        element={
          <NavigatorLayout>
            <Outlet />
          </NavigatorLayout>
        }
      >
        <Route path={PRIVATE_ROUTES.HOME} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.HOME} element={<HomePage />} />
        </Route>

        <Route
          path={PRIVATE_ROUTES.ANALYTICS}
          element={<Navigate to={PRIVATE_ROUTES.ANALYTICS_DASHBOARD} replace />}
        />

        <Route
          path={PRIVATE_ROUTES.ANALYTICS_DASHBOARD}
          element={<AuthRoute />}
        >
          <Route
            path={PRIVATE_ROUTES.ANALYTICS_DASHBOARD}
            element={<DashboardPage />}
          />
        </Route>
        <Route
          path={PRIVATE_ROUTES.ANALYTICS_SUBSCRIPTIONS}
          element={<AuthRoute />}
        >
          <Route
            path={PRIVATE_ROUTES.ANALYTICS_SUBSCRIPTIONS}
            element={<SubscriptionsReportPage />}
          />
        </Route>
        <Route path={PRIVATE_ROUTES.ANALYTICS_USAGE} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.ANALYTICS_USAGE}
            element={<UsagePage />}
          />
        </Route>

        <Route path={PRIVATE_ROUTES.FILE_MANAGER} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.FILE_MANAGER}
            element={<FileManagerPage />}
          />
        </Route>
        <Route path={PRIVATE_ROUTES.PLAYLISTS} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.PLAYLISTS} element={<PlaylistPage />} />
        </Route>
        <Route path={PRIVATE_ROUTES.PLANS} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.PLANS} element={<PlanPage />} />
        </Route>
        <Route path={PRIVATE_ROUTES.PROMOTIONS} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.PROMOTIONS} element={<PromotionPage />} />
        </Route>
        <Route path={PRIVATE_ROUTES.CATEGORIES} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.CATEGORIES} element={<CategoryPage />} />
        </Route>
        <Route path={PRIVATE_ROUTES.QUIZZES} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.QUIZZES} element={<QuizPage />} />
        </Route>
        <Route path={PRIVATE_ROUTES.USERS_APP} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.USERS_APP} element={<UserAppPage />} />
        </Route>
        <Route path={PRIVATE_ROUTES.USERS_ADMIN} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.USERS_ADMIN}
            element={<UserAdminPage />}
          />
        </Route>
        <Route path={PRIVATE_ROUTES.SUBSCRIBERS} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.SUBSCRIBERS}
            element={<SubscriberPage />}
          />
        </Route>
        <Route
          path={PRIVATE_ROUTES.TEACHING_INSTITUTIONS}
          element={<AuthRoute />}
        >
          <Route
            path={PRIVATE_ROUTES.TEACHING_INSTITUTIONS}
            element={<TeachingInstitutionsPage />}
          />
        </Route>
        <Route path={PRIVATE_ROUTES.STUDENT_REQUESTS} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.STUDENT_REQUESTS}
            element={<StudentRequestPage />}
          />
        </Route>

        <Route
          path={PRIVATE_ROUTES.LOGS}
          element={<Navigate to={PRIVATE_ROUTES.LOGS_USERS} replace />}
        />
        <Route path={PRIVATE_ROUTES.LOGS_USERS} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.LOGS_USERS} element={<UserLogsPage />} />
        </Route>
        <Route path={PRIVATE_ROUTES.LOGS_ADMIN} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.LOGS_ADMIN} element={<AdminLogsPage />} />
        </Route>

        <Route
          path={PRIVATE_ROUTES.INSTITUTIONAL}
          element={<Navigate to={PRIVATE_ROUTES.INSTITUTIONAL_HOME} replace />}
        />
        <Route path={PRIVATE_ROUTES.INSTITUTIONAL_HOME} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.INSTITUTIONAL_HOME}
            element={<InstitutionalHomePage />}
          />
        </Route>
        <Route
          path={PRIVATE_ROUTES.INSTITUTIONAL_CONTACT}
          element={<AuthRoute />}
        >
          <Route
            path={PRIVATE_ROUTES.INSTITUTIONAL_CONTACT}
            element={<InstitutionalContactPage />}
          />
        </Route>
        <Route path={PRIVATE_ROUTES.INSTITUTIONAL_FAQ} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.INSTITUTIONAL_FAQ}
            element={<InstitutionalFaqPage />}
          />
        </Route>
        <Route
          path={PRIVATE_ROUTES.INSTITUTIONAL_TERMS}
          element={<AuthRoute />}
        >
          <Route
            path={PRIVATE_ROUTES.INSTITUTIONAL_TERMS}
            element={<InstitutionalTermsPage />}
          />
        </Route>
        <Route
          path={PRIVATE_ROUTES.INSTITUTIONAL_ABOUT}
          element={<AuthRoute />}
        >
          <Route
            path={PRIVATE_ROUTES.INSTITUTIONAL_ABOUT}
            element={<InstitutionalAboutUsPage />}
          />
        </Route>
        <Route path={PRIVATE_ROUTES.INSTITUTIONAL_NEWS} element={<AuthRoute />}>
          <Route
            path={PRIVATE_ROUTES.INSTITUTIONAL_NEWS}
            element={<InstitutionalNewsPage />}
          />
        </Route>

        <Route path={PRIVATE_ROUTES.TRASH} element={<AuthRoute />}>
          <Route path={PRIVATE_ROUTES.TRASH} element={<TrashPage />} />
        </Route>
      </Route>

      <Route
        path={PRIVATE_ROUTES.FORBIDDEN}
        element={<AuthRoute type="guest" />}
      >
        <Route element={<ForbiddenPage />} />
      </Route>

      <Route element={<AuthRoute type="guest" />}>
        <Route element={<NotFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
