import { AnalyticsState } from './types';

export const ANALYTICS_INITIAL_STATE: AnalyticsState = {
  usagePerHours: [],
  playlistsUsage: [],
  activeUsersByPlan: [],
  activeUsersByPeriod: {
    labels: [],
    data: []
  },
  canceledOrActivePayingUsers: {
    labels: [],
    data: []
  },
  canceledAndActiveUsersAfterTest: [],
  canceledUsersAfterTest: [],
  usersInPromotion: {
    expectAmount: 0,
    numbersubscriptions: 0
  },
  usersInTest: {
    numbersubscriptions: 0
  },
  paymentAmount: {
    amountPayment: 0,
    curentAmountPayment: 0
  },
  financialReport: [],
  subscriptionsSummary: {
    active: 0,
    overDue: 0,
    suspended: 0,
    inTest: 0,
    total: 0
  },
  subscriptions: [],
  error: [],
  success: [],
  loading: [],
  startsIn: '',
  endsIn: ''
};
