import { Reducer } from 'redux';
import {
  InstitutionalHomeTypes,
  InstitutionalHomeState,
  InstitutionalHomeAction
} from './types';
import { INITIAL_STATE } from './utils';

const reducer: Reducer<InstitutionalHomeState, InstitutionalHomeAction> = (
  state: InstitutionalHomeState = INITIAL_STATE,
  action: InstitutionalHomeAction
) => {
  switch (action.type) {
    case InstitutionalHomeTypes.FETCH_REQUEST_SUCCESS: {
      return {
        ...state,
        banner: action.payload.banner,
        differentials: action.payload.differentials,
        testimonials: action.payload.testimonials
      };
    }
    case InstitutionalHomeTypes.BANNER_EDIT: {
      return {
        ...state,
        banner: action.payload.banner
      };
    }
    case InstitutionalHomeTypes.DIFFERENTIAL_CREATE: {
      return {
        ...state,
        differential: action.payload.differential
      };
    }
    case InstitutionalHomeTypes.DIFFERENTIAL_EDIT: {
      return {
        ...state,
        differential: action.payload.differential
      };
    }
    case InstitutionalHomeTypes.DIFFERENTIAL_REMOVE: {
      return {
        ...state,
        differential: action.payload.differential
      };
    }
    case InstitutionalHomeTypes.TESTIMONIAL_CREATE: {
      return {
        ...state,
        testimonial: action.payload.testimonial
      };
    }
    case InstitutionalHomeTypes.TESTIMONIAL_EDIT: {
      return {
        ...state,
        testimonial: action.payload.testimonial
      };
    }
    case InstitutionalHomeTypes.TESTIMONIAL_REMOVE: {
      return {
        ...state,
        testimonial: action.payload.testimonial
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
