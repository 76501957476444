import { action } from 'typesafe-actions';
import { QuizAlternative } from 'models/Quiz';
import { QuizAlternativesTypes } from './types';

export const createQuizAlternativeRequest = (one: QuizAlternative) =>
  action(QuizAlternativesTypes.CREATE_QUESTION_ALTERNATIVE_REQUEST, { one });

export const createQuizAlternativeSuccess = () =>
  action(QuizAlternativesTypes.CREATE_QUESTION_ALTERNATIVE_SUCCESS);

export const createQuizAlternativeError = () =>
  action(QuizAlternativesTypes.CREATE_QUESTION_ALTERNATIVE_ERROR);

export const updateQuizAlternativeRequest = (one: QuizAlternative) =>
  action(QuizAlternativesTypes.UPDATE_QUESTION_ALTERNATIVE_REQUEST, { one });

export const updateQuizAlternativeSuccess = () =>
  action(QuizAlternativesTypes.UPDATE_QUESTION_ALTERNATIVE_SUCCESS);

export const updateQuizAlternativeError = () =>
  action(QuizAlternativesTypes.UPDATE_QUESTION_ALTERNATIVE_ERROR);

export const deleteQuizAlternativeRequest = (one: QuizAlternative) =>
  action(QuizAlternativesTypes.DELETE_QUESTION_ALTERNATIVE_REQUEST, { one });

export const deleteQuizAlternativeSuccess = () =>
  action(QuizAlternativesTypes.DELETE_QUESTION_ALTERNATIVE_SUCCESS);

export const deleteQuizAlternativeError = () =>
  action(QuizAlternativesTypes.UPDATE_QUESTION_ALTERNATIVE_ERROR);
