import styled from 'styled-components';

export const Form = styled.form`
  margin-top: 8px;
  width: 95%;
`;

export const InputRadioGroup = styled.div`
  display: flex;
  align-items: center;

  span.radioGroupLabel {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.weight.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.secondary};
    padding: 8px 14px;
    flex-grow: 4;
  }

  label:first-of-type {
    margin-right: 32px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  justify-content: center;

  button {
    text-align: right;

    &:first-child {
      margin-right: 8px;
    }
  }
`;

export const FileUploadWrapper = styled.div`
  margin-top: 18px;

  .p-button {
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.text.secondary};
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 4px;
    padding: 15px;
    transition: all 0.3s !important;

    &:hover,
    &:focus {
      .p-button-label,
      .p-button-icon {
        color: white;
      }
    }

    .p-button-label {
      font-family: 'Mulish', sans-serif;
      font-size: 14px;
      font-weight: ${({ theme }) => theme.weight.bold};
      text-align: left;
      transition: all 0.3s !important;
    }
  }
`;
