import { action } from 'typesafe-actions';
import { UserAdmin } from 'models/User';
import { UserAdminTypes } from './types';

export const fetchUsersAdminRequest = () =>
  action(UserAdminTypes.FETCH_USERS_ADMIN_REQUEST);

export const fetchUsersAdminSuccess = (all: UserAdmin[]) =>
  action(UserAdminTypes.FETCH_USERS_ADMIN_SUCCESS, { all });

export const fetchUsersAdminError = () =>
  action(UserAdminTypes.FETCH_USERS_ADMIN_ERROR);

export const fetchOneUserAdminRequest = (id: number) =>
  action(UserAdminTypes.FETCH_ONE_USER_ADMIN_REQUEST, { id });

export const fetchOneUserAdminSuccess = (one: UserAdmin) =>
  action(UserAdminTypes.FETCH_ONE_USER_ADMIN_SUCCESS, { one });

export const fetchOneUserAdminError = () =>
  action(UserAdminTypes.FETCH_ONE_USER_ADMIN_ERROR);

export const createUserAdminRequest = (one: UserAdmin) =>
  action(UserAdminTypes.CREATE_USER_ADMIN_REQUEST, { one });

export const createUserAdminSuccess = () =>
  action(UserAdminTypes.CREATE_USER_ADMIN_SUCCESS);

export const createUserAdminError = () =>
  action(UserAdminTypes.CREATE_USER_ADMIN_ERROR);

export const updateUserAdminRequest = (one: UserAdmin) =>
  action(UserAdminTypes.UPDATE_USER_ADMIN_REQUEST, { one });

export const updateUserAdminSuccess = () =>
  action(UserAdminTypes.UPDATE_USER_ADMIN_SUCCESS);

export const updateUserAdminError = () =>
  action(UserAdminTypes.UPDATE_USER_ADMIN_ERROR);

export const deleteUserAdminRequest = (one: UserAdmin) =>
  action(UserAdminTypes.DELETE_USER_ADMIN_REQUEST, { one });

export const deleteUserAdminSuccess = () =>
  action(UserAdminTypes.DELETE_USER_ADMIN_SUCCESS);

export const deleteUserAdminError = () =>
  action(UserAdminTypes.DELETE_USER_ADMIN_ERROR);
