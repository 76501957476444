import { useEffect, useState } from 'react';
import { FaArrowLeft, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { NewsArticle } from 'models/Institutional';
import {
  fetchNewsRequest,
  createArticleRequest,
  updateArticleRequest
} from 'store/ducks/institutionalNews/actions';
import { showToast } from 'store/ducks/toast/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { TableEditorDisplayMode } from 'helpers/types/TableEditorDisplayMode';
import { NewsDataTable, NewsEditor } from './components';
import * as S from './NewsPage.styles';
import { articleInitialState } from './NewsPage.utils';

const NewsPage = () => {
  const dispatch = useDispatch();
  const { list } = useSelector(
    (state: ApplicationState) => state.institutionalNews
  );

  const [mode, setMode] = useState<DrawerMode>('CLOSE');
  const [displayMode, setDisplayMode] =
    useState<TableEditorDisplayMode>('DATATABLE');
  const [article, setArticle] = useState<NewsArticle>(articleInitialState);

  useEffect(() => {
    dispatch(fetchNewsRequest());
  }, [dispatch]);

  const handleAddPressed = () => {
    setArticle(articleInitialState);
    setDisplayMode('EDITOR');
    setMode('OPEN_ADD');
  };

  const handleEditPressed = (article: NewsArticle) => {
    setArticle(article);
    setDisplayMode('EDITOR');
    setMode('OPEN_EDIT');
  };

  const handleBackPressed = () => {
    setArticle(articleInitialState);
    setDisplayMode('DATATABLE');
    setMode('CLOSE');
  };

  const handleResetPressed = () => {
    setArticle(articleInitialState);
    setDisplayMode('DATATABLE');
    setMode('CLOSE');
  };

  const handleSavePressed = () => {
    try {
      Yup.object()
        .shape({
          title: Yup.string().required('O campo título é obrigatório'),
          description: Yup.string().required('O campo descrição é obrigatório')
        })
        .validateSync(article, { abortEarly: false });

      if (mode === 'OPEN_ADD') {
        if (!article.cover) {
          dispatch(
            showToast({
              summary: 'Falha na validação',
              detail: 'O campo capa é obrigatório',
              severity: 'error'
            })
          );
          return;
        }

        dispatch(createArticleRequest(article));
      }

      if (mode === 'OPEN_EDIT') {
        dispatch(updateArticleRequest(article));
      }

      handleResetPressed();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={handleBackPressed}
      disabled={displayMode === 'DATATABLE'}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  const getActionButtons = () =>
    displayMode === 'EDITOR' ? (
      <S.Row>
        <Button type="button" severity="primary" onClick={handleSavePressed}>
          <FaSave />
          Salvar
        </Button>

        <Button type="button" severity="primary" onClick={handleResetPressed}>
          <FaTrash />
          Descartar
        </Button>
      </S.Row>
    ) : (
      <S.Row>
        <Button type="button" severity="primary" onClick={handleAddPressed}>
          <FaPlus />
          Criar
        </Button>
      </S.Row>
    );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <S.Container>
          <PageHeader
            title="Institucional - Notícias"
            breadcrumbsBottomComponent={getBackButton()}
            actionsComponent={getActionButtons()}
          />

          {displayMode === 'DATATABLE' && (
            <NewsDataTable data={list} onPressItem={handleEditPressed} />
          )}

          {displayMode === 'EDITOR' && (
            <NewsEditor article={article} setArticle={setArticle} />
          )}
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { NewsPage };
