import { Checkbox, Select } from 'components/Form';
import { iSelectProps } from 'components/Form/Select/interfaces/iSelectProps';
import { iActivatableInputProps } from './interfaces/iActivatableInput';
import * as S from './styled';

type iActivatableSelectProps = iActivatableInputProps &
  Omit<iSelectProps, 'onSelection' | 'selected'>;

export const ActivatableSelect = ({
  onChangeValue,
  customValue,
  ...props
}: iActivatableSelectProps) => {
  const handleChangeValue = (value: any) => {
    onChangeValue({
      value,
      enable: customValue.enable
    });
  };

  const handleCheckChange = () => {
    onChangeValue({
      enable: !customValue.enable,
      value: customValue.value
    });
  };

  return (
    <S.ActivatableInputWrapper>
      <Checkbox checked={customValue.enable} onChange={handleCheckChange} />
      <Select
        onSelection={handleChangeValue}
        selected={customValue.value}
        disabled={!customValue.enable}
        {...props}
      />
    </S.ActivatableInputWrapper>
  );
};
