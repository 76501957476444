import { Reducer } from 'redux';
import { TrashTypes, TrashState } from './types';

export type Action = {
  type: TrashTypes;
  payload: {
    data: any[];
    old: any;
  };
};

const INITIAL_STATE: TrashState = {
  data: [],
  old: {},
};

const reducer: Reducer<TrashState, Action> = (
  state: TrashState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case TrashTypes.FETCH_TRASH_SUCCESS: {
    return {
      ...state,
      data: action.payload.data,
    };
  }
  case TrashTypes.REMOVE_TRASH_AUDIO_REQUEST: {
    return {
      ...state,
      old: action.payload.old,
    };
  }
  case TrashTypes.RESTORE_TRASH_AUDIO_REQUEST: {
    return {
      ...state,
      old: action.payload.old,
    };
  }
  case TrashTypes.REMOVE_TRASH_FOLDER_REQUEST: {
    return {
      ...state,
      old: action.payload.old,
    };
  }
  case TrashTypes.RESTORE_TRASH_FOLDER_REQUEST: {
    return {
      ...state,
      old: action.payload.old,
    };
  }
  default: {
    return state;
  }
  }
};

export default reducer;
