import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { showToast } from 'store/ducks/toast/actions';
import dateUtils from 'helpers/date';
import localeSettings from 'helpers/locale';
import * as S from './DateInterval.styles';
import { DateIntervalProps } from './interfaces/DateIntervalProps';

const currentDate = new Date(Date.now());
const currentYear = currentDate.getFullYear();
const minDate = new Date(currentYear - 2, 0);
const maxDate = new Date(currentYear, currentDate.getMonth());

const DateInterval = (props: DateIntervalProps) => {
  const { disable, onStartsInChange, onEndsInChange, endsIn, startsIn } = props;
  const { convertDateToString, convertStringToDate } = dateUtils;
  const dispatch = useDispatch();

  const handleStartsChange = (event: CalendarChangeParams) => {
    if (event.value) {
      let date = Array.isArray(event.value) ? event.value[0] : event.value;

      const momentStartsIn = moment(date);
      const momentEndsIn = moment(endsIn);

      const isInvalid =
        momentStartsIn.isAfter(momentEndsIn) ||
        momentStartsIn.isSame(momentEndsIn, 'month');

      if (isInvalid) {
        if (momentEndsIn.isSame(moment(), 'month')) {
          dispatch(
            showToast({
              summary: 'Atenção',
              detail: 'A mês inicial deve ser menor que o mês atual',
              severity: 'warn'
            })
          );

          date = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        } else {
          dispatch(
            showToast({
              summary: 'Atenção',
              detail: 'A mês final deve ser maior que o mês inicial',
              severity: 'warn'
            })
          );

          const newEndsIn = new Date(
            date.getFullYear(),
            date.getMonth() + 2,
            0
          );
          onEndsInChange(convertDateToString(newEndsIn));
        }
      }

      onStartsInChange(convertDateToString(date));
    }
  };

  const handleEndsChange = (event: CalendarChangeParams) => {
    if (event.value) {
      const eventDate = Array.isArray(event.value)
        ? event.value[0]
        : event.value;

      const momentStartsIn = moment(startsIn);
      const momentEndsIn = moment(eventDate);

      const isInvalid =
        momentStartsIn.isAfter(momentEndsIn) ||
        momentStartsIn.isSame(momentEndsIn, 'month');

      if (isInvalid) {
        dispatch(
          showToast({
            summary: 'Atenção',
            detail: 'A mês final deve ser maior que o mês inicial',
            severity: 'warn'
          })
        );

        const newStartsIn = new Date(
          eventDate.getFullYear(),
          eventDate.getMonth() - 1,
          1
        );
        onStartsInChange(convertDateToString(newStartsIn));
      }

      // Instancia a mesma data mas jogando para o último dia do mês
      const date = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth() + 1,
        0
      );
      onEndsInChange(convertDateToString(date));
    }
  };

  return (
    <S.DateIntervalContainer>
      <Calendar
        value={convertStringToDate(startsIn)}
        onChange={handleStartsChange}
        view="month"
        dateFormat="mm/yy"
        yearNavigator
        yearRange={`${currentYear - 2}:${currentYear}`}
        disabled={disable}
        minDate={minDate}
        maxDate={maxDate}
      />
      <S.DateIntervalMiddleTerm
        className={classNames({
          'p-disabled': disable
        })}
      >
        até
      </S.DateIntervalMiddleTerm>
      <Calendar
        value={convertStringToDate(endsIn)}
        onChange={handleEndsChange}
        view="month"
        dateFormat="mm/yy"
        yearNavigator
        yearRange={`${currentYear - 2}:${currentYear}`}
        disabled={disable}
        minDate={minDate}
        maxDate={maxDate}
      />
    </S.DateIntervalContainer>
  );
};

DateInterval.defaultProps = {
  disable: false
};

export { DateInterval };
