/* eslint-disable no-plusplus */

const alphaNumericSort = (arr: any[], field: string) => {
  const sortFunction = (a: any, b: any) => {
    const isNumber = (v: any) => (+v).toString() === v;

    const aPart = a[field].match(/\d+|\D+/g);
    const bPart = b[field].match(/\d+|\D+/g);

    let i = 0;

    const len = Math.min(aPart.length, bPart.length);

    while (i < len && aPart[i] === bPart[i]) {
      i++;
    }

    if (i === len) {
      return aPart.length - bPart.length;
    }

    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    }

    return aPart[i].localeCompare(bPart[i]);
  };

  return arr.sort(sortFunction);
};

export { alphaNumericSort };
