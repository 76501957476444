import TeachingInstitution from 'models/TeachingInstitution';

export enum TeachingInstitutionTypes {
  FETCH_TEACHING_INSTITUTIONS_REQUEST = '@teachingInstitution/FETCH_TEACHING_INSTITUTIONS_REQUEST',
  FETCH_TEACHING_INSTITUTIONS_SUCCESS = '@teachingInstitution/FETCH_TEACHING_INSTITUTIONS_SUCCESS',
  FETCH_TEACHING_INSTITUTIONS_ERROR = '@teachingInstitution/FETCH_TEACHING_INSTITUTIONS_ERROR',

  FETCH_ONE_TEACHING_INSTITUTION_REQUEST = '@teachingInstitution/FETCH_ONE_TEACHING_INSTITUTION_REQUEST',
  FETCH_ONE_TEACHING_INSTITUTION_SUCCESS = '@teachingInstitution/FETCH_ONE_TEACHING_INSTITUTION_SUCCESS',
  FETCH_ONE_TEACHING_INSTITUTION_ERROR = '@teachingInstitution/FETCH_ONE_TEACHING_INSTITUTION_ERROR',

  CREATE_TEACHING_INSTITUTION_REQUEST = '@teachingInstitution/CREATE_TEACHING_INSTITUTION_REQUEST',
  CREATE_TEACHING_INSTITUTION_SUCCESS = '@teachingInstitution/CREATE_TEACHING_INSTITUTION_SUCCESS',
  CREATE_TEACHING_INSTITUTION_ERROR = '@teachingInstitution/CREATE_TEACHING_INSTITUTION_ERROR',

  UPDATE_TEACHING_INSTITUTION_REQUEST = '@teachingInstitution/UPDATE_TEACHING_INSTITUTION_REQUEST',
  UPDATE_TEACHING_INSTITUTION_SUCCESS = '@teachingInstitution/UPDATE_TEACHING_INSTITUTION_SUCCESS',
  UPDATE_TEACHING_INSTITUTION_ERROR = '@teachingInstitution/UPDATE_TEACHING_INSTITUTION_ERROR',

  DELETE_TEACHING_INSTITUTION_REQUEST = '@teachingInstitution/DELETE_TEACHING_INSTITUTION_REQUEST',
  DELETE_TEACHING_INSTITUTION_SUCCESS = '@teachingInstitution/DELETE_TEACHING_INSTITUTION_SUCCESS',
  DELETE_TEACHING_INSTITUTION_ERROR = '@teachingInstitution/DELETE_TEACHING_INSTITUTION_ERROR',
}

export interface TeachingInstitutionState {
  readonly id: number;
  readonly one: TeachingInstitution;
  readonly all: TeachingInstitution[];
}
