import styled from 'styled-components';

export const ChartStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .p-chart {
    width: 60%;
  }
`;

export const Row = styled.div`
  display: flex;
`;
