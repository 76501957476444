import { Reducer } from 'redux';
import { QuizQuestion } from 'models/Quiz';
import { QuizQuestionsTypes, QuizQuestionsState } from './types';

export type Action = {
    type: QuizQuestionsTypes;
    payload: {
      id: number;
      one: QuizQuestion;
    }
};

const INITIAL_STATE: QuizQuestionsState = {
  id: 0,
  one: {
    id: 0,
    question: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    isMultiple: false,
    quizId: 0,
    quizAlternative: [],
  },
};

const reducer: Reducer<QuizQuestionsState, Action> = (
  state: QuizQuestionsState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case QuizQuestionsTypes.FETCH_ONE_QUIZ_QUESTIONS_REQUEST: {
    return {
      ...state,
      id: action.payload.id,
    };
  }
  case QuizQuestionsTypes.FETCH_ONE_QUIZ_QUESTIONS_SUCCESS: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  case QuizQuestionsTypes.CREATE_QUIZ_QUESTION_REQUEST: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  case QuizQuestionsTypes.UPDATE_QUIZ_QUESTION_REQUEST: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  case QuizQuestionsTypes.DELETE_QUIZ_QUESTION_REQUEST: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  default: {
    return state;
  }
  }
};

export default reducer;
