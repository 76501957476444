import { useEffect, useState } from 'react';
import { FaArrowLeft, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Faq } from 'models/Institutional';
import {
  fetchFaqListRequest,
  createFaqQuestionRequest,
  updateFaqQuestionRequest
} from 'store/ducks/institutionalFaq/actions';
import { showToast } from 'store/ducks/toast/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { TableEditorDisplayMode } from 'helpers/types/TableEditorDisplayMode';
import { FaqDataTable, FaqEditor } from './components';
import * as S from './FaqPage.styles';
import { faqInitialState } from './FaqPage.utils';

const FaqPage = () => {
  const dispatch = useDispatch();
  const { list } = useSelector(
    (state: ApplicationState) => state.institutionalFaq
  );

  const [mode, setMode] = useState<DrawerMode>('CLOSE');
  const [displayMode, setDisplayMode] =
    useState<TableEditorDisplayMode>('DATATABLE');
  const [question, setQuestion] = useState<Faq>(faqInitialState);

  useEffect(() => {
    dispatch(fetchFaqListRequest());
  }, [dispatch]);

  const handleAddPressed = () => {
    setQuestion(faqInitialState);
    setDisplayMode('EDITOR');
    setMode('OPEN_ADD');
  };

  const handleEditPressed = (question: Faq) => {
    setQuestion(question);
    setDisplayMode('EDITOR');
    setMode('OPEN_EDIT');
  };

  const handleBackPressed = () => {
    setQuestion(faqInitialState);
    setDisplayMode('DATATABLE');
    setMode('CLOSE');
  };

  const handleResetPressed = () => {
    setQuestion(faqInitialState);
    setDisplayMode('DATATABLE');
    setMode('CLOSE');
  };

  const handleSavePressed = () => {
    try {
      Yup.object()
        .shape({
          title: Yup.string().required('O campo questão é obrigatório'),
          description: Yup.string().required('O campo resposta é obrigatório')
        })
        .validateSync(question, { abortEarly: false });

      if (mode === 'OPEN_ADD') {
        dispatch(createFaqQuestionRequest(question));
      }

      if (mode === 'OPEN_EDIT') {
        dispatch(updateFaqQuestionRequest(question));
      }

      handleResetPressed();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={handleBackPressed}
      disabled={displayMode === 'DATATABLE'}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  const getActionButtons = () =>
    displayMode === 'EDITOR' ? (
      <S.Row>
        <Button type="button" severity="primary" onClick={handleSavePressed}>
          <FaSave />
          Salvar
        </Button>

        <Button type="button" severity="primary" onClick={handleResetPressed}>
          <FaTrash />
          Descartar
        </Button>
      </S.Row>
    ) : (
      <S.Row>
        <Button type="button" severity="primary" onClick={handleAddPressed}>
          <FaPlus />
          Criar
        </Button>
      </S.Row>
    );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <S.Container>
          <PageHeader
            title="Institucional - Dúvidas Frequentes"
            breadcrumbsBottomComponent={getBackButton()}
            actionsComponent={getActionButtons()}
          />

          {displayMode === 'DATATABLE' && (
            <FaqDataTable data={list} onPressItem={handleEditPressed} />
          )}

          {displayMode === 'EDITOR' && (
            <FaqEditor question={question} setQuestion={setQuestion} />
          )}
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { FaqPage };
