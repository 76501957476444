import styled from 'styled-components';

export const ThemeSwitchWrapper = styled.div`
  .input-switch__container {
    justify-content: space-between;
    margin-bottom: 12px;

    .input-switch__label {
      font-size: 12px;
      color: #f3f3f3;
    }
  }
`;

export const LabelContainer = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: stretch;

  .label {
    width: 75%;
    font-size: 12px;
    text-align: left;
    margin-right: 8px;
  }
`;

export const InputSwitchContainer = styled.div`
  .p-inputswitch {
    width: 3em;
    height: 1.75em;
  }

  .p-inputswitch .p-inputswitch-slider {
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
    background: ${({ theme }) => theme.yellow};
  }

  .p-inputswitch .p-inputswitch-slider:before {
    background-color: ${({ theme }) => theme.blue};
    height: 1.25em;
    width: 1.25em;
    left: 0.25em;
    bottom: 0.25em;
    border-radius: 50%;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    -webkit-transform: translateX(1.25em);
    -ms-transform: translateX(1.25em);
    transform: translateX(1.25em);
    background-color: ${({ theme }) => theme.blue};
  }

  .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
    /* outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em ${({ theme }) => theme.shadow};
    -moz-box-shadow: 0 0 0 0.2em ${({ theme }) => theme.shadow};
    box-shadow: 0 0 0 0.2em ${({ theme }) => theme.shadow}; */
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: ${({ theme }) => theme.yellowDarker};
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: ${({ theme }) => theme.blueDarker};
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background-color: ${({ theme }) => theme.blueLighter};
  }

  .p-inputswitch.p-error {
    border: 1px solid #a80000;
  }
`;
