import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { removeLoadingModule, addLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  editTermsSuccess,
  editTermsError,
  fetchTermsRequest,
  fetchTermsSuccess,
  fetchTermsError
} from './actions';
import { InstitutionalTermsAction } from './types';

export function* fetchTerms() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALTERMS));

    const { data: terms }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/terms-and-conditions'
    );

    yield put(fetchTermsSuccess(terms));
  } catch (err: any) {
    yield put(fetchTermsError());
    yield put(
      showToast({
        summary: 'Institucional - Termos e Condições',
        detail: 'Falha ao buscar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALTERMS));
  }
}

export function* editTerms(action: InstitutionalTermsAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALTERMS));

    const { terms } = action.payload;

    yield call(Connector().put, '/landing-page/terms-and-conditions', terms);

    yield put(editTermsSuccess());
    yield put(fetchTermsRequest());
    yield put(
      showToast({
        summary: 'Institucional - Termos e Condições',
        detail: 'Dados salvos com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(editTermsError());
    yield put(
      showToast({
        summary: 'Institucional - Termos e Condições',
        detail: 'Falha ao salvar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALTERMS));
  }
}
