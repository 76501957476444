import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Log from 'models/Log';
import { fetchLogsRequest } from 'store/ducks/logs/actions';
import { PageHeader } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './AdminLogsPage.styles';
import { AdminLogActionSidebar } from './components/AdminLogActionSidebar/AdminLogActionSidebar';
import { AdminLogDataTable } from './components/AdminLogDataTable/AdminLogDataTable';

const logInitialState: Log = {
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  content: JSON.parse('{}')
};

const AdminLogsPage = () => {
  const dispatch = useDispatch();

  const { logs: stateLogs } = useSelector(
    (state: ApplicationState) => state.logs
  );

  const [selectedLog, setSelectedLog] = useState<Log>(logInitialState);

  const [logs, setLogs] = useState<Log[]>([]);

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchLogsRequest());
  }, [dispatch]);

  useEffect(() => {
    setLogs(stateLogs.filter((log) => log.isAdmin) || []);
  }, [logs]);

  const handleEditPressed = (data: Log) => {
    setSelectedLog(data);
    setDrawerMode('OPEN_VIEW');
  };

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <AdminLogActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedLog={selectedLog}
        />

        <S.Container>
          <PageHeader title="Logs Administrativos" />

          <AdminLogDataTable data={logs} onPressItem={handleEditPressed} />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { AdminLogsPage };
