import { useEffect, useState } from 'react';
import { FaArrowLeft, FaFilter, FaFileImport } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Filters } from 'models/Filters';
import Subscriber from 'models/Subscriber';
import {
  fetchOneSubscriberRequest,
  fetchSubscribersRequest,
  unsubscribeRequest,
  updateSubscriberRequest
} from 'store/ducks/subscriber/actions';
import { PageHeader, Button } from 'components';
import { filterInitialState } from 'helpers/filters';
import QueryBuilder from 'helpers/queryBuilder';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { SubscriberDisplayMode } from 'helpers/types/SubscriberDisplayMode';
import {
  SubscriberActionSidebar,
  SubscriberDataTable,
  SubscriberFilterSidebar,
  SubscriberImportSidebar,
  SubscriberProfileTable
} from './components';
import * as S from './SubscriberPage.styles';

const subscriberInitialState: Subscriber = {
  id: 0,
  hubId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  canceledAt: new Date(),
  status: '',
  inPromotion: false,
  expiresTrialAt: new Date(),
  cancelationReason: '',
  userId: 0,
  planId: 0,
  teachingInstitutionId: 0,
  userEmail: '',
  userName: '',
  cpf: '',
  phone: ''
};

const SubscriberPage = () => {
  const dispatch = useDispatch();

  const subscribers = useSelector(
    (state: ApplicationState) => state.subscriber.all
  );

  const [selectedSubscriber, setSelectedSubscriber] = useState<Subscriber>(
    subscriberInitialState
  );
  const [selectedFilters, setSelectedFilters] =
    useState<Filters>(filterInitialState);

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');
  const [importDrawerMode, setImportDrawerMode] = useState<DrawerMode>('CLOSE');
  const [filterDrawerMode, setFilterDrawerMode] = useState<DrawerMode>('CLOSE');
  const [displayMode, setDisplayMode] =
    useState<SubscriberDisplayMode>('ALL_SUBSCRIBERS');

  useEffect(() => {
    dispatch(fetchSubscribersRequest());
  }, [dispatch]);

  const handleSavePressed = (subscriber: Subscriber) => {
    if (subscriber.hubId && subscriber.hubId !== '') {
      dispatch(updateSubscriberRequest(subscriber));
    }
  };

  const handleApplyPressed = (filters: Filters) => {
    const serializedFilters = Object.entries(QueryBuilder.qobj(filters as any));

    let buildedQuery = '';

    if (serializedFilters.length) {
      buildedQuery = '?';

      serializedFilters.forEach(([key, value], index) => {
        buildedQuery +=
          index === serializedFilters.length - 1
            ? `${key}=${value}`
            : `${key}=${value}&`;
      });
    }

    dispatch(fetchSubscribersRequest(buildedQuery));
    setSelectedFilters(filters);
  };

  const handleCleanPress = () => {
    dispatch(fetchSubscribersRequest());
    setSelectedFilters({ ...filterInitialState });
    setFilterDrawerMode('CLOSE');
  };

  // Handlers de botões e ações
  const handleNavigation = () => {
    switch (displayMode) {
      case 'ONE_SUBSCRIBER':
        setDisplayMode('ALL_SUBSCRIBERS');
        break;
      default:
        break;
    }
  };

  const handleImportPressed = () => {
    setSelectedSubscriber(subscriberInitialState);
    setImportDrawerMode('OPEN_IMPORT');
  };

  const handleFilterPressed = () => {
    setFilterDrawerMode('OPEN_FILTER');
  };

  const handleEditPressed = (data: Subscriber) => {
    setSelectedSubscriber(data);
    setDrawerMode('OPEN_EDIT');
  };

  const handleDoubleClick = (data: Subscriber) => {
    dispatch(fetchOneSubscriberRequest(data.hubId));

    switch (displayMode) {
      case 'ALL_SUBSCRIBERS':
        setSelectedSubscriber(data);
        setDisplayMode('ONE_SUBSCRIBER');
        break;
      default:
        break;
    }
  };

  const handleUnsubscribe = (userId: string) => {
    dispatch(unsubscribeRequest(userId));
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={() => handleNavigation()}
      disabled={displayMode === 'ALL_SUBSCRIBERS'}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  const getActionButtons = () => (
    <S.Row>
      <Button
        type="button"
        severity="primary"
        size="big"
        onClick={handleImportPressed}
        disabled={displayMode === 'ONE_SUBSCRIBER'}
      >
        <FaFileImport />
        Importar
      </Button>

      <Button
        type="button"
        severity="primary"
        size="big"
        onClick={handleFilterPressed}
        disabled={displayMode === 'ONE_SUBSCRIBER'}
      >
        <FaFilter />
        Filtrar
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <SubscriberActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedSubscriber={selectedSubscriber}
          onConfirmPress={handleSavePressed}
        />
        <SubscriberImportSidebar
          mode={importDrawerMode}
          setMode={setImportDrawerMode}
          // onConfirmPress={handleImportButton}
        />
        <SubscriberFilterSidebar
          mode={filterDrawerMode}
          setMode={setFilterDrawerMode}
          selectedFilters={selectedFilters}
          onConfirmPress={handleApplyPressed}
          onCleanPress={handleCleanPress}
        />

        <S.Container>
          <PageHeader
            title="Assinantes"
            breadcrumbsBottomComponent={getBackButton()}
            actionsComponent={getActionButtons()}
          />

          {displayMode === 'ALL_SUBSCRIBERS' ? (
            <SubscriberDataTable
              data={subscribers}
              onPressItem={handleEditPressed}
              onDoubleClick={handleDoubleClick}
              onUnsubscribe={handleUnsubscribe}
            />
          ) : (
            <SubscriberProfileTable data={selectedSubscriber} />
          )}
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { SubscriberPage };
