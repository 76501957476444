import styled from 'styled-components';
import { ContentLayout } from 'components';
import * as Breakpoint from 'styles/breakpoints';

export const ContainerFluid = styled.div`
  ${Breakpoint.Fluid};
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Container = styled(ContentLayout)``;

export const Row = styled.div`
  display: flex;
  height: auto;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ChartContainer = styled.div``;
