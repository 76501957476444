import styled from 'styled-components';

const translate = 'transform: translateY(-80%); font-size: 12px;';

export const SelectContainer = styled.div`
  position:relative;

  & + div {
    margin-top: 18px;
  }

  label {
    padding: 6px 4px;
    position: absolute;
    left: 8px;
    top: 7px;
    z-index: 10;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.weight.bold};
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text.secondary};
    transition: transform 150ms ease-out, font-size 150ms ease-out;
    ${translate};
  }

  &.is--disabled {
    opacity: 0.7;
  }
`;

export const SelectInput = styled.input`
  color: ${({ theme }) => theme.text.primary};
  position: relative;
  width: 100%;
  outline: 0;
  border: 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.table.border};
  border-radius: 4px!important;
  padding: 12px 8px;
  cursor: pointer;
  text-align: left;
  caret-color: transparent;

  /* Custom arrow */
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='${({ theme }) => theme.select.arrow.regular}'/></g></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
  transition: background-image 0.3s;

  &:hover {
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='${({ theme }) => theme.select.arrow.hover}'/></g></svg>");
  }

  &::placeholder {
    color: ${({ theme }) => theme.text.secondary};
  }

  &:disabled {
    cursor: default;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='${({ theme }) => theme.select.arrow.hover}'/></g></svg>");
  }
`;

export const SelectDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.table.border};
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
  height: 0;
  transition: opacity 0.3s, z-index 0.3s, height 0.5s ease-in-out;
  max-height: 320px;
  width: 100%;
  overflow-y: scroll;
  
  &.select__dropdown-list {
    &--is-open {
      opacity: 1;
      z-index: 1000;
      height: auto;
    }
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.scroll.color} ${({ theme }) => theme.scroll.hover};

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.shadow};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 1px;
    padding: 2px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.scroll.color};
    transition: background-color 0.3s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.scroll.hover};
  }
`;

export const SelectOptionList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const OptionButton = styled.button`
  padding: 14px 18px;
  transition: background-color 0.3s, color 0.3s;
  text-align: left;
  border-radius: 0;
  font-size: 14px;

  &:hover {
    background-color: ${({ theme }) => theme.btn.secondary.bg};
  }
`;

export const SelectSearch = styled.div`
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.table.border};
  position: sticky;
  top: 0;
  width: 100%;

  input {
    border: 1px solid ${({ theme }) => theme.table.border};
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text.primary};
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    font-size: 14px;
  }
`;

export const NoOptions = styled.div`
  padding: 8px 16px;
  font-size: 12px;
`;
