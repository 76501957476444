/* eslint-disable no-prototype-builtins */
/* eslint-disable no-new-object */
import { useState, useEffect } from 'react';
import { MultiSelect as PRMultiSelect } from 'primereact/multiselect';
import PlaylistCategory from 'models/PlaylistCategory';
import { MultiSelectWrapper } from './styled';

interface MSProps {
  data: PlaylistCategory[];
  onSelectionChange: (tags: PlaylistCategory[]) => void;
  selecteds: PlaylistCategory[];
}

const MultiSelect: React.FC<MSProps> = (props: MSProps) => {
  const { data: pData, onSelectionChange, selecteds: pSelecteds } = props;
  const [selecteds, setSelecteds] = useState<any[]>(pSelecteds);
  const [data, setData] = useState<PlaylistCategory[]>(pData);

  useEffect(() => {
    if (pData) {
      setData(pData.map((item) => Object.assign(item, { label: item.name })));
    }
  }, [pData]);

  useEffect(() => {
    if (pSelecteds && typeof pSelecteds.map === 'function') {
      setSelecteds(pSelecteds.map((item) => item.id));
    }
  }, [pSelecteds]);

  useEffect(() => {
    onSelectionChange(selecteds.map((item) => Object({ id: Number(item) })));
  }, [selecteds]); // eslint-disable-line

  const itemTemplate = (option: { label: string }) => (
    <div className="p-clearfix">
      <span>{option.label}</span>
    </div>
  );

  const selectedItemTemplate = (obj: any) => {
    if (obj) {
      const isObj = new Object(obj).hasOwnProperty('id');

      if (isObj) {
        return (
          <div className="multiselect-audio-token">
            <span>{obj.name}</span>
          </div>
        );
      }
      return (
        <div className="multiselect-audio-token">
          <span>{obj}</span>
        </div>
      );
    }

    return (
      <span className="multiselect-empty-token">Selecione as categorias</span>
    );
  };

  return (
    <MultiSelectWrapper className="p-multiselect-container">
      <PRMultiSelect
        value={selecteds}
        options={data}
        optionValue="id"
        onChange={(e) => setSelecteds(e.value)}
        style={{ width: '100%' }}
        filter
        filterPlaceholder="Pesquisar..."
        itemTemplate={itemTemplate}
        selectedItemTemplate={selectedItemTemplate}
        selectedItemsLabel={`${selecteds.length} tags selecionadas!`}
        maxSelectedLabels={9}
      />
    </MultiSelectWrapper>
  );
};

export default MultiSelect;
