import {
  useState,
  useEffect,
  useContext,
  useCallback,
  memo,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { ThemeContext } from 'styled-components';
import { activeUsersByPeriodRequest } from 'store/ducks/analytics/actions';
import { Card, DateInterval, LoadingIndicator, MiniCard } from 'components';
import dateUtils from 'helpers/date';
import money from 'helpers/money';
import ApplicationState from 'helpers/types/ApplicationState';
import { CustomChartOptions } from 'helpers/types/ChartOptionsType';
import { ChartConfig } from 'helpers/types/ChartTypes';
import {
  ActiveUsersByPeriodData,
  ActiveUsersByPeriodDataOnMonth
} from 'interfaces/Analytics';
import * as S from './ActiveByPeriodChart.styles';

const {
  convertDateToString,
  getInitialEndDate,
  getInitialStartDate,
  getMonthRangeLabels
} = dateUtils;

const ActiveByPeriodChart = () => {
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();

  const { activeUsersByPeriod, loading } = useSelector(
    (state: ApplicationState) => state.analytics
  );

  const [startsIn, setStartsIn] = useState(
    convertDateToString(getInitialStartDate())
  );

  const [endsIn, setEndsIn] = useState(
    convertDateToString(getInitialEndDate())
  );

  useEffect(() => {
    dispatch(activeUsersByPeriodRequest(startsIn, endsIn));
  }, [dispatch, startsIn, endsIn]);

  const chartConfig = useMemo<ChartConfig>(
    () => ({
      labels: getMonthRangeLabels(startsIn, endsIn),
      datasets: activeUsersByPeriod.data.map(
        (activeUsersPeriodData: ActiveUsersByPeriodData, index: number) => ({
          label: activeUsersPeriodData.key,
          data: activeUsersPeriodData.data.map(
            (activeUsersMonthData: ActiveUsersByPeriodDataOnMonth) =>
              activeUsersMonthData.amount
          ),
          backgroundColor: themeContext.background,
          borderColor:
          index === 1
              ? themeContext.chart.bg.blue1
              : themeContext.chart.bg.orange1,
          fill: false,
          tension: 0.4
        })
      )
    }),
    [activeUsersByPeriod, themeContext]
  );

  const chartOptionsMemo = useMemo<CustomChartOptions>(
    () => ({
      plugins: {
        legend: {
          labels: {
            color: themeContext.text.primary
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: themeContext.text.primary
          },
          grid: {
            color: [themeContext.chart.grid]
          }
        },
        y: {
          title: {
            display: true,
            text: 'Receita',
            color: themeContext.text.secondary,
            font: {
              family: 'Mulish , sans-serif',
              weight: 'bold',
              size: 20
            },
            padding: {
              bottom: 24
            }
          },
          ticks: {
            color: themeContext.text.primary,
            callback: (value: number) => `R$ ${value}`
          },
          grid: {
            color: [themeContext.chart.grid]
          }
        }
      }
    }),
    [themeContext]
  );

  const chartMemo = useMemo(
    () => (
      <Chart
        type="line"
        data={chartConfig}
        options={chartOptionsMemo}
        className="chart"
      />
    ),
    [chartConfig, chartOptionsMemo]
  );

  const getPeriodIncome = () => {
    const periodIncome = activeUsersByPeriod.data
      .map((monthsData: ActiveUsersByPeriodData) =>
        monthsData.data.map(
          (monthsData: ActiveUsersByPeriodDataOnMonth) => monthsData.amount
        )
      )
      .reduce(
        (previous: number, items: any[]) =>
          items.reduce(
            (itemPrevious: number, item: any) => itemPrevious + item,
            0
          ) + previous,
        0
      );

    return money(periodIncome);
  };

  const getPeriodActiveUsers = () => {
    const activeUsers = activeUsersByPeriod.data
      .map((monthsData: ActiveUsersByPeriodData) =>
        monthsData.data.map(
          (monthsData: ActiveUsersByPeriodDataOnMonth) =>
            monthsData.numbersubscriptions
        )
      )
      .reduce(
        (previous: number, items: any[]) =>
          items.reduce(
            (itemPrevious: number, item: any) => itemPrevious + item,
            0
          ) + previous,
        0
      );

    return activeUsers;
  };

  const isModuleLoading = () => loading.includes('ACTIVE_USERS_BY_PERIOD');

  return (
    <Card title="Receita por período">
      <LoadingIndicator isLoading={isModuleLoading()} position="absolute" />

      <S.Container>
        <DateInterval
          startsIn={startsIn}
          onStartsInChange={setStartsIn}
          endsIn={endsIn}
          onEndsInChange={setEndsIn}
          disable={isModuleLoading()}
        />

        {chartMemo}

        <S.Row>
          <MiniCard title="Receita" body={getPeriodIncome()} />

          <MiniCard title="Total de assinantes" body={getPeriodActiveUsers()} />
        </S.Row>
      </S.Container>
    </Card>
  );
};

export { ActiveByPeriodChart };
