import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressUploadProps } from './interfaces/ProgressUploadProps';
import * as S from './ProgressUpload.styles';

const ProgressUpload = (props: ProgressUploadProps) => {
  const { visibleProgressBar, progressValue } = props;
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setStatus(Math.round(progressValue * 100));
  }, [progressValue]);

  const header = status < 100 ? 'Enviando' : 'Pronto!';

  return (
    <S.DialogWrapper>
      <Dialog
        header={header}
        visible={visibleProgressBar}
        onHide={() => false}
        closable={false}
      >
        <ProgressBar value={status} />
      </Dialog>
    </S.DialogWrapper>
  );
};

export { ProgressUpload };
