import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import TeachingInstitution from 'models/TeachingInstitution';
import {
  createTeachingInstitutionRequest,
  fetchTeachingInstitutionsRequest,
  updateTeachingInstitutionRequest
} from 'store/ducks/teachingInstitution/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import {
  TeachingInstitutionsActionSidebar,
  TeachingInstitutionsDataTable
} from './components';
import * as S from './TeachingInstitutionsPage.styles';

const teachingInstitutionInitialState: TeachingInstitution = {
  id: 0,
  name: '',
  cnpj: '',
  code: '',
  createdAt: new Date(),
  updatedAt: new Date()
};

const TeachingInstitutionsPage = () => {
  const dispatch = useDispatch();

  const teachingInstitutions = useSelector(
    (state: ApplicationState) => state.teachingInstitution.all
  );

  const [selectedTeachingInstitution, setSelectedTeachingInstitution] =
    useState<TeachingInstitution>(teachingInstitutionInitialState);

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchTeachingInstitutionsRequest());
  }, [dispatch]);

  // Handlers de estado
  const handleSavePressed = (teachingInstitution: TeachingInstitution) => {
    if (teachingInstitution.id && teachingInstitution.id !== 0) {
      dispatch(updateTeachingInstitutionRequest(teachingInstitution));
    } else {
      dispatch(createTeachingInstitutionRequest(teachingInstitution));
    }
    setSelectedTeachingInstitution(teachingInstitutionInitialState);
  };

  // Handlers de botões e ações
  const handleAddPressed = () => {
    setSelectedTeachingInstitution(teachingInstitutionInitialState);
    setDrawerMode('OPEN_ADD');
  };

  const handleEditPressed = (data: TeachingInstitution) => {
    setSelectedTeachingInstitution(data);
    setDrawerMode('OPEN_EDIT');
  };

  const getAddButton = () => (
    <S.Row>
      <Button
        type="button"
        severity="primary"
        size="big"
        onClick={handleAddPressed}
      >
        <FaPlus />
        Adicionar
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <TeachingInstitutionsActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedTeachingInstitution={selectedTeachingInstitution}
          onConfirmPress={handleSavePressed}
        />

        <S.Container>
          <PageHeader
            title="Instituições de Ensino"
            actionsComponent={getAddButton()}
          />

          <TeachingInstitutionsDataTable
            data={teachingInstitutions}
            onPressItem={handleEditPressed}
          />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { TeachingInstitutionsPage };
