import { AiOutlineDashboard } from 'react-icons/ai';
import {
  FaFileAlt,
  FaStar,
  FaTags,
  FaSignOutAlt,
  FaTrash,
  FaQuestion,
  FaUsers,
  FaCog,
  FaCoins,
  FaSchool,
  FaGraduationCap,
  FaChartLine,
  FaMusic,
  FaUniversity,
  FaEnvelope,
  FaQuestionCircle,
  FaList,
  FaInfo,
  FaNewspaper,
  FaDollarSign,
  FaHandHoldingUsd,
  FaUsersCog,
  FaFileInvoice,
  FaCogs
} from 'react-icons/fa';
import { MdPlaylistPlay } from 'react-icons/md';
import {
  CMS_PERMISSION,
  CMS_PERMISSION_TYPE,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES
} from 'helpers/constants';

export interface MenuType {
  id: string;
  isOpen: boolean;
  scrollHeight: string;
  title: string;
  icon: JSX.Element;
  links: {
    id: CMS_PERMISSION_TYPE;
    to: string;
    label: string;
    icon: JSX.Element;
  }[];
}

const dashboardMenu: MenuType = {
  id: 'analyticsMenu',
  isOpen: false,
  scrollHeight: '100%',
  title: 'Dados analíticos',
  icon: <FaChartLine />,
  links: [
    {
      id: CMS_PERMISSION.ANALYTICS_DASHBOARD,
      to: PRIVATE_ROUTES.ANALYTICS_DASHBOARD,
      label: 'Dashboard',
      icon: <AiOutlineDashboard />
    },
    {
      id: CMS_PERMISSION.ANALYTICS_SUBSCRIPTIONS,
      to: PRIVATE_ROUTES.ANALYTICS_SUBSCRIPTIONS,
      label: 'Relatório de Assinaturas',
      icon: <FaCoins />
    },
    {
      id: CMS_PERMISSION.ANALYTICS_USAGE,
      to: PRIVATE_ROUTES.ANALYTICS_USAGE,
      label: 'Dados de uso',
      icon: <FaCogs />
    }
  ]
};

const audiosMenu: MenuType = {
  id: 'audioMenu',
  isOpen: false,
  scrollHeight: '0px',
  title: 'Gestão de áudios',
  icon: <FaMusic />,
  links: [
    {
      id: CMS_PERMISSION.INSTITUTIONAL,
      to: PRIVATE_ROUTES.FILE_MANAGER,
      label: 'Arquivos',
      icon: <FaFileAlt />
    },
    {
      id: CMS_PERMISSION.CATEGORIES,
      to: PRIVATE_ROUTES.CATEGORIES,
      label: 'Categorias',
      icon: <FaTags />
    },
    {
      id: CMS_PERMISSION.PLAYLISTS,
      to: PRIVATE_ROUTES.PLAYLISTS,
      label: 'Playlists',
      icon: <MdPlaylistPlay />
    },
    {
      id: CMS_PERMISSION.QUIZZES,
      to: PRIVATE_ROUTES.QUIZZES,
      label: 'Quizzes',
      icon: <FaQuestion />
    }
  ]
};

const subscriptionsMenu: MenuType = {
  id: 'subscriptionsMenu',
  isOpen: false,
  scrollHeight: '0px',
  title: 'Assinaturas',
  icon: <FaDollarSign />,
  links: [
    {
      id: CMS_PERMISSION.PLANS,
      to: PRIVATE_ROUTES.PLANS,
      label: 'Planos',
      icon: <FaTags />
    },
    {
      id: CMS_PERMISSION.PROMOTIONS,
      to: PRIVATE_ROUTES.PROMOTIONS,
      label: 'Promoções',
      icon: <FaStar />
    },
    {
      id: CMS_PERMISSION.TEACHING_INSTITUTIONS,
      to: PRIVATE_ROUTES.TEACHING_INSTITUTIONS,
      label: 'Instituições',
      icon: <FaSchool />
    }
  ]
};

const usersMenu: MenuType = {
  id: 'usersMenu',
  isOpen: false,
  scrollHeight: '0px',
  title: 'Gestão de usuários',
  icon: <FaUsersCog />,
  links: [
    {
      id: CMS_PERMISSION.USERS_APP,
      to: PRIVATE_ROUTES.USERS_APP,
      label: 'Usuários',
      icon: <FaUsers />
    },
    {
      id: CMS_PERMISSION.STUDENT_REQUESTS,
      to: PRIVATE_ROUTES.STUDENT_REQUESTS,
      label: 'Estudantes',
      icon: <FaGraduationCap />
    },
    {
      id: CMS_PERMISSION.SUBSCRIBERS,
      to: PRIVATE_ROUTES.SUBSCRIBERS,
      label: 'Assinantes',
      icon: <FaHandHoldingUsd />
    }
  ]
};

const logsMenu: MenuType = {
  id: 'logsMenu',
  isOpen: false,
  scrollHeight: '0px',
  title: 'Logs de uso',
  icon: <FaFileInvoice />,
  links: [
    {
      id: CMS_PERMISSION.LOGS,
      to: PRIVATE_ROUTES.LOGS_USERS,
      label: 'Usuários',
      icon: <FaFileInvoice />
    },
    {
      id: CMS_PERMISSION.LOGS,
      to: PRIVATE_ROUTES.LOGS_ADMIN,
      label: 'Administrativo',
      icon: <FaFileInvoice />
    }
  ]
};

const institutionalMenu: MenuType = {
  id: 'institutionalMenu',
  isOpen: false,
  scrollHeight: '0px',
  title: 'Conteúdo Institucional',
  icon: <FaUniversity />,
  links: [
    {
      id: CMS_PERMISSION.INSTITUTIONAL,
      to: PRIVATE_ROUTES.INSTITUTIONAL_HOME,
      label: 'Home',
      icon: <FaUniversity />
    },
    {
      id: CMS_PERMISSION.INSTITUTIONAL,
      to: PRIVATE_ROUTES.INSTITUTIONAL_CONTACT,
      label: 'Contato',
      icon: <FaEnvelope />
    },
    {
      id: CMS_PERMISSION.INSTITUTIONAL,
      to: PRIVATE_ROUTES.INSTITUTIONAL_FAQ,
      label: 'Dúvidas Frequentes',
      icon: <FaQuestionCircle />
    },
    {
      id: CMS_PERMISSION.INSTITUTIONAL,
      to: PRIVATE_ROUTES.INSTITUTIONAL_TERMS,
      label: 'Termos e condições',
      icon: <FaList />
    },
    {
      id: CMS_PERMISSION.INSTITUTIONAL,
      to: PRIVATE_ROUTES.INSTITUTIONAL_ABOUT,
      label: 'Sobre',
      icon: <FaInfo />
    },
    {
      id: CMS_PERMISSION.INSTITUTIONAL,
      to: PRIVATE_ROUTES.INSTITUTIONAL_NEWS,
      label: 'Notícias',
      icon: <FaNewspaper />
    }
  ]
};

const configurationsMenu: MenuType = {
  id: 'configurationsMenu',
  isOpen: false,
  scrollHeight: '0px',
  title: 'Configurações',
  icon: <FaCog />,
  links: [
    {
      id: CMS_PERMISSION.USERS_ADMIN,
      to: PRIVATE_ROUTES.USERS_ADMIN,
      label: 'Administradores',
      icon: <FaUsers />
    }
  ]
};

export const mainMenu: MenuType[] = [
  dashboardMenu,
  audiosMenu,
  subscriptionsMenu,
  usersMenu,
  logsMenu,
  institutionalMenu,
  configurationsMenu
];

export const footerMenu = [
  { to: PRIVATE_ROUTES.TRASH, label: 'Lixeira', icon: <FaTrash /> },
  // { to: '', label: 'Configurações', icon: <FaCog /> },
  // { to: '', label: 'Meu perfil', icon: <FaUserAlt /> },
  { to: PUBLIC_ROUTES.LOGOUT, label: 'Sair', icon: <FaSignOutAlt /> }
];
