import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchOneFolderSuccess,
  fetchOneFolderError,
  saveFolderSuccess,
  saveFolderError,
  editFolderSuccess,
  editFolderError,
  removeFolderSuccess,
  removeFolderError,
  fetchViewFolderRequest,
  fetchViewFolderSuccess,
  fetchViewFolderError
} from './actions';
import { FolderAction } from './types';

export function* fetchOneFolder(action: FolderAction) {
  try {
    const response: ConnectorResponse = yield call(
      Connector().get,
      `folders?id=${action.payload.id}`
    );

    yield put(addLoadingModule(StoreModule.FOLDER));
    yield put(fetchOneFolderSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchOneFolderError());
  } finally {
    yield put(removeLoadingModule(StoreModule.FOLDER));
  }
}

export function* fetchViewFolder(action: FolderAction) {
  try {
    const response: ConnectorResponse = yield call(
      Connector().get,
      `folders/view?id=${action.payload.id}`
    );

    yield put(addLoadingModule(StoreModule.FOLDER));
    yield put(fetchViewFolderSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchViewFolderError());
  } finally {
    yield put(removeLoadingModule(StoreModule.FOLDER));
  }
}

export function* saveFolder(action: FolderAction) {
  try {
    const { folder } = action.payload;

    const data = {
      name: folder.name,
      narratorName: folder.narratorName,
      description: folder.description,
      parentFolder: {
        connect: {
          id: folder.parentId || 0
        }
      },
      user: { connect: { id: 1 } }
    };

    yield put(addLoadingModule(StoreModule.FOLDER));

    yield call(Connector().post, 'folders', data);

    yield put(saveFolderSuccess());
    yield put(fetchViewFolderRequest(folder.parentId || 0));
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Dados salvos com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Falha ao salvar dados',
        severity: 'error'
      })
    );
    yield put(saveFolderError());
  } finally {
    yield put(removeLoadingModule(StoreModule.FOLDER));
  }
}

export function* editFolder(action: FolderAction) {
  try {
    const obj = action.payload.folder as any;

    const { parentId } = action.payload.folder;
    const { id } = action.payload.folder;

    delete obj.id;
    delete obj.audios;
    delete obj.Folder;
    delete obj.createdAt;
    delete obj.updatedAt;
    delete obj.removedAt;
    delete obj.parentId;
    delete obj.type;

    yield put(addLoadingModule(StoreModule.FOLDER));

    yield call(Connector().put, `folders?id=${id}`, obj);

    yield put(editFolderSuccess());
    yield put(fetchViewFolderRequest(parentId || 0));
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(editFolderError());
  } finally {
    yield put(removeLoadingModule(StoreModule.FOLDER));
  }
}

export function* removeFolder(action: FolderAction) {
  try {
    const { parentId } = action.payload.folder;

    yield put(addLoadingModule(StoreModule.FOLDER));

    yield call(
      Connector().delete,
      `folders/in-trash?id=${action.payload.folder.id}`
    );

    yield put(removeFolderSuccess());
    yield put(fetchViewFolderRequest(parentId || 0));
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Pasta',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(removeFolderError());
  } finally {
    yield put(removeLoadingModule(StoreModule.FOLDER));
  }
}
