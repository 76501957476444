import { useMemo } from 'react';
import classNames from 'classnames';
import { iViewInputTextProps } from './interfaces/iViewInputTextProps';
import * as S from './ViewInputText.styles';

export const ViewInputText = (props: iViewInputTextProps) => {
  const { value, name, placeholder, className, label } = props;

  const isFilled = useMemo(
    () => (value !== undefined && value !== '') || !!placeholder,
    [value, placeholder]
  );

  return (
    <S.ViewInputTextWrapper className="input-text">
      <div
        className={classNames({
          className,
          filled: isFilled
        })}
      >
        {value}
      </div>
      {!!label && <label htmlFor={name}>{label}</label>}
    </S.ViewInputTextWrapper>
  );
};

ViewInputText.defaultProps = {
  type: 'text',
  placeholder: '',
  label: ''
};
