import { action } from 'typesafe-actions';
import SuperCategory from 'models/SuperCategory';
import { SuperCategoryTypes } from './types';

export const fetchSuperCategoryRequest = () =>
  action(SuperCategoryTypes.FETCH_SUPER_CATEGORY_REQUEST);

export const fetchSuperCategorySuccess = (data: SuperCategory[]) =>
  action(SuperCategoryTypes.FETCH_SUPER_CATEGORY_SUCCESS, { data });

export const fetchSuperCategoryError = () =>
  action(SuperCategoryTypes.FETCH_SUPER_CATEGORY_ERROR);
