/* eslint-disable no-prototype-builtins */
/* eslint-disable no-new-object */
import { useState, useEffect } from 'react';
import { MultiSelect as PRMultiSelect } from 'primereact/multiselect';
import { UserPermission, UserPermissions } from 'models/User';
import { MultiSelectWrapper } from './UserAdminPermissionsMultiSelect.styles';

interface UserAdminPermissionsMultiSelectProps {
  onSelectionChange: (tags: any[]) => void;
  selected: UserPermission[];
}

const UserAdminPermissionsMultiSelect = (
  props: UserAdminPermissionsMultiSelectProps
) => {
  const { onSelectionChange, selected } = props;
  const [selectedPermissions, setSelectedPermissions] = useState<
    UserPermission[]
  >([]);

  useEffect(() => {
    if (selected) {
      setSelectedPermissions(selected);
    }
  }, [selected]);

  useEffect(() => {
    const original = selectedPermissions.map((item) =>
      UserPermissions.find((p) => p.id === (item as unknown as string))
    );
    onSelectionChange(original);
  }, [selectedPermissions]); // eslint-disable-line

  const itemTemplate = (option: { label: string }) => (
    <div className="p-clearfix">
      <span>{option.label}</span>
    </div>
  );

  const selectedItemTemplate = (obj: any) =>
    obj ? (
      <div className="multiselect-permission-token">
        <span>{UserPermissions.find((item) => item.id === obj)?.label}</span>
      </div>
    ) : (
      <span className="multiselect-empty-token">Selecione as permissões</span>
    );

  return (
    <MultiSelectWrapper>
      <PRMultiSelect
        value={selectedPermissions}
        options={UserPermissions}
        optionValue="id"
        onChange={(e) => setSelectedPermissions(e.value)}
        style={{ width: '100%' }}
        filter
        filterPlaceholder="Pesquisar..."
        itemTemplate={itemTemplate}
        selectedItemTemplate={selectedItemTemplate}
        selectedItemsLabel={`${selectedPermissions.length} permissões selecionadas`}
      />
    </MultiSelectWrapper>
  );
};

export { UserAdminPermissionsMultiSelect };
