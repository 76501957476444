import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { NewsArticle } from 'models/Institutional';
import { showDialog } from 'store/ducks/dialog/actions';
import { deleteArticleRequest } from 'store/ducks/institutionalNews/actions';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './NewsDataTable.styles';

interface NewsDataTableProps {
  data: NewsArticle[];
  onPressItem: (article: NewsArticle) => void;
}

const NewsDataTable = (props: NewsDataTableProps) => {
  const { data, onPressItem } = props;

  const dispatch = useDispatch();

  const handleRemovePressed = (selectedArticle: NewsArticle) => {
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover Artigo',
        message: `Tem certeza que deseja remover o artigo "${selectedArticle.title}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () => dispatch(deleteArticleRequest(selectedArticle))
      })
    );
  };

  const getActionButtons = (selectedArticle: NewsArticle) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(selectedArticle)}
        severity="confirm"
        className="p-button-success"
        icon="pi pi-pencil"
      />
      <Button
        type="button"
        onClick={() => handleRemovePressed(selectedArticle)}
        severity="danger"
        className="p-button-danger"
        icon="pi pi-trash"
      />
    </S.ActionButtons>
  );

  const publishBody = (article: NewsArticle) => (
    <div
      className={classNames({
        'is--published': article.publish,
        'is--draft': !article.publish
      })}
    >
      {article.publish && 'Publicado'}
      {!article.publish && 'Rascunho'}
    </div>
  );

  const formatCreateBody = (article: NewsArticle) => (
    <span>{moment(article.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const formatUpdateBody = (article: NewsArticle) => (
    <span>{moment(article.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  return (
    <DataTableLayout>
      <DataTable
        sortField="id"
        sortOrder={1}
        value={data}
        selectionMode="single"
        onRowDoubleClick={(e) => onPressItem(e.data)}
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        filterDisplay="row"
      >
        <Column field="id" header="ID" style={{ width: '60px' }} sortable />
        <Column
          field="title"
          header="Título"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="published"
          header="Status"
          body={publishBody}
          style={{ width: '120px' }}
          sortable
        />
        <Column
          field="createdAt"
          header="Criado em"
          body={formatCreateBody}
          style={{ width: '140px' }}
          sortable
        />
        <Column
          field="updatedAt"
          header="Atualizado em"
          body={formatUpdateBody}
          style={{ width: '160px' }}
          sortable
        />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          body={getActionButtons}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { NewsDataTable };
