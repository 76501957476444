import { Reducer } from 'redux';
import {
  InstitutionalFaqTypes,
  InstitutionalFaqState,
  InstitutionalFaqAction
} from './types';
import { FAQ_INITIAL_STATE } from './utils';

const reducer: Reducer<InstitutionalFaqState, InstitutionalFaqAction> = (
  state: InstitutionalFaqState = FAQ_INITIAL_STATE,
  action: InstitutionalFaqAction
) => {
  switch (action.type) {
    case InstitutionalFaqTypes.FETCH_FAQ_LIST_SUCCESS: {
      return {
        ...state,
        list: action.payload.list
      };
    }
    case InstitutionalFaqTypes.CREATE_FAQ_QUESTION_REQUEST: {
      return {
        ...state,
        question: action.payload.question
      };
    }
    case InstitutionalFaqTypes.UPDATE_FAQ_QUESTION_REQUEST: {
      return {
        ...state,
        question: action.payload.question
      };
    }
    case InstitutionalFaqTypes.DELETE_FAQ_QUESTION_REQUEST: {
      return {
        ...state,
        question: action.payload.question
      };
    }
    case InstitutionalFaqTypes.FAQ_REQUEST_SUCCESS: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    case InstitutionalFaqTypes.FAQ_REQUEST_ERROR: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
