import styled from 'styled-components';

export const ChartStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media screen and (max-width: 1024px) {
    .p-chart {
      width: 100%;
    }
  }

  @media screen and (min-width: 1025px) {
    .p-chart {
      width: 60%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
`;
