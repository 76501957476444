import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchLogsSuccess,
  fetchLogsError,
  fetchLogByIdSuccess,
  fetchLogByIdError,
  fetchLogByUserError,
  fetchLogByUserSuccess,
  fetchLogByUserSubscriptionError,
  fetchLogByUserSubscriptionSuccess
} from './actions';
import { Action } from './index';

export function* fetchLogs() {
  try {
    yield put(addLoadingModule(StoreModule.LOGS));
    const response: ConnectorResponse = yield call(
      Connector().get,
      'logs/list'
    );

    yield put(fetchLogsSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Logs',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchLogsError());
  } finally {
    yield put(removeLoadingModule(StoreModule.LOGS));
  }
}

export function* fetchLogById(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.LOGS));

    const response: ConnectorResponse = yield call(
      Connector().get,
      `logs?id=${action.payload.id}`
    );

    yield put(fetchLogByIdSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Logs',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchLogsError());
  } finally {
    yield put(fetchLogByIdError());
  }
}

export function* fetchLogsByUser(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.LOGS));

    const response: ConnectorResponse = yield call(
      Connector().get,
      `logs/by-user?userId=${action.payload.id}`
    );

    yield put(fetchLogByUserSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Logs',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchLogsError());
  } finally {
    yield put(fetchLogByUserError());
  }
}

export function* fetchLogsByUserSubscription(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.LOGS));

    const response: ConnectorResponse = yield call(
      Connector().get,
      `logs/by-user-subscription?userId=${action.payload.id}`
    );

    yield put(fetchLogByUserSubscriptionSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Logs',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchLogByUserSubscriptionError());
  } finally {
    yield put(removeLoadingModule(StoreModule.LOGS));
  }
}
