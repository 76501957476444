/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeEvent, useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { UserApp } from 'models/User';
import { fetchOneSubscriberRequest } from 'store/ducks/subscriber/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer, PasswordChecklist } from 'components';
import { InputText } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import PasswordChecklistRules from 'helpers/types/PasswordChecklistRules';
import { userValidation } from 'helpers/validation/user';
import * as S from './UserAppActionSidebar.styles';

interface UserAppActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedUser: UserApp;
  onConfirmPress: (user: UserApp) => void;
  onCancelPress?: () => void;
}

export const passwordChecklistRulesInitialState: PasswordChecklistRules = {
  minLength: false,
  hasNumber: false,
  match: false
};

const UserAppActionSidebar = (props: UserAppActionSidebarProps) => {
  const { mode, setMode, selectedUser, onCancelPress, onConfirmPress } = props;

  const dispatch = useDispatch();
  const { one } = useSelector((state: ApplicationState) => state.subscriber);

  const [user, setUser] = useState<UserApp>(selectedUser);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordChecklistRules>(passwordChecklistRulesInitialState);

  useEffect(() => {
    setUser(selectedUser);

    if (selectedUser.hubId) {
      dispatch(fetchOneSubscriberRequest(selectedUser.hubId));
    }
  }, [selectedUser]);

  const handleConfirmPress = () => {
    const serializedUser =
      newPassword !== '' ? { ...user, password: newPassword } : user;

    try {
      userValidation(
        serializedUser,
        mode === 'OPEN_ADD' || newPassword !== '',
        newPasswordAgain,
        passwordValidation
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error',
                life: 10000
              })
            );
          }, index * 200);
        });
      }

      return;
    }

    if (onConfirmPress) {
      onConfirmPress(serializedUser);
    }

    setMode('CLOSE');
    setNewPassword('');
    setNewPasswordAgain('');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
    setNewPassword('');
    setNewPasswordAgain('');
  };

  const getPasswordLabel = () =>
    mode === 'OPEN_EDIT' ? 'Forçar nova senha' : 'Senha';

  const getPasswordAgainLabel = () =>
    mode === 'OPEN_EDIT' ? 'Confirmar nova senha' : 'Confirmar a senha';

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleChangePasswordAgain = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordAgain(e.target.value);
  };

  return (
    <Drawer
      title="Editar usuário"
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Nome"
          name="name"
          type="text"
          value={user.name}
          onChange={(e) =>
            setUser({
              ...user,
              name: e.target.value
            })
          }
        />

        <InputText
          label="Email"
          name="email"
          type="text"
          value={user.email}
          onChange={(e) =>
            setUser({
              ...user,
              email: e.target.value
            })
          }
        />

        {mode === 'OPEN_EDIT' && (
          <InputText
            label="CPF"
            name="cpf"
            type="text"
            value={user.cpf}
            onChange={(e) =>
              setUser({
                ...user,
                cpf: e.target.value
              })
            }
            disabled
          />
        )}

        <InputText
          label="País"
          name="country"
          type="text"
          value={one.country || one.userData?.country}
          disabled
        />

        <InputText
          label="Estado"
          name="state"
          type="text"
          value={one.state || one.userData?.state}
          disabled
        />

        <InputText
          label="Cidade"
          name="city"
          type="text"
          value={one.city || one.userData?.city}
          disabled
        />

        <InputText
          label="Endereço"
          name="address"
          type="text"
          value={one.address || one.userData?.address}
          disabled
        />

        <PasswordChecklist
          value={newPassword}
          onChange={handleChangePassword}
          valueAgain={newPasswordAgain}
          onChangeAgain={handleChangePasswordAgain}
          onChecklistChange={setPasswordValidation}
          placeholders={{
            password: getPasswordLabel(),
            passwordAgain: getPasswordAgainLabel()
          }}
          inputClasses={{
            password: 'input__user input__user--is-current-password',
            passwordAgain: 'input__user input__user--is-current-password'
          }}
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

UserAppActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { UserAppActionSidebar };
