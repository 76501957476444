import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserApp } from 'models/User';
import {
  createUserAppRequest,
  fetchUsersAppRequest,
  updateUserAppRequest
} from 'store/ducks/userApp/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { UserAppActionSidebar, UserAppDataTable } from './components';
import * as S from './UserAppPage.styles';

const userAppInitialState: UserApp = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  password: '',
  isAdmin: false,
  removed: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date(),
  customerId: '',
  firstLogin: true,
  hubId: ''
};

const UserAppPage = () => {
  const dispatch = useDispatch();

  const users = useSelector((state: ApplicationState) => state.userApp.all);

  const [selectedUser, setSelectedUser] =
    useState<UserApp>(userAppInitialState);

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchUsersAppRequest());
  }, [dispatch]);

  // Handlers de estado
  const handleSavePressed = (user: UserApp) => {
    if (user.id && user.id !== 0) {
      dispatch(updateUserAppRequest(user));
    } else {
      dispatch(createUserAppRequest(user));
    }
    setSelectedUser(userAppInitialState);
  };

  const handleEditPressed = (data: UserApp) => {
    setSelectedUser(data);
    setDrawerMode('OPEN_EDIT');
  };

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <UserAppActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedUser={selectedUser}
          onConfirmPress={handleSavePressed}
        />

        <S.Container>
          <PageHeader title="Usuários do Aplicativo" />

          <UserAppDataTable data={users} onPressItem={handleEditPressed} />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { UserAppPage };
