import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Playlist from 'models/Playlist';
import { showDialog } from 'store/ducks/dialog/actions';
import * as SagasPlaylist from 'store/ducks/playlist/actions';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './PlaylistDataTable.styles';

interface PlaylistDataTableProps {
  data: Playlist[];
  onPressItem: (data: any) => void;
  onDoubleClick: (data: any) => void;
  clearFields: () => void;
}

const PlaylistDataTable = (props: PlaylistDataTableProps) => {
  const { data, onPressItem, onDoubleClick, clearFields } = props;

  const dispatch = useDispatch();

  const handleRemovePressed = (dtRm: any) => {
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover playlist',
        message: `Deseja reamente remover a playlist "${dtRm.name}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () =>
          dispatch(SagasPlaylist.removePlaylistRequest(dtRm))
      })
    );
    clearFields();
  };

  const handleRowDoubleClick = (dtDc: any) => {
    onDoubleClick(dtDc);
  };

  const getActionButtons = (dtAc: any) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(dtAc)}
        severity="confirm"
        className="p-button-success"
        icon="pi pi-pencil"
      />
      <Button
        type="button"
        onClick={() => handleRemovePressed(dtAc)}
        severity="danger"
        className="p-button-danger"
        icon="pi pi-trash"
      />
    </S.ActionButtons>
  );

  const updatedAtBody = (dtUp: any) => (
    <span>{moment(dtUp.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const createdAtBody = (dtCr: any) => (
    <span>{moment(dtCr.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  return (
    <DataTableLayout>
      <DataTable
        value={data}
        sortField="name"
        sortOrder={1}
        filterDisplay="row"
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
      >
        <Column
          field="id"
          header="ID"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          style={{ width: '60px' }}
          sortable
        />
        <Column
          field="name"
          header="Nome"
          filter
          filterPlaceholder="Buscar pelo nome"
          filterHeaderClassName="p-filter-single"
          sortable
        />
        <Column
          field="createdAt"
          header="Criado em"
          sortable
          body={createdAtBody}
          style={{ width: '200px' }}
        />
        <Column
          field="updatedAt"
          header="Atualizado em"
          sortable
          body={updatedAtBody}
          style={{ width: '200px' }}
        />
        <Column
          header="Ações"
          style={{ width: '120px' }}
          body={getActionButtons}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { PlaylistDataTable };
