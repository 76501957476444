import styled from 'styled-components';

export const Form = styled.form`
  button {
    margin-top: 12px;
  }
`;

export const EditorWrapper = styled.div<{ $visible: boolean }>`
  margin-top: 12px;
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
`;

export const EditorHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    margin: 0;
  }

  button:not(:last-child) {
    margin-right: 12px;
  }
`;
