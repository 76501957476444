import { useEffect, useMemo, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { Calendar, CalendarDateTemplateParams } from 'primereact/calendar';
import { Filters } from 'models/Filters';
import { Button, Drawer } from 'components';
import { ActivatableCalendar } from 'components/ActivatableInput';
import localeSettings from 'helpers/locale';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './StudentRequestFilterSidebar.styles';

interface StudentRequestFilterSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedFilters: Filters;
  onConfirmPress: (filters: Filters) => void;
  onCleanPress: () => void;
  onCancelPress?: () => void;
}

const currentYear = new Date().getFullYear();
const minDate = new Date(currentYear - 2, 0);
const maxDate = new Date();

const StudentRequestFilterSidebar = (
  props: StudentRequestFilterSidebarProps
) => {
  const {
    mode,
    setMode,
    selectedFilters,
    onCancelPress,
    onConfirmPress,
    onCleanPress
  } = props;
  const [filters, setFilters] = useState<Filters>(selectedFilters);

  useEffect(() => {
    setFilters(selectedFilters);
  }, [selectedFilters]);

  const requestStatus = [
    { id: 'ALL', name: 'Todas' },
    { id: 'APPROVED', name: 'Aprovada' },
    { id: 'DISAPPROVED', name: 'Recusada' },
    { id: 'PENDING', name: 'Pendente' }
  ];

  useEffect(() => {
    setFilters({
      ...filters,
      status: {
        value: requestStatus[0].id,
        enable: false
      }
    });
  }, []);

  const handleConfirmPress = () => {
    if (onConfirmPress) {
      onConfirmPress(filters);
    }
    setMode('CLOSE');
  };

  const handleCleanFilters = () => {
    if (onCleanPress) {
      onCleanPress();
    }
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  return (
    <Drawer
      title="Selecionar filtros"
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <ActivatableCalendar
          label="Data inicial"
          name="startsIn"
          selectionMode="single"
          dateFormat="dd/mm/yy"
          customValue={filters.startsIn}
          onChangeValue={(startsIn: any) =>
            setFilters({
              ...filters,
              startsIn
            })
          }
          minDate={minDate}
          maxDate={maxDate}
        />

        <ActivatableCalendar
          label="Data final"
          name="endsIn"
          selectionMode="single"
          dateFormat="dd/mm/yy"
          customValue={filters.endsIn}
          onChangeValue={(endsIn: any) =>
            setFilters({
              ...filters,
              endsIn
            })
          }
          minDate={minDate}
          maxDate={maxDate}
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Aplicar
          </Button>

          <Button
            type="button"
            size="big"
            severity="info"
            onClick={handleCleanFilters}
          >
            <FaSave />
            Limpar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

StudentRequestFilterSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { StudentRequestFilterSidebar };
