import { InstitutionalHomeState } from './types';

export const INITIAL_STATE: InstitutionalHomeState = {
  banner: {
    id: 0,
    title: '',
    subtitle: '',
    description: '',
  },
  differential: {
    x: {
      id: 0,
      title: '',
      description: '',
      iconFileName: '',
    },
    iconUrl: '',
  },
  testimonial: {
    id: 0,
    message: '',
    contact: '',
    avatarUrl: '',
    showInHome: false,
  },
  differentials: [],
  testimonials: [],
};
