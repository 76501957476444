import { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import StudentRequest from 'models/StudentRequest';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputTextarea } from 'components/Form';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './StudentRequestContactSidebar.styles';

interface StudentRequestContactSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedStudentRequest: StudentRequest;
  onConfirmPress: (studentRequest: StudentRequest, message: string) => void;
  onCancelPress?: () => void;
}

const StudentRequestContactSidebar = (
  props: StudentRequestContactSidebarProps
) => {
  const {
    mode,
    setMode,
    selectedStudentRequest,
    onCancelPress,
    onConfirmPress
  } = props;

  const dispatch = useDispatch();

  const [message, setMessage] = useState('');

  const handleConfirmPress = () => {
    try {
      Yup.string()
        .required('O campo mensagem é obrigatório')
        .validateSync(message, { abortEarly: false });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    onConfirmPress(selectedStudentRequest, message);
    setMode('CLOSE');
    setMessage('');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
    setMessage('');
  };

  return (
    <Drawer
      title="Entrar em contato"
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputTextarea
          label="Mensagem"
          name="message"
          rows={6}
          cols={30}
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

StudentRequestContactSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { StudentRequestContactSidebar };
