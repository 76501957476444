import styled from 'styled-components';

export const Form = styled.form``;

export const ButtonGroup = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  justify-content: flex-end;

  button {
    text-align: right;

    &:first-child {
      margin-right: 8px;
    }
  }
`;
