import { Reducer } from 'redux';
import { AudioTypes, AudioState, AudioAction } from './types';
import { AUDIO_INITIAL_STATE } from './utils';

const reducer: Reducer<AudioState, AudioAction> = (
  state: AudioState = AUDIO_INITIAL_STATE,
  action: AudioAction
) => {
  switch (action.type) {
    case AudioTypes.SAVE_AUDIO_REQUEST: {
      return {
        ...state,
        audio: action.payload.audio
      };
    }

    case AudioTypes.EDIT_AUDIO_REQUEST: {
      return {
        ...state,
        audio: action.payload.audio
      };
    }

    case AudioTypes.REMOVE_AUDIO_REQUEST: {
      return {
        ...state,
        audio: action.payload.audio
      };
    }

    case AudioTypes.DOWNLOAD_AUDIO_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }

    case AudioTypes.DOWNLOAD_AUDIO_SUCCESS: {
      return {
        ...state,
        audioUrl: action.payload.audioUrl
      };
    }

    case AudioTypes.SET_SELECTED_AUDIO: {
      return {
        ...state,
        audio: action.payload.audio
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
