import styled from 'styled-components';
import { ContentLayout } from 'components';
import { H1, H4 } from 'components/Styled/Text';
import * as Breakpoint from 'styles/breakpoints';

export const ContainerFluid = styled.div`
  ${Breakpoint.Fluid};
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Container = styled(ContentLayout)``;

export const Row = styled.div`
  display: flex;
  height: 100%;
`;

export const Title = styled(H1)``;

export const Subtitle = styled(H4)``;
