import { Reducer } from 'redux';
import { UserAdmin } from 'models/User';
import { UserAdminTypes, UserAdminState } from './types';

export type Action = {
  type: UserAdminTypes;
  payload: {
    id: number;
    one: UserAdmin;
    all: UserAdmin[];
  };
};

const INITIAL_STATE: UserAdminState = {
  id: 0,
  one: {
    id: 0,
    name: '',
    email: '',
    cpf: '',
    password: '',
    isAdmin: false,
    removed: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    removedAt: new Date(),
    userPermissions: [],
    firstLogin: true,
    hubId: ''
  },
  all: []
};

const reducer: Reducer<UserAdminState, Action> = (
  state: UserAdminState = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case UserAdminTypes.FETCH_USERS_ADMIN_SUCCESS: {
      return {
        ...state,
        all: action.payload.all
      };
    }
    case UserAdminTypes.FETCH_ONE_USER_ADMIN_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    case UserAdminTypes.FETCH_ONE_USER_ADMIN_SUCCESS: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case UserAdminTypes.CREATE_USER_ADMIN_REQUEST: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case UserAdminTypes.UPDATE_USER_ADMIN_REQUEST: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case UserAdminTypes.DELETE_USER_ADMIN_REQUEST: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
