import PlaylistCategory from 'models/PlaylistCategory';

export enum PlaylistCategoryTypes {
    FETCH_PLAYLIST_CATEGORY_REQUEST = '@playlistCategory/FETCH_PLAYLIST_CATEGORY_REQUEST',
    FETCH_PLAYLIST_CATEGORY_SUCCESS = '@playlistCategory/FETCH_PLAYLIST_CATEGORY_SUCCESS',
    FETCH_PLAYLIST_CATEGORY_ERROR = '@playlistCategory/FETCH_PLAYLIST_CATEGORY_ERROR',

    EDIT_PLAYLIST_CATEGORY_REQUEST = '@playlistCategory/EDIT_PLAYLIST_CATEGORY_REQUEST',
    EDIT_PLAYLIST_CATEGORY_SUCCESS = '@playlistCategory/EDIT_PLAYLIST_CATEGORY_SUCCESS',
    EDIT_PLAYLIST_CATEGORY_ERROR = '@playlistCategory/EDIT_PLAYLIST_CATEGORY_ERROR',

    REMOVE_PLAYLIST_CATEGORY_REQUEST = '@playlistCategory/REMOVE_PLAYLIST_CATEGORY_REQUEST',
    REMOVE_PLAYLIST_CATEGORY_SUCCESS = '@playlistCategory/REMOVE_PLAYLIST_CATEGORY_SUCCESS',
    REMOVE_PLAYLIST_CATEGORY_ERROR = '@playlistCategory/REMOVE_PLAYLIST_CATEGORY_ERROR',

    SAVE_PLAYLIST_CATEGORY_REQUEST = '@playlistCategory/SAVE_PLAYLIST_CATEGORY_REQUEST',
    SAVE_PLAYLIST_CATEGORY_SUCCESS = '@playlistCategory/SAVE_PLAYLIST_CATEGORY_SUCCESS',
    SAVE_PLAYLIST_CATEGORY_ERROR = '@playlistCategory/SAVE_PLAYLIST_CATEGORY_ERROR',

}

export interface PlaylistCategoryState {
  readonly data: PlaylistCategory[];
  readonly old: PlaylistCategory;
}
