import moment from 'moment';
import { Tag } from 'primereact/tag';
import Audio from 'models/Audio';
import { FileStatus } from 'models/FileStatus';
import Folder from 'models/Folder';
import { getFileType } from 'pages/FileManager/FileManagerPage.utils';

const getNameBody = (file: Folder | Audio) =>
  getFileType(file) === 'AUDIO' ? (
    <span className="p-column-text-with-icon p-column-folder-name">
      <i className="pi pi-file p-column-text-with-icon" />
      {(file as Audio).title}
    </span>
  ) : (
    <span className="p-column-text-with-icon p-column-audio-name">
      <i className="pi pi-folder p-column-text-with-icon" />
      {(file as Folder).name}
    </span>
  );

const updatedAtBody = (file: Folder | Audio) => (
  <span>{moment(file.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
);

const createdAtBody = (file: Folder | Audio) => (
  <span>{moment(file.createdAt).format('DD/MM/YYYY HH:mm')}</span>
);

const typeBody = (file: Folder | Audio) =>
  getFileType(file) === 'AUDIO' ? 'Áudio' : 'Pasta';

const statusBody = (file: Folder | Audio) =>
  file.status === FileStatus.ENABLED ? (
    <Tag
      value="Ativo"
      icon="pi pi-check-circle"
      severity="info"
      rounded
      className="p-column-text-with-icon"
    />
  ) : (
    <Tag
      value="Inativo"
      icon="pi pi-times"
      severity="danger"
      rounded
      className="p-column-text-with-icon"
    />
  );

export { getNameBody, updatedAtBody, createdAtBody, typeBody, statusBody };
