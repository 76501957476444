import { action } from 'typesafe-actions';
import AudioPlaylistOrderer from 'models/AudioPlaylistOrderer';
import AudioToPlaylist from 'models/AudioToPlaylist';
import FolderTree from 'models/FolderTree';
import Playlist from 'models/Playlist';
import { PlaylistTypes } from './types';

export const fetchPlaylistRequest = () =>
  action(PlaylistTypes.FETCH_PLAYLIST_REQUEST);

export const fetchPlaylistSuccess = (data: Playlist[]) =>
  action(PlaylistTypes.FETCH_PLAYLIST_SUCCESS, { data });

export const fetchPlaylistError = () =>
  action(PlaylistTypes.FETCH_PLAYLIST_ERROR);

export const fetchOnePlaylistRequest = (old: Playlist) =>
  action(PlaylistTypes.FETCH_ONE_PLAYLIST_REQUEST, { old });

export const fetchOnePlaylistSuccess = (old: Playlist) =>
  action(PlaylistTypes.FETCH_ONE_PLAYLIST_SUCCESS, { old });

export const fetchOnePlaylistError = () =>
  action(PlaylistTypes.FETCH_ONE_PLAYLIST_ERROR);

export const editPlaylistRequest = (old: Playlist) =>
  action(PlaylistTypes.EDIT_PLAYLIST_REQUEST, { old });

export const editPlaylistSuccess = () =>
  action(PlaylistTypes.EDIT_PLAYLIST_SUCESS);

export const editPlaylistError = () =>
  action(PlaylistTypes.EDIT_PLAYLIST_ERROR);

export const editPlaylistOrdererRequest = (orderer: AudioPlaylistOrderer[]) =>
  action(PlaylistTypes.EDIT_PLAYLIST_ORDERER_REQUEST, { orderer });

export const editPlaylistOrdererSuccess = () =>
  action(PlaylistTypes.EDIT_PLAYLIST_ORDERER_SUCESS);

export const editPlaylistOrdererError = () =>
  action(PlaylistTypes.EDIT_PLAYLIST_ORDERER_ERROR);

export const savePlaylistRequest = (old: Playlist, cover: File) =>
  action(PlaylistTypes.SAVE_PLAYLIST_REQUEST, { old, cover });

export const savePlaylistSuccess = (old: Playlist) =>
  action(PlaylistTypes.SAVE_PLAYLIST_SUCESS, { old });

export const savePlaylistError = () =>
  action(PlaylistTypes.SAVE_PLAYLIST_ERROR);

export const removePlaylistRequest = (old: Playlist) =>
  action(PlaylistTypes.REMOVE_PLAYLIST_REQUEST, { old });

export const removePlaylistSuccess = () =>
  action(PlaylistTypes.REMOVE_PLAYLIST_SUCESS);

export const removePlaylistError = () =>
  action(PlaylistTypes.REMOVE_PLAYLIST_ERROR);

export const saveOrdererRequest = (old: Playlist) =>
  action(PlaylistTypes.SAVE_ORDER_REQUEST, { old });

export const saveOrdererSuccess = () => action(PlaylistTypes.SAVE_ORDER_SUCESS);

export const saveOrdererError = () => action(PlaylistTypes.SAVE_ORDER_ERROR);

export const fetchAllFoldersRequest = () =>
  action(PlaylistTypes.FETCH_ALL_FOLDERS_REQUEST);

export const fetchAllFoldersSuccess = (folderTree: FolderTree[]) =>
  action(PlaylistTypes.FETCH_ALL_FOLDERS_SUCESS, { folderTree });

export const fetchAllFoldersError = () =>
  action(PlaylistTypes.FETCH_ALL_FOLDERS_ERROR);

export const addAudioToPlaylistRequest = (audioToPlaylist: AudioToPlaylist) =>
  action(PlaylistTypes.ADD_AUDIO_TO_PLAYLIST_REQUEST, { audioToPlaylist });

export const addAudioToPlaylistSuccess = () =>
  action(PlaylistTypes.ADD_AUDIO_TO_PLAYLIST_SUCESS);

export const addAudioToPlaylistError = () =>
  action(PlaylistTypes.ADD_AUDIO_TO_PLAYLIST_ERROR);

export const removeAudioFromPlaylistRequest = (
  audioToPlaylist: AudioToPlaylist
) =>
  action(PlaylistTypes.REMOVE_AUDIO_FROM_PLAYLIST_REQUEST, { audioToPlaylist });

export const removeAudioFromPlaylistSuccess = () =>
  action(PlaylistTypes.REMOVE_AUDIO_FROM_PLAYLIST_SUCESS);

export const removeAudioFromPlaylistError = () =>
  action(PlaylistTypes.REMOVE_AUDIO_FROM_PLAYLIST_ERROR);
