import { useSelector } from 'react-redux';
import { ExportCsvButton, ExportPdfButton } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { SubscriptionReportPdfDocument } from '../SubscriptionReportPdfDocument/SubscriptionReportPdfDocument';
import * as S from './SubscriptionsReportExportButtons.styles';
import { getTranslatedFields } from './SubscriptionsReportExportButtons.utils';

const SubscriptionsReportExportButtons = () => {
  const { subscriptions } = useSelector(
    (state: ApplicationState) => state.subscriptions
  );

  return (
    <S.Row>
      <ExportCsvButton
        data={getTranslatedFields(subscriptions)}
        fileName="relatorio_de_assinaturas"
      />
      <ExportPdfButton
        document={<SubscriptionReportPdfDocument data={subscriptions} />}
        fileName="relatorio_de_assinaturas"
      />
    </S.Row>
  );
};

export { SubscriptionsReportExportButtons };
