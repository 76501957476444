import { Reducer } from 'redux';
import Log from 'models/Log';
import { LogTypes, LogState } from './types';

export type Action = {
  type: LogTypes;
  payload: {
    id: number;
    logs: Log[];
    log: Log;
  };
};

const INITIAL_STATE: LogState = {
  id: 0,
  logs: [],
  log: {
    id: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    content: JSON.parse('{}')
  }
};

const reducer: Reducer<LogState, Action> = (
  state: LogState = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case LogTypes.FETCH_LOGS_SUCCESS: {
      return {
        ...state,
        logs: action.payload.logs
      };
    }
    case LogTypes.FETCH_LOG_BY_ID_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    case LogTypes.FETCH_LOG_BY_ID_SUCCESS: {
      return {
        ...state,
        log: action.payload.log
      };
    }
    case LogTypes.FETCH_LOGS_BY_USER_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    case LogTypes.FETCH_LOGS_BY_USER_SUCCESS: {
      return {
        ...state,
        logs: action.payload.logs
      };
    }
    case LogTypes.FETCH_LOGS_BY_USER_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    case LogTypes.FETCH_LOGS_BY_USER_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        logs: action.payload.logs
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
