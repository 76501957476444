/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Quiz from 'models/Quiz';
import { fetchPlaylistRequest } from 'store/ducks/playlist/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, InputTextarea, Select } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import quizzesValidate from 'helpers/validation/quizzes';
import { quizInitialState } from '../../helpers/initialState';
import * as S from './QuizPlaylistActionSidebar.styles';

interface QuizPlaylistActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedQuiz: Quiz;
  onConfirmPress: (quiz: Quiz) => void;
  onCancelPress?: () => void;
}

const QuizPlaylistActionSidebar = (props: QuizPlaylistActionSidebarProps) => {
  const { mode, setMode, selectedQuiz, onCancelPress, onConfirmPress } = props;

  const dispatch = useDispatch();

  const playlist = useSelector(
    (state: ApplicationState) => state.playlist.data
  );

  const [quiz, setQuiz] = useState<Quiz>(selectedQuiz);

  useEffect(() => {
    dispatch(fetchPlaylistRequest());
  }, [dispatch]);

  useEffect(() => {
    if (mode === 'OPEN_ADD' && playlist.length) {
      Object.assign(quiz, { playListId: playlist[0].id });
    }
  }, [mode, playlist]);

  useEffect(() => {
    setQuiz(selectedQuiz);
  }, [selectedQuiz]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar quiz';
        break;
      case 'OPEN_EDIT':
        title = 'Editar quiz';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      quizzesValidate(quiz);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    if (onConfirmPress) {
      onConfirmPress(quiz);
    }
    setQuiz(quizInitialState);
    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setQuiz(quizInitialState);
    setMode('CLOSE');
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Nome"
          name="name"
          type="text"
          value={quiz.name}
          onChange={(e) =>
            setQuiz({
              ...quiz,
              name: e.target.value
            })
          }
        />

        <InputTextarea
          label="Descrição"
          name="description"
          rows={5}
          cols={30}
          value={quiz.description}
          onChange={(e) =>
            setQuiz({
              ...quiz,
              description: e.target.value
            })
          }
        />

        <Select
          label="Playlist"
          name="playlist"
          data={playlist}
          dataId="id"
          dataValue="name"
          selected={quiz.playListId}
          onSelection={(playlist) =>
            setQuiz({
              ...quiz,
              playListId: Number(playlist)
            })
          }
          placeholder="Selecione uma playlist"
          searchable
          searchPlaceholder="Buscar playlist"
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

QuizPlaylistActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { QuizPlaylistActionSidebar };
