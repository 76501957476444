import { takeLatest, all, AllEffect, ForkEffect } from 'redux-saga/effects';
import * as AnalyticsSagas from './ducks/analytics/sagas';
import { AnalyticsTypes } from './ducks/analytics/types';
import * as SagasAudio from './ducks/audio/sagas';
import { AudioTypes } from './ducks/audio/types';
import * as SagasFolder from './ducks/folder/sagas';
import { FolderTypes } from './ducks/folder/types';
import * as SagasInstitutionalAboutUs from './ducks/institutionalAboutUs/sagas';
import { InstitutionalAboutUsTypes } from './ducks/institutionalAboutUs/types';
import * as SagasInstitutionalContact from './ducks/institutionalContact/sagas';
import { InstitutionalContactTypes } from './ducks/institutionalContact/types';
import * as SagasInstitutionalFaq from './ducks/institutionalFaq/sagas';
import { InstitutionalFaqTypes } from './ducks/institutionalFaq/types';
import * as SagasInstitutionalHome from './ducks/institutionalHome/sagas';
import { InstitutionalHomeTypes } from './ducks/institutionalHome/types';
import * as SagasInstitutionalNews from './ducks/institutionalNews/sagas';
import { InstitutionalNewsTypes } from './ducks/institutionalNews/types';
import * as SagasInstitutionalTerms from './ducks/institutionalTerms/sagas';
import { InstitutionalTermsTypes } from './ducks/institutionalTerms/types';
import * as SagasLogin from './ducks/login/sagas';
import { LoginTypes } from './ducks/login/types';
import * as SagasLog from './ducks/logs/sagas';
import { LogTypes } from './ducks/logs/types';
import * as SagasPlan from './ducks/plan/sagas';
import { PlanTypes } from './ducks/plan/types';
import * as SagasPlaylist from './ducks/playlist/sagas';
import { PlaylistTypes } from './ducks/playlist/types';
import * as SagasTag from './ducks/playlistCategory/sagas';
import { PlaylistCategoryTypes } from './ducks/playlistCategory/types';
import * as SagasPromotion from './ducks/promotion/sagas';
import { PromotionTypes } from './ducks/promotion/types';
import * as SagasQuizAlternatives from './ducks/quizAlternatives/sagas';
import { QuizAlternativesTypes } from './ducks/quizAlternatives/types';
import * as SagasQuizQuestions from './ducks/quizQuestions/sagas';
import { QuizQuestionsTypes } from './ducks/quizQuestions/types';
import * as SagasQuizzes from './ducks/quizzes/sagas';
import { QuizTypes } from './ducks/quizzes/types';
import * as SagasStudentRequest from './ducks/studentRequest/sagas';
import { StudentRequestTypes } from './ducks/studentRequest/types';
import * as SagasSubscriber from './ducks/subscriber/sagas';
import { SubscriberTypes } from './ducks/subscriber/types';
import * as SagasSubscription from './ducks/subscriptions/sagas';
import { SubscriptionTypes } from './ducks/subscriptions/types';
import * as SagasSuperCategory from './ducks/superCategory/sagas';
import { SuperCategoryTypes } from './ducks/superCategory/types';
import * as SagasTeachingInstitution from './ducks/teachingInstitution/sagas';
import { TeachingInstitutionTypes } from './ducks/teachingInstitution/types';
import * as SagasTrash from './ducks/trash/sagas';
import { TrashTypes } from './ducks/trash/types';
import * as SagasUserAdmin from './ducks/userAdmin/sagas';
import { UserAdminTypes } from './ducks/userAdmin/types';
import * as SagasUserApp from './ducks/userApp/sagas';
import { UserAppTypes } from './ducks/userApp/types';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  any,
  unknown
> {
  return yield all([
    takeLatest(
      PlaylistTypes.FETCH_PLAYLIST_REQUEST,
      SagasPlaylist.fetchPlaylist
    ),
    takeLatest(
      PlaylistTypes.FETCH_ONE_PLAYLIST_REQUEST,
      SagasPlaylist.fetchOnePlaylist
    ),
    takeLatest(PlaylistTypes.EDIT_PLAYLIST_REQUEST, SagasPlaylist.editPlaylist),
    takeLatest(
      PlaylistTypes.EDIT_PLAYLIST_ORDERER_REQUEST,
      SagasPlaylist.editPlaylistOrderer
    ),
    takeLatest(PlaylistTypes.SAVE_PLAYLIST_REQUEST, SagasPlaylist.savePlaylist),
    takeLatest(
      PlaylistTypes.REMOVE_PLAYLIST_REQUEST,
      SagasPlaylist.removePlaylist
    ),
    takeLatest(
      PlaylistTypes.FETCH_ALL_FOLDERS_REQUEST,
      SagasPlaylist.fetchAllFolders
    ),
    takeLatest(
      PlaylistTypes.ADD_AUDIO_TO_PLAYLIST_REQUEST,
      SagasPlaylist.addAudioToPlaylist
    ),
    takeLatest(
      PlaylistTypes.REMOVE_AUDIO_FROM_PLAYLIST_REQUEST,
      SagasPlaylist.removeAudioFromPlaylist
    ),
    takeLatest(AudioTypes.EDIT_AUDIO_REQUEST, SagasAudio.editAudio),
    takeLatest(AudioTypes.REMOVE_AUDIO_REQUEST, SagasAudio.removeAudio),
    takeLatest(
      PromotionTypes.FETCH_ALL_PROMOTIONS_REQUEST,
      SagasPromotion.fetchAllPromotions
    ),
    takeLatest(
      PromotionTypes.CREATE_PROMOTION_REQUEST,
      SagasPromotion.createPromotion
    ),
    takeLatest(
      PromotionTypes.EDIT_PROMOTION_REQUEST,
      SagasPromotion.editPromotion
    ),
    takeLatest(
      PromotionTypes.DELETE_PROMOTION_REQUEST,
      SagasPromotion.deletePromotion
    ),
    takeLatest(PlanTypes.FETCH_PLAN_REQUEST, SagasPlan.fetchPlan),
    takeLatest(PlanTypes.CREATE_PLAN_REQUEST, SagasPlan.createPlan),
    takeLatest(PlanTypes.UPDATE_PLAN_REQUEST, SagasPlan.updatePlan),
    takeLatest(PlanTypes.DELETE_PLAN_REQUEST, SagasPlan.deletePlan),
    // takeLatest(
    //   FolderTypes.FETCH_FOLDER_REQUEST,
    //   SagasFolder.fetchFolder,
    // ),
    takeLatest(
      FolderTypes.FETCH_ONE_FOLDER_REQUEST,
      SagasFolder.fetchOneFolder
    ),
    takeLatest(
      FolderTypes.FETCH_VIEW_FOLDER_REQUEST,
      SagasFolder.fetchViewFolder
    ),
    takeLatest(FolderTypes.EDIT_FOLDER_REQUEST, SagasFolder.editFolder),
    takeLatest(FolderTypes.SAVE_FOLDER_REQUEST, SagasFolder.saveFolder),
    takeLatest(FolderTypes.REMOVE_FOLDER_REQUEST, SagasFolder.removeFolder),
    takeLatest(
      PlaylistCategoryTypes.FETCH_PLAYLIST_CATEGORY_REQUEST,
      SagasTag.fetchCategory
    ),
    takeLatest(
      PlaylistCategoryTypes.EDIT_PLAYLIST_CATEGORY_REQUEST,
      SagasTag.editCategory
    ),
    takeLatest(
      PlaylistCategoryTypes.REMOVE_PLAYLIST_CATEGORY_REQUEST,
      SagasTag.removeCategory
    ),
    takeLatest(
      PlaylistCategoryTypes.SAVE_PLAYLIST_CATEGORY_REQUEST,
      SagasTag.saveCategory
    ),
    takeLatest(TrashTypes.FETCH_TRASH_REQUEST, SagasTrash.fetchTrash),
    takeLatest(
      TrashTypes.RESTORE_TRASH_AUDIO_REQUEST,
      SagasTrash.restoreTrashAudio
    ),
    takeLatest(
      TrashTypes.REMOVE_TRASH_AUDIO_REQUEST,
      SagasTrash.removeTrashAudio
    ),
    takeLatest(
      TrashTypes.RESTORE_TRASH_FOLDER_REQUEST,
      SagasTrash.restoreTrashFolder
    ),
    takeLatest(
      TrashTypes.REMOVE_TRASH_FOLDER_REQUEST,
      SagasTrash.removeTrashFolder
    ),
    takeLatest(
      SubscriptionTypes.FETCH_SUBSCRIPTION_REQUEST,
      SagasSubscription.fetchSubscription
    ),
    takeLatest(
      SuperCategoryTypes.FETCH_SUPER_CATEGORY_REQUEST,
      SagasSuperCategory.fetchSuperCategory
    ),
    takeLatest(QuizTypes.FETCH_QUIZZES_REQUEST, SagasQuizzes.fetchQuizzes),
    takeLatest(QuizTypes.FETCH_ONE_QUIZ_REQUEST, SagasQuizzes.fetchOneQuiz),
    takeLatest(QuizTypes.CREATE_QUIZ_REQUEST, SagasQuizzes.createQuiz),
    takeLatest(QuizTypes.UPDATE_QUIZ_REQUEST, SagasQuizzes.updateQuiz),
    takeLatest(QuizTypes.DELETE_QUIZ_REQUEST, SagasQuizzes.deleteQuiz),
    takeLatest(
      QuizQuestionsTypes.FETCH_ONE_QUIZ_QUESTIONS_REQUEST,
      SagasQuizQuestions.fetchOneQuizQuestions
    ),
    takeLatest(
      QuizQuestionsTypes.CREATE_QUIZ_QUESTION_REQUEST,
      SagasQuizQuestions.createQuizQuestion
    ),
    takeLatest(
      QuizQuestionsTypes.UPDATE_QUIZ_QUESTION_REQUEST,
      SagasQuizQuestions.updateQuizQuestion
    ),
    takeLatest(
      QuizQuestionsTypes.DELETE_QUIZ_QUESTION_REQUEST,
      SagasQuizQuestions.deleteQuizQuestion
    ),
    takeLatest(
      QuizAlternativesTypes.CREATE_QUESTION_ALTERNATIVE_REQUEST,
      SagasQuizAlternatives.createQuizAlternative
    ),
    takeLatest(
      QuizAlternativesTypes.UPDATE_QUESTION_ALTERNATIVE_REQUEST,
      SagasQuizAlternatives.updateQuizAlternative
    ),
    takeLatest(
      QuizAlternativesTypes.DELETE_QUESTION_ALTERNATIVE_REQUEST,
      SagasQuizAlternatives.deleteQuizAlternative
    ),
    takeLatest(
      UserAppTypes.FETCH_USERS_APP_REQUEST,
      SagasUserApp.fetchUsersApp
    ),
    takeLatest(
      UserAppTypes.FETCH_ONE_USER_APP_REQUEST,
      SagasUserApp.fetchOneUserApp
    ),
    takeLatest(
      UserAppTypes.CREATE_USER_APP_REQUEST,
      SagasUserApp.createUserApp
    ),
    takeLatest(
      UserAppTypes.UPDATE_USER_APP_REQUEST,
      SagasUserApp.updateUserApp
    ),
    takeLatest(
      UserAppTypes.DELETE_USER_APP_REQUEST,
      SagasUserApp.deleteUserApp
    ),
    takeLatest(
      UserAdminTypes.FETCH_USERS_ADMIN_REQUEST,
      SagasUserAdmin.fetchUsersAdmin
    ),
    takeLatest(
      UserAdminTypes.FETCH_ONE_USER_ADMIN_REQUEST,
      SagasUserAdmin.fetchOneUserAdmin
    ),
    takeLatest(
      UserAdminTypes.CREATE_USER_ADMIN_REQUEST,
      SagasUserAdmin.createUserAdmin
    ),
    takeLatest(
      UserAdminTypes.UPDATE_USER_ADMIN_REQUEST,
      SagasUserAdmin.updateUserAdmin
    ),
    takeLatest(
      UserAdminTypes.DELETE_USER_ADMIN_REQUEST,
      SagasUserAdmin.deleteUserAdmin
    ),
    takeLatest(LogTypes.FETCH_LOGS_REQUEST, SagasLog.fetchLogs),
    takeLatest(LogTypes.FETCH_LOG_BY_ID_REQUEST, SagasLog.fetchLogById),
    takeLatest(LogTypes.FETCH_LOGS_BY_USER_REQUEST, SagasLog.fetchLogsByUser),
    takeLatest(
      LogTypes.FETCH_LOGS_BY_USER_SUBSCRIPTION_REQUEST,
      SagasLog.fetchLogsByUserSubscription
    ),
    takeLatest(
      SubscriberTypes.FETCH_SUBSCRIBERS_REQUEST,
      SagasSubscriber.fetchSubscribers
    ),
    takeLatest(
      SubscriberTypes.FETCH_ONE_SUBSCRIBER_REQUEST,
      SagasSubscriber.fetchOneSubscriber
    ),
    takeLatest(
      SubscriberTypes.UPDATE_SUBSCRIBER_REQUEST,
      SagasSubscriber.updateSubscriber
    ),
    takeLatest(
      TeachingInstitutionTypes.FETCH_TEACHING_INSTITUTIONS_REQUEST,
      SagasTeachingInstitution.fetchTeachingInstitutions
    ),
    takeLatest(
      TeachingInstitutionTypes.FETCH_ONE_TEACHING_INSTITUTION_REQUEST,
      SagasTeachingInstitution.fetchOneTeachingInstitution
    ),
    takeLatest(
      TeachingInstitutionTypes.CREATE_TEACHING_INSTITUTION_REQUEST,
      SagasTeachingInstitution.createTeachingInstitution
    ),
    takeLatest(
      TeachingInstitutionTypes.UPDATE_TEACHING_INSTITUTION_REQUEST,
      SagasTeachingInstitution.updateTeachingInstitution
    ),
    takeLatest(
      TeachingInstitutionTypes.DELETE_TEACHING_INSTITUTION_REQUEST,
      SagasTeachingInstitution.deleteTeachingInstitution
    ),
    takeLatest(LoginTypes.LOGIN_REQUEST, SagasLogin.login),
    takeLatest(LoginTypes.LOGOUT_REQUEST, SagasLogin.logout),
    takeLatest(AudioTypes.DOWNLOAD_AUDIO_REQUEST, SagasAudio.downloadAudio),
    takeLatest(
      StudentRequestTypes.FETCH_STUDENTS_REQ_REQUEST,
      SagasStudentRequest.fetchStudentsRequest
    ),
    takeLatest(
      StudentRequestTypes.UPDATE_STUDENT_REQ_REQUEST,
      SagasStudentRequest.updateStudentRequest
    ),
    takeLatest(
      StudentRequestTypes.SEND_STUDENT_MESSAGE_REQUEST,
      SagasStudentRequest.sendStudentMessage
    ),
    takeLatest(
      InstitutionalHomeTypes.FETCH_HOME_DATA,
      SagasInstitutionalHome.fetchHomeData
    ),
    takeLatest(
      InstitutionalHomeTypes.BANNER_EDIT,
      SagasInstitutionalHome.editHomeBanner
    ),
    takeLatest(
      InstitutionalHomeTypes.DIFFERENTIAL_CREATE,
      SagasInstitutionalHome.createHomeDifferential
    ),
    takeLatest(
      InstitutionalHomeTypes.DIFFERENTIAL_EDIT,
      SagasInstitutionalHome.editHomeDifferential
    ),
    takeLatest(
      InstitutionalHomeTypes.DIFFERENTIAL_REMOVE,
      SagasInstitutionalHome.removeHomeDifferential
    ),
    takeLatest(
      InstitutionalHomeTypes.TESTIMONIAL_CREATE,
      SagasInstitutionalHome.createHomeTestimonials
    ),
    takeLatest(
      InstitutionalHomeTypes.TESTIMONIAL_EDIT,
      SagasInstitutionalHome.editHomeTestimonials
    ),
    takeLatest(
      InstitutionalHomeTypes.TESTIMONIAL_REMOVE,
      SagasInstitutionalHome.removeHomeTestimonials
    ),
    takeLatest(
      InstitutionalHomeTypes.HOME_REQUEST_SUCCESS,
      SagasInstitutionalHome.showSuccessMessage
    ),
    takeLatest(
      InstitutionalHomeTypes.HOME_REQUEST_ERROR,
      SagasInstitutionalHome.showErrorMessage
    ),
    takeLatest(
      InstitutionalAboutUsTypes.FETCH_ABOUT_US_REQUEST,
      SagasInstitutionalAboutUs.fetchAboutUs
    ),
    takeLatest(
      InstitutionalAboutUsTypes.UPDATE_ABOUT_US_REQUEST,
      SagasInstitutionalAboutUs.updateAboutUs
    ),
    takeLatest(
      InstitutionalAboutUsTypes.ABOUT_US_REQUEST_SUCCESS,
      SagasInstitutionalAboutUs.showSuccessMessage
    ),
    takeLatest(
      InstitutionalAboutUsTypes.ABOUT_US_REQUEST_ERROR,
      SagasInstitutionalAboutUs.showErrorMessage
    ),
    takeLatest(
      InstitutionalTermsTypes.FETCH_TERMS_REQUEST,
      SagasInstitutionalTerms.fetchTerms
    ),
    takeLatest(
      InstitutionalTermsTypes.EDIT_TERMS_REQUEST,
      SagasInstitutionalTerms.editTerms
    ),
    takeLatest(
      InstitutionalNewsTypes.FETCH_NEWS_REQUEST,
      SagasInstitutionalNews.fetchNewsArticles
    ),
    takeLatest(
      InstitutionalNewsTypes.CREATE_NEWS_REQUEST,
      SagasInstitutionalNews.createArticle
    ),
    takeLatest(
      InstitutionalNewsTypes.UPDATE_NEWS_REQUEST,
      SagasInstitutionalNews.updateArticle
    ),
    takeLatest(
      InstitutionalNewsTypes.DELETE_NEWS_REQUEST,
      SagasInstitutionalNews.deleteArticle
    ),
    takeLatest(
      InstitutionalNewsTypes.NEWS_REQUEST_SUCCESS,
      SagasInstitutionalNews.showSuccessMessage
    ),
    takeLatest(
      InstitutionalNewsTypes.NEWS_REQUEST_ERROR,
      SagasInstitutionalNews.showErrorMessage
    ),
    takeLatest(
      InstitutionalFaqTypes.FETCH_FAQ_LIST_REQUEST,
      SagasInstitutionalFaq.fetchFaqList
    ),
    takeLatest(
      InstitutionalFaqTypes.CREATE_FAQ_QUESTION_REQUEST,
      SagasInstitutionalFaq.createQuestion
    ),
    takeLatest(
      InstitutionalFaqTypes.UPDATE_FAQ_QUESTION_REQUEST,
      SagasInstitutionalFaq.updateQuestion
    ),
    takeLatest(
      InstitutionalFaqTypes.DELETE_FAQ_QUESTION_REQUEST,
      SagasInstitutionalFaq.deleteQuestion
    ),
    takeLatest(
      InstitutionalFaqTypes.FAQ_REQUEST_SUCCESS,
      SagasInstitutionalFaq.showSuccessMessage
    ),
    takeLatest(
      InstitutionalFaqTypes.FAQ_REQUEST_ERROR,
      SagasInstitutionalFaq.showErrorMessage
    ),
    takeLatest(
      InstitutionalContactTypes.FETCH_CONTACTS_REQUEST,
      SagasInstitutionalContact.fetchContacts
    ),
    takeLatest(
      SubscriberTypes.CANCEL_SUBSCRIPTION_REQUEST,
      SagasSubscriber.cancelSubscription
    ),
    takeLatest(
      AnalyticsTypes.USAGE_PER_HOURS_REQUEST,
      AnalyticsSagas.usagePerHours
    ),
    takeLatest(
      AnalyticsTypes.PLAYLISTS_USAGE_REQUEST,
      AnalyticsSagas.playlistsUsage
    ),
    takeLatest(
      AnalyticsTypes.ACTIVE_USERS_BY_PLAN_REQUEST,
      AnalyticsSagas.activeUsersByPlan
    ),
    takeLatest(
      AnalyticsTypes.ACTIVE_USERS_BY_PERIOD_REQUEST,
      AnalyticsSagas.activeUsersByPeriod
    ),
    takeLatest(
      AnalyticsTypes.CANCELED_OR_ACTIVE_PAYING_USERS_REQUEST,
      AnalyticsSagas.canceledOrActivePayingUsers
    ),
    takeLatest(
      AnalyticsTypes.CANCELED_AND_ACTIVE_USERS_AFTER_TEST_REQUEST,
      AnalyticsSagas.canceledAndActiveUsersAfterTest
    ),
    takeLatest(
      AnalyticsTypes.CANCELED_USERS_AFTER_TEST_REQUEST,
      AnalyticsSagas.canceledUsersAfterTest
    ),
    takeLatest(
      AnalyticsTypes.USERS_IN_PROMOTION_REQUEST,
      AnalyticsSagas.usersInPromotion
    ),
    takeLatest(
      AnalyticsTypes.USERS_IN_TEST_REQUEST,
      AnalyticsSagas.usersInTest
    ),
    takeLatest(
      AnalyticsTypes.PAYMENT_AMOUNT_REQUEST,
      AnalyticsSagas.paymentAmount
    ),
    takeLatest(
      AnalyticsTypes.FINANCIAL_REPORT_REQUEST,
      AnalyticsSagas.financialReport
    ),
    takeLatest(
      AnalyticsTypes.SUBSCRIPTIONS_SUMMARY_REQUEST,
      AnalyticsSagas.subscriptionsSummary
    ),
    takeLatest(
      AnalyticsTypes.ALL_SUBSCRIPTIONS_REQUEST,
      AnalyticsSagas.allSubscriptions
    )
  ]);
}
