import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { UserApp } from 'models/User';
import { showDialog } from 'store/ducks/dialog/actions';
import * as UserSagas from 'store/ducks/userApp/actions';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './UserAppDataTable.styles';

interface UserAppDataTableProps {
  data: UserApp[];
  onPressItem: (quiz: UserApp) => void;
}

const UserAppDataTable = (props: UserAppDataTableProps) => {
  const { data, onPressItem } = props;

  const dispatch = useDispatch();

  const handleRemovePressed = (user: UserApp) => {
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover usuário',
        message: `Deseja reamente remover o user "${user.name}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () => dispatch(UserSagas.deleteUserAppRequest(user))
      })
    );
  };

  const getActionButtons = (user: UserApp) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(user)}
        severity="confirm"
        className="p-button-success"
        icon="pi pi-pencil"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleRemovePressed(user)}
        severity="danger"
        className="p-button-danger"
        icon="pi pi-trash"
      >
        <FaTrash />
      </Button>
    </S.ActionButtons>
  );

  const updatedAtBody = (user: UserApp) => (
    <span>{moment(user.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const createdAtBody = (user: UserApp) => (
    <span>{moment(user.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const isNotFullhd = useMediaQuery({ maxWidth: '1368px' });

  const emailBody = (user: UserApp) =>
    isNotFullhd ? `${user.email.substring(0, 22)}...` : user.email;

  const handleRowDoubleClick = (data: UserApp) => {
    onPressItem(data);
  };

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        value={data}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        filterDisplay="row"
      >
        <Column field="id" header="ID" style={{ width: '60px' }} sortable />
        <Column
          field="name"
          header="NOME"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Buscar"
          body={emailBody}
          sortable
        />
        <Column
          sortField="createdAt"
          header="Criado em"
          style={{ width: '145px' }}
          body={createdAtBody}
          sortable
        />
        <Column
          sortField="updatedAt"
          header="Atualizado em"
          style={{ width: '160px' }}
          body={updatedAtBody}
          sortable
        />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          body={getActionButtons}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { UserAppDataTable };
