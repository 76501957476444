import * as yup from 'yup';
import PlaylistCategory from 'models/PlaylistCategory';

const promotionSchema = yup.object().shape({
  name: yup.string().required('O campo Nome é obrigatório'),
  superCategoryId: yup
    .number()
    .required('O campo Super Categoria é obrigatório')
});

export default (category: PlaylistCategory) =>
  promotionSchema.validateSync(category, { abortEarly: false });
