import styled from 'styled-components';

export const EditorWrapper = styled.div`
  padding: 16px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.section.bg};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
`;

export const FormGroup = styled.div<{ $column?: boolean }>`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 32px;
  }
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
`;