/* eslint-disable no-prototype-builtins */
/* eslint-disable no-new-object */
// eslint-disable react/jsx-one-expression-per-line
import { useEffect, useState } from 'react';
import {
  FaTrash,
  FaFolder,
  FaFileAudio,
  FaTimesCircle,
  FaRegCheckCircle,
  FaRecycle
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Audio from 'models/Audio';
import { FileStatus } from 'models/FileStatus';
import Folder from 'models/Folder';
import { showDialog } from 'store/ducks/dialog/actions';
import * as SagasTrash from 'store/ducks/trash/actions';
import { Button, DataTableLayout } from 'components';
import * as utils from 'pages/FileManager/components/DataTable/FileManagerDataTable.utils';
import { getFileType } from 'pages/FileManager/FileManagerPage.utils';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './TrashDataTable.styles';

interface TrashDataTableProps {
  data: any[];
  onChangeMultipleSelecteds: (data: any) => void;
  multipleSelecteds: any[];
}

const TrashDataTable = (props: TrashDataTableProps) => {
  const { data, multipleSelecteds } = props;

  const dispatch = useDispatch();
  const [multiSelectionItems, setMultiSelectionItems] =
    useState<any[]>(multipleSelecteds);

  const isAudio = (e: any) => new Object(e).hasOwnProperty('title');

  useEffect(() => {
    data.map((item) => {
      if (isAudio(item)) {
        Object.assign(item, { type: 'audio' });
        Object.assign(item, { name: item.title });
      } else {
        Object.assign(item, { type: 'folder' });
      }
      return item;
    });
  }, [data]);

  useEffect(() => {
    setMultiSelectionItems(multipleSelecteds);
  }, [multipleSelecteds]);

  const handleSingleRemovePressed = (dtSRm: any) => {
    const titleRemove = 'Remover';
    const messageRemove = 'Deseja realmente remover definitivamente?';

    let remove;

    if (isAudio(dtSRm)) {
      remove = () => dispatch(SagasTrash.removeTrashAudioRequest(dtSRm));
    } else {
      remove = () => dispatch(SagasTrash.removeTrashFolderRequest(dtSRm));
    }

    dispatch(
      showDialog({
        visible: true,
        title: titleRemove,
        message: messageRemove,
        mode: 'CONFIRM',
        onCancelPress: () => '',
        onConfirmPress: remove
      })
    );
  };

  const handleSingleRestorePressed = (dtSRe: any) => {
    const titleRestore = 'Restaurar';
    const messageRestore = 'Deseja realmente restaurar?';

    let restore;

    if (isAudio(dtSRe)) {
      restore = () => dispatch(SagasTrash.restoreTrashAudioRequest(dtSRe));
    } else {
      restore = () => dispatch(SagasTrash.restoreTrashFolderRequest(dtSRe));
    }

    dispatch(
      showDialog({
        visible: true,
        title: titleRestore,
        message: messageRestore,
        mode: 'CONFIRM',
        onCancelPress: () => '',
        onConfirmPress: restore
      })
    );
  };

  const getActionButtons = (dtAc: any) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => handleSingleRestorePressed(dtAc)}
        className="p-button-success"
        icon="pi pi-pencil"
      />
      <Button
        type="button"
        onClick={() => handleSingleRemovePressed(dtAc)}
        severity="danger"
        className="p-button-danger"
        icon="pi pi-trash"
      />
    </S.ActionButtons>
  );

  const removedAtBody = (dtRm: any) => (
    <span>{moment(dtRm.removedAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const typeBody = (dtTb: any) => (isAudio(dtTb) ? 'Áudio' : 'Pasta');

  const statusBody = (file: Folder | Audio) =>
    file.status === FileStatus.ENABLED ? (
      <span className="active">
        <FaRegCheckCircle />
        Ativo
      </span>
    ) : (
      <span className="inactive">
        <FaTimesCircle />
        Inativo
      </span>
    );

  const handleMultiSelectionChange = (e: any) => {
    if (!Array.isArray(e.value)) return;
    setMultiSelectionItems(e.value);
    props.onChangeMultipleSelecteds(e.value);
  };

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        value={data}
        selection={multiSelectionItems}
        onSelectionChange={handleMultiSelectionChange}
        onRowDoubleClick={(e) => handleSingleRestorePressed(e.data)}
        paginator
        rows={10}
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
      >
        <Column
          className="selectionColumn"
          selectionMode="multiple"
          style={{ width: '2em' }}
        />
        <Column
          field="id"
          header="ID"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          style={{ width: '60px' }}
        />
        <Column
          field="name"
          header="NOME"
          body={utils.getNameBody}
          className="fileNameColumn"
          filterHeaderClassName="searchFileName"
          filter
          filterField="name"
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          header="Removido em"
          sortable
          body={removedAtBody}
          style={{ width: '180px' }}
          sortField="removedAt"
        />
        <Column
          header="Tipo"
          sortable
          sortField="type"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          body={typeBody}
          style={{ width: '90px' }}
        />
        <Column
          header="Status"
          sortable
          sortField="status"
          headerClassName="isCentered"
          bodyClassName="statusColumn isCentered"
          style={{ width: '120px' }}
          body={utils.statusBody}
        />
        <Column
          bodyClassName="actionsColumn"
          header="Ações"
          style={{ width: '100px' }}
          body={getActionButtons}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { TrashDataTable };
