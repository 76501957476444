import { Reducer } from 'redux';
import { CurrentFolderTypes, CurrentFolderState } from './types';

export type Action = {
  type: CurrentFolderTypes;
  payload: {
      parentId: number;
  };
};

const INITIAL_STATE: CurrentFolderState = {
  parentId: 0,
};

const reducer: Reducer<CurrentFolderState, Action> = (
  state: CurrentFolderState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case CurrentFolderTypes.SET_FOLDER_ID: {
    return {
      ...state,
      parentId: action.payload.parentId,
    };
  }

  default: {
    return state;
  }
  }
};

export default reducer;
