import { useEffect, useState } from 'react';
import { FaPlus, FaArrowLeft, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import AudioToPlaylist from 'models/AudioToPlaylist';
import Playlist from 'models/Playlist';
import { showDialog } from 'store/ducks/dialog/actions';
import {
  fetchPlaylistRequest,
  editPlaylistRequest,
  savePlaylistRequest,
  fetchAllFoldersRequest,
  removeAudioFromPlaylistRequest
} from 'store/ducks/playlist/actions';
import { fetchPlaylistCategoryRequest } from 'store/ducks/playlistCategory/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { PlaylistDisplayMode } from 'helpers/types/PlaylistDisplayMode';
import {
  PlaylistActionSidebar,
  PlaylistActionSidebarImport,
  PlaylistAddNewCategoryDialog,
  PlaylistDataTable,
  PlaylistDragrable
} from './components';
import playlistInitialState from './helpers/initialState';
import * as S from './PlaylistPage.styles';

const PlaylistPage = () => {
  const dispatch = useDispatch();

  const playlists = useSelector(
    (state: ApplicationState) => state.playlist.data
  );

  const [playlistSelected, setPlaylistSelected] =
    useState<Playlist>(playlistInitialState);
  const [drawerModePlaylist, setDrawerModePlaylist] =
    useState<DrawerMode>('CLOSE');
  const [drawerModeMountPlaylist, setDrawerModeMountPlaylist] =
    useState<DrawerMode>('CLOSE');
  const [multiSelectedAudios, setMultiSelectedAudios] = useState<number[]>([]);

  const [displayMode, setDisplayMode] =
    useState<PlaylistDisplayMode>('DATATABLE');
  const [visibleAddNewCategory, setVisibleAddNewCategory] = useState(false);

  useEffect(() => {
    dispatch(fetchPlaylistRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllFoldersRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPlaylistCategoryRequest());
  }, [dispatch]);

  const handleAddPlaylistPressed = () => {
    setPlaylistSelected(playlistInitialState);
    setDrawerModeMountPlaylist('CLOSE');
    setDrawerModePlaylist('OPEN_ADD');
  };

  const handleEditPressed = (item: any) => {
    setPlaylistSelected(item);
    setDrawerModeMountPlaylist('CLOSE');
    setDrawerModePlaylist('OPEN_EDIT');
  };

  const handleAddAudioPressed = () => {
    setDrawerModePlaylist('CLOSE');
    setDrawerModeMountPlaylist('OPEN_ADD');
  };

  const handleSavePressed = (data: any, cover?: File) => {
    if (data.id && data.id !== 0) {
      dispatch(editPlaylistRequest(data));
    } else if (cover) {
      dispatch(savePlaylistRequest(data, cover));
    }
  };

  const handleAddPressed = () => {
    if (displayMode === 'DATATABLE') {
      handleAddPlaylistPressed();
    } else {
      handleAddAudioPressed();
    }
  };

  const navTo = (data: any) => {
    setPlaylistSelected(data);
    setDisplayMode('DRAGRABLE');
  };

  const handleBackPress = () => {
    setPlaylistSelected({ ...playlistInitialState });
    setDisplayMode('DATATABLE');
  };

  const handleMultiRemovePress = () => {
    const audiosToRemove = multiSelectedAudios.map((id) => ({ id }));

    const obj: AudioToPlaylist = {
      playlist: playlistSelected,
      audios: audiosToRemove
    };

    const remove = () => dispatch(removeAudioFromPlaylistRequest(obj));

    dispatch(
      showDialog({
        visible: true,
        title: 'Remover audio',
        message: `Deseja realmente desvincular ${multiSelectedAudios.length} audios desta playlist?`,
        mode: 'CONFIRM',
        onCancelPress: () => '',
        onConfirmPress: remove
      })
    );
  };

  const clearFields = () => {
    // setPlaylistSelected(playlistInitialState);
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={handleBackPress}
      disabled={displayMode === 'DATATABLE'}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  const getActionButtons = () => (
    <S.Row>
      <Button
        type="button"
        severity="danger"
        onClick={handleMultiRemovePress}
        disabled={!multiSelectedAudios.length}
      >
        <FaTrash />
        Desvincular
      </Button>
      <Button type="button" severity="primary" onClick={handleAddPressed}>
        <FaPlus />
        {displayMode === 'DATATABLE' ? 'Adicionar Playlist' : 'Adicionar Audio'}
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <PlaylistAddNewCategoryDialog
        visible={visibleAddNewCategory}
        setVisible={setVisibleAddNewCategory}
      />

      <S.Row className="height">
        <PlaylistActionSidebarImport
          mode={drawerModeMountPlaylist}
          setMode={setDrawerModeMountPlaylist}
          playlist={playlistSelected}
        />

        <PlaylistActionSidebar
          mode={drawerModePlaylist}
          setMode={setDrawerModePlaylist}
          playlist={playlistSelected}
          onConfirmPress={handleSavePressed}
          setVisibleAddNewCategory={setVisibleAddNewCategory}
          handleClear={clearFields}
        />

        <S.Container>
          <PageHeader
            title="Playlists"
            breadcrumbsBottomComponent={getBackButton()}
            actionsComponent={getActionButtons()}
          />

          {displayMode === 'DATATABLE' ? (
            <PlaylistDataTable
              data={playlists}
              onPressItem={handleEditPressed}
              onDoubleClick={navTo}
              clearFields={clearFields}
            />
          ) : (
            <PlaylistDragrable
              playlist={playlistSelected}
              onChangeMultiAudiosSelecteds={setMultiSelectedAudios}
            />
          )}
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { PlaylistPage };
