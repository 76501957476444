import { Banner, Differential, Testimonial } from 'models/Institutional';

export const banner: Banner = {
  id: 0,
  title: '',
  subtitle: '',
  description: '',
};

export const differential: Differential = {
  x: {
    id: 0,
    title: '',
    description: '',
    iconFileName: '',
  },
  iconUrl: '',
};

export const testimonial: Testimonial = {
  id: 0,
  message: '',
  contact: '',
  avatarUrl: '',
  showInHome: false,
};

export const INITIAL_STATE = { banner, differential, testimonial };
