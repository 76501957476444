import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationError } from 'yup';
import { Filters } from 'models/Filters';
import { fetchTeachingInstitutionsRequest } from 'store/ducks/teachingInstitution/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import {
  ActivatableCalendar,
  ActivatableTextInput,
  ActivatableSelect
} from 'components/ActivatableInput';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { validateSubscribersFilters } from 'helpers/validation/subscribersFilters';
import * as S from './SubscriberFilterSidebar.styles';

interface SubscriberFilterSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedFilters: Filters;
  onConfirmPress: (filters: Filters) => void;
  onCleanPress: () => void;
  onCancelPress?: () => void;
}

const SubscriberFilterSidebar = (props: SubscriberFilterSidebarProps) => {
  const {
    mode,
    setMode,
    selectedFilters,
    onCancelPress,
    onConfirmPress,
    onCleanPress
  } = props;
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<Filters>(selectedFilters);

  const teachingInstitutions = useSelector(
    (state: ApplicationState) => state.teachingInstitution.all
  );

  const subscriptionStatus = [
    { id: 'ENABLED', name: 'Ativa' },
    { id: 'OVERDUE', name: 'Atrasada' },
    { id: 'CANCELED', name: 'Cancelada' },
    { id: 'IN_TEST', name: 'Em teste' }
  ];

  useEffect(() => {
    setFilters(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    setFilters({
      ...filters,
      status: {
        value: subscriptionStatus[0].id,
        enable: false
      }
    });
  }, []);

  useEffect(() => {
    if (teachingInstitutions.length) {
      setFilters({
        ...filters,
        teachingInstitutionId: {
          value: teachingInstitutions[0].id.toString(),
          enable: false
        }
      });
    }
  }, [teachingInstitutions]);

  useEffect(() => {
    dispatch(fetchTeachingInstitutionsRequest());
  }, [dispatch]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_FILTER':
        title = 'Selecionar filtros';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      validateSubscribersFilters(filters);
      onConfirmPress({
        ...filters,
        planValue: {
          ...filters.planValue,
          value: Number(filters.planValue.value)
        }
      });
      setMode('CLOSE');
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const handleCleanFilters = () => {
    onCleanPress();
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  const getTeachingInstitutions = () =>
    teachingInstitutions.map((teachingInstitution) => ({
      ...teachingInstitution,
      id: teachingInstitution.id.toString()
    }));

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <ActivatableSelect
          label="Instituição de ensino"
          name="teachingInstitutionId"
          placeholder="Selecione uma instituição"
          data={getTeachingInstitutions()}
          dataId="id"
          dataValue="name"
          customValue={filters.teachingInstitutionId}
          onChangeValue={(teachingInstitutionId) =>
            setFilters({
              ...filters,
              teachingInstitutionId
            })
          }
          searchable
          searchPlaceholder="Pesquisar"
        />

        <ActivatableSelect
          label="Status da assinatura"
          name="status"
          placeholder="Selecione um status"
          data={subscriptionStatus}
          dataId="id"
          dataValue="name"
          customValue={filters.status}
          onChangeValue={(status) =>
            setFilters({
              ...filters,
              status
            })
          }
          searchable
          searchPlaceholder="Pesquisar"
        />

        <ActivatableCalendar
          label="Data de cadastro"
          name="createdAt"
          selectionMode="range"
          dateFormat="dd/mm/yy"
          customValue={filters.CreatedAt}
          onChangeValue={(CreatedAt) => {
            setFilters({
              ...filters,
              CreatedAt
            });
          }}
        />

        <ActivatableCalendar
          label="Data de cancelamento"
          name="canceledAt"
          selectionMode="single"
          dateFormat="dd/mm/yy"
          customValue={filters.CanceledAt}
          onChangeValue={(CanceledAt) =>
            setFilters({
              ...filters,
              CanceledAt
            })
          }
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Aplicar
          </Button>

          <Button
            type="button"
            size="big"
            severity="info"
            onClick={handleCleanFilters}
          >
            <FaSave />
            Limpar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

SubscriberFilterSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { SubscriberFilterSidebar };
