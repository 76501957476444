import Cookies from 'js-cookie';
import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { PRIVATE_ROUTES } from 'helpers/constants';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { removeLoadingModule, addLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError
} from './actions';
import { Action } from './index';

export function* login(action: Action) {
  let error = false;
  let loginExpirenIn: any;
  let loginIsAdmin = 'false';
  let serializedToken = '';
  let serializedPermissions = '';

  try {
    yield put(addLoadingModule(StoreModule.LOGIN));

    const { info } = action.payload;

    const response: ConnectorResponse = yield call(
      Connector().post,
      'sessions/cms',
      info
    );

    const { token, expireIn, admin, permissions } = response.data;
    serializedToken = `Bearer ${token}`;
    serializedPermissions = JSON.stringify(
      permissions.map((p: string) => btoa(p))
    );
    loginExpirenIn = expireIn;
    loginIsAdmin = admin;

    yield put(loginSuccess(response.data));
    yield put(
      showToast({
        summary: 'Login',
        detail: 'Login efetuado com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    error = true;
    yield put(loginError());
    yield put(
      showToast({
        summary: 'Login',
        detail: 'Falha ao efetuar login',
        severity: 'error'
      })
    );
  } finally {
    if (!error) {
      window.location.replace(PRIVATE_ROUTES.HOME);

      Cookies.set('AuthToken', serializedToken, {
        expires: new Date(loginExpirenIn)
      });
      Cookies.set('AuthAdmin', loginIsAdmin, {
        expires: new Date(loginExpirenIn)
      });
      Cookies.set(btoa('AuthAccess'), serializedPermissions, {
        expires: new Date(loginExpirenIn)
      });
      localStorage.setItem('@tokenExpiresIn', loginExpirenIn);
    }
    yield put(removeLoadingModule(StoreModule.LOGIN));
  }
}

export function* logout() {
  try {
    yield put(addLoadingModule(StoreModule.LOGIN));

    yield call(Connector().delete, 'sessions/logout');

    Cookies.remove('AuthToken');
    Cookies.remove('AuthAdmin');
    Cookies.remove(btoa('AuthAccess'));
    localStorage.removeItem('@tokenExpiresIn');

    yield put(logoutSuccess());
    yield put(
      showToast({
        summary: 'Login',
        detail: 'Logout efetuado com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(logoutError());
    yield put(
      showToast({
        summary: 'Login',
        detail: 'Falha ao efetuar logout',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.LOGIN));
  }
}
