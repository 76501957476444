import { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Promotion from 'models/Promotion';
import { dismissAllToasts } from 'store/ducks/toast/actions';
import { InputMonetary, Button, Drawer } from 'components';
import {
  InputText,
  InputTextarea,
  Calendar,
  InputRadio,
  Select
} from 'components/Form';
import { ViewInputText } from 'components/Form/ViewInputText/ViewInputText';
import { DiscountValueTypeEnum } from 'helpers/enum/DiscountValueTypeEnum';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { promotionValidation } from 'helpers/validation/promotion';
import { formatValue } from 'utils/NumberFormatter/NumberFormatter';
import * as S from './PromotionActionSidebar.styles';
import utils from './PromotionActionSidebar.utils';

interface PromotionActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedPromotion: Promotion;
  onConfirmPress?: (promotion: Promotion, planId: number) => void;
  onCancelPress?: () => void;
}

const PromotionActionSidebar = (props: PromotionActionSidebarProps) => {
  const { mode, setMode, selectedPromotion, onConfirmPress, onCancelPress } =
    props;

  const dispatch = useDispatch();

  const { plans } = useSelector((state: ApplicationState) => state.plan);

  const [promotion, setPromotion] = useState<Promotion>(selectedPromotion);
  const [dates, setDates] = useState<Date | Date[]>();
  const [selectedPlanId, setSelectedPlanId] = useState<number>(0);

  useEffect(() => {
    setPromotion(selectedPromotion);
    setDates([
      new Date(selectedPromotion.startsIn),
      new Date(selectedPromotion.endsIn)
    ]);
  }, [selectedPromotion]);

  useEffect(() => {
    if (Array.isArray(dates)) {
      setPromotion({
        ...promotion,
        startsIn: dates[0],
        endsIn: dates[1]
      });
    }
  }, [dates]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar nova promoção';
        break;
      case 'OPEN_EDIT':
        title = 'Editar promoção';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      dispatch(dismissAllToasts());

      if (mode === 'OPEN_EDIT') {
        promotionValidation(promotion, null, dates);
      } else {
        promotionValidation(promotion, selectedPlanId, dates);
      }

      if (onConfirmPress) {
        onConfirmPress(promotion, selectedPlanId);
      }

      setMode('CLOSE');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        utils.triggerErrorToasts(dispatch, err);
      }
    }
  };

  const handleCancelPress = () => {
    if (onCancelPress) {
      onCancelPress();
    }

    setMode('CLOSE');
  };

  const handleDiscountChange = (discount: number) =>
    setPromotion({ ...promotion, discount });

  const handleValueTypeChange = (valueType: DiscountValueTypeEnum) =>
    setPromotion({ ...promotion, discountType: valueType });

  const getPlanValue = () => {
    if (mode === 'OPEN_EDIT') {
      return promotion.plan?.value;
    }

    const plan = plans.find((p) => p.id === selectedPlanId);

    if (plan) {
      return plan.value;
    }
  };

  const getPlan = () => {
    if (mode === 'OPEN_EDIT') {
      return (
        <ViewInputText
          label="Plano"
          name="planId"
          type="text"
          value={promotion.plan?.name}
        />
      );
    }

    return (
      <Select
        label="Plano"
        name="promotionPlanId"
        placeholder="Selecione um plano"
        data={plans.filter((plan) => plan.name !== '7 dias grátis')}
        dataId="id"
        dataValue="name"
        selected={selectedPlanId}
        onSelection={setSelectedPlanId}
      />
    );
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        {getPlan()}

        <ViewInputText
          label="Valor do plano"
          name="value"
          type="text"
          value={getPlanValue()}
        />

        <InputText
          label="Nome da promoção"
          name="name"
          type="text"
          value={promotion.name}
          onChange={(e) =>
            setPromotion({
              ...promotion,
              name: e.target.value
            })
          }
        />

        <InputTextarea
          label="Descrição"
          name="description"
          rows={5}
          cols={30}
          value={promotion.description}
          onChange={(e) =>
            setPromotion({
              ...promotion,
              description: e.currentTarget.value
            })
          }
        />

        <InputMonetary
          switchId="discountInput"
          inputTextLabel="Desconto"
          inputTextName="discount"
          textValue={promotion.discount}
          onTextValueChange={handleDiscountChange}
          valueType={promotion.discountType}
          onValueTypeChange={handleValueTypeChange}
        />

        <Calendar
          label="Vigência da promoção"
          name="duration"
          selectionMode="range"
          dateFormat="dd/mm/yy"
          value={dates}
          onChange={(e) => setDates(e.value)}
        />

        <InputText
          label="Validade do desconto (em meses)"
          placeholder="Tempo em que o desconto valerá para o usuário"
          name="promoPeriod"
          type="text"
          value={promotion.promoPeriod || ''}
          onChange={(e) => {
            setPromotion({
              ...promotion,
              promoPeriod: formatValue({
                oldValue: promotion.promoPeriod,
                newValue: e.target.value,
                withNegative: false,
                withDecimals: false,
                decimalDigits: 2
              })
            });
          }}
        />

        <InputText
          label="Limite de assinaturas"
          placeholder="Deixe esse campo vazio para assinaturas ilimitadas"
          name="totalQuantity"
          type="text"
          value={promotion.totalQuantity || ''}
          onChange={(e) => {
            setPromotion({
              ...promotion,
              totalQuantity: formatValue({
                oldValue: promotion.totalQuantity,
                newValue: e.target.value,
                withNegative: true,
                withDecimals: false,
                decimalDigits: 0,
                minNumber: -1
              })
            });
          }}
        />

        <S.InputRadioGroup>
          <span className="radioGroupLabel">Status</span>

          <InputRadio
            label="Ativo"
            name="status"
            value="active"
            onClick={() => {
              setPromotion({
                ...promotion,
                status: true
              });
            }}
            checked={promotion.status}
          />

          <InputRadio
            label="Inativo"
            name="status"
            value="inactive"
            onClick={() => {
              setPromotion({
                ...promotion,
                status: false
              });
            }}
            checked={!promotion.status}
          />
        </S.InputRadioGroup>

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

PromotionActionSidebar.defaultProps = {
  onConfirmPress: () => false,
  onCancelPress: () => false
};

export { PromotionActionSidebar };
