import styled from 'styled-components';
import { H1 } from 'components/Styled/Text';

export const PageHeaderContainer = styled.div``;

export const PageHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const PageHeaderActionsColumn = styled(PageHeaderColumn)`
  flex-grow: 1;
  align-items: flex-end;

  a:disabled,
  button:disabled {
    /* display: none; */
  }

  a:not(:last-child),
  button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const PageHeaderRow = styled.div`
  display: flex;
  height: auto;

  &.page-header__title-actions-row {
    margin-bottom: 32px;
  }
`;

export const PageHeaderTitle = styled(H1)``;
