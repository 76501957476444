import { action } from 'typesafe-actions';
import { UserApp } from 'models/User';
import { UserAppTypes } from './types';

export const fetchUsersAppRequest = () =>
  action(UserAppTypes.FETCH_USERS_APP_REQUEST);

export const fetchUsersAppSuccess = (all: UserApp[]) =>
  action(UserAppTypes.FETCH_USERS_APP_SUCCESS, { all });

export const fetchUsersAppError = () =>
  action(UserAppTypes.FETCH_USERS_APP_ERROR);

export const fetchOneUserAppRequest = (id: number | string) =>
  action(UserAppTypes.FETCH_ONE_USER_APP_REQUEST, { id });

export const fetchOneUserAppSuccess = (one: UserApp) =>
  action(UserAppTypes.FETCH_ONE_USER_APP_SUCCESS, { one });

export const fetchOneUserAppError = () =>
  action(UserAppTypes.FETCH_ONE_USER_APP_ERROR);

export const createUserAppRequest = (one: UserApp) =>
  action(UserAppTypes.CREATE_USER_APP_REQUEST, { one });

export const createUserAppSuccess = () =>
  action(UserAppTypes.CREATE_USER_APP_SUCCESS);

export const createUserAppError = () =>
  action(UserAppTypes.CREATE_USER_APP_ERROR);

export const updateUserAppRequest = (one: UserApp) =>
  action(UserAppTypes.UPDATE_USER_APP_REQUEST, { one });

export const updateUserAppSuccess = () =>
  action(UserAppTypes.UPDATE_USER_APP_SUCCESS);

export const updateUserAppError = () =>
  action(UserAppTypes.UPDATE_USER_APP_ERROR);

export const deleteUserAppRequest = (one: UserApp) =>
  action(UserAppTypes.DELETE_USER_APP_REQUEST, { one });

export const deleteUserAppSuccess = () =>
  action(UserAppTypes.DELETE_USER_APP_SUCCESS);

export const deleteUserAppError = () =>
  action(UserAppTypes.DELETE_USER_APP_ERROR);
