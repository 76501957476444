import styled from 'styled-components';
import { Button } from 'components';

const translate = 'transform: translateY(-80%); font-size: 12px;';

export const InputWrapper = styled.div`
  position: relative;

  & + div {
    margin-top: 18px;
  }

  &:hover {
    label {
      ${translate}
    }
  }

  label {
    padding: 6px 4px;
    position: absolute;
    left: 8px;
    top: 7px;
    z-index: 10;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.weight.bold};
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text.secondary};
    transition: transform 150ms ease-out, font-size 150ms ease-out;
  }

  input {
    color: ${({ theme }) => theme.text.primary};
    position: relative;
    width: 100%;
    outline: 0;
    border: 0;
    font-size: 14px;
    background-color: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 4px;
    padding: 12px 8px;
    cursor: text;

    &.is--password-input {
      padding: 18px 8px;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &.filled {
      + label {
        ${translate};
      }
    }

    &.is--disabled {
      opacity: 0.7;
      cursor: default;
    }
  }

  .p-float-button {
    width: fit-content;
    position: absolute;
    top: 8px;
    bottom: 0;
    right: 8px;
    padding: 0 18px;
    background-color: none;
    border: 0;
  }
`;
