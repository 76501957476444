import { action } from 'typesafe-actions';
import { Banner, Differential, Testimonial } from 'models/Institutional';
import { InstitutionalHomeTypes } from './types';

export const fetchHomeData = () =>
  action(InstitutionalHomeTypes.FETCH_HOME_DATA);

export const editBanner = (banner: Banner) =>
  action(InstitutionalHomeTypes.BANNER_EDIT, { banner });

export const createDifferential = (differential: Differential) =>
  action(InstitutionalHomeTypes.DIFFERENTIAL_CREATE, { differential });

export const editDifferential = (differential: Differential) =>
  action(InstitutionalHomeTypes.DIFFERENTIAL_EDIT, { differential });

export const removeDifferential = (differential: Differential) =>
  action(InstitutionalHomeTypes.DIFFERENTIAL_REMOVE, { differential });

export const createTestimonial = (testimonial: Testimonial) =>
  action(InstitutionalHomeTypes.TESTIMONIAL_CREATE, { testimonial });

export const editTestimonial = (testimonial: Testimonial) =>
  action(InstitutionalHomeTypes.TESTIMONIAL_EDIT, { testimonial });

export const removeTestimonial = (testimonial: Testimonial) =>
  action(InstitutionalHomeTypes.TESTIMONIAL_REMOVE, { testimonial });

export const fetchRequestSuccess = (
  banner: Banner,
  differentials: Differential[],
  testimonials: Testimonial[]
) =>
  action(InstitutionalHomeTypes.FETCH_REQUEST_SUCCESS, {
    banner,
    differentials,
    testimonials
  });

export const fetchRequestError = () =>
  action(InstitutionalHomeTypes.FETCH_REQUEST_ERROR);

export const homeRequestSuccess = () =>
  action(InstitutionalHomeTypes.HOME_REQUEST_SUCCESS);

export const homeRequestError = () =>
  action(InstitutionalHomeTypes.HOME_REQUEST_ERROR);
