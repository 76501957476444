import { FaEye } from 'react-icons/fa';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Log from 'models/Log';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './AdminLogDataTable.styles';

interface AdminLogDataTableProps {
  data: Log[];
  onPressItem: (log: Log) => void;
}

const AdminLogDataTable = (props: AdminLogDataTableProps) => {
  const { data, onPressItem } = props;

  const updatedAtBody = (log: Log) => (
    <span>{moment(log.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const createdAtBody = (log: Log) => (
    <span>{moment(log.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const isAdminBody = (log: Log) =>
    log.isAdmin ? (
      <span className="admin">Administrador</span>
    ) : (
      <span className="regular">Comum</span>
    );

  const actions = (logs: Log) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(logs)}
        severity="warning"
        icon="pi pi-eye"
      />
    </S.ActionButtons>
  );

  const handleRowDoubleClick = (logs: Log) => {
    onPressItem(logs);
  };

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        paginator
        rows={10}
        value={data}
        selectionMode="single"
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
      >
        <Column field="id" header="ID" style={{ width: '60px' }} sortable />
        <Column
          field="userId"
          header="Usuário"
          style={{ width: '120px' }}
          sortable
        />
        <Column field="content.action" header="Ação" sortable />
        <Column field="content.model" header="Recurso" sortable />
        <Column
          sortField="isAdmin"
          header="Nível de acesso"
          style={{ width: '165px' }}
          body={isAdminBody}
          sortable
        />
        <Column
          sortField="createdAt"
          header="Criado em"
          style={{ width: '145px' }}
          body={createdAtBody}
          sortable
        />
        <Column
          sortField="updatedAt"
          header="Atualizado em"
          style={{ width: '160px' }}
          body={updatedAtBody}
          sortable
        />
        <Column header="Ações" style={{ width: '100px' }} body={actions} />
      </DataTable>
    </DataTableLayout>
  );
};

export { AdminLogDataTable };
