import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchFaqListRequest,
  fetchFaqListSuccess,
  faqRequestSuccess,
  faqRequestError
} from './actions';
import { InstitutionalFaqAction } from './types';

export function* fetchFaqList() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALFAQ));

    const { data }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/common-questions/cms'
    );

    yield put(fetchFaqListSuccess(data));
  } catch (err: any) {
    yield put(faqRequestError('Falha ao carregar questões'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALFAQ));
  }
}

export function* createQuestion(action: InstitutionalFaqAction) {
  yield put(addLoadingModule(StoreModule.INSTITUTIONALFAQ));

  try {
    const { title, description } = action.payload.question;

    const question = { title, description };

    yield call(Connector().post, '/landing-page/common-questions', question);

    yield put(faqRequestSuccess('Questão criada com sucesso'));
    yield put(fetchFaqListRequest());
  } catch (err: any) {
    yield put(faqRequestError('Falha ao criar questão'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALFAQ));
  }
}

export function* updateQuestion(action: InstitutionalFaqAction) {
  yield put(addLoadingModule(StoreModule.INSTITUTIONALFAQ));

  try {
    const { id, ...question } = action.payload.question;

    yield call(
      Connector().put,
      `/landing-page/common-questions?id=${id}`,
      question
    );

    yield put(faqRequestSuccess('Questão atualizada com sucesso'));
    yield put(fetchFaqListRequest());
  } catch (err: any) {
    yield put(faqRequestError('Falha ao atualizar questão'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALFAQ));
  }
}

export function* deleteQuestion(action: InstitutionalFaqAction) {
  yield put(addLoadingModule(StoreModule.INSTITUTIONALFAQ));

  try {
    const { id } = action.payload.question;

    yield call(Connector().delete, `/landing-page/common-questions?id=${id}`);

    yield put(faqRequestSuccess('Questão removida com sucesso'));
    yield put(fetchFaqListRequest());
  } catch (err: any) {
    yield put(faqRequestError('Falha ao remover questão'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALFAQ));
  }
}

export function* showSuccessMessage(action: InstitutionalFaqAction) {
  const { message } = action.payload;
  yield put(
    showToast({
      summary: 'Institucional - FAQ',
      detail: message,
      severity: 'success'
    })
  );
}

export function* showErrorMessage(action: InstitutionalFaqAction) {
  const { message } = action.payload;
  yield put(
    showToast({
      summary: 'Institucional - FAQ',
      detail: message,
      severity: 'error'
    })
  );
}
