import styled from 'styled-components';

export const Form = styled.form``;

export const ButtonGroup = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  justify-content: flex-end;

  button {
    text-align: right;

    &:first-child {
      margin-right: 8px;
    }
  }
`;

export const InputRadioGroup = styled.div`
  display: flex;
  align-items: center;

  span.radioGroupLabel {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.weight.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.secondary};
    padding: 8px 14px;
    flex-grow: 4;
  }

  label:first-of-type {
    margin-right: 32px;
  }
`;

export const SidebarTable = styled.table`
  width: 100%;
  margin-bottom: 8px;
  padding: 12px;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.table.border};
  border-collapse: collapse;

  &.table {
    &_general,
    &_from,
    &_to {
      th {
        background-color: ${({ theme }) => theme.sidebarTable.bg};
        color: ${({ theme }) => theme.sidebarTable.color};
      }
    }

    &_from,
    &_to {
      th {
        text-align: center;
      }
    }
  }

  th,
  td {
    padding: 6px;
    border: 1px solid ${({ theme }) => theme.table.border};
    width: 50%;
  }

  th {
    font-size: 12px;
    text-transform: uppercase;
  }

  caption {
    padding: 12px 0 8px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.secondary};
  }
`;
