import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Subscriber from 'models/Subscriber';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, Select } from 'components/Form';
import { DrawerMode } from 'helpers/types/DrawerMode';
import subscriberValidate from 'helpers/validation/subscriber';
import * as S from './SubscriberActionSidebar.styles';

interface SubscriberActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedSubscriber: Subscriber;
  onConfirmPress: (subscriber: Subscriber) => void;
  onCancelPress?: () => void;
}

const SubscriberActionSidebar = (props: SubscriberActionSidebarProps) => {
  const { mode, setMode, selectedSubscriber, onCancelPress, onConfirmPress } =
    props;

  const dispatch = useDispatch();

  const [subscriber, setSubscriber] = useState<Subscriber>(selectedSubscriber);

  useEffect(() => {
    setSubscriber(selectedSubscriber);
  }, [selectedSubscriber]);

  const subscriptionStatus = [
    { id: 'ENABLED', name: 'Ativa' },
    { id: 'OVERDUE', name: 'Atrasada' },
    { id: 'CANCELED', name: 'Cancelada' },
    { id: 'IN_TEST', name: 'Em teste' }
  ];

  const handleConfirmPress = () => {
    onConfirmPress(subscriber);
    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  return (
    <Drawer
      title="Editar assinante"
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          name="user"
          label="Usuário"
          value={selectedSubscriber.userName}
          disabled
        />

        <Select
          name="subscriptionStatus"
          label="Status da assinatura"
          placeholder="Selecione o status da assinatura"
          data={subscriptionStatus}
          dataId="id"
          dataValue="name"
          selected={subscriber.status}
          onSelection={(status) =>
            setSubscriber({
              ...subscriber,
              status
            })
          }
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

SubscriberActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { SubscriberActionSidebar };
