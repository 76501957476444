import { Reducer } from 'redux';
import {
  InstitutionalContactTypes,
  InstitutionalContactState,
  InstitutionalContactAction
} from './types';
import { CONTACT_INITIAL_STATE } from './utils';

const reducer: Reducer<
  InstitutionalContactState,
  InstitutionalContactAction
> = (
  state: InstitutionalContactState = CONTACT_INITIAL_STATE,
  action: InstitutionalContactAction
) => {
  switch (action.type) {
    case InstitutionalContactTypes.FETCH_CONTACTS_SUCCESS: {
      return {
        ...state,
        contacts: action.payload.contacts
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
