import React from 'react';
import classNames from 'classnames';
import { DiscountValueTypeEnum } from 'helpers/enum/DiscountValueTypeEnum';
import * as formatter from 'utils/NumberFormatter/NumberFormatter';
import { InputText } from '../Form/InputText/InputText';
import { Switch } from '../Switch/Switch';
import * as S from './InputMonetary.styles';
import { InputMonetaryProps } from './interfaces/InputMonetaryProps';

export const InputMonetary = (props: InputMonetaryProps) => {
  const {
    className,
    inputTextName,
    inputTextLabel,
    textValue,
    onTextValueChange,
    valueType,
    onValueTypeChange,
    switchId
  } = props;

  const handleCheck = () => {
    if (valueType === DiscountValueTypeEnum.MONETARY) {
      onValueTypeChange(DiscountValueTypeEnum.PERCENT);
    } else {
      onValueTypeChange(DiscountValueTypeEnum.MONETARY);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = formatter.formatValue({
      oldValue: textValue,
      newValue: e.target.value,
      withDecimals: true,
      decimalDigits: 2
    });

    onTextValueChange(newValue);
  };

  return (
    <S.InputMonetaryContainer
      className={classNames('input-monetary', className)}
    >
      <InputText
        type="string"
        name={inputTextName}
        label={inputTextLabel}
        value={textValue}
        onChange={handleChange}
        className={classNames('input-monetary__text')}
      />

      <Switch
        id={`${switchId}-${Date.now()}`}
        checked={valueType === DiscountValueTypeEnum.PERCENT}
        onCheck={handleCheck}
        leftSideLabel="R$"
        rightSideLabel="%"
      />
    </S.InputMonetaryContainer>
  );
};
