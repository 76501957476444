import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchSubscribersRequest,
  fetchSubscribersSuccess,
  fetchSubscribersError,
  fetchOneSubscriberSuccess,
  fetchOneSubscriberError,
  updateSubscriberSuccess,
  updateSubscriberError,
  unsubscribeSuccess,
  unsubscribeError
} from './actions';
import { SubscriberAction } from './types';

export function* fetchSubscribers(action: SubscriberAction) {
  try {
    yield put(addLoadingModule(StoreModule.SUBSCRIBER));

    const { filters } = action.payload;

    const response: ConnectorResponse = yield call(
      Connector(true).get,
      `subscriptions/list${filters || ''}`
    );

    yield put(fetchSubscribersSuccess(response.data));
  } catch (err: any) {
    yield put(fetchSubscribersError());
    yield put(
      showToast({
        summary: 'Assinantes',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.SUBSCRIBER));
  }
}

export function* fetchOneSubscriber(action: SubscriberAction) {
  try {
    yield put(addLoadingModule(StoreModule.SUBSCRIBER));

    const response: ConnectorResponse = yield call(
      Connector(true).get,
      `subscriptions?id=${action.payload.id}`
    );

    yield put(fetchOneSubscriberSuccess(response.data));
  } catch (err: any) {
    yield put(fetchOneSubscriberError());
    yield put(
      showToast({
        summary: 'Assinantes',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.SUBSCRIBER));
  }
}

export function* updateSubscriber(action: SubscriberAction) {
  try {
    yield put(addLoadingModule(StoreModule.SUBSCRIBER));

    const subscriber = {
      ...action.payload.one
    } as any;

    yield call(Connector().put, 'subscriptions', {
      hubId: subscriber.hubId,
      status: subscriber.status
    });

    yield put(updateSubscriberSuccess());
    yield put(fetchSubscribersRequest());
    yield put(
      showToast({
        summary: 'Assinantes',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(updateSubscriberError());
    yield put(
      showToast({
        summary: 'Assinantes',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.SUBSCRIBER));
  }
}

export function* cancelSubscription(action: SubscriberAction) {
  try {
    yield put(addLoadingModule(StoreModule.SUBSCRIBER));

    const { id } = action.payload;

    yield call(Connector(true).put, `subscriptions/cancel?hubId=${id}`);

    yield put(
      showToast({
        summary: 'Assinantes',
        detail: 'Assinatura cancelada com sucesso',
        severity: 'success'
      })
    );
    yield put(unsubscribeSuccess());
    yield put(fetchSubscribersRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Assinantes',
        detail: 'Falha ao cancelar assinatura',
        severity: 'error'
      })
    );
    yield put(unsubscribeError());
  } finally {
    yield put(removeLoadingModule(StoreModule.SUBSCRIBER));
  }
}
