import { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AboutUs } from 'models/Institutional';
import {
  fetchAboutUsRequest,
  updateAboutUsRequest
} from 'store/ducks/institutionalAboutUs/actions';
import { showToast } from 'store/ducks/toast/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { AboutUsEditor } from './components';
import * as S from './AboutUsPage.styles';

const AboutUsPage = () => {
  const dispatch = useDispatch();
  const { aboutUs: stateAbout } = useSelector(
    (state: ApplicationState) => state.institutionalAboutUs
  );

  const [aboutUs, setAboutUs] = useState<AboutUs>(stateAbout);

  useEffect(() => {
    setAboutUs(stateAbout);
  }, [stateAbout]);

  useEffect(() => {
    dispatch(fetchAboutUsRequest());
  }, [dispatch]);

  const handleSavePressed = () => {
    try {
      Yup.object()
        .shape({
          description: Yup.string().required('O campo descrição é obrigatório')
        })
        .validateSync(aboutUs, { abortEarly: false });

      dispatch(updateAboutUsRequest(aboutUs));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const getSaveButton = () => (
    <S.Row>
      <Button type="button" severity="primary" onClick={handleSavePressed}>
        <FaSave />
        Salvar
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <S.Container>
          <PageHeader
            title="Institucional - Sobre"
            actionsComponent={getSaveButton()}
          />

          <AboutUsEditor aboutUs={aboutUs} setAboutUs={setAboutUs} />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { AboutUsPage };
