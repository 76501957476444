import * as yup from 'yup';
import Plan from 'models/Plan';

const planSchema = yup.object().shape({
  name: yup.string().required('O campo nome é obrigatório!'),
  value: yup.number().required('O campo valor é obrigatório!'),
  description: yup.string().required('O campo descrição é obrigatório!'),
});

export default (plan:Plan) => planSchema.validateSync(
  plan, { abortEarly: false },
);
