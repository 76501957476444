import classNames from 'classnames';
import * as S from './ExportCsvButton.styles';
import { ExportCsvButtonProps } from './interfaces/ExportCsvButtonProps';

const ExportCsvButton = (props: ExportCsvButtonProps) => {
  const { data, fileName, disabled, big, dataLoading } = props;

  return (
    <S.CsvLink
      className={classNames({
        'btn-disabled': disabled,
        'btn-small': !big,
        'btn-big': big
      })}
      data={data}
      filename={`${fileName}.csv`}
    >
      {dataLoading ? (
        <>
          <i className="pi pi-spin pi-spinner" />
          Carregando...
        </>
      ) : (
        <>
          <i className="pi pi-file-o" />
          Exportar CSV
        </>
      )}
    </S.CsvLink>
  );
};

ExportCsvButton.defaultProps = {
  fileName: 'generatedCSV'
};

export { ExportCsvButton };
