export const PUBLIC_ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/sair',
  FORBIDDEN: '/403'
};

export const PRIVATE_ROUTES = {
  HOME: '/',
  ANALYTICS: '/analytics',
  ANALYTICS_DASHBOARD: '/analytics/dashboard',
  ANALYTICS_SUBSCRIPTIONS: '/analytics/relatorio-de-assinaturas',
  ANALYTICS_USAGE: '/analytics/dados-de-uso',
  FILE_MANAGER: '/arquivos',
  PLAYLISTS: '/playlists',
  PLANS: '/planos',
  PROMOTIONS: '/promocoes',
  QUIZZES: '/quizzes',
  CATEGORIES: '/categorias',
  USERS_APP: '/usuarios',
  USERS_ADMIN: '/administradores',
  SUBSCRIBERS: '/assinantes',
  TEACHING_INSTITUTIONS: '/instituicoes-de-ensino',
  STUDENT_REQUESTS: '/estudantes',
  LOGS: '/logs',
  LOGS_USERS: '/logs/usuarios',
  LOGS_ADMIN: '/logs/administrativo',
  INSTITUTIONAL: '/institucional',
  INSTITUTIONAL_HOME: '/institucional/home',
  INSTITUTIONAL_CONTACT: '/institucional/contato',
  INSTITUTIONAL_FAQ: '/institucional/duvidas-frequentes',
  INSTITUTIONAL_TERMS: '/institucional/termos-e-condicoes',
  INSTITUTIONAL_ABOUT: '/institucional/sobre',
  INSTITUTIONAL_NEWS: '/institucional/noticias',
  TRASH: '/lixeira',
  FORBIDDEN: '/403'
};

export enum CMS_PERMISSION {
  ANALYTICS_SUBSCRIPTIONS = 'DASH_SUBSCRIPTION',
  ANALYTICS_DASHBOARD = 'DASH',
  ANALYTICS_USAGE = 'USING_DATA',
  FILE_MANAGER = 'FILES',
  PLAYLISTS = 'PLAYLISTS',
  SUBSCRIBERS = 'SUBSCRIPTIONS',
  USERS_APP = 'USERS',
  USERS_ADMIN = 'USERS_ADMIN',
  TEACHING_INSTITUTIONS = 'INSTITUTIONS',
  STUDENT_REQUESTS = 'STUDENTS',
  INSTITUTIONAL = 'INSTITUTIONAL_CONTENT',
  CATEGORIES = 'CATEGORIES',
  PROMOTIONS = 'PROMOTIONS',
  QUIZZES = 'QUIZZES',
  PLANS = 'PLANS',
  LOGS = 'LOGS'
}

export type CMS_PERMISSION_TYPE =
  | 'DASH_SUBSCRIPTION'
  | 'DASH'
  | 'FILES'
  | 'PLAYLISTS'
  | 'SUBSCRIPTIONS'
  | 'USERS'
  | 'USERS_ADMIN'
  | 'INSTITUTIONS'
  | 'STUDENTS'
  | 'INSTITUTIONAL_CONTENT'
  | 'USING_DATA'
  | 'CATEGORIES'
  | 'PROMOTIONS'
  | 'QUIZZES'
  | 'PLANS'
  | 'LOGS';
