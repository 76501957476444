import styled from 'styled-components';

export const H1 = styled.h1(({ theme }) => `
  font-family: 'Mulish', sans-serif;
  font-size: ${theme.size.h1};
  font-weight: ${theme.weight.light};
`);

export const H2 = styled.h2(({ theme }) => `
  font-family: 'Mulish', sans-serif;
  font-size: ${theme.size.h2};
  font-weight: ${theme.weight.light};
`);

export const H3 = styled.h3(({ theme }) => `
  font-family: 'Mulish', sans-serif;
  font-size: ${theme.size.h3};
  font-weight: ${theme.weight.light};
`);

export const H4 = styled.h4(({ theme }) => `
  font-family: 'Mulish', sans-serif;
  font-size: ${theme.size.h4};
  font-weight: ${theme.weight.light};
`);

export const Heading = {
  H1, H2, H3, H4,
};

export const Big = styled.p(({ theme }) => `
  font-size: ${theme.size.p.big};
`);

export const Medium = styled.p(({ theme }) => `
  font-size: ${theme.size.p.medium};
`);

export const Small = styled.p(({ theme }) => `
  font-size: ${theme.size.p.small};
`);

export const Paragraph = {
  Big, Medium, Small,
};
