import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as PlaylegisLogoYellow } from 'assets/playlegis-logo-yellow.svg';
import * as S from './NotFoundPage.styles';

const NotFoundPage = () => {
  const [redirect, setRedirect] = useState(false);
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]); // eslint-disable-line

  setTimeout(() => {
    setRedirect(true);
  }, 5000);

  if (redirect) {
    return <Navigate to="/" />;
  }

  return (
    <S.Container>
      <PlaylegisLogoYellow />
      <h1>Página não encontrada</h1>
      <h2>
        {`Você será direcionado para a página inicial em ${counter} segundos...`}
      </h2>
      <Link to="/">Página inicial</Link>
    </S.Container>
  );
};
export { NotFoundPage };
