import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentAmountRequest } from 'store/ducks/analytics/actions';
import { Card, LoadingIndicator, MiniCard } from 'components';
import money from 'helpers/money';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './PaymentAmount.styles';

const PaymentAmount = () => {
  const dispatch = useDispatch();

  const { paymentAmount, loading } = useSelector(
    (state: ApplicationState) => state.analytics
  );
  const { curentAmountPayment, amountPayment } = paymentAmount;

  const isModuleLoading = () => loading.includes('PAYMENT_AMOUNT');

  const handlePaymentAmountRequest = () => {
    dispatch(paymentAmountRequest());
  };

  useEffect(() => {
    handlePaymentAmountRequest();
  }, [dispatch]);

  return (
    <Card title="Receitas">
      <LoadingIndicator
        isLoading={isModuleLoading()}
        position="absolute"
        size="small"
        showRefreshButton
        onRefresh={handlePaymentAmountRequest}
      />

      <S.RevenueContainer>
        <MiniCard title="Receita vigente" body={money(curentAmountPayment)} />
        <MiniCard title="Receita bruta total" body={money(amountPayment)} />
      </S.RevenueContainer>
    </Card>
  );
};

export { PaymentAmount };
