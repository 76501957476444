import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from 'store/ducks/drawer/actions';
import { FileDragAndDrop, ProgressUpload } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';

const AddAudioSidebar = () => {
  const [visibleProgressBar, setVisibleProgressBar] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const currentParentId = useSelector(
    (state: ApplicationState) => state.currentFolder.parentId
  );
  const dispatch = useDispatch();

  const handleFinish = () => {
    dispatch(closeDrawer());
  };

  return (
    <>
      <ProgressUpload
        progressValue={progressValue}
        visibleProgressBar={visibleProgressBar}
      />

      <FileDragAndDrop
        folderId={currentParentId}
        progress={setProgressValue}
        changeProgressBarState={setVisibleProgressBar}
        onFinish={handleFinish}
      />
    </>
  );
};

AddAudioSidebar.defaultProps = {
  onConfirmPress: () => {},
  onCancelPress: () => {}
};

export { AddAudioSidebar };
