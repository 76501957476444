import { Reducer } from 'redux';
import {
  InstitutionalTermsTypes,
  InstitutionalTermsState,
  InstitutionalTermsAction
} from './types';
import { INITIAL_STATE } from './utils';

const reducer: Reducer<InstitutionalTermsState, InstitutionalTermsAction> = (
  state: InstitutionalTermsState = INITIAL_STATE,
  action: InstitutionalTermsAction
) => {
  switch (action.type) {
    case InstitutionalTermsTypes.FETCH_TERMS_SUCCESS: {
      return {
        ...state,
        terms: action.payload.terms
      };
    }
    case InstitutionalTermsTypes.EDIT_TERMS_REQUEST: {
      return {
        ...state,
        terms: action.payload.terms
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
