import { FileStatus } from 'models/FileStatus';
import Playlist from 'models/Playlist';

const playlistInitialState: Playlist = {
  id: 0,
  name: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date(),
  audios: [],
  categories: [],
  description: '',
  removed: false,
  status: FileStatus.ENABLED,
  userId: 0,
  coverUrl: ''
};

export default playlistInitialState;
