import Subscriber from 'models/Subscriber';
import { UserApp } from 'models/User';
import ApplicationState from 'helpers/types/ApplicationState';
import { SubscriberState } from './types';

export const getUsers = (state: ApplicationState) => state.userApp;

export const getSubscribersWithUserInfo = (
  subscribers: Subscriber[],
  users: UserApp[]
) =>
  subscribers.map((s) => ({
    ...s,
    user: users.filter((u) => s.userId === u.id).shift()
  }));

export const SUBSCRIBER_INITIAL_STATE: SubscriberState = {
  id: 0,
  one: {
    id: 0,
    hubId: '0',
    createdAt: new Date(),
    updatedAt: new Date(),
    canceledAt: new Date(),
    status: '',
    inPromotion: false,
    expiresTrialAt: new Date(),
    cancelationReason: '',
    planId: 0,
    userData: {
      address: '',
      city: '',
      state: '',
      country: '',
      phone: ''
    },
    teachingInstitutionId: 0,
    userName: '',
    userEmail: '',
    cpf: '',
    phone: ''
  },
  all: [],
  filters: {
    createdAt: '',
    canceledAt: '',
    status: '',
    planValue: '',
    subscriberData: {
      address: '',
      city: '',
      state: '',
      country: '',
      phone: ''
    },
    teachingInstitutionId: ''
  },
  updatePlanError: false
};
