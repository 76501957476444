import styled, { css } from 'styled-components';

const SvgBase = css`
  display: flex;
  align-items: center;
  margin: 10px 0;

  svg {
    margin-right: 10px;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.section.bg};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
  border-radius: 6px;
  font-size: 14px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &.userProfileInfo {
    width: 250px;
  }

  &.rightColumn {
    width: calc(100% - 250px);
  }

  &.alignCenter {
    align-items: center;
  }

  &:first-child {
    content: '';
    display: block;
    height: 100%;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid ${({ theme }) => theme.table.border};
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const UserAvatar = styled.div`
  font-size: 120px;
  text-align: center;
  color: ${({ theme }) => theme.userAvatar};

  svg {
    border: 5px solid currentColor;
    border-radius: 50%;
  }
`;

export const UserName = styled.h2`
  font-size: 22px;
  font-weight: 500;
`;

export const UserEmail = styled.div`
  font-size: 14px;
  margin: 8px 0 14px;
  word-wrap: break-word;
`;

export const UserLocation = styled.div`
  ${SvgBase};
`;

export const UserCard = styled.div`
  ${SvgBase};
`;

export const UserCreatedAt = styled.div`
  ${SvgBase};
`;

export const UserUpdatedAt = styled.div`
  ${SvgBase};
`;

export const Item = styled.div`
  ${SvgBase};
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 0px 10px ${({ theme }) => theme.shadow};

  &:not(:last-child) {
    margin-right: 8px;
  }

  h3 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
`;

export const PaymentsTitle = styled.h3`
  font-size: 22px;
  margin-top: 16px;
`;

export const CanceledBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  text-align: center;
`;

export const CanceledTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 16px;
`;

export const SidebarTable = styled.table`
  width: 100%;
  margin-top: 16px;
  padding: 12px;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.table.border};
  border-collapse: collapse;
  table-layout: auto;
  overflow-wrap: break-word;

  th {
    background-color: ${({ theme }) => theme.sidebarTable.bg};
    color: ${({ theme }) => theme.sidebarTable.color};
    text-align: center;
  }

  th,
  td {
    padding: 6px;
    border: 1px solid ${({ theme }) => theme.table.border};
  }

  th {
    font-size: 12px;
    text-transform: uppercase;
  }

  caption {
    padding: 12px 0 8px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.secondary};
  }
`;
