import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import StudentRequest from 'models/StudentRequest';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, InputTextarea, Select } from 'components/Form';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './StudentRequestActionSidebar.styles';

interface StudentRequestActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedStudentRequest: StudentRequest;
  onConfirmPress: (studentRequest: StudentRequest) => void;
  onCancelPress?: () => void;
}

const StudentRequestActionSidebar = (
  props: StudentRequestActionSidebarProps
) => {
  const {
    mode,
    setMode,
    selectedStudentRequest,
    onCancelPress,
    onConfirmPress
  } = props;

  const dispatch = useDispatch();

  const [studentRequest, setStudentRequest] = useState<StudentRequest>(
    selectedStudentRequest
  );

  useEffect(() => {
    setStudentRequest(selectedStudentRequest);
  }, [selectedStudentRequest]);

  const statusList = [
    { id: 'PENDING', name: 'Pendente' },
    { id: 'APPROVED', name: 'Aprovado' },
    { id: 'DISAPPROVED', name: 'Negado' }
  ];

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_EDIT':
        title = 'Alterar status';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      if (studentRequest.status === 'DISAPPROVED') {
        Yup.object()
          .shape({
            id: Yup.number().required(),
            status: Yup.string().required('O campo Status é obrigatório'),
            reasonRepprove: Yup.string()
              .required('Informe o motivo da reprovação')
              .nullable()
          })
          .validateSync(studentRequest, { abortEarly: false });
      } else {
        Yup.object()
          .shape({
            id: Yup.number().required(),
            status: Yup.string().required('O campo Status é obrigatório')
          })
          .validateSync(studentRequest, { abortEarly: false });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    onConfirmPress(studentRequest);
    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Assinante"
          name="subscriptionId"
          value={studentRequest.userEmail}
          disabled
        />

        {mode !== 'OPEN_ADD' && (
          <InputTextarea
            label="Motivo de reprovação"
            name="reasonRepprove"
            rows={5}
            cols={30}
            value={studentRequest.reasonRepprove}
            onChange={(e) =>
              setStudentRequest({
                ...studentRequest,
                reasonRepprove: e.target.value
              })
            }
            disabled={studentRequest.status !== 'DISAPPROVED'}
          />
        )}

        <Select
          label="Status"
          name="status"
          placeholder="Selecione um status"
          data={statusList}
          dataId="id"
          dataValue="name"
          selected={studentRequest.status}
          onSelection={(id) =>
            setStudentRequest({
              ...studentRequest,
              status: id
            })
          }
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

StudentRequestActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { StudentRequestActionSidebar };
