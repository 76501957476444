import styled from 'styled-components';
import { FlexDirectionProps } from './interfaces/ActivatableDateIntervalStyleProps';

export const ActivatableDateIntervalContainer = styled.div<FlexDirectionProps>`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  justify-content: center;
  align-items: center;
`;

export const CheckboxWrapper = styled.div<FlexDirectionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $flexDirection }) =>
    $flexDirection === 'column' ? 'margin-bottom: 16px;' : ''};
`;

export const CheckboxLabel = styled.span`
  margin-left: 12px;
  font-size: 14px;
`;
