import { FormEvent, useEffect, useRef, useState } from 'react';
import { FaCamera } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Differential } from 'models/Institutional';
import {
  createDifferential,
  editDifferential,
  removeDifferential
} from 'store/ducks/institutionalHome/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button } from 'components';
import { InputText, InputTextarea } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { INITIAL_STATE } from '../../HomePage.utils';
import * as S from './HomeDifferentialsForm.styles';

const HomeDifferentialsForm = () => {
  const dispatch = useDispatch();
  const { differentials } = useSelector(
    (state: ApplicationState) => state.institutionalHome
  );

  const [differential, setDifferential] = useState<Differential>(
    INITIAL_STATE.differential
  );
  // Form action
  const [action, setAction] = useState('create');
  // Icon states (preview + file)
  const [preview, setPreview] = useState<string>('');
  const [differentialIcon, setDifferentialIcon] = useState<File>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (differentialIcon) {
      setPreview(URL.createObjectURL(differentialIcon));
      setDifferential((d) => ({
        ...d,
        icon: differentialIcon
      }));
    }
  }, [differentialIcon]);

  useEffect(() => {
    setPreview(differential.iconUrl);
    setDifferentialIcon(undefined);
  }, [differential.iconUrl]);

  const onUploadSingleFile = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setDifferentialIcon(e.currentTarget.files[0]);
    }
  };

  const handleReset = () => {
    setDifferential(INITIAL_STATE.differential);
    setPreview('');
    setDifferentialIcon(undefined);
    setAction('create');
  };

  const handleItemDoubleClick = (item: Differential) => {
    setDifferential(item);
    setAction('update');
  };

  const handleDifferentialSubmit = (formAction: string) => {
    try {
      Yup.object()
        .shape({
          x: Yup.object().shape({
            title: Yup.string().required('O campo título é obrigatório!'),
            description: Yup.string().required(
              'O campo descrição é obrigatório!'
            )
          })
        })
        .validateSync(differential, { abortEarly: false });

      if (formAction === 'create') {
        if (!differentialIcon) {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: 'É necessário selecionar um ícone',
                severity: 'error'
              })
            );
          }, 800);

          return;
        }

        dispatch(createDifferential(differential));
      }

      if (formAction === 'update') {
        dispatch(editDifferential(differential));
      }

      if (formAction === 'remove') {
        dispatch(removeDifferential(differential));
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }

    setDifferential(INITIAL_STATE.differential);
  };

  return (
    <S.DifferentialsFormWrapper className="institutional__form">
      <h2>Nossos diferenciais</h2>

      <S.DifferentialsList>
        {differentials
          .sort((a, b) => a.x.id - b.x.id)
          .map((item) => (
            <S.DifferentialItem
              key={item.x.id}
              title="Clique duas vezes para editar"
              onDoubleClick={() => handleItemDoubleClick(item)}
            >
              <S.DifferentialItemIcon>
                <img src={item.iconUrl} alt="Ícone do item" />
              </S.DifferentialItemIcon>
              <S.DifferentialItemText>
                <p className="item__title">{item.x.title}</p>
                <p className="item__description">{item.x.description}</p>
              </S.DifferentialItemText>
            </S.DifferentialItem>
          ))}
      </S.DifferentialsList>

      <S.Form as="div">
        <S.FormRow>
          <S.FormGroup>
            <S.DifferentialFormIcon title="Selecione uma imagem para ser utilizada como ícone">
              <label
                style={{
                  backgroundImage: `url(${preview})`
                }}
                className={classNames({
                  'has-cover': preview !== ''
                })}
                htmlFor="selectedDifferentialIcon"
              >
                <FaCamera />
                <input
                  id="selectedDifferentialIcon"
                  type="file"
                  accept="image/*"
                  onChange={onUploadSingleFile}
                  ref={fileInputRef}
                  required
                />
              </label>
            </S.DifferentialFormIcon>
          </S.FormGroup>

          <S.FormGroup>
            <InputText
              name="title"
              type="text"
              placeholder="Título"
              value={differential.x.title}
              onChange={(ev) =>
                setDifferential({
                  ...differential,
                  x: {
                    ...differential.x,
                    title: ev.target.value
                  }
                })
              }
            />

            <InputTextarea
              name="description"
              placeholder="Descrição"
              value={differential.x.description}
              onChange={(ev) =>
                setDifferential({
                  ...differential,
                  x: {
                    ...differential.x,
                    description: ev.target.value
                  }
                })
              }
            />
          </S.FormGroup>
        </S.FormRow>

        <S.ButtonGroup>
          <Button
            type="button"
            onClick={() => handleDifferentialSubmit(action)}
          >
            Salvar item
          </Button>

          <Button
            type="button"
            severity="danger"
            onClick={handleReset}
            disabled={
              !differential.x.description &&
              !differential.x.title &&
              !differentialIcon
            }
          >
            Descartar alterações
          </Button>

          <Button
            type="button"
            severity="danger"
            onClick={() => handleDifferentialSubmit('remove')}
            disabled={!differential.x.id}
          >
            Remover item
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </S.DifferentialsFormWrapper>
  );
};

export { HomeDifferentialsForm };
