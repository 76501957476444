import { Reducer } from 'typesafe-actions';
import { DrawerAction, DrawerState, DrawerTypes } from './types';
import { DRAWER_INITIAL_STATE } from './utils';

const reducer: Reducer<DrawerState, DrawerAction> = (
  state: DrawerState = DRAWER_INITIAL_STATE,
  action: DrawerAction
) => {
  switch (action.type) {
    case DrawerTypes.OPEN: {
      return {
        ...action.payload,
        isOpen: true
      };
    }

    case DrawerTypes.CLOSE: {
      return DRAWER_INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
