import { action } from 'typesafe-actions';
import Subscription from 'models/Subscription';
import { SubscriptionTypes } from './types';

export const fetchSubscriptionsRequest = (query?: string) =>
  action(SubscriptionTypes.FETCH_SUBSCRIPTION_REQUEST, { query });
export const fetchSubscriptionsSuccess = (subscriptions: Subscription[]) =>
  action(SubscriptionTypes.FETCH_SUBSCRIPTION_SUCCESS, { subscriptions });
export const fetchSubscriptionsError = () =>
  action(SubscriptionTypes.FETCH_SUBSCRIPTION_ERROR);
