import { Faq } from 'models/Institutional';

export enum InstitutionalFaqTypes {
  FETCH_FAQ_LIST_REQUEST = '@institutionalFaq/FETCH_FAQ_LIST_REQUEST',
  FETCH_FAQ_LIST_SUCCESS = '@institutionalFaq/FETCH_FAQ_LIST_SUCCESS',

  CREATE_FAQ_QUESTION_REQUEST = '@institutionalFaq/CREATE_FAQ_QUESTION_REQUEST',
  UPDATE_FAQ_QUESTION_REQUEST = '@institutionalFaq/UPDATE_FAQ_QUESTION_REQUEST',
  DELETE_FAQ_QUESTION_REQUEST = '@institutionalFaq/DELETE_FAQ_QUESTION_REQUEST',

  FAQ_REQUEST_SUCCESS = '@institutionalFaq/FAQ_REQUEST_SUCCESS',
  FAQ_REQUEST_ERROR = '@institutionalFaq/FAQ_REQUEST_ERROR',
}

export interface InstitutionalFaqState {
  readonly question: Faq;
  readonly list: Faq[];
  readonly message: string;
}

export type InstitutionalFaqAction = {
  type: InstitutionalFaqTypes;
  payload: InstitutionalFaqState,
};
