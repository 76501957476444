import Audio from 'models/Audio';
import Folder from 'models/Folder';

const getFileType = (e: any) =>
  Object.prototype.hasOwnProperty.call(e, 'title') ? 'AUDIO' : 'FOLDER';

const onRemoveMultipleItems = (
  files: (Folder | Audio)[],
  audioRemoveCallback: (audio: Audio) => void,
  fileRemoveCallback: (folder: Folder) => void
) => {
  Promise.all([
    files.map((file) => {
      if (getFileType(file) === 'AUDIO') {
        return Promise.resolve(audioRemoveCallback(file as Audio));
      }
      return Promise.resolve(fileRemoveCallback(file as Folder));
    })
  ]);
};

export { getFileType, onRemoveMultipleItems };
