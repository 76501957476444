import styled from 'styled-components';

export const RichTextEditorContainer = styled.div`
  min-height: 370px;

  .p-editor-toolbar {
    display: none;
  }

  .ql-toolbar.ql-snow {
    background: white;
  }

  span.ql-formats button {
    margin: 0;
  }

  .ql-tooltip.ql-editing {
    margin-top: 0px !important;
    left: initial !important;
    right: 50px;
    top: 0px !important;
    transform: none !important;
  }
`;
