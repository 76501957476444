import { useEffect, useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Quiz, { QuizQuestion } from 'models/Quiz';
import { showDialog } from 'store/ducks/dialog/actions';
import * as QuizQuestionsSagas from 'store/ducks/quizQuestions/actions';
import { fetchOneQuizRequest } from 'store/ducks/quizzes/actions';
import { Button, DataTableLayout } from 'components';
import filterMatch from 'helpers/filterMatch';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './QuizQuestionDataTable.styles';

interface QuizQuestionDataTableProps {
  data: Quiz;
  onPressItem: (data: QuizQuestion) => void;
  onDoubleClick: (data: QuizQuestion) => void;
}
const QuizQuestionDataTable = (props: QuizQuestionDataTableProps) => {
  const { data, onPressItem, onDoubleClick } = props;

  const dispatch = useDispatch();

  const quiz = useSelector((state: ApplicationState) => state.quizzes.data);

  const [questions, setQuestions] = useState<QuizQuestion[]>(
    quiz.quizQuestions
  );

  useEffect(() => {
    dispatch(fetchOneQuizRequest(data.id));
  }, [dispatch]);

  useEffect(() => {
    setQuestions(quiz.quizQuestions);
  }, [quiz]);

  const handleRemovePressed = (question: QuizQuestion) => {
    const dtQuestion = { ...question, quizId: quiz.id };
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover quiz',
        message: `Deseja reamente remover o quiz "${dtQuestion.question}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () =>
          dispatch(QuizQuestionsSagas.deleteQuizQuestionRequest(dtQuestion))
      })
    );
  };

  const handleRowDoubleClick = (question: any) => {
    onDoubleClick(question);
  };

  const getActionButtons = (question: QuizQuestion) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(question)}
        severity="confirm"
        className="p-button-success"
        icon="pi pi-pencil"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleRemovePressed(question)}
        className="p-button-danger"
        icon="pi pi-trash"
      >
        <FaTrash />
      </Button>
    </S.ActionButtons>
  );

  const updatedAtBody = (question: QuizQuestion) => (
    <span>{moment(question.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const createdAtBody = (question: QuizQuestion) => (
    <span>{moment(question.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const isMultipleBody = (question: QuizQuestion) => (
    <span>{question.isMultiple ? 'Sim' : 'Não'}</span>
  );

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        value={questions}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        filterDisplay="row"
      >
        <Column
          field="id"
          header="ID"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          style={{ width: '60px' }}
          sortable
        />
        <Column
          field="question"
          header="Questão"
          filter
          filterHeaderClassName="p-filter-single"
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          header="Criado em"
          sortable
          body={createdAtBody}
          style={{ width: '200px' }}
          sortField="createdAt"
        />
        <Column
          header="Atualizado em"
          sortable
          body={updatedAtBody}
          style={{ width: '200px' }}
          sortField="updatedAt"
        />
        <Column
          field="isMultiple"
          header="Múltipla Resposta"
          body={isMultipleBody}
          sortable
          style={{ width: '200px' }}
        />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          body={getActionButtons}
          headerClassName="isCentered"
          bodyClassName="actionsColumn isCentered"
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { QuizQuestionDataTable };
