import { useEffect, useState } from 'react';
import { FaPen, FaEnvelope } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import StudentRequest from 'models/StudentRequest';
import { fetchSubscribersRequest } from 'store/ducks/subscriber/actions';
import { Button } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './StudentRequestDataTable.styles';

interface StudentRequestDataTableProps {
  data: StudentRequest[];
  onPressItem: (studentRequest: StudentRequest) => void;
  onContactClick: (studentRequest: StudentRequest) => void;
  onDoubleClick: (studentRequest: StudentRequest) => void;
  requestStatus: 'PENDING' | 'APPROVED' | 'DISAPPROVED';
}

const StudentRequestDataTable = (props: StudentRequestDataTableProps) => {
  const { data, onPressItem, onContactClick, onDoubleClick, requestStatus } =
    props;

  const [studentsRequests, setStudentsRequests] =
    useState<StudentRequest[]>(data);

  useEffect(() => {
    setStudentsRequests(data);
  }, [data]);

  const handleRowDoubleClick = (student: StudentRequest) => {
    onDoubleClick(student);
  };

  const getActionsBody = (student: StudentRequest) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onContactClick(student)}
        severity="info"
        title="Entrar em contato"
        icon="pi pi-envelope"
      />
      <Button
        type="button"
        onClick={() => onPressItem(student)}
        severity="warning"
        title="Alterar status"
        icon="pi pi-pencil"
      />
    </S.ActionButtons>
  );

  const getLastStatusHeader = () => {
    switch (requestStatus) {
      case 'PENDING':
        return 'Data Solicitação';
      case 'APPROVED':
        return 'Data Aprovação';
      case 'DISAPPROVED':
        return 'Data Reprovação';
      default:
        return '';
    }
  };

  const getLastStatusBody = (studentRequest: StudentRequest) => {
    switch (requestStatus) {
      case 'PENDING':
        return (
          <span>
            {moment(studentRequest.createdAt).format('DD/MM/YYYY HH:mm')}
          </span>
        );
      case 'APPROVED':
      case 'DISAPPROVED':
        return (
          <span>
            {moment(studentRequest.updatedAt).format('DD/MM/YYYY HH:mm')}
          </span>
        );
      default:
        return '';
    }
  };

  const getStatusBody = (student: StudentRequest) => (
    <div>
      {student.status === 'PENDING' && 'Pendente'}
      {student.status === 'APPROVED' && 'Aprovada'}
      {student.status === 'DISAPPROVED' && 'Negada'}
    </div>
  );

  const getLastStatusFieldName = () => {
    switch (requestStatus) {
      case 'PENDING':
        return 'createdAt';
      case 'APPROVED':
      case 'DISAPPROVED':
        return 'updatedAt';
      default:
        return '';
    }
  };

  return (
    <S.DataTableLayoutWrapper>
      <DataTable
        sortField="id"
        sortOrder={1}
        value={studentsRequests}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
      >
        <Column
          field="id"
          header="ID"
          style={{ width: '100px' }}
          bodyClassName="p-table-center"
          sortable
        />
        <Column field="userName" header="Nome" sortable />
        <Column field="userEmail" header="Email" sortable />
        <Column
          field="files.length"
          header="Arquivos"
          bodyClassName="p-table-center"
          sortable
        />
        <Column
          field={getLastStatusFieldName()}
          header={getLastStatusHeader()}
          body={getLastStatusBody}
          sortable
        />
        <Column field="status" header="Status" body={getStatusBody} sortable />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          headerClassName="p-table-center"
          body={getActionsBody}
        />
      </DataTable>
    </S.DataTableLayoutWrapper>
  );
};

export { StudentRequestDataTable };
