import Promotion from 'models/Promotion';

export enum PromotionTypes {
  FETCH_ALL_PROMOTIONS_REQUEST = '@promotion/FETCH_ALL_PROMOTIONS_REQUEST',
  FETCH_ALL_PROMOTIONS_SUCCESS = '@promotion/FETCH_ALL_PROMOTIONS_SUCCESS',
  FETCH_ALL_PROMOTIONS_ERROR = '@promotion/FETCH_ALL_PROMOTIONS_ERROR',

  CREATE_PROMOTION_REQUEST = '@promotion/CREATE_PROMOTION_REQUEST',
  CREATE_PROMOTION_SUCCESS = '@promotion/CREATE_PROMOTION_SUCCESS',
  CREATE_PROMOTION_ERROR = '@promotion/CREATE_PROMOTION_ERROR',

  EDIT_PROMOTION_REQUEST = '@promotion/EDIT_PROMOTION_REQUEST',
  EDIT_PROMOTION_SUCCESS = '@promotion/EDIT_PROMOTION_SUCCESS',
  EDIT_PROMOTION_ERROR = '@promotion/EDIT_PROMOTION_ERROR',

  DELETE_PROMOTION_REQUEST = '@promotion/DELETE_PROMOTION_REQUEST',
  DELETE_PROMOTION_SUCCESS = '@promotion/DELETE_PROMOTION_SUCCESS',
  DELETE_PROMOTION_ERROR = '@promotion/DELETE_PROMOTION_ERROR'
}

export interface PromotionState {
  readonly promotions: Promotion[];
  readonly promotion: Promotion;
}

export type PromotionAction = {
  type: PromotionTypes;
  payload: {
    promotions: Promotion[];
    promotion: Promotion;
    promotionId: 0;
    planId: 0;
  };
};
