// TODO: verificar porque a estilização de cores não funciona
// [{ color: [] }, { background: [] }],
export const editorModules = {
  toolbar: [
    [{ size: ['small', 'medium', 'large', 'huge'] }, { font: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }, 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      { align: [] }
    ],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

export const editorFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'align',
  'link',
  'image',
  'video'
];
