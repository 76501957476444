import { action } from 'typesafe-actions';
import Subscription from 'models/Subscription';
import {
  ActiveUsers,
  ActiveUsersByPeriod,
  CanceledAndActiveUsersAfterTest,
  CanceledOrActivePayingUsers,
  CanceledUsersAfterTest,
  FinancialReport,
  PaymentAmount,
  PlaylistsUsage,
  SubscriptionsSummary,
  UsagePerHour,
  UsersInPromotion,
  UsersInTest
} from 'interfaces/Analytics';
import { AnalyticsTypes } from './types';

// ---------------------------------------------------------
export const usagePerHoursRequest = (startsIn: string, endsIn: string) =>
  action(AnalyticsTypes.USAGE_PER_HOURS_REQUEST, { startsIn, endsIn });
export const usagePerHoursSuccess = (usagePerHours: UsagePerHour[]) =>
  action(AnalyticsTypes.USAGE_PER_HOURS_SUCCESS, { usagePerHours });
export const usagePerHoursFailure = () =>
  action(AnalyticsTypes.USAGE_PER_HOURS_FAILURE);

// ---------------------------------------------------------
export const playlistsUsageRequest = (startsIn: string, endsIn: string) =>
  action(AnalyticsTypes.PLAYLISTS_USAGE_REQUEST, { startsIn, endsIn });
export const playlistsUsageSuccess = (playlistsUsage: PlaylistsUsage[]) =>
  action(AnalyticsTypes.PLAYLISTS_USAGE_SUCCESS, { playlistsUsage });
export const playlistsUsageFailure = () =>
  action(AnalyticsTypes.PLAYLISTS_USAGE_FAILURE);

// ---------------------------------------------------------
export const activeUsersByPlanRequest = () =>
  action(AnalyticsTypes.ACTIVE_USERS_BY_PLAN_REQUEST);
export const activeUsersByPlanSuccess = (activeUsersByPlan: ActiveUsers[]) =>
  action(AnalyticsTypes.ACTIVE_USERS_BY_PLAN_SUCCESS, { activeUsersByPlan });
export const activeUsersByPlanFailure = () =>
  action(AnalyticsTypes.ACTIVE_USERS_BY_PLAN_FAILURE);

// ---------------------------------------------------------
export const activeUsersByPeriodRequest = (startsIn: string, endsIn: string) =>
  action(AnalyticsTypes.ACTIVE_USERS_BY_PERIOD_REQUEST, { startsIn, endsIn });
export const activeUsersByPeriodSuccess = (
  activeUsersByPeriod: ActiveUsersByPeriod
) =>
  action(AnalyticsTypes.ACTIVE_USERS_BY_PERIOD_SUCCESS, {
    activeUsersByPeriod
  });
export const activeUsersByPeriodFailure = () =>
  action(AnalyticsTypes.ACTIVE_USERS_BY_PERIOD_FAILURE);

// ---------------------------------------------------------
export const canceledOrActivePayingUsersRequest = (
  startsIn: string,
  endsIn: string
) =>
  action(AnalyticsTypes.CANCELED_OR_ACTIVE_PAYING_USERS_REQUEST, {
    startsIn,
    endsIn
  });
export const canceledOrActivePayingUsersSuccess = (
  canceledOrActivePayingUsers: CanceledOrActivePayingUsers
) =>
  action(AnalyticsTypes.CANCELED_OR_ACTIVE_PAYING_USERS_SUCCESS, {
    canceledOrActivePayingUsers
  });
export const canceledOrActivePayingUsersFailure = () =>
  action(AnalyticsTypes.CANCELED_OR_ACTIVE_PAYING_USERS_FAILURE);

// ---------------------------------------------------------
export const canceledAndActiveUsersAfterTestRequest = (
  startsIn: string,
  endsIn: string
) =>
  action(AnalyticsTypes.CANCELED_AND_ACTIVE_USERS_AFTER_TEST_REQUEST, {
    startsIn,
    endsIn
  });
export const canceledAndActiveUsersAfterTestSuccess = (
  canceledAndActiveUsersAfterTest: CanceledAndActiveUsersAfterTest[]
) =>
  action(AnalyticsTypes.CANCELED_AND_ACTIVE_USERS_AFTER_TEST_SUCCESS, {
    canceledAndActiveUsersAfterTest
  });
export const canceledAndActiveUsersAfterTestFailure = () =>
  action(AnalyticsTypes.CANCELED_AND_ACTIVE_USERS_AFTER_TEST_FAILURE);

// ---------------------------------------------------------
export const canceledUsersAfterTestRequest = (
  startsIn: string,
  endsIn: string
) =>
  action(AnalyticsTypes.CANCELED_USERS_AFTER_TEST_REQUEST, {
    startsIn,
    endsIn
  });
export const canceledUsersAfterTestSuccess = (
  canceledUsersAfterTest: CanceledUsersAfterTest[]
) =>
  action(AnalyticsTypes.CANCELED_USERS_AFTER_TEST_SUCCESS, {
    canceledUsersAfterTest
  });
export const canceledUsersAfterTestFailure = () =>
  action(AnalyticsTypes.CANCELED_USERS_AFTER_TEST_FAILURE);

// ---------------------------------------------------------
export const usersInPromotionRequest = () =>
  action(AnalyticsTypes.USERS_IN_PROMOTION_REQUEST);
export const usersInPromotionSuccess = (usersInPromotion: UsersInPromotion) =>
  action(AnalyticsTypes.USERS_IN_PROMOTION_SUCCESS, {
    usersInPromotion
  });
export const usersInPromotionFailure = () =>
  action(AnalyticsTypes.USERS_IN_PROMOTION_FAILURE);

// ---------------------------------------------------------
export const usersInTestRequest = () =>
  action(AnalyticsTypes.USERS_IN_TEST_REQUEST);
export const usersInTestSuccess = (usersInTest: UsersInTest) =>
  action(AnalyticsTypes.USERS_IN_TEST_SUCCESS, {
    usersInTest
  });
export const usersInTestFailure = () =>
  action(AnalyticsTypes.USERS_IN_TEST_FAILURE);

// ---------------------------------------------------------
export const paymentAmountRequest = () =>
  action(AnalyticsTypes.PAYMENT_AMOUNT_REQUEST);
export const paymentAmountSuccess = (paymentAmount: PaymentAmount) =>
  action(AnalyticsTypes.PAYMENT_AMOUNT_SUCCESS, {
    paymentAmount
  });
export const paymentAmountFailure = () =>
  action(AnalyticsTypes.PAYMENT_AMOUNT_FAILURE);

// ---------------------------------------------------------
export const financialReportRequest = (startsIn?: string, endsIn?: string) =>
  action(AnalyticsTypes.FINANCIAL_REPORT_REQUEST, { startsIn, endsIn });
export const financialReportSuccess = (financialReport: FinancialReport[]) =>
  action(AnalyticsTypes.FINANCIAL_REPORT_SUCCESS, {
    financialReport
  });
export const financialReportFailure = () =>
  action(AnalyticsTypes.FINANCIAL_REPORT_FAILURE);

// ---------------------------------------------------------
export const subscriptionsSummaryRequest = () =>
  action(AnalyticsTypes.SUBSCRIPTIONS_SUMMARY_REQUEST);
export const subscriptionsSummarySuccess = (
  subscriptionsSummary: SubscriptionsSummary
) =>
  action(AnalyticsTypes.SUBSCRIPTIONS_SUMMARY_SUCCESS, {
    subscriptionsSummary
  });
export const subscriptionsSummaryFailure = () =>
  action(AnalyticsTypes.SUBSCRIPTIONS_SUMMARY_FAILURE);

// ---------------------------------------------------------
export const allSubscriptionsRequest = () =>
  action(AnalyticsTypes.ALL_SUBSCRIPTIONS_REQUEST);
export const allSubscriptionsSuccess = (subscriptions: Subscription[]) =>
  action(AnalyticsTypes.ALL_SUBSCRIPTIONS_SUCCESS, {
    subscriptions
  });
export const allSubscriptionsFailure = () =>
  action(AnalyticsTypes.ALL_SUBSCRIPTIONS_FAILURE);
