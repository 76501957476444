import { useState, useEffect, useRef } from 'react';

export default function useOutsideClickCapture() {
  const ref = useRef<HTMLDivElement>(null);
  const [clickOutside, setClickOutside] = useState(false);
  
  const handleClickOutside = (event: MouseEvent) => {
    const clickedOutside = ref.current && !ref.current.contains(event.target as Node);

    setClickOutside(!!clickedOutside);
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return { ref, clickOutside, setClickOutside };
}