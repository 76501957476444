import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.blue};
`;

export const FormWrapper = styled.form`
  width: 440px;
  margin: 0 auto;

  input {
    color: #f3f3f3;
    background-color: #131827;
    border-color: #b0b4c4;
  }

  label {
    background-color: #131827;
    color: #b0b4c4;
  }
`;

export const PlaylegisLogo = styled.figure`
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
`;

export const ButtonGroup = styled.div`
  display: flex;

  button {
    width: 100%;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const LogoutMessage = styled.div`
  text-align: center;
  margin-bottom: 30px;
  color: white;
`;
