import SuperCategory from 'models/SuperCategory';

export enum SuperCategoryTypes {
    FETCH_SUPER_CATEGORY_REQUEST = '@superCategory/FETCH_SUPER_CATEGORY_REQUEST',
    FETCH_SUPER_CATEGORY_SUCCESS = '@superCategory/FETCH_SUPER_CATEGORY_SUCCESS',
    FETCH_SUPER_CATEGORY_ERROR = '@superCategory/FETCH_SUPER_CATEGORY_ERROR',

}

export interface SuperCategoryState {
  readonly data: SuperCategory[];
}
