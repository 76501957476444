export enum TrashTypes {
    FETCH_TRASH_REQUEST = '@trash/FETCH_TRASH_REQUEST',
    FETCH_TRASH_SUCCESS = '@trash/FETCH_TRASH_SUCCESS',
    FETCH_TRASH_ERROR = '@trash/FETCH_TRASH_ERROR',

    REMOVE_TRASH_AUDIO_REQUEST = '@trash/REMOVE_TRASH_AUDIO_REQUEST',
    REMOVE_TRASH_AUDIO_SUCCESS = '@trash/REMOVE_TRASH_AUDIO_SUCCESS',
    REMOVE_TRASH_AUDIO_ERROR = '@trash/REMOVE_TRASH_AUDIO_ERROR',

    REMOVE_TRASH_FOLDER_REQUEST = '@trash/REMOVE_TRASH_FOLDER_REQUEST',
    REMOVE_TRASH_FOLDER_SUCCESS = '@trash/REMOVE_TRASH_FOLDER_SUCCESS',
    REMOVE_TRASH_FOLDER_ERROR = '@trash/REMOVE_TRASH_FOLDER_ERROR',

    RESTORE_TRASH_AUDIO_REQUEST = '@trash/RESTORE_TRASH_AUDIO_REQUEST',
    RESTORE_TRASH_AUDIO_SUCCESS = '@trash/RESTORE_TRASH_AUDIO_SUCCESS',
    RESTORE_TRASH_AUDIO_ERROR = '@trash/RESTORE_TRASH_AUDIO_ERROR',

    RESTORE_TRASH_FOLDER_REQUEST = '@trash/RESTORE_TRASH_FOLDER_REQUEST',
    RESTORE_TRASH_FOLDER_SUCCESS = '@trash/RESTORE_TRASH_FOLDER_SUCCESS',
    RESTORE_TRASH_FOLDER_ERROR = '@trash/RESTORE_TRASH_FOLDER_ERROR',
}

export interface TrashState {
  readonly data: any[];
  readonly old: any;
}
