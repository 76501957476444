import { ThemeModes, ThemeType } from 'helpers/types/Theme';

export enum ThemeTypes {
  SWITCH_MODE = '@theme/SWITCH_MODE'
}

export interface ThemeState {
  readonly mode: ThemeModes;
  readonly theme: ThemeType;
}

export type ThemeAction = {
  type: ThemeTypes;
  payload: ThemeState;
};
