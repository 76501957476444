import classNames from 'classnames';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { PageHeaderProps } from './interfaces/PageHeaderProps';
import * as S from './PageHeader.styles';

const PageHeader = (props: PageHeaderProps) => {
  const { title, actionsComponent, breadcrumbsBottomComponent } = props;

  const getBreadcrumbsBottomComponent = () => {
    if (breadcrumbsBottomComponent) {
      return breadcrumbsBottomComponent;
    }
  };

  const getActionsComponent = () => {
    if (actionsComponent) {
      return actionsComponent;
    }
  };

  return (
    <>
      <S.PageHeaderRow className={classNames('page-header__breadcrumbs')}>
        <S.PageHeaderColumn>
          <Breadcrumbs />

          {getBreadcrumbsBottomComponent()}
        </S.PageHeaderColumn>
      </S.PageHeaderRow>

      <S.PageHeaderRow className={classNames('page-header__title-actions-row')}>
        <S.PageHeaderColumn>
          <S.PageHeaderTitle>{title}</S.PageHeaderTitle>
        </S.PageHeaderColumn>

        <S.PageHeaderActionsColumn
          className={classNames('page-header__actions-component-column')}
        >
          {getActionsComponent()}
        </S.PageHeaderActionsColumn>
      </S.PageHeaderRow>
    </>
  );
};

PageHeader.defaultProps = {
  actionsComponent: '',
  actionsComponentAlignment: 'flex-end',
  actionsComponentColumnClassName: '',
  breadcrumbsRowClassName: '',
  titleActionsRowClassName: ''
};

export { PageHeader };
