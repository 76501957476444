import Log from 'models/Log';

export enum LogTypes {
  FETCH_LOGS_REQUEST = '@logs/FETCH_LOGS_REQUEST',
  FETCH_LOGS_SUCCESS = '@logs/FETCH_LOGS_SUCCESS',
  FETCH_LOGS_ERROR = '@logs/FETCH_LOGS_ERROR',

  FETCH_LOG_BY_ID_REQUEST = '@logs/FETCH_LOG_BY_ID_REQUEST',
  FETCH_LOG_BY_ID_SUCCESS = '@logs/FETCH_LOG_BY_ID_SUCCESS',
  FETCH_LOG_BY_ID_ERROR = '@logs/FETCH_LOG_BY_ID_ERROR',

  FETCH_LOGS_BY_USER_REQUEST = '@logs/FETCH_LOGS_BY_USER_REQUEST',
  FETCH_LOGS_BY_USER_SUCCESS = '@logs/FETCH_LOGS_BY_USER_SUCCESS',
  FETCH_LOGS_BY_USER_ERROR = '@logs/FETCH_LOGS_BY_USER_ERROR',

  FETCH_LOGS_BY_USER_SUBSCRIPTION_REQUEST = '@logs/FETCH_LOGS_BY_USER_SUBSCRIPTION_REQUEST',
  FETCH_LOGS_BY_USER_SUBSCRIPTION_SUCCESS = '@logs/FETCH_LOGS_BY_USER_SUBSCRIPTION_SUCCESS',
  FETCH_LOGS_BY_USER_SUBSCRIPTION_ERROR = '@logs/FETCH_LOGS_BY_USER_SUBSCRIPTION_ERROR'
}

export interface LogState {
  readonly id: number;
  readonly logs: Log[];
  readonly log: Log;
}
