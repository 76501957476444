import { action } from 'typesafe-actions';
import { QuizQuestion } from 'models/Quiz';
import { QuizQuestionsTypes } from './types';

export const fetchOneQuizQuestionsRequest = (id: number) =>
  action(QuizQuestionsTypes.FETCH_ONE_QUIZ_QUESTIONS_REQUEST, { id });

export const fetchOneQuizQuestionsSuccess = (one: QuizQuestion) =>
  action(QuizQuestionsTypes.FETCH_ONE_QUIZ_QUESTIONS_SUCCESS, { one });

export const fetchOneQuizQuestionsError = () =>
  action(QuizQuestionsTypes.FETCH_ONE_QUIZ_QUESTIONS_ERROR);

export const createQuizQuestionRequest = (one: QuizQuestion) =>
  action(QuizQuestionsTypes.CREATE_QUIZ_QUESTION_REQUEST, { one });

export const createQuizQuestionSuccess = () =>
  action(QuizQuestionsTypes.CREATE_QUIZ_QUESTION_SUCCESS);

export const createQuizQuestionError = () =>
  action(QuizQuestionsTypes.CREATE_QUIZ_QUESTION_ERROR);

export const updateQuizQuestionRequest = (one: QuizQuestion) =>
  action(QuizQuestionsTypes.UPDATE_QUIZ_QUESTION_REQUEST, { one });

export const updateQuizQuestionSuccess = () =>
  action(QuizQuestionsTypes.UPDATE_QUIZ_QUESTION_SUCCESS);

export const updateQuizQuestionError = () =>
  action(QuizQuestionsTypes.UPDATE_QUIZ_QUESTION_ERROR);

export const deleteQuizQuestionRequest = (one: QuizQuestion) =>
  action(QuizQuestionsTypes.DELETE_QUIZ_QUESTION_REQUEST, { one });

export const deleteQuizQuestionSuccess = () =>
  action(QuizQuestionsTypes.DELETE_QUIZ_QUESTION_SUCCESS);

export const deleteQuizQuestionError = () =>
  action(QuizQuestionsTypes.DELETE_QUIZ_QUESTION_ERROR);
