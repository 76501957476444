import styled from 'styled-components';

export const DialogWrapper = styled.div`
  .p-dialog {
    padding: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    min-width: 300px;
    max-width: 500px;
  }

  .p-dialog .p-dialog-titlebar {
    border: 0;
    border-bottom: 1px solid ${({ theme }) => theme.dialog.border};
    background-color: ${({ theme }) => theme.dialog.header.bg};
    color: ${({ theme }) => theme.dialog.header.color};
    padding: 8px;
    font-weight: 700;
    border-radius: 5px 5px 0px 0px;

    text-align: center;
    font-size: 14px;
    font-family: 'Mulish';
  }

  .p-dialog .p-dialog-titlebar .p-dialog-title {
    margin: 0;
    float: none;
    font-weight: 700;
  }

  .p-dialog .p-dialog-content {
    background-color: ${({ theme }) => theme.dialog.content.bg};
    color: ${({ theme }) => theme.dialog.content.color};
    border: 0;
    padding: 1em;
  }

  .p-dialog .p-dialog-footer {
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.dialog.border};
    background-color: ${({ theme }) => theme.dialog.header.bg};
    padding: 8px 16px;
    border-radius: 0px 0px 5px 5px;
  }

  .p-dialog-title {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Mulish';
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .p-button {
    width: 130px !important;
    font-size: 14px;
    justify-content: center;

    &:last-of-type {
      margin-left: 8px !important;
    }
  }
`;
