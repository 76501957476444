import { Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { getTranslatedSubscriptionStatus } from '../ExportButtons/SubscriptionsReportExportButtons.utils';
import { SubscriptionReportPdfDocumentProps } from './interfaces/SubscriptionReportPdfDocumentProps';
import { styles } from './SubscriptionReportPdfDocument.styles';

const SubscriptionReportPdfDocument = (
  props: SubscriptionReportPdfDocumentProps
) => {
  const { data } = props;

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>Lista de assinaturas</Text>
        </View>

        <View style={styles.body}>
          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>Usuário</Text>
            </View>

            <View style={styles.column}>
              {data.map(({ userName }, index) => (
                <View key={index.toString()} style={styles.row}>
                  <Text>{userName}</Text>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>CPF</Text>
            </View>

            <View style={styles.column}>
              {data.map(({ cpf }, index) => (
                <View key={index.toString()} style={styles.row}>
                  <Text>{cpf || '-'}</Text>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>Email</Text>
            </View>

            <View style={styles.column}>
              {data.map(({ userEmail }, index) => (
                <View key={index.toString()} style={styles.row}>
                  <Text>{userEmail || '-'}</Text>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>Telefone</Text>
            </View>

            <View style={styles.column}>
              {data.map(({ phone }, index) => (
                <View key={index.toString()} style={styles.row}>
                  <Text>{phone || '-'}</Text>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>Plano</Text>
            </View>

            {data.map(({ planName }, index) => (
              <View key={index.toString()} style={styles.row}>
                <Text>{planName}</Text>
              </View>
            ))}
          </View>

          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>Valor</Text>
            </View>

            {data.map(({ value }, index) => (
              <View key={index.toString()} style={styles.row}>
                <Text>{`R$ ${value}`}</Text>
              </View>
            ))}
          </View>

          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>Assinou em</Text>
            </View>

            {data.map(({ createdAt }, index) => (
              <View key={index.toString()} style={styles.row}>
                <Text>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</Text>
              </View>
            ))}
          </View>

          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.title}>Status da assinatura</Text>
            </View>

            {data.map(({ status }, index) => (
              <View key={index.toString()} style={styles.row}>
                <Text>{getTranslatedSubscriptionStatus(status)}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export { SubscriptionReportPdfDocument };
