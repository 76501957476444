import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { UserAdmin } from 'models/User';
import {
  createUserAdminRequest,
  fetchUsersAdminRequest,
  updateUserAdminRequest
} from 'store/ducks/userAdmin/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { UserAdminActionSidebar, UserAdminDataTable } from './components';
import * as S from './UserAdminPage.styles';

const userAdminInitialState: UserAdmin = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  password: '',
  isAdmin: false,
  removed: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date(),
  userPermissions: [],
  firstLogin: true,
  hubId: ''
};

const UserAdminPage = () => {
  const dispatch = useDispatch();

  const users = useSelector((state: ApplicationState) => state.userAdmin.all);

  const [selectedUser, setSelectedUser] = useState<UserAdmin>(
    userAdminInitialState
  );

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchUsersAdminRequest());
  }, [dispatch]);

  // Handlers de estado
  const handleSavePressed = (user: UserAdmin) => {
    if (user.id && user.id !== 0) {
      dispatch(updateUserAdminRequest(user));
    } else {
      dispatch(createUserAdminRequest(user));
    }
    setSelectedUser(userAdminInitialState);
  };

  // Handlers de botões e ações
  const handleAddPressed = () => {
    setSelectedUser(userAdminInitialState);
    setDrawerMode('OPEN_ADD');
  };

  const handleEditPressed = (data: UserAdmin) => {
    setSelectedUser(data);
    setDrawerMode('OPEN_EDIT');
  };

  const getAddButton = () => (
    <Button
      type="button"
      severity="primary"
      size="big"
      onClick={handleAddPressed}
    >
      <FaPlus />
      Adicionar
    </Button>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <UserAdminActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedUser={selectedUser}
          onConfirmPress={handleSavePressed}
        />

        <S.Container>
          <PageHeader
            title="Usuários Administradores"
            actionsComponent={getAddButton()}
          />

          <UserAdminDataTable data={users} onPressItem={handleEditPressed} />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { UserAdminPage };
