import { action } from 'typesafe-actions';
import { TrashTypes } from './types';

export const fetchTrashRequest = () => action(TrashTypes.FETCH_TRASH_REQUEST);

export const fetchTrashSuccess = (data:any[]) => action(TrashTypes.FETCH_TRASH_SUCCESS, { data });

export const fetchTrashError = () => action(TrashTypes.FETCH_TRASH_ERROR);

export const removeTrashAudioRequest = (old:any) => action(
  TrashTypes.REMOVE_TRASH_AUDIO_REQUEST, { old },
);
export const removeTrashAudioSuccess = () => action(TrashTypes.REMOVE_TRASH_AUDIO_SUCCESS);
export const removeTrashAudioError = () => action(TrashTypes.REMOVE_TRASH_AUDIO_ERROR);

export const removeTrashFolderRequest = (old:any) => action(
  TrashTypes.REMOVE_TRASH_FOLDER_REQUEST, { old },
);
export const removeTrashFolderSuccess = () => action(TrashTypes.REMOVE_TRASH_FOLDER_SUCCESS);
export const removeTrashFolderError = () => action(TrashTypes.REMOVE_TRASH_FOLDER_ERROR);

export const restoreTrashAudioRequest = (old:any) => action(
  TrashTypes.RESTORE_TRASH_AUDIO_REQUEST, { old },
);
export const restoreTrashAudioSuccess = () => action(TrashTypes.RESTORE_TRASH_AUDIO_SUCCESS);
export const restoreTrashAudioError = () => action(TrashTypes.RESTORE_TRASH_AUDIO_ERROR);

export const restoreTrashFolderRequest = (old:any) => action(
  TrashTypes.RESTORE_TRASH_FOLDER_REQUEST, { old },
);
export const restoreTrashFolderSuccess = () => action(TrashTypes.RESTORE_TRASH_FOLDER_SUCCESS);
export const restoreTrashFolderError = () => action(TrashTypes.RESTORE_TRASH_FOLDER_ERROR);
