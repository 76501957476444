import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTrash, FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Audio from 'models/Audio';
import AudioPlaylistOrderer from 'models/AudioPlaylistOrderer';
import AudioToPlaylist from 'models/AudioToPlaylist';
import Playlist from 'models/Playlist';
import { showDialog } from 'store/ducks/dialog/actions';
import {
  fetchOnePlaylistRequest,
  editPlaylistOrdererRequest,
  removeAudioFromPlaylistRequest
} from 'store/ducks/playlist/actions';
import { Checkbox } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { Button } from '../Button/Button';
import * as S from './DragAndDrop.styles';
import { DragAndDropProps } from './interfaces/DragAndDropProps';

const DragAndDrop = (props: DragAndDropProps) => {
  const { playlist: selectedPlaylist } = props;
  const [playlist, setPlaylist] = useState<Playlist>(selectedPlaylist);
  const [selectedAudios, setSelectedAudios] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const { onChangeMultiAudiosSelecteds } = props;

  const audios = useSelector(
    (state: ApplicationState) => state.playlist.old.audios
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setPlaylist(selectedPlaylist);
  }, [selectedPlaylist]);

  useEffect(() => {
    onChangeMultiAudiosSelecteds(selectedAudios);
  }, [onChangeMultiAudiosSelecteds, selectedAudios]);

  useEffect(() => {
    setPlaylist((p) => ({
      ...p,
      audios
    }));
  }, [audios]);

  useEffect(() => {
    if (selectedPlaylist.id !== 0)
      dispatch(fetchOnePlaylistRequest(selectedPlaylist));
    // eslint-disable-next-line
  }, [selectedPlaylist, dispatch]);

  const handleSave = (audiosToSave: Audio[]) => {
    const orderedAudios: AudioPlaylistOrderer[] = audiosToSave.map((audio) => ({
      audioId: audio.id,
      playListId: playlist.id,
      order: audio.audioPlaylistOrder
    }));

    dispatch(editPlaylistOrdererRequest(orderedAudios));
  };

  const reorder = (auds: Audio[], startIndex: number, endIndex: number) => {
    const [removed] = auds.splice(startIndex, 1);
    auds.splice(endIndex, 0, removed);

    const resultOrdered: Audio[] = auds
      .filter((item) => typeof item !== 'undefined')
      .map((audio, index) =>
        Object.assign(audio, { audioPlaylistOrder: index })
      );

    setPlaylist({
      ...playlist,
      audios: resultOrdered
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    reorder(playlist.audios, result.source.index, result.destination.index);
  };

  const handleSelectAudios = (value: string) => {
    if (value === 'selectAll') {
      if (isAllChecked) {
        setIsAllChecked(false);
        setSelectedAudios([]);
      } else {
        setIsAllChecked(true);
        setSelectedAudios(playlist.audios.map((item) => item.id));
      }
    } else {
      const newId = Number(value);

      if (selectedAudios.find((id) => id === newId)) {
        const newArray = selectedAudios.filter((id) => id !== newId);
        setSelectedAudios(newArray);
      } else {
        setSelectedAudios([...selectedAudios, newId]);
      }
    }
  };

  const isAudioSelected = (audioId: number) => {
    if (selectedAudios.find((id) => id === audioId)) return true;
    return false;
  };

  useEffect(() => {
    const selectedLen = selectedAudios.length;
    const audiosLen = playlist.audios.length;

    if (selectedLen === audiosLen && selectedLen > 0) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
    // eslint-disable-next-line
  }, [selectedAudios]);

  const handleSingleRemovePress = (item: Audio) => {
    const obj: AudioToPlaylist = {
      playlist,
      audios: [{ id: item.id }]
    };

    const remove = () => dispatch(removeAudioFromPlaylistRequest(obj));

    dispatch(
      showDialog({
        visible: true,
        title: 'Remover audio',
        message: `Deseja realmente desvincular o audio "${item.title}" desta playlist?`,
        mode: 'CONFIRM',
        onCancelPress: () => '',
        onConfirmPress: remove
      })
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Button
        type="button"
        severity="confirm"
        onClick={() => handleSave(playlist.audios)}
      >
        <FaSave />
        Salvar
      </Button>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <S.Table
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={snapshot.isDraggingOver ? 'isDraggingOver' : ''}
          >
            <thead>
              <tr>
                <th className="audioSelect">
                  <Checkbox
                    id="selectAll"
                    name="selectAll"
                    value="selectAll"
                    onChange={(e) => handleSelectAudios(e.target.value)}
                    checked={isAllChecked}
                  />
                </th>
                <th className="audioOrder">Ordem</th>
                <th className="audioId">ID</th>
                <th className="audioTitle">Titulo</th>
                <td className="audioActions" />
              </tr>
            </thead>
            <tbody>
              {Array.isArray(playlist.audios) &&
                playlist.audios.map((item) => (
                  <Draggable
                    key={`${item.id}-${item.title}`}
                    draggableId={item.audioPlaylistOrder.toString() + item.id}
                    index={item.audioPlaylistOrder}
                  >
                    {(dragProvided, dragSnapshot) => (
                      <tr
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        className={dragSnapshot.isDragging ? 'isDragging' : ''}
                      >
                        <td className="audioSelect">
                          <Checkbox
                            id={`audio-${item.id}`}
                            name={`audio-${item.id}`}
                            value={item.id}
                            onChange={(e) => handleSelectAudios(e.target.value)}
                            checked={
                              (isAllChecked || isAudioSelected(item.id)) && true
                            }
                          />
                        </td>
                        <td className="audioOrder">
                          {item.audioPlaylistOrder}
                        </td>
                        <td className="audioId">{item.id}</td>
                        <td className="audioTitle">{item.title}</td>
                        <td className="audioActions">
                          <Button
                            type="button"
                            severity="danger"
                            className="noMargin"
                            onClick={() => handleSingleRemovePress(item)}
                          >
                            <FaTrash />
                          </Button>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
            </tbody>
            {provided.placeholder}
          </S.Table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DragAndDrop };
