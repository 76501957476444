import { useDispatch, useSelector } from 'react-redux';
import { switchMode } from 'store/ducks/theme/actions';
import ApplicationState from 'helpers/types/ApplicationState';
import { ThemeModes } from 'helpers/types/Theme';
import { Switch } from '../Switch/Switch';
import * as S from './ThemeSwitch.styles';

const ThemeSwitch = () => {
  const { mode } = useSelector((state: ApplicationState) => state.theme);
  const dispatch = useDispatch();

  const handleThemeSwitchCheck = () => {
    let newMode: ThemeModes = 'DARK';

    if (mode === 'DARK') {
      newMode = 'LIGHT';
    }

    dispatch(switchMode(newMode));
    localStorage.setItem('theme', newMode);
  };

  const getStatus = () => mode === 'LIGHT';

  const getLabel = () => {
    const modeToken = mode === 'DARK' ? 'escuro' : 'claro';

    return `Modo ${modeToken}`;
  };

  return (
    <S.ThemeSwitchWrapper>
      <Switch
        id="themeSwitcher"
        checked={getStatus()}
        onCheck={handleThemeSwitchCheck}
        leftSideLabel={getLabel()}
        switchLabelColor={false}
      />
    </S.ThemeSwitchWrapper>
  );
};

export { ThemeSwitch };
