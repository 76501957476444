import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editTermsRequest,
  fetchTermsRequest
} from 'store/ducks/institutionalTerms/actions';
import { PageHeader } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { TermsEditor } from './components';
import * as S from './TermsPage.styles';

const TermsPage = () => {
  const dispatch = useDispatch();

  const { terms: stateTerms } = useSelector(
    (state: ApplicationState) => state.institutionalTerms
  );

  const [termsOfUse, setTermsOfUse] = useState(stateTerms.termsOfUse);
  const [privacyPolicies, setPrivacyPolicies] = useState(
    stateTerms.privacyPolicies
  );
  const [cookiesPolicy, setCookiesPolicy] = useState(stateTerms.cookiesPolicy);
  const [openTab, setOpenTabChange] = useState<
    'none' | 'use' | 'privacy' | 'cookies'
  >('none');

  useEffect(() => {
    dispatch(fetchTermsRequest());
  }, [dispatch]);

  useEffect(() => {
    setTermsOfUse(stateTerms.termsOfUse);
    setPrivacyPolicies(stateTerms.privacyPolicies);
    setCookiesPolicy(stateTerms.cookiesPolicy);
  }, [stateTerms]);

  const handleOpenTabChange = (
    newTab: 'none' | 'use' | 'privacy' | 'cookies'
  ) => {
    if (newTab === openTab) {
      setOpenTabChange('none');
    } else {
      setOpenTabChange(newTab);
    }
  };

  const handleTermsOfUseChange = (ev: { htmlValue: string | null }) =>
    setTermsOfUse(ev.htmlValue!);

  const handlePrivacyPoliciesChange = (ev: { htmlValue: string | null }) => {
    setPrivacyPolicies(ev.htmlValue!);
  };

  const handleCookiesPolicyChange = (ev: { htmlValue: string | null }) =>
    setCookiesPolicy(ev.htmlValue!);

  const handleTermsSubmit = () => {
    dispatch(
      editTermsRequest({
        ...stateTerms,
        termsOfUse,
        cookiesPolicy,
        privacyPolicies
      })
    );
  };

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <S.Container>
          <PageHeader title="Institucional - Termos e condições" />

          <S.FormsWrapper>
            <TermsEditor
              openTab={openTab === 'use'}
              onOpenTabChange={() => handleOpenTabChange('use')}
              title="Termos de uso"
              textValue={termsOfUse}
              onTextValueChange={handleTermsOfUseChange}
              onSubmit={handleTermsSubmit}
            />
            <TermsEditor
              openTab={openTab === 'privacy'}
              onOpenTabChange={() => handleOpenTabChange('privacy')}
              title="Política de privacidade"
              textValue={privacyPolicies}
              onTextValueChange={handlePrivacyPoliciesChange}
              onSubmit={handleTermsSubmit}
            />
            <TermsEditor
              openTab={openTab === 'cookies'}
              onOpenTabChange={() => handleOpenTabChange('cookies')}
              title="Política de cookies"
              textValue={cookiesPolicy}
              onTextValueChange={handleCookiesPolicyChange}
              onSubmit={handleTermsSubmit}
            />
          </S.FormsWrapper>
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { TermsPage };
