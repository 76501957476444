import styled from 'styled-components';
import {
  SwitchContainerProps,
  SwitchLabelProps
} from './interfaces/SwitchStyleProps';

export const SwitchContainer = styled.div<SwitchContainerProps>`
  .p-inputswitch {
    .p-inputswitch-slider {
      background: ${({ theme }) => theme.yellow};

      &:before {
        background-color: ${({ theme }) => theme.blue};
      }
    }
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background-color: ${({ theme }) => theme.blue};
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: ${({ theme }) => theme.yellowDarker};
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: ${({ theme }) => theme.blueDarker};
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background-color: ${({ theme }) => theme.blueLighter};
  }

  display: flex;
  align-items: center;

  .input-switch__component {
    ${({ $hasLeftLabel }) => $hasLeftLabel && 'margin-left: 10px'};
    ${({ $hasRightLabel }) => $hasRightLabel && 'margin-right: 10px'};
  }
`;

export const SwitchLabel = styled.span<SwitchLabelProps>`
  color: ${({ $checked, theme }) =>
    $checked ? theme.toggler.label.active : theme.toggler.label.regular};
`;
