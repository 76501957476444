import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import * as S from './styled';

interface iOptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const Option = (props: iOptionProps) => (
  <S.OptionButton
    type="button"
    className={classNames('select__option', props.className)}
    value={props.id}
    onClick={props.onClick}
  >
    {props.value}
  </S.OptionButton>
);

Option.defaultProps = {
  className: ''
};

export default Option;
