/* eslint-disable no-plusplus */
/* eslint-disable radix */
import * as yup from 'yup';
import TeachingInstitution from 'models/TeachingInstitution';

const teachingInstitutionSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required('O campo "NOME" é obrigatório'),
  cnpj: yup.string().required('O campo "CNPJ" é obrigatório')
});

export default (teachingInstitution: TeachingInstitution) =>
  teachingInstitutionSchema.validateSync(teachingInstitution, {
    abortEarly: false
  });
