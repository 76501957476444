import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  padding: 20px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.table.border};
  border-radius: 3px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.btn.secondary.bg};
  }
`;

export const InputFile = styled.input`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -100;
`;
