import { FileStatus } from 'models/FileStatus';
import { AudioState } from './types';

const AUDIO_INITIAL_STATE: AudioState = {
  id: 0,
  audio: {
    id: 0,
    description: '',
    fileName: '',
    url: '',
    audioFolderOrder: 0,
    audioPlaylistOrder: 0,
    createdAt: new Date(),
    removedAt: new Date(),
    updatedAt: new Date(),
    folderId: 0,
    removed: false,
    status: FileStatus.DISABLED,
    title: '',
    transcription: '',
    userId: 0,
    parentId: 0
  },
  audioUrl: ''
};

export { AUDIO_INITIAL_STATE };
