import styled from 'styled-components';

export const Container = styled.div`
  .p-progressbar {
    top: 0;
    border: 0 none;
    border-radius: 25px;
    height: 24px;
    background-color: ${({ theme }) => theme.progressBar.bg};
  }

  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: ${({ children, theme }) => {
    const param = children as any;

    if (param.props.value === 100) { return 'red'; }

    return theme.progressBar.status;
  }};
  }

  .p-progressbar .p-progressbar-label {
    margin-top: 0;
    color: ${({ theme }) => theme.progressBar.color};
    line-height: 24px;
  }
`;
