import styled from 'styled-components';
import { DataTableLayout } from 'components';

export const DataTableWrapper = styled(DataTableLayout)`
  table {
    .isCentered {
      text-align: center !important;

      .p-column-header-content {
        justify-content: center;
      }
    }

    tbody.p-datatable-tbody {
      td {
        .p-column-duration {
          display: flex;
          flex-direction: column;
          justify-items: center;

          .p-tag {
            align-items: center;

            &:first-child {
              margin-bottom: 6px;
            }
          }
        }
      }
    }
  }
`;

export { ActionButtons } from 'components/DataTableLayout/DataTableLayout.styles';
