import { action } from 'typesafe-actions';
import Promotion from 'models/Promotion';
import { PromotionTypes } from './types';

export const fetchPromotionsRequest = () =>
  action(PromotionTypes.FETCH_ALL_PROMOTIONS_REQUEST);

export const fetchPromotionsSuccess = (promotions: Promotion[]) =>
  action(PromotionTypes.FETCH_ALL_PROMOTIONS_SUCCESS, { promotions });

export const fetchPromotionsError = () =>
  action(PromotionTypes.FETCH_ALL_PROMOTIONS_ERROR);

export const createPromotionRequest = (promotion: Promotion, planId: number) =>
  action(PromotionTypes.CREATE_PROMOTION_REQUEST, {
    promotion,
    planId
  });

export const createPromotionSuccess = () =>
  action(PromotionTypes.CREATE_PROMOTION_SUCCESS);

export const createPromotionError = () =>
  action(PromotionTypes.CREATE_PROMOTION_ERROR);

export const editPromotionRequest = (promotion: Promotion) =>
  action(PromotionTypes.EDIT_PROMOTION_REQUEST, { promotion });

export const editPromotionSuccess = () =>
  action(PromotionTypes.EDIT_PROMOTION_SUCCESS);

export const editPromotionError = () =>
  action(PromotionTypes.EDIT_PROMOTION_ERROR);

export const deletePromotionRequest = (promotionId: number) =>
  action(PromotionTypes.DELETE_PROMOTION_REQUEST, { promotionId });

export const deletePromotionSuccess = () =>
  action(PromotionTypes.DELETE_PROMOTION_SUCCESS);

export const deletePromotionError = () =>
  action(PromotionTypes.DELETE_PROMOTION_ERROR);
