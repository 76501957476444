import Subscriber from 'models/Subscriber';

export enum SubscriberTypes {
  FETCH_SUBSCRIBERS_REQUEST = '@subscriber/FETCH_SUBSCRIBERS_REQUEST',
  FETCH_SUBSCRIBERS_SUCCESS = '@subscriber/FETCH_SUBSCRIBERS_SUCCESS',
  FETCH_SUBSCRIBERS_ERROR = '@subscriber/FETCH_SUBSCRIBERS_ERROR',

  FETCH_ONE_SUBSCRIBER_REQUEST = '@subscriber/FETCH_ONE_SUBSCRIBER_REQUEST',
  FETCH_ONE_SUBSCRIBER_SUCCESS = '@subscriber/FETCH_ONE_SUBSCRIBER_SUCCESS',
  FETCH_ONE_SUBSCRIBER_ERROR = '@subscriber/FETCH_ONE_SUBSCRIBER_ERROR',

  CREATE_SUBSCRIBER_REQUEST = '@subscriber/CREATE_SUBSCRIBER_REQUEST',
  CREATE_SUBSCRIBER_SUCCESS = '@subscriber/CREATE_SUBSCRIBER_SUCCESS',
  CREATE_SUBSCRIBER_ERROR = '@subscriber/CREATE_SUBSCRIBER_ERROR',

  UPDATE_SUBSCRIBER_REQUEST = '@subscriber/UPDATE_SUBSCRIBER_REQUEST',
  UPDATE_SUBSCRIBER_SUCCESS = '@subscriber/UPDATE_SUBSCRIBER_SUCCESS',
  UPDATE_SUBSCRIBER_ERROR = '@subscriber/UPDATE_SUBSCRIBER_ERROR',

  FILTER_SUBSCRIBER_REQUEST = '@subscriber/FILTER_SUBSCRIBER_REQUEST',
  FILTER_SUBSCRIBER_SUCCESS = '@subscriber/FILTER_SUBSCRIBER_SUCCESS',
  FILTER_SUBSCRIBER_ERROR = '@subscriber/FILTER_SUBSCRIBER_ERROR',

  UPDATE_SUBSCRIBER_PLAN_REQUEST = '@subscriber/UPDATE_SUBSCRIBER_PLAN_REQUEST',
  UPDATE_SUBSCRIBER_PLAN_SUCCESS = '@subscriber/UPDATE_SUBSCRIBER_PLAN_SUCCESS',
  UPDATE_SUBSCRIBER_PLAN_ERROR = '@subscriber/UPDATE_SUBSCRIBER_PLAN_ERROR',

  CANCEL_SUBSCRIPTION_REQUEST = '@subscriber/CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS = '@subscriber/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR = '@subscriber/CANCEL_SUBSCRIPTION_ERROR'
}

interface Filters {
  createdAt?: string;
  canceledAt?: string;
  status?: string;
  planValue?: string;
  subscriberData?: {
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    phone?: string;
  };
  teachingInstitutionId?: string;
}

export interface SubscriberState {
  readonly id: number;
  readonly one: Subscriber;
  readonly all: Subscriber[];
  readonly filters: Filters;
  readonly updatePlanError: boolean;
}

export type SubscriberAction = {
  type: SubscriberTypes;
  payload: {
    id: number;
    one: Subscriber;
    all: Subscriber[];
    filters: Filters;
    newPlanId: number;
  };
};
