import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.section.bg};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
  border-radius: 6px;
  font-size: 14px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;

  th, td {
    border: 1px solid ${({ theme }) => theme.table.border};
    padding: 8px;
    text-align: left;

    &.alignCenter {
      text-align: left;
    }
  }
`;

export const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.h3`
  font-size: 20px;
`;

export const Blockquote = styled.blockquote`
  border-left: 5px solid ${({ theme }) => theme.btn.primary.bg};
  padding: 20px;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const DownloadSectionHeader = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;

  h3 {
    flex-grow: 1;
  }
`;

export const TogglerGroup = styled.div`
  display: flex;
  /* position: absolute;
  right: 0;
  top: 0;
  padding: 20px 28px; */

  button {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

interface ButtonGroupProps {
  mode?: string;
}

export const ButtonGroup = styled.div<ButtonGroupProps>`
  display: flex;
  flex-wrap: wrap;

  ${(props) => props.mode && props.mode === 'list' && `
    flex-direction: column;
  `};
`;

export const DownloadButton = styled.a<ButtonGroupProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.btn.info.color};
  border-radius: 10px;
  flex-grow: 1;
  color: ${({ theme }) => theme.btn.info.color};
  transition: opacity 0.3s;

  svg {
    font-size: 48px;
    margin-bottom: 12px;
  }

  ${(props) => props.mode && props.mode === 'list' && `
    flex-direction: row;

    svg {
      font-size: 22px;
      margin-right: 8px;
      margin-bottom: 0;
    }
  `};

  &:hover {
    opacity: 0.5;
  }
`;
