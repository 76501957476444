import styled from 'styled-components';

export const Form = styled.form``;

export const InputRadioGroup = styled.div`
  display: flex;
  align-items: center;

  span.radioGroupLabel {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.weight.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.secondary};
    padding: 8px 14px;
    flex-grow: 4;
  }

  label:first-of-type {
    margin-right: 32px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  justify-content: center;

  button {
    text-align: right;

    &:first-child {
      margin-right: 8px;
    }
  }
`;
