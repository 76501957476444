import styled from 'styled-components';
import { ContentLayout } from 'components';
import * as Breakpoint from 'styles/breakpoints';

export const ContainerFluid = styled.div`
  ${Breakpoint.Fluid};
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Container = styled(ContentLayout)`
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;

  &.container-row {
    height: 100%;
  }

  &.welcome-message-title {
    margin-right: 100px;
  }

  &.welcome-message-description {
    align-items: center;
    justify-items: center;
  }
`;

export const LogoIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  img {
    width: 130px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Title = styled.div`
  font-family: 'Mulish', sans-serif;
  font-size: 48px;
  font-weight: ${({ theme }) => theme.weight.bold};
  margin-bottom: 32px;
`;

export const Description = styled.div`
  font-family: 'Mulish', sans-serif;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.weight.light};
`;
