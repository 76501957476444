import { Reducer } from 'redux';
import StudentRequest from 'models/StudentRequest';
import { StudentRequestTypes, StudentRequestState, Filters } from './types';

export type Action = {
  type: StudentRequestTypes;
  payload: {
    id: number;
    one: StudentRequest;
    all: StudentRequest[];
    filters: Filters;
    message: string;
  };
};

const INITIAL_STATE: StudentRequestState = {
  id: 0,
  one: {
    id: 0,
    hubId: '',
    userEmail: '',
    userName: '',
    status: 'PENDING',
    files: [
      {
        url: '',
        fileName: ''
      }
    ],
    comments: '',
    subscriptionId: 0,
    reasonRepprove: '',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  all: [],
  filters: {
    startsIn: '',
    endsIn: '',
    status: ''
  },
  message: ''
};

const reducer: Reducer<StudentRequestState, Action> = (
  state: StudentRequestState = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case StudentRequestTypes.FETCH_STUDENTS_REQ_SUCCESS: {
      return {
        ...state,
        all: action.payload.all
      };
    }
    case StudentRequestTypes.CREATE_STUDENT_REQ_REQUEST: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case StudentRequestTypes.UPDATE_STUDENT_REQ_REQUEST: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case StudentRequestTypes.SEND_STUDENT_MESSAGE_REQUEST: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
