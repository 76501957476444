import styled from 'styled-components';

export const DifferentialsFormWrapper = styled.div``;

export const DifferentialsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const DifferentialItem = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid transparent;
  transition: border 0.3s;
  padding: 6px;

  &:hover {
    border-color: ${({ theme }) => theme.table.border};
  }
`;

export const DifferentialItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  max-width: 60px;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.blueLighter};
  border-radius: 5px;
  padding: 12px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DifferentialItemText = styled.div`
  text-align: left;

  .item__title {
    font-weight: 600;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const FormGroup = styled.fieldset`
  display: flex;
  flex-direction: column;

  &:last-child {
    width: 100%;
  }
`;

export const DifferentialFormIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 12px;

  label {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 5px;
    transition: all 0.3s;
    margin-bottom: 12px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.background};

    input {
      display: none;
    }

    svg {
      opacity: 0.8;
      font-size: 20px;
      transition: all 0.3s;
      color: ${({ theme }) => theme.text.primary};
    }

    &:hover {
      background-color: rgba(82,89,112,0.8);

      svg {
        opacity: 0.6;
        color: white;
      }
    }

  }

  .has-cover {
    animation: fadeIn 0.5s;

    svg {
      display: none;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;

  button:not(:last-child) {
    margin-right: 12px;
  }
`;
