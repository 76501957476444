import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import store from 'store';
import { fetchViewFolderRequest } from 'store/ducks/folder/actions';
import { showToast } from 'store/ducks/toast/actions';
import { BASE_URL } from 'helpers/connector/Connector';

export default class FileService {
  private api: AxiosInstance;

  constructor() {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: `${BASE_URL}/v1`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: Cookies.get('AuthToken') || ''
      }
    };

    this.api = axios.create(axiosConfig);
  }

  async uploadAudio(
    file: File,
    id: number,
    progress: (progress: number) => void
  ) {
    const data = new FormData();

    data.append('audio', file);

    try {
      await this.api.put(`audios/upload?id=${id}`, data, {
        onUploadProgress: (p) => progress(p.loaded / p.total)
      });
      store.dispatch<any>(
        fetchViewFolderRequest(store.getState().currentFolder.parentId)
      );
      store.dispatch<any>(
        showToast({
          summary: 'Audio',
          detail: 'Upload concluído com sucesso',
          severity: 'success'
        })
      );
      return Promise.resolve();
    } catch (error) {
      store.dispatch<any>(
        showToast({
          summary: 'Audio',
          detail: 'Falha ao realizar upload',
          severity: 'error'
        })
      );
      return Promise.reject(error);
    }
  }

  async uploadMultipleAudio(
    files: File[],
    folderId: number,
    progress: (progress: number) => void
  ) {
    const data = new FormData();

    files.forEach((file) => data.append('audios', file));

    try {
      await this.api.post(`audios/upload?folderId=${folderId}`, data, {
        onUploadProgress: (p) => progress(p.loaded / p.total)
      });
      return setTimeout(() => {
        store.dispatch<any>(
          showToast({
            summary: 'Áudios',
            detail: 'Upload concluído com sucesso',
            severity: 'success'
          })
        );
        store.dispatch<any>(
          fetchViewFolderRequest(store.getState().currentFolder.parentId)
        );
        return Promise.resolve();
      }, 1000);
    } catch (error) {
      store.dispatch<any>(
        showToast({
          summary: 'Áudios',
          detail: 'Falha ao realizar upload',
          severity: 'error'
        })
      );
      return Promise.reject(error);
    }
  }

  async uploadImagePlaylist(
    file: File,
    playlistId: number,
    progress: (progress: number) => void
  ) {
    const data = new FormData();
    data.append('cover', file);

    try {
      await this.api.put(`playlists/upload-cover?id=${playlistId}`, data, {
        onUploadProgress: (p) => progress(p.loaded / p.total)
      });
      store.dispatch<any>(
        showToast({
          summary: 'Capa da playlist',
          detail: 'Upload concluído com sucesso',
          severity: 'success'
        })
      );
    } catch (error) {
      store.dispatch<any>(
        showToast({
          summary: 'Capa da playlist',
          detail: 'Falha ao realizar upload',
          severity: 'error'
        })
      );
    }
  }

  async importSubscribersFromCsv(
    file: File,
    progress: (progress: number) => void
  ) {
    const data = new FormData();

    data.append('csv', file);

    try {
      await this.api.post('subscriptions/import-to-csv', data, {
        onUploadProgress: (p) => progress(p.loaded / p.total)
      });
      store.dispatch<any>(
        showToast({
          summary: 'Importação de assinantes',
          detail: 'Upload concluído com sucesso',
          severity: 'success'
        })
      );
    } catch (error) {
      store.dispatch<any>(
        showToast({
          summary: 'Importação de assinantes',
          detail: 'Falha ao realizar upload',
          severity: 'error'
        })
      );
    }
  }
}
