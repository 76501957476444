import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import * as S from './Drawer.styles';
import { DrawerProps } from './interfaces/DrawerProps';

const Drawer = (props: DrawerProps) => {
  const { drawerMode, onBackDropPress, title, children, className } = props;

  return (
    <Sidebar
      className={classNames(className, 'p-drawer')}
      position="right"
      visible={drawerMode !== 'CLOSE'}
      onHide={() => onBackDropPress()}
      blockScroll
      closeOnEscape
      showCloseIcon={false}
    >
      <S.DrawerHeader>
        <h2>{title}</h2>

        <Button
          className="p-button-rounded p-button-danger p-button-outlined"
          icon="pi pi-times"
          onClick={() => onBackDropPress()}
        />
      </S.DrawerHeader>

      {children}
    </Sidebar>
  );
};

Drawer.defaultProps = {
  className: ''
};

export { Drawer };
