import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { showToast } from '../toast/actions';
import { fetchSuperCategorySuccess, fetchSuperCategoryError } from './actions';

export function* fetchSuperCategory() {
  try {
    const response: ConnectorResponse = yield call(
      Connector().get,
      'super-categories/list'
    );

    yield put(fetchSuperCategorySuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Super Categoria',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchSuperCategoryError());
  }
}
