import { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import PlaylistCategory from 'models/PlaylistCategory';
import SuperCategory from 'models/SuperCategory';
import { savePlaylistCategoryRequest } from 'store/ducks/playlistCategory/actions';
import { fetchSuperCategoryRequest } from 'store/ducks/superCategory/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button } from 'components';
import { ButtonGroup } from 'components/Dialog/Dialog.styles';
import { InputText, InputTextarea, Select } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import categoryValidate from 'helpers/validation/category';
import * as S from './PlaylistAddNewCategoryDialog.styles';

const newCategory = {
  id: 0,
  label: '',
  name: '',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  superCategoryId: 0,
  showInHome: false
};

interface PlaylistAddNewCategoryDialogProps {
  visible: boolean;
  setVisible: (visibility: boolean) => any;
}

const PlaylistAddNewCategoryDialog = (
  props: PlaylistAddNewCategoryDialogProps
) => {
  const { setVisible, visible } = props;

  const [superCategorySelected, setSuperCategorySelected] =
    useState<SuperCategory>();
  const [category, setCategory] = useState<PlaylistCategory>(newCategory);

  const dispatch = useDispatch();

  const superCategory = useSelector(
    (state: ApplicationState) => state.superCategory.data
  );

  useEffect(() => {
    dispatch(fetchSuperCategoryRequest());
  }, [dispatch]);

  useEffect(() => {
    if (superCategory.length > 0) {
      setSuperCategorySelected(
        superCategory.find((item) => item.id === category.superCategoryId) ||
          superCategory[0]
      );
    }
  }, [superCategory, category.superCategoryId]);

  const handleCancelPress = () => {
    setVisible(false);
  };

  const handleConfirmPress = () => {
    try {
      categoryValidate(category);
      dispatch(savePlaylistCategoryRequest(category));
      setCategory(newCategory);
      handleCancelPress();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((err_, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: err_.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const footer = (
    <ButtonGroup>
      <Button
        type="button"
        size="big"
        severity="confirm"
        onClick={handleConfirmPress}
        icon="pi pi-save"
        label="Salvar"
      />

      <Button
        type="button"
        size="big"
        severity="danger"
        onClick={handleCancelPress}
        icon="pi pi-times"
        label="Cancelar"
      />
    </ButtonGroup>
  );

  return (
    <Dialog
      header="Nova Categoria"
      footer={footer}
      visible={visible}
      modal
      onHide={() => setVisible(false)}
    >
      <S.Form>
        <InputText
          label="Nome"
          name="name"
          type="text"
          value={category.name}
          onChange={(e) =>
            setCategory({
              ...category,
              name: e.target.value
            })
          }
        />

        <InputTextarea
          label="Descrição"
          name="description"
          rows={5}
          cols={30}
          value={category.description}
          onChange={(e) =>
            setCategory({
              ...category,
              description: e.currentTarget.value
            })
          }
        />

        <Dropdown
          optionLabel="name"
          value={superCategorySelected}
          options={superCategory}
          onChange={(e) => {
            setSuperCategorySelected(e.value);
            setCategory({ ...category, superCategoryId: e.value.id || 0 });
          }}
        />
      </S.Form>
    </Dialog>
  );
};

export { PlaylistAddNewCategoryDialog };
