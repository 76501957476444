import { StyleSheet } from '@react-pdf/renderer';

export const documentStyles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '9pt',
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'NotoSans'
  },

  header: {
    backgroundColor: '#131827',
    padding: '9 0',
    color: '#FFF'
  },

  headerTitle: {
    width: '100%',
    fontSize: '14pt',
    textTransform: 'uppercase',
    textAlign: 'center'
  },

  body: {
    display: 'flex',
    flexDirection: 'row'
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1pt solid #bbb',
    padding: '8'
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  title: {
    color: '#131827'
  }
});
