import { Reducer } from 'redux';
import { darkTheme, lightTheme } from 'styles/theme';
import { ThemeTypes, ThemeState, ThemeAction } from './types';
import { THEME_INITIAL_STATE } from './utils';

const reducer: Reducer<ThemeState, ThemeAction> = (
  state: ThemeState = THEME_INITIAL_STATE,
  action: ThemeAction
) => {
  switch (action.type) {
    case ThemeTypes.SWITCH_MODE: {
      switch (action.payload.mode) {
        case 'DARK': {
          return {
            ...action.payload,
            theme: darkTheme
          };
        }
        case 'LIGHT': {
          return {
            ...action.payload,
            theme: lightTheme
          };
        }
        default: {
          return state;
        }
      }
    }
    default: {
      return state;
    }
  }
};

export default reducer;
