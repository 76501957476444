import styled from 'styled-components';

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
    cursor: pointer;
  }

  .radioCheckmark {
    display: block;
    padding: 6px;
    background-color: unset;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.radio.border};
    transition: background-color 0.3s, color 0.3s;
    margin-right: 6px;
  }

  input:hover ~ .radioCheckmark {
    background-color: ${({ theme }) => theme.radio.bg.hover};
  }

  input:checked ~ .radioCheckmark {
    border-color: ${({ theme }) => theme.radio.bg.checked};
    background-color: ${({ theme }) => theme.radio.bg.checked};
  }

  .radioLabel {
    color: ${({ theme }) => theme.text.primary};
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
    margin-left: 4px;
  }
`;
