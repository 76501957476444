import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { removeLoadingModule, addLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchTeachingInstitutionsRequest,
  fetchTeachingInstitutionsSuccess,
  fetchTeachingInstitutionsError,
  fetchOneTeachingInstitutionSuccess,
  fetchOneTeachingInstitutionError,
  createTeachingInstitutionSuccess,
  createTeachingInstitutionError,
  updateTeachingInstitutionSuccess,
  updateTeachingInstitutionError,
  deleteTeachingInstitutionSuccess,
  deleteTeachingInstitutionError
} from './actions';
import { Action } from './index';

export function* fetchTeachingInstitutions() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const response: ConnectorResponse = yield call(
      Connector().get,
      'teaching-institutions/list'
    );

    yield put(fetchTeachingInstitutionsSuccess(response.data));
  } catch (err: any) {
    yield put(fetchTeachingInstitutionsError());
    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* fetchOneTeachingInstitution(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { data: teachingInstitution }: ConnectorResponse = yield call(
      Connector().get,
      `teachingInstitutions?id=${action.payload.id}`
    );

    yield put(fetchOneTeachingInstitutionSuccess(teachingInstitution));
  } catch (err: any) {
    yield put(fetchOneTeachingInstitutionError());
    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* createTeachingInstitution(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const teachingInstitution = {
      name: action.payload.one.name,
      cnpj: action.payload.one.cnpj,
      code: action.payload.one.code
    } as any;

    yield call(Connector().post, 'teaching-institutions', teachingInstitution);

    yield put(createTeachingInstitutionSuccess());
    yield put(fetchTeachingInstitutionsRequest());
    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Dados criados com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(createTeachingInstitutionError());
    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Falha ao criar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* updateTeachingInstitution(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const teachingInstitution = action.payload.one;

    yield call(
      Connector().put,
      `teaching-institutions?id=${teachingInstitution.id}`,
      teachingInstitution
    );

    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
    yield put(updateTeachingInstitutionSuccess());
    yield put(fetchTeachingInstitutionsRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(updateTeachingInstitutionError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* deleteTeachingInstitution(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const teachingInstitution = action.payload.one;

    yield call(
      Connector().delete,
      `teaching-institutions?id=${teachingInstitution.id}`
    );

    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
    yield put(deleteTeachingInstitutionSuccess());
    yield put(fetchTeachingInstitutionsRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Instituições de ensino',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(deleteTeachingInstitutionError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}
