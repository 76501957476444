import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableRowClickEventParams,
  DataTableSelectionChangeParams
} from 'primereact/datatable';
import Audio from 'models/Audio';
import Folder from 'models/Folder';
import {
  removeAudioRequest,
  setSelectedAudio
} from 'store/ducks/audio/actions';
import { showDialog } from 'store/ducks/dialog/actions';
import { openDrawer } from 'store/ducks/drawer/actions';
import {
  removeFolderRequest,
  setSelectedFolder
} from 'store/ducks/folder/actions';
import { showToast } from 'store/ducks/toast/actions';
import { DataTableLayout } from 'components';
import { getFileType } from 'pages/FileManager/FileManagerPage.utils';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import ApplicationState from 'helpers/types/ApplicationState';
import { EditAudioSidebar } from '../AudioActionSidebars/EditSidebar/EditAudioSidebar';
import { FolderActionSidebar } from '../FolderActionSidebar/FolderActionSidebar';
import * as S from './FileManagerDataTable.styles';
import * as utils from './FileManagerDataTable.utils';
import { FileManagerDataTableProps } from './interfaces/FileManagerDataTableProps';

const FileManagerDataTable = (props: FileManagerDataTableProps) => {
  const { selectedItems, setSelectedItems, folderNavigator } = props;

  const dispatch = useDispatch();
  const { viewFolder } = useSelector((state: ApplicationState) => state.folder);
  const [files, setFiles] = useState<(Folder | Audio)[]>([]);

  const currentParentId = useSelector(
    (state: ApplicationState) => state.currentFolder.parentId
  );
  const [folderHints, setFolderHints] = useState(0);
  const [audioHints, setAudioHints] = useState(0);

  useEffect(() => {
    const newFiles = [
      ...viewFolder.audios.map((item) => ({ ...item, name: item.title })),
      ...viewFolder.Folder
    ];

    setFiles(newFiles);
  }, [viewFolder]);

  const handleEditClick = (data: Folder | Audio) => {
    if (getFileType(data) === 'AUDIO') {
      dispatch(setSelectedAudio(data as Audio));
      dispatch(
        openDrawer(<EditAudioSidebar />, {
          title: 'Editar audio'
        })
      );
    } else {
      dispatch(setSelectedFolder(data as Folder));
      dispatch(
        openDrawer(<FolderActionSidebar />, {
          title: 'Editar pasta'
        })
      );
    }
  };

  const handleRemoveClick = (data: Folder | Audio) => {
    let remove;
    let titleRemove = '';
    let messageRemove = '';

    Object.assign(data, { parentId: currentParentId });

    if (getFileType(data) === 'AUDIO') {
      const file = data as Audio;

      remove = () => dispatch(removeAudioRequest(file));
      titleRemove = 'Excluir audio';
      messageRemove = `Tem certeza que deseja excluir o áudio "${file.title}"?`;
    } else {
      const file = data as Folder;

      remove = () => dispatch(removeFolderRequest(file));
      titleRemove = 'Excluir pasta';
      messageRemove = `Tem certeza que deseja excluir a pasta "${file.name}"?`;
    }

    dispatch(
      showDialog({
        visible: true,
        title: titleRemove,
        message: messageRemove,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: remove
      })
    );
  };

  const handleRowClick = (e: DataTableRowClickEventParams) => {
    if (getFileType(e.data) === 'FOLDER' && folderHints < 1) {
      dispatch(
        showToast({
          summary: '',
          detail: 'Clique duas vezes para abrir',
          severity: 'info'
        })
      );
      setFolderHints(folderHints + 1);
    }

    if (getFileType(e.data) === 'AUDIO' && audioHints < 1) {
      dispatch(
        showToast({
          summary: '',
          detail: 'Clique duas vezes para editar',
          severity: 'info'
        })
      );
      setAudioHints(audioHints + 1);
    }
  };

  const handleRowDoubleClick = (e: DataTableRowClickEventParams) => {
    if (getFileType(e.data) === 'AUDIO') {
      dispatch(setSelectedAudio(e.data as Audio));
      dispatch(
        openDrawer(<EditAudioSidebar />, {
          title: 'Editar audio'
        })
      );
    } else {
      folderNavigator(e.data as Folder);
    }
  };

  const handleSelectionChange = (e: DataTableSelectionChangeParams) => {
    if (!Array.isArray(e.value)) return;
    setSelectedItems(e.value as (Folder | Audio)[]);
  };

  const getActions = (file: Folder | Audio) => (
    <S.ActionButtons>
      <Button
        onClick={() => handleEditClick(file)}
        className="p-button-success"
        icon="pi pi-pencil"
      />
      <Button
        type="button"
        onClick={() => handleRemoveClick(file)}
        className="p-button-danger"
        icon="pi pi-trash"
      />
    </S.ActionButtons>
  );

  return (
    <DataTableLayout>
      <DataTable
        value={files}
        sortField="createdAt"
        sortOrder={1}
        selectionMode="checkbox"
        filterDisplay="row"
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={files.length}
        rowsPerPageOptions={[10, 15, 20]}
        responsiveLayout="scroll"
        selection={selectedItems}
        onRowClick={handleRowClick}
        onRowDoubleClick={handleRowDoubleClick}
        onSelectionChange={handleSelectionChange}
      >
        <Column
          className="selectionColumn"
          selectionMode="multiple"
          style={{ width: '2em' }}
        />
        <Column
          field="id"
          header="ID"
          style={{ width: '60px' }}
          align="center"
        />
        <Column
          field="name"
          header="Nome"
          body={utils.getNameBody}
          filter
          filterPlaceholder="Buscar"
          filterHeaderClassName="p-filter-single-with-selection"
          sortable
        />
        <Column
          header="Criado em"
          sortable
          body={utils.createdAtBody}
          style={{ width: '150px' }}
          sortField="createdAt"
        />
        <Column
          header="Atualizado em"
          sortable
          body={utils.updatedAtBody}
          style={{ width: '180px' }}
          sortField="updatedAt"
        />
        <Column
          header="Tipo"
          sortable
          sortField="type"
          body={utils.typeBody}
          style={{ width: '100px' }}
          align="center"
        />
        <Column
          sortField="status"
          header="Status"
          sortable
          style={{ width: '130px' }}
          body={utils.statusBody}
          align="center"
        />
        <Column
          bodyClassName="actionsColumn"
          header="Ações"
          style={{ width: '130px' }}
          body={getActions}
          align="center"
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { FileManagerDataTable };
