import { FinancialReport } from 'interfaces/Analytics';

const translateFields = (financialReport: FinancialReport[]) => {
  const getTranslatedSubscriptionStatus = (
    status: 'ENABLED' | 'CANCELED' | 'IN_TEST' | 'OVERDUE'
  ) => {
    switch (status) {
      case 'CANCELED':
        return 'Cancelada';
      case 'ENABLED':
        return 'Ativa';
      case 'IN_TEST':
        return 'Em teste';
      case 'OVERDUE':
        return 'Pagamento atrasado';
      default:
        return 'Ativa';
    }
  };

  return financialReport.map((item) => ({
    Plano: item.plan,
    ValorDoPlano: item.planValue,
    Usuário: item.user,
    Email: item.email,
    StatusDaAssinatura: getTranslatedSubscriptionStatus(
      item.subscriptionStatus
    ),
    MotivoDeCancelamento: item.cancelationReason,
    DataDeCriação: item.createdAt,
    DataDeCancelamento: item.canceledAt
  }));
};

export { translateFields };
