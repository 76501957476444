import TreeNode from 'primereact/treenode/treenode';
import Audio from 'models/Audio';
import FolderTree from 'models/FolderTree';

const buildFolder = (folder: FolderTree) => {
  const node: TreeNode = {
    key: JSON.stringify({ type: 'folder', id: folder.id }),
    label: folder.name,
    children: [],
    icon: 'pi pi-fw pi-folder',
    data: folder
  };
  return node;
};

const buildAudios = (audios: Audio[]) =>
  audios.map((audio) => ({
    key: JSON.stringify({ type: 'audio', id: audio.id }),
    label: audio.title,
    children: [],
    icon: 'pi pi-fw pi-file',
    data: audio
  }));

const buildNodes = (folders: FolderTree[], nodeT: TreeNode) => {
  for (let i = 0; i < folders.length; i += 1) {
    if (folders[i] !== null) {
      const folder = buildFolder(folders[i]);

      folder.children = buildAudios(folder.data.audios);

      nodeT.children?.unshift(folder);

      const el = nodeT.children?.indexOf(folder);

      if (folders[i].folders.length > 0)
        buildNodes(folders[i].folders, nodeT.children![el!]);
    }
  }

  return nodeT;
};

export default (data: FolderTree[], nodeMaster: TreeNode) =>
  buildNodes(data, nodeMaster);
