import { FileDragAndDropConsumer } from './components/FileDragAndDropConsumer/FileDragAndDropConsumer';
import { FileDragAndDropProvider } from './components/FileDragAndDropProvider/FileDragAndDropProvider';
import { FileDragAndDropProps } from './interfaces/FileDragAndDropProps';

const FileDragAndDrop = (props: FileDragAndDropProps) => {
  const { changeProgressBarState, onFinish, folderId, progress } = props;

  return (
    <FileDragAndDropProvider>
      <FileDragAndDropConsumer
        folderId={folderId}
        progress={progress}
        changeProgressBarState={changeProgressBarState}
        onFinish={onFinish}
      />
    </FileDragAndDropProvider>
  );
};

export { FileDragAndDrop };
