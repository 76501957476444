import { InstitutionalNewsState } from './types';

export const NEWS_INITIAL_STATE: InstitutionalNewsState = {
  article: {
    id: 0,
    title: '',
    description: '',
    coverFilename: '',
    coverUrl: '',
    publish: false,
    createdAt: new Date(),
    updatedAt: new Date()
  },
  list: [],
  message: ''
};
