import { useEffect } from 'react';
import {
  FaUserGraduate,
  FaMapMarkerAlt,
  FaIdCard,
  FaUserEdit,
  FaUserPlus,
  FaStar,
  FaDollarSign,
  FaPhone
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Subscriber from 'models/Subscriber';
import { fetchTeachingInstitutionsRequest } from 'store/ducks/teachingInstitution/actions';
import { generateNewUID } from 'helpers/generateUID';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './SubscriberProfileTable.styles';

interface SubscriberProfileTableProps {
  data: Subscriber;
}

const SubscriberProfileTable = (props: SubscriberProfileTableProps) => {
  const { data } = props;

  const dispatch = useDispatch();

  const {
    subscriber: { one: subscriber },
    teachingInstitution: { all: teachingInstitutions }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    dispatch(fetchTeachingInstitutionsRequest());
  }, [dispatch]);

  const dateFormat = (date: Date) => (
    <span>{moment(date).format('DD/MM/YYYY HH:mm')}</span>
  );

  const subscriptionStatusBody = (subscriber: Subscriber) => {
    switch (subscriber.status) {
      case 'IN_TEST':
        return <span className="in-test">Assinatura em teste</span>;
      case 'ENABLED':
        return <span className="enabled">Assinatura ativa</span>;
      case 'OVERDUE':
        return <span className="disabled">Assinatura atrasada</span>;
      case 'CANCELED':
        return <span className="cancelled">Assinatura cancelada</span>;
      default:
        return null;
    }
  };

  const getSubscriberInstitutionInfo = () => {
    const institutionId = data.teachingInstitutionId;
    const institution = teachingInstitutions.find(
      ({ id }) => id === institutionId
    );

    return `${institutionId} - ${institution?.name}`;
  };

  const getLocation = () => {
    if (subscriber.userData) {
      let localization = '';

      if (subscriber.userData.city) {
        localization = `${subscriber.userData?.city},`;
      }

      if (subscriber.userData.state) {
        localization = `${subscriber.userData?.state},`;
      }

      if (subscriber.userData.country) {
        localization = `${subscriber.userData?.country}`;
      }

      return (
        <S.UserLocation title="Localização">
          <FaMapMarkerAlt />
          {localization}
        </S.UserLocation>
      );
    }
  };

  const getCancelationReason = () => {
    if (subscriber.status === 'CANCELED') {
      if (subscriber.cancelationReason) {
        return (
          <S.CanceledBlock>
            <S.CanceledTitle>Motivo de cancelamento</S.CanceledTitle>
            <p>{subscriber.cancelationReason}</p>
          </S.CanceledBlock>
        );
      }
      return (
        <S.CanceledBlock>
          <S.CanceledTitle>Não foi informado</S.CanceledTitle>
          <p>{subscriber.cancelationReason}</p>
        </S.CanceledBlock>
      );
    }
  };

  return (
    <S.TableWrapper>
      <S.Column className="userProfileInfo">
        <S.UserAvatar>
          <FaUserGraduate />
        </S.UserAvatar>
        <S.UserName>{subscriber.userName}</S.UserName>
        <S.UserEmail>{subscriber.userEmail}</S.UserEmail>
        {getLocation()}
        <S.UserCard title="CPF">
          <FaIdCard />
          {subscriber.cpf}
        </S.UserCard>
        <S.UserCard title="Telefone">
          <FaPhone />
          {subscriber.userData?.phone}
        </S.UserCard>
        <S.UserCreatedAt title="Data de criação">
          <FaUserPlus />
          {dateFormat(subscriber.createdAt)}
        </S.UserCreatedAt>
        <S.UserUpdatedAt title="Última atualização">
          <FaUserEdit />
          {dateFormat(subscriber.updatedAt)}
        </S.UserUpdatedAt>
        {data.inPromotion && (
          <S.Item title="Promoção ativa">
            <FaStar />
            Em promoção
          </S.Item>
        )}
        <S.Item>
          <FaDollarSign title="Status da assinatura" />
          {subscriptionStatusBody(data)}
        </S.Item>
      </S.Column>

      <S.Column className="rightColumn alignCenter">
        <S.Row>
          {data.expiresTrialAt && (
            <S.CardItem>
              <h3>Fim do período de teste</h3>
              {data.expiresTrialAt && dateFormat(data.expiresTrialAt)}
            </S.CardItem>
          )}
          {data.promotion && (
            <S.CardItem>
              <h3>Promoção</h3>
              {data.promotion.id}
            </S.CardItem>
          )}
          {data.teachingInstitutionId && (
            <S.CardItem>
              <h3>Instituição de ensino</h3>
              <>{getSubscriberInstitutionInfo()}</>
            </S.CardItem>
          )}
        </S.Row>

        <S.Column className="alignCenter">
          <S.PaymentsTitle>Histórico de pagamentos</S.PaymentsTitle>
          <S.SidebarTable>
            <tr>
              <th>Valor</th>
              <th>Status</th>
              <th>Data de pagamento</th>
            </tr>
            {subscriber.payments?.map((payment) => (
              <tr key={generateNewUID()}>
                <td>{payment.total}</td>
                <td>{payment.status}</td>
                <td>{dateFormat(payment.dueDate)}</td>
              </tr>
            ))}
          </S.SidebarTable>
        </S.Column>

        {getCancelationReason()}
      </S.Column>
    </S.TableWrapper>
  );
};

export { SubscriberProfileTable };
