import { action } from 'typesafe-actions';
import Plan from 'models/Plan';
import Promotion from 'models/Promotion';
import { PlanTypes } from './types';

export const fetchPlanRequest = () => action(PlanTypes.FETCH_PLAN_REQUEST);

export const fetchPlanSuccess = (plans: Plan[]) =>
  action(PlanTypes.FETCH_PLAN_SUCCESS, { plans });

export const fetchPlanError = () => action(PlanTypes.FETCH_PLAN_ERROR);

export const createPlanRequest = (plan: Plan) =>
  action(PlanTypes.CREATE_PLAN_REQUEST, { plan });

export const createPlanSuccess = () => action(PlanTypes.CREATE_PLAN_SUCCESS);

export const createPlanError = () => action(PlanTypes.CREATE_PLAN_ERROR);

export const updatePlanRequest = (plan: Plan) =>
  action(PlanTypes.UPDATE_PLAN_REQUEST, { plan });

export const updatePlanSuccess = () => action(PlanTypes.UPDATE_PLAN_SUCCESS);

export const updatePlanError = () => action(PlanTypes.UPDATE_PLAN_ERROR);

export const deletePlanRequest = (plan: Plan) =>
  action(PlanTypes.DELETE_PLAN_REQUEST, { plan });

export const deletePlanSuccess = () => action(PlanTypes.DELETE_PLAN_SUCCESS);

export const deletePlanError = () => action(PlanTypes.DELETE_PLAN_ERROR);
