import { Reducer } from 'redux';
import AlertDialog from 'models/AlertDialog';
import { DialogTypes, DialogState } from './types';

export type Action = {
  type: DialogTypes;
  payload: {
    data: AlertDialog;
  };
};

const INITIAL_STATE: DialogState = {
  data: {
    visible: false,
    message: '',
    title: '',
    mode: 'OK',
    onCancelPress: () => false,
    onConfirmPress: () => false,
  },
};

const reducer: Reducer<DialogState, Action> = (
  state: DialogState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case DialogTypes.DIALOG_SHOW: {
    return {
      data: action.payload.data,
    };
  }

  case DialogTypes.DIALOG_HIDE: {
    return {
      ...state,
      data: {
        ...state.data,
        visible: false,
      },
    };
  }

  default: { return state; }
  }
};

export default reducer;
