import { createGlobalStyle } from 'styled-components';
import { normalize, reset } from './reset';

const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${reset};

  html {
    height: 100%;
    display: table;
  }

  html, body {  
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  body {
    font-family: 'Noto Sans', sans-serif;
    display: table-cell;
    -webkit-font-smoothing: antialiased !important;
    color: ${({ theme }) => theme.text.primary};
    background: ${({ theme }) => theme.background};
    transition: all 0.1s linear;
  }

  body, #root {
    height: 100%;
    min-height: 100%;
  }
  
  .p-component {
    &:not(.p-button) {
      font-family: 'Noto Sans', sans-serif!important;
      font-size: 14px!important;
    }
  }
  
  .p-component-overlay {
    background-color: rgba(0,0,0, 0.4);
  }

  .p-datepicker-month, {
  .p-datepicker-year {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text.primary};
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    padding: 4px 8px;
  }
  
  .p-datepicker-month {
    display: initial!important;
    min-width: 100px!important;
    border-color: ${({ theme }) => theme.table.border};
    padding: 3px 0!important;
  }
  
  .p-dialog {
    .p-dialog-header {
      background-color: ${({ theme }) => theme.background}!important;
    }

    .p-dialog-content {
      background-color: ${({ theme }) => theme.background}!important;
    }

    .p-dialog-footer {
      background-color: ${({ theme }) => theme.background}!important;
    }
  }

  .p-drawer {
    min-width: 500px !important;
    height: 100%;
    background-color: ${({ theme }) => theme.background}!important;
    color: ${({ theme }) => theme.text.primary};
    /* border-radius: 10px 0px 0px 10px; */
    transition-duration: 0.3s;
    padding: 12px 16px;

    .p-sidebar-header {
      display: none;
    }
  }
`;

export default GlobalStyle;
