import { Reducer } from 'redux';
import { LoadingTypes, LoadingState, LoadingAction } from './types';
import { LOADING_INITIAL_STATE } from './utils';

const reducer: Reducer<LoadingState, LoadingAction> = (
  state: LoadingState = LOADING_INITIAL_STATE,
  action: LoadingAction
) => {
  switch (action.type) {
    case LoadingTypes.ADD_LOADING_MODULE: {
      return {
        ...state,
        modules: [...state.modules, action.payload.latestModule]
      };
    }

    case LoadingTypes.REMOVE_LOADING_MODULE: {
      return {
        ...state,
        modules: state.modules.filter(
          (item) => item !== action.payload.latestModule
        )
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
