import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { InputSwitch } from 'primereact/inputswitch';
import { SwitchProps } from './interfaces/SwitchProps';
import * as S from './Switch.styles';

const Switch = (props: SwitchProps) => {
  const {
    onCheck,
    id,
    leftSideLabel,
    rightSideLabel,
    checked,
    switchLabelColor
  } = props;

  const [leftLabelCheck, setLeftLabelCheck] = useState(false);
  const [rightLabelCheck, setRightLabelCheck] = useState(false);

  useEffect(() => {
    if (switchLabelColor) {
      if (rightSideLabel) {
        setLeftLabelCheck(!checked);
      } else {
        setLeftLabelCheck(checked);
      }
    } else {
      setLeftLabelCheck(true);
    }
  }, [rightSideLabel, checked]);

  useEffect(() => {
    if (switchLabelColor) {
      setRightLabelCheck(checked);
    } else {
      setRightLabelCheck(true);
    }
  }, [checked]);

  return (
    <S.SwitchContainer
      as="label"
      htmlFor={id}
      className={classNames('input-switch__container')}
      $hasLeftLabel={!!leftSideLabel}
      $hasRightLabel={!!rightSideLabel}
    >
      {!!leftSideLabel && (
        <S.SwitchLabel
          className={classNames('input-switch__label')}
          $checked={leftLabelCheck}
        >
          {leftSideLabel}
        </S.SwitchLabel>
      )}

      <InputSwitch
        className={classNames('input-switch__component')}
        ariaLabelledBy={id}
        onChange={(e) => onCheck(e.value)}
        checked={checked}
      />

      {!!rightSideLabel && (
        <S.SwitchLabel
          className={classNames('input-switch__label')}
          $checked={rightLabelCheck}
        >
          {rightSideLabel}
        </S.SwitchLabel>
      )}
    </S.SwitchContainer>
  );
};

Switch.defaultProps = {
  switchLabelColor: true
};

export { Switch };
