import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Contact } from 'models/Institutional';
import { DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './ContactDataTable.styles';

interface ContactDataTableProps {
  data: Contact[];
  onPressItem: (contact: Contact) => void;
}

const ContactDataTable = (props: ContactDataTableProps) => {
  const { data, onPressItem } = props;

  const messageBody = (contact: Contact) => {
    if (contact.message.length > 80) {
      return `${contact.message.substring(0, 80)}...`;
    }
    return contact.message;
  };

  const createdAtBody = (date: any) => (
    <span>{moment(date.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  return (
    <DataTableLayout>
      <DataTable
        value={data}
        onRowDoubleClick={(e) => onPressItem(e.data)}
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        filterDisplay="row"
      >
        <Column field="id" header="ID" style={{ width: '60px' }} sortable />
        <Column
          field="name"
          header="Nome"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="phone"
          header="Telefone"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="message"
          header="Mensagem"
          style={{ width: '320px' }}
          body={messageBody}
          sortable
        />
        <Column
          field="createdAt"
          header="Criado em"
          sortable
          body={createdAtBody}
          style={{ width: '150px' }}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { ContactDataTable };
