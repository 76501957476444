import { action } from 'typesafe-actions';
import LoginResponse, { LoginInfo } from 'models/Login';
import { LoginTypes } from './types';

export const loginRequest = (info: LoginInfo, navigate: any) =>
  action(LoginTypes.LOGIN_REQUEST, { info, navigate });

export const loginSuccess = (data: LoginResponse) =>
  action(LoginTypes.LOGIN_SUCCESS, { data });

export const loginError = () => action(LoginTypes.LOGIN_ERROR);

export const logoutRequest = () => action(LoginTypes.LOGOUT_REQUEST);

export const logoutSuccess = () => action(LoginTypes.LOGOUT_SUCCESS);

export const logoutError = () => action(LoginTypes.LOGOUT_ERROR);
