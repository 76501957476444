import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchAboutUsRequest,
  fetchAboutUsSuccess,
  aboutUsRequestError,
  aboutUsRequestSuccess
} from './actions';
import { InstitutionalAboutUsAction } from './types';

export function* fetchAboutUs() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALABOUTUS));

    const { data }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/about-us'
    );

    yield put(fetchAboutUsSuccess(data));
  } catch (err: any) {
    yield put(aboutUsRequestError('Falha ao carregar dados'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALABOUTUS));
  }
}

export function* updateAboutUs(action: InstitutionalAboutUsAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALABOUTUS));

    const { aboutUs } = action.payload;

    yield call(Connector().put, '/landing-page/about-us', aboutUs);

    yield put(aboutUsRequestSuccess('Dados salvos com sucesso'));
    yield put(fetchAboutUsRequest());
  } catch (err: any) {
    yield put(aboutUsRequestError('Falha ao salvar dados'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALABOUTUS));
  }
}

export function* showSuccessMessage(action: InstitutionalAboutUsAction) {
  const { message } = action.payload;
  yield put(
    showToast({
      summary: 'Institucional - Sobre',
      detail: message,
      severity: 'success'
    })
  );
}

export function* showErrorMessage(action: InstitutionalAboutUsAction) {
  const { message } = action.payload;
  yield put(
    showToast({
      summary: 'Institucional - Sobre',
      detail: message,
      severity: 'error'
    })
  );
}
