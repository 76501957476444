import styled from 'styled-components';

export const ToastWrapper = styled.div`
  .p-toast {
    bottom: 10px;
    right: 30px;
    opacity: 1;

    .p-toast-icon-close {
      color: ${({ theme }) => theme.toast.text}!important;
    }

    .p-toast-message {
      color: ${({ theme }) => theme.toast.text}!important;
      border-radius: 6px;
      border-width: 1 !important;

      .p-toast-message-icon {
        color: ${({ theme }) => theme.toast.text}!important;
      }

      .p-toast-message-content {
        padding: 8px !important;
        font-family: 'Noto Sans';

        .p-toast-summary {
          text-transform: uppercase;
          font-size: 12px;
        }
      }

      &.p-toast-message-success {
        border: solid ${({ theme }) => theme.toast.success}!important;

        .p-toast-message-content {
          background-color: ${({ theme }) => theme.toast.success};
        }
      }

      &.p-toast-message-warn {
        border: solid ${({ theme }) => theme.toast.warning}!important;

        .p-toast-message-content {
          background-color: ${({ theme }) => theme.toast.warning};
        }
      }

      &.p-toast-message-info {
        border: solid ${({ theme }) => theme.toast.info}!important;

        .p-toast-message-content {
          background-color: ${({ theme }) => theme.toast.info};
        }
      }

      &.p-toast-message-error {
        border: solid ${({ theme }) => theme.toast.error}!important;

        .p-toast-message-content {
          background-color: ${({ theme }) => theme.toast.error};
        }
      }
    }
  }
`;
