import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector
} from 'react-redux';
import { Font } from '@react-pdf/renderer';
import Cookies from 'js-cookie';
import { locale, addLocale } from 'primereact/api';
import { ThemeProvider } from 'styled-components';
import { setPermissions } from 'store/ducks/navigator/action';
import { Toast, Dialog, LoadingOverlay } from 'components';
import { Drawer } from 'components/DrawerTest/Drawer';
import { CMS_PERMISSION_TYPE } from 'helpers/constants';
import ptlocale from 'helpers/ptlocale.json';
import ApplicationState from 'helpers/types/ApplicationState';
import GlobalStyle from 'styles/global';
import Router from './router';
import store from './store/index';

addLocale('pt', ptlocale);
locale('pt');

Font.register({
  family: 'NotoSans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/notosans/v26/o-0IIpQlx3QUlC5A4PNb4g.ttf'
    }
  ]
});

const App = () => {
  const { theme, mode } = useSelector((state: ApplicationState) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const cookies = Cookies.get(btoa('AuthAccess'));

    if (cookies) {
      const permissions: CMS_PERMISSION_TYPE[] = JSON.parse(cookies).map(
        (p: string) => atob(p)
      );

      dispatch(setPermissions(permissions));
    }
  }, []);

  useEffect(() => {
    const { head } = document;
    const firstCSS = document.getElementById('firstCSS');

    if (firstCSS) {
      head.removeChild(firstCSS);
    }

    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';

    if (mode === 'DARK') {
      link.href = '/css/darkTheme.css';
    } else {
      link.href = '/css/lightTheme.css';
    }

    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, [mode]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router />
      <Drawer />
      <Dialog />
      <Toast />
      <LoadingOverlay />
    </ThemeProvider>
  );
};

const AppWithReduxProvider = () => (
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);

ReactDOM.render(<AppWithReduxProvider />, document.getElementById('root'));
