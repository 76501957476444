import { css } from 'styled-components';

export const DatePickerBase = css``;
// .p-disabled {
//   color: ${({ theme }) => theme.text.secondary} !important;
// }

// .p-datepicker-title {
//   font-weight: 700;
// }

// div.p-datepicker {
//   top: -120px !important;
//   left: 60px !important;
//   /* width: 100%; */
//   font-family: 'Mulish', 'sans-serif';
// }

// .p-datepicker {
//   padding: 0.857em;
//   min-width: 20em;
//   background-color: ${({ theme }) => theme.background};
//   color: ${({ theme }) => theme.text.primary};
//   border: 1px solid ${({ theme }) => theme.calendar.border};
//   border-radius: 3px;
// }
// .p-datepicker:not(.p-datepicker-inline) {
//   border: 1px solid ${({ theme }) => theme.calendar.border};
//   box-shadow: 0;
// }
// .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
// .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
//   color: ${({ theme }) => theme.btn.primary.bg};
// }
// .p-datepicker:not(.p-disabled)
//   table
//   td
//   span:not(.p-highlight):not(.p-disabled) {
//   transition: border-color 0.2s, box-shadow 0.2s;
// }
// .p-datepicker:not(.p-disabled)
//   table
//   td
//   span:not(.p-highlight):not(.p-disabled):hover {
//   background-color: ${({ theme }) => theme.btn.primary.color};
// }
// .p-datepicker:not(.p-disabled)
//   table
//   td
//   span:not(.p-highlight):not(.p-disabled):focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: 0;
// }
// .p-datepicker:not(.p-disabled)
//   .p-monthpicker
//   .p-monthpicker-month:not(.p-highlight):hover {
//   background-color: ${({ theme }) => theme.btn.primary.color};
// }
// .p-datepicker .p-datepicker-header {
//   padding: 0.429em 0.857em 0.429em 0.857em;
//   background-color: ${({ theme }) => theme.background};
//   color: ${({ theme }) => theme.text.primary};
//   border-radius: 0;
// }
// .p-datepicker .p-datepicker-header .p-datepicker-prev,
// .p-datepicker .p-datepicker-header .p-datepicker-next {
//   cursor: pointer;
//   top: 0;
//   color: ${({ theme }) => theme.table.border};
//   transition: color 0.2s, box-shadow 0.2s;
// }
// .p-datepicker .p-datepicker-header .p-datepicker-title {
//   margin: -6px 0 0 0;
//   padding: 0;
//   line-height: 1;
// }
// .p-datepicker .p-datepicker-header .p-datepicker-title select {
//   /* margin-top: -0.35em; */
//   margin-bottom: 0;
//   transition: border-color 0.2s, box-shadow 0.2s;
// }
// .p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: 0;
//   border-color: ${({ theme }) => theme.btn.primary.bg};
// }
// .p-datepicker table {
//   font-size: 14px;
//   margin: 0.857em 0 0 0;
// }
// .p-datepicker table th {
//   padding: 0.5em;
// }
// .p-datepicker table th.p-datepicker-weekheader {
//   border-right: 1px solid ${({ theme }) => theme.table.border};
// }
// .p-datepicker table td {
//   padding: 0.5em;
// }
// .p-datepicker table td > span {
//   display: block;
//   text-align: center;
//   color: ${({ theme }) => theme.text.primary};
//   cursor: pointer;
//   padding: 0.5em;
//   border-radius: 3px;
// }
// .p-datepicker table td > span.p-highlight {
//   color: ${({ theme }) => theme.background};
//   background-color: ${({ theme }) => theme.btn.primary.bg};
// }
// .p-datepicker table td.p-datepicker-today > span {
//   transition: background-color 0.2s;
//   background-color: ${({ theme }) => theme.cards.percent};
//   color: ${({ theme }) => theme.text.primary};
// }
// .p-datepicker table td.p-datepicker-today > span.p-highlight {
//   color: ${({ theme }) => theme.btn.primary.color};
//   background-color: ${({ theme }) => theme.btn.primary.bg};
// }
// .p-datepicker table td.p-datepicker-weeknumber {
//   border-right: 1px solid ${({ theme }) => theme.table.border};
// }
// .p-datepicker .p-datepicker-buttonbar {
//   border-top: 1px solid ${({ theme }) => theme.table.border};
// }
// .p-datepicker .p-timepicker {
//   border: 0 none;
//   border-top: 1px solid ${({ theme }) => theme.table.border};
//   padding: 0.857em;
// }
// .p-datepicker .p-timepicker button {
//   color: ${({ theme }) => theme.table.border};
//   font-size: 1.286em;
//   transition: color 0.2s, box-shadow 0.2s;
// }
// .p-datepicker .p-timepicker button:hover {
//   color: ${({ theme }) => theme.btn.primary.bg};
// }
// .p-datepicker .p-timepicker span {
//   font-size: 1.286em;
// }
// .p-datepicker .p-monthpicker .p-monthpicker-month {
//   color: ${({ theme }) => theme.text.primary};
// }
// .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
//   color: ${({ theme }) => theme.background};
//   background-color: ${({ theme }) => theme.btn.primary.bg};
// }
// .p-datepicker.p-datepicker-timeonly {
//   padding: 0;
// }
// .p-datepicker.p-datepicker-timeonly .p-timepicker {
//   border-top: 0 none;
// }
// .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
//   border-right: 1px solid ${({ theme }) => theme.table.border};
//   padding-right: 0.857em;
//   padding-left: 0.857em;
//   padding-top: 0;
//   padding-bottom: 0;
// }
// .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
//   padding-left: 0;
// }
// .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
//   padding-right: 0;
//   border-right: 0 none;
// }

// .p-datepicker {
//   background-color: ${({ theme }) => theme.background};
//   border: 1px solid ${({ theme }) => theme.shadow};
//   border-radius: 6px;
// }
