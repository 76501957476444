import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tree } from 'primereact/tree';
import TreeNode from 'primereact/treenode/treenode';
import Playlist from 'models/Playlist';
import { showDialog } from 'store/ducks/dialog/actions';
import { fetchViewFolderRequest } from 'store/ducks/folder/actions';
import { addAudioToPlaylistRequest } from 'store/ducks/playlist/actions';
import { Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import DataBuilder from './PlaylistActionSidebarImport.builder';
import * as S from './PlaylistActionSidebarImport.styles';

interface PlaylistActionSidebarImportProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  playlist: Playlist;
  onCancelPress?: () => void;
}

const PlaylistActionSidebarImport = (
  props: PlaylistActionSidebarImportProps
) => {
  const { mode, setMode } = props;
  const [playlist, setPlaylist] = useState<Playlist>(props.playlist);
  const [selectionKeys, setSelectionKeys] = useState<any>({});
  const [importAudioIds, setImportAudioIds] = useState<any[]>([]);

  const [builded, setBuilded] = useState<TreeNode[] | undefined>([]);
  const folderTree = useSelector(
    (state: ApplicationState) => state.playlist.folderTree
  );
  const folderTreeLoading = useSelector(
    (state: ApplicationState) => state.playlist.folderTreeLoading
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchViewFolderRequest(1));
    return () => {
      setBuilded([]);
    };
  }, [dispatch]);

  useEffect(() => {
    let nodeMaster: any = null;

    nodeMaster = {
      children: []
    };
    setBuilded([]);

    if (folderTree.length > 0) {
      setBuilded(DataBuilder(folderTree, nodeMaster).children);
    }
  }, [folderTree]);

  useEffect(() => {
    const importAudioIdsTmp = Object.keys(selectionKeys)
      .map((key) => JSON.parse(key))
      .filter((key) => key.type === 'audio')
      .map((key) => ({
        id: key.id
      }));

    setImportAudioIds(importAudioIdsTmp);
  }, [selectionKeys]);

  useEffect(() => {
    setPlaylist(props.playlist);
  }, [props.playlist]);

  const handleConfirmPress = () => {
    dispatch(addAudioToPlaylistRequest({ playlist, audios: importAudioIds }));

    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (props.onCancelPress) {
      props.onCancelPress();
    }

    setMode('CLOSE');
  };

  const handleImportPress = () => {
    dispatch(
      showDialog({
        title: 'Importar áudios!',
        message: `Deseja realmente importar ${importAudioIds.length} audios para a playlist ${playlist.name}?`,
        mode: 'CONFIRM',
        onConfirmPress: handleConfirmPress,
        visible: true
      })
    );
  };

  return (
    <S.DrawerWrapper
      title="Selecionar Audios"
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <Tree
        value={builded}
        filter
        filterBy="data.name,data.title"
        filterPlaceholder="Buscar"
        loading={folderTreeLoading}
        selectionMode="checkbox"
        selectionKeys={selectionKeys}
        onSelectionChange={(e) => setSelectionKeys(e.value)}
      />

      <Button
        onClick={handleImportPress}
        type="button"
        disabled={importAudioIds.length < 1}
      >
        IMPORTAR
      </Button>
    </S.DrawerWrapper>
  );
};

PlaylistActionSidebarImport.defaultProps = {
  onCancelPress: () => false
};

export { PlaylistActionSidebarImport };
