import { Reducer } from 'redux';
import SuperCategory from 'models/SuperCategory';
import { SuperCategoryState, SuperCategoryTypes } from './types';

export type Action = {
  type: SuperCategoryTypes;
  payload: {
    data: SuperCategory[];
  };
};

const INITIAL_STATE: SuperCategoryState = {
  data: [],
};

const reducer: Reducer<SuperCategoryState, Action> = (
  state: SuperCategoryState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case SuperCategoryTypes.FETCH_SUPER_CATEGORY_SUCCESS: {
    return {
      ...state,
      data: action.payload.data,
    };
  }

  default: {
    return state;
  }
  }
};

export default reducer;
