import { action } from 'typesafe-actions';
import { Faq } from 'models/Institutional';
import { InstitutionalFaqTypes } from './types';

export const fetchFaqListRequest = () =>
  action(InstitutionalFaqTypes.FETCH_FAQ_LIST_REQUEST);

export const fetchFaqListSuccess = (list: Faq[]) =>
  action(InstitutionalFaqTypes.FETCH_FAQ_LIST_SUCCESS, { list });

export const createFaqQuestionRequest = (question: Faq) =>
  action(InstitutionalFaqTypes.CREATE_FAQ_QUESTION_REQUEST, { question });

export const updateFaqQuestionRequest = (question: Faq) =>
  action(InstitutionalFaqTypes.UPDATE_FAQ_QUESTION_REQUEST, { question });

export const deleteFaqQuestionRequest = (question: Faq) =>
  action(InstitutionalFaqTypes.DELETE_FAQ_QUESTION_REQUEST, { question });

export const faqRequestSuccess = (message: string) =>
  action(InstitutionalFaqTypes.FAQ_REQUEST_SUCCESS, { message });

export const faqRequestError = (message: string) =>
  action(InstitutionalFaqTypes.FAQ_REQUEST_ERROR, { message });
