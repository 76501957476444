import { action } from 'typesafe-actions';
import { NewsArticle } from 'models/Institutional';
import { InstitutionalNewsTypes } from './types';

export const fetchNewsRequest = () =>
  action(InstitutionalNewsTypes.FETCH_NEWS_REQUEST);

export const fetchNewsSuccess = (list: NewsArticle[]) =>
  action(InstitutionalNewsTypes.FETCH_NEWS_SUCCESS, { list });

export const createArticleRequest = (article: NewsArticle) =>
  action(InstitutionalNewsTypes.CREATE_NEWS_REQUEST, { article });

export const updateArticleRequest = (article: NewsArticle) =>
  action(InstitutionalNewsTypes.UPDATE_NEWS_REQUEST, { article });

export const deleteArticleRequest = (article: NewsArticle) =>
  action(InstitutionalNewsTypes.DELETE_NEWS_REQUEST, { article });

export const newsRequestSuccess = (message: string) =>
  action(InstitutionalNewsTypes.NEWS_REQUEST_SUCCESS, { message });

export const newsRequestError = (message: string) =>
  action(InstitutionalNewsTypes.NEWS_REQUEST_ERROR, { message });
