import { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { ThemeContext } from 'styled-components';
import { canceledAndActiveUsersAfterTestRequest } from 'store/ducks/analytics/actions';
import { Card, DateInterval, LoadingIndicator, MiniCard } from 'components';
import dateUtils from 'helpers/date';
import ApplicationState from 'helpers/types/ApplicationState';
import { CustomChartOptions } from 'helpers/types/ChartOptionsType';
import { ChartConfig } from 'helpers/types/ChartTypes';
import * as S from './CanceledAndActiveUsersAfter.styles';

const {
  convertDateToString,
  getInitialEndDate,
  getInitialStartDate,
  getMonthRangeLabels
} = dateUtils;

const CanceledAndActiveUsersAfter = () => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { canceledAndActiveUsersAfterTest, loading } = useSelector(
    (state: ApplicationState) => state.analytics
  );

  const isModuleLoading = () =>
    loading.includes('CANCELED_AND_ACTIVE_USERS_AFTER_TEST');

  const [startsIn, setStartsIn] = useState(
    convertDateToString(getInitialStartDate())
  );

  const [endsIn, setEndsIn] = useState(
    convertDateToString(getInitialEndDate())
  );

  useEffect(() => {
    dispatch(canceledAndActiveUsersAfterTestRequest(startsIn, endsIn));
  }, [dispatch, startsIn, endsIn]);

  const chartConfig: ChartConfig = useMemo(() => {
    const canceledData: number[] = [];
    const activeData: number[] = [];

    canceledAndActiveUsersAfterTest.forEach((item) => {
      if (item.data.length) {
        item.data.forEach((it) => {
          if (it.subscriptionStatus === 'CANCELED') {
            canceledData.push(it.numbersubscriptions);
          } else {
            activeData.push(it.numbersubscriptions);
          }
        });
      } else {
        canceledData.push(0);
        activeData.push(0);
      }
    });

    return {
      labels: getMonthRangeLabels(startsIn, endsIn),
      datasets: [
        {
          label: 'Cancelados',
          data: canceledData,
          backgroundColor: themeContext.chart.bg.red1
        },
        {
          label: 'Ativos',
          data: activeData,
          backgroundColor: themeContext.chart.bg.green1
        }
      ]
    };
  }, [canceledAndActiveUsersAfterTest, themeContext]);

  const chartOptions = useMemo<CustomChartOptions>(
    () => ({
      scales: {
        x: {
          ticks: {
            color: themeContext.text.primary
          },
          grid: {
            color: themeContext.chart.grid
          }
        },
        y: {
          min: 0,
          ticks: {
            stepSize: 5,
            color: themeContext.text.primary
          },
          grid: {
            color: themeContext.chart.grid
          },
          title: {
            display: true,
            text: 'Usuários',
            color: themeContext.text.secondary,
            font: {
              family: 'Mulish , sans-serif',
              weight: 'bold',
              size: 20
            },
            padding: {
              bottom: 24
            }
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            color: themeContext.text.primary
          }
        }
      }
    }),
    [themeContext]
  );

  const chartMemo = useMemo(
    () => (
      <Chart
        type="bar"
        data={chartConfig}
        className="chart"
        options={chartOptions}
      />
    ),
    [chartConfig, chartOptions]
  );

  return (
    <Card title="Usuários ativos e cancelados após o teste">
      <LoadingIndicator isLoading={isModuleLoading()} position="absolute" />

      <S.Container>
        <DateInterval
          startsIn={startsIn}
          onStartsInChange={setStartsIn}
          endsIn={endsIn}
          onEndsInChange={setEndsIn}
          disable={isModuleLoading()}
        />

        {chartMemo}
      </S.Container>
    </Card>
  );
};

export { CanceledAndActiveUsersAfter };
