import { useState, useEffect, useCallback } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation, Location } from 'react-router-dom';
import classNames from 'classnames';
import { Console } from 'console';
import { PRIVATE_ROUTES } from 'helpers/constants';
import * as S from './Breadcrumbs.styles';
import { pathIsModule } from './Breadcrumbs.utils';
import { BreadcrumbsPath } from './interfaces/BreadcrumbsPath';
import { BreadcrumbsProps } from './interfaces/BreadcrumbsProps';

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { label, divider, additionalCrumbs } = props;
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<BreadcrumbsPath[]>([]);

  const getCrumbs = (newLocation: Location) => {
    const paths: BreadcrumbsPath[] = [];
    let index = 0;
    let helper = 1;
    let url = '';

    if (newLocation.pathname === '/') {
      return [
        {
          url: PRIVATE_ROUTES.HOME,
          name: 'home'
        },
        {
          name: 'Analytics'
        },
        {
          url: PRIVATE_ROUTES.ANALYTICS_DASHBOARD,
          name: 'Dashboard'
        }
      ];
    }

    const pathCrumbs = newLocation.pathname.split('/');

    pathCrumbs.forEach((location) => {
      if (index > helper) {
        url += `/${paths[helper].name}`;
        helper = index;
      }

      const name = location === '' ? 'home' : location;

      paths.push({
        url: pathIsModule(location) ? `${url}/${location}` : undefined,
        name
      });

      index += 1;
    });

    if (additionalCrumbs) {
      const { pageUrl, crumbs: addCrumbs } = additionalCrumbs;

      addCrumbs.forEach((crumb) => {
        paths.push({
          url: pageUrl,
          name: crumb
        });
      });
    }

    return paths;
  };

  const handleCrumbs = useCallback(getCrumbs, [additionalCrumbs]);

  useEffect(() => {
    setCrumbs(handleCrumbs(location));
  }, [location, handleCrumbs, additionalCrumbs]);

  return (
    <S.Container>
      <span className="crumbLabel">{label}</span>

      {crumbs.map((path, key) => (
        <S.Item key={`${key.toString()}-${path.name}`}>
          {path.url ? (
            <S.CrumbLink className="crumbLink" to={path.url} $isModule>
              {path.name}
            </S.CrumbLink>
          ) : (
            <S.CrumbLink className="crumbLink" to="" as="span">
              {path.name}
            </S.CrumbLink>
          )}
          {crumbs.length === key + 1 || (
            <S.Divider
              className={classNames({
                isIcon: typeof divider !== 'string'
              })}
            >
              {divider}
            </S.Divider>
          )}
        </S.Item>
      ))}
    </S.Container>
  );
};

Breadcrumbs.defaultProps = {
  divider: <FaCaretRight />,
  label: 'Você está aqui:',
  additionalCrumbs: undefined
};

export { Breadcrumbs };
