import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Faq } from 'models/Institutional';
import { showDialog } from 'store/ducks/dialog/actions';
import { deleteFaqQuestionRequest } from 'store/ducks/institutionalFaq/actions';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './FaqDataTable.styles';

interface FaqDataTableProps {
  data: Faq[];
  onPressItem: (question: Faq) => void;
}

const FaqDataTable = (props: FaqDataTableProps) => {
  const { data, onPressItem } = props;

  const dispatch = useDispatch();

  const handleRemovePressed = (selectedQuestion: Faq) => {
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover Questão',
        message: `Tem certeza que deseja remover a questão "${selectedQuestion.title}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () =>
          dispatch(deleteFaqQuestionRequest(selectedQuestion))
      })
    );
  };

  const actions = (selectedQuestion: Faq) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(selectedQuestion)}
        severity="confirm"
        icon="pi pi-pencil"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleRemovePressed(selectedQuestion)}
        severity="danger"
        icon="pi pi-trash"
      >
        <FaTrash />
      </Button>
    </S.ActionButtons>
  );

  const descriptionBody = (question: Faq) => {
    if (question.description.length > 80) {
      return `${question.description.substring(0, 80)}...`;
    }
    return question.description;
  };

  return (
    <DataTableLayout>
      <DataTable
        sortField="id"
        sortOrder={1}
        value={data}
        onRowDoubleClick={(e) => onPressItem(e.data)}
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        filterDisplay="row"
      >
        <Column field="id" header="ID" style={{ width: '60px' }} sortable />
        <Column
          field="title"
          header="Questão"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="description"
          header="Resposta"
          body={descriptionBody}
          sortable
        />
        <Column header="Ações" style={{ width: '100px' }} body={actions} />
      </DataTable>
    </DataTableLayout>
  );
};

export { FaqDataTable };
