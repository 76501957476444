import { PageHeader } from 'components';
import { UsagePerHours, PlaylistUsage } from './components';
import * as S from './UsagePage.styles';

const UsagePage = () => (
  <S.ContainerFluid>
    <S.Row className="height">
      <S.Container>
        <PageHeader title="Dados de uso" />

        <S.ChartContainer>
          <S.Row>
            <UsagePerHours />
          </S.Row>

          <S.Row>
            <PlaylistUsage />
          </S.Row>
        </S.ChartContainer>
      </S.Container>
    </S.Row>
  </S.ContainerFluid>
);

export { UsagePage };
