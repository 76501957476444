import { Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { documentStyles } from './FinancialReportPdfDocument.styles';
import { FinancialReportPdfDocumentProps } from './interfaces/FinancialReportPdfDocumentProps';

const statusOptions = [
  { id: 'ENABLED', value: 'Ativa' },
  { id: 'CANCELED', value: 'Cancelada' },
  { id: 'IN_TEST', value: 'Em teste' },
  { id: 'OVERDUE', value: 'Pagamento atrasado' }
];

const FinancialReportPdfDocument = (props: FinancialReportPdfDocumentProps) => {
  const { data } = props;

  const handleStatus = (
    status: 'ENABLED' | 'CANCELED' | 'IN_TEST' | 'OVERDUE'
  ) => statusOptions.find((item) => item.id === status)!;

  const handlePlanValue = (value: number) => {
    if (value) {
      return `R$ ${value.toFixed(2).replace('.', ',')}`;
    }

    return 'R$ 0,00';
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={documentStyles.page}>
        <View style={documentStyles.header}>
          <Text style={documentStyles.headerTitle}>Balanço Financeiro</Text>
        </View>

        <View style={documentStyles.body}>
          <View style={documentStyles.column}>
            <View style={documentStyles.row}>
              <Text style={documentStyles.title}>Usuário</Text>
            </View>

            <View style={documentStyles.column}>
              {data.map(({ user }, index) => (
                <View key={index.toString()} style={documentStyles.row}>
                  <Text>{user}</Text>
                </View>
              ))}
            </View>
          </View>

          <View style={documentStyles.column}>
            <View style={documentStyles.row}>
              <Text style={documentStyles.title}>Data de criação</Text>
            </View>

            <View style={documentStyles.column}>
              {data.map(({ createdAt }, index) => (
                <View key={index.toString()} style={documentStyles.row}>
                  <Text>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</Text>
                </View>
              ))}
            </View>
          </View>

          <View style={documentStyles.column}>
            <View style={documentStyles.row}>
              <Text style={documentStyles.title}>Plano</Text>
            </View>

            {data.map(({ plan }, index) => (
              <View key={index.toString()} style={documentStyles.row}>
                <Text>{plan}</Text>
              </View>
            ))}
          </View>

          <View style={documentStyles.column}>
            <View style={documentStyles.row}>
              <Text style={documentStyles.title}>Email</Text>
            </View>

            {data.map(({ email }, index) => (
              <View key={index.toString()} style={documentStyles.row}>
                <Text>{email}</Text>
              </View>
            ))}
          </View>

          <View style={documentStyles.column}>
            <View style={documentStyles.row}>
              <Text style={documentStyles.title}>Valor do plano</Text>
            </View>

            {data.map(({ planValue: value }, index) => (
              <View key={index.toString()} style={documentStyles.row}>
                <Text>{handlePlanValue(value)}</Text>
              </View>
            ))}
          </View>

          <View style={documentStyles.column}>
            <View style={documentStyles.row}>
              <Text style={documentStyles.title}>Status da assinatura</Text>
            </View>

            {data.map(({ subscriptionStatus: status }, index) => (
              <View key={index.toString()} style={documentStyles.row}>
                <Text>{handleStatus(status).value}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export { FinancialReportPdfDocument };
