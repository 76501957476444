import styled from 'styled-components';

export const ActivatableInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  > div {
    width: 100%;
    margin-left: 24px;
  }
`;
