import { Contact } from 'models/Institutional';
import { Drawer } from 'components';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './ContactActionSidebar.styles';

interface ContactActionSidebarProps {
  mode: DrawerMode;
  contact: Contact;
  onCancelPress: () => void;
}

const ContactActionSidebar = (props: ContactActionSidebarProps) => {
  const { mode, contact, onCancelPress } = props;

  return (
    <Drawer title="Contato" drawerMode={mode} onBackDropPress={onCancelPress}>
      <S.SidebarTable className="table_general">
        <tbody>
          <tr>
            <th>Usuário</th>
            <td>{contact.name}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{contact.email}</td>
          </tr>
          <tr>
            <th>Telefone</th>
            <td>{contact.phone}</td>
          </tr>
          <tr>
            <th>Mensagem</th>
            <td>{contact.message}</td>
          </tr>
        </tbody>
      </S.SidebarTable>
    </Drawer>
  );
};

export { ContactActionSidebar };
