import { DrawerState } from './types';

const DRAWER_INITIAL_STATE: DrawerState = {
  children: null,
  isOpen: false,
  className: undefined,
  onHide: () => {},
  title: ''
};

export { DRAWER_INITIAL_STATE };
