import { useCallback } from 'react';
import * as S from './InputFile.styles';
import InputFileProps from './interfaces/InputFileProps';

const InputFile = (props: InputFileProps) => {
  const { label, name, onFileChange } = props;

  const getLabel = useCallback(() => {
    if (label) {
      return label;
    }

    return 'Selecione um arquivo';
  }, [label]);

  return (
    <>
      <S.Label htmlFor={name}>{getLabel()}</S.Label>
      <S.InputFile type="file" id={name} name={name} onChange={onFileChange} />
    </>
  );
};
export { InputFile };
