import { Terms } from 'models/Institutional';

export enum InstitutionalTermsTypes {
  FETCH_TERMS_REQUEST = '@institutionalTerms/FETCH_TERMS_REQUEST',
  FETCH_TERMS_SUCCESS = '@institutionalTerms/FETCH_TERMS_SUCCESS',
  FETCH_TERMS_ERROR = '@institutionalTerms/FETCH_TERMS_ERROR',

  EDIT_TERMS_REQUEST = '@institutionalTerms/EDIT_TERMS_REQUEST',
  EDIT_TERMS_SUCCESS = '@institutionalTerms/EDIT_TERMS_SUCCESS',
  EDIT_TERMS_ERROR = '@institutionalTerms/EDIT_TERMS_ERROR',
}

export interface InstitutionalTermsState {
  readonly terms: Terms;
}

export type InstitutionalTermsAction = {
  type: InstitutionalTermsTypes;
  payload: InstitutionalTermsState;
};
