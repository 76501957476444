import { useEffect, useState } from 'react';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Audio from 'models/Audio';
import Folder from 'models/Folder';
import {
  setSelectedAudio,
  removeAudioRequest
} from 'store/ducks/audio/actions';
import { setCurrentFolderId } from 'store/ducks/currentFolder/actions';
import { showDialog } from 'store/ducks/dialog/actions';
import { openDrawer } from 'store/ducks/drawer/actions';
import {
  fetchViewFolderRequest,
  setSelectedFolder,
  removeFolderRequest
} from 'store/ducks/folder/actions';
import { FOLDER_INITIAL_STATE } from 'store/ducks/folder/utils';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import {
  FileManagerDataTable,
  AddAudioSidebar,
  EditAudioSidebar,
  FolderActionSidebar
} from './components';
import * as S from './FileManagerPage.styles';
import * as utils from './FileManagerPage.utils';

const FileManagerPage = () => {
  const dispatch = useDispatch();

  const { viewFolder } = useSelector((state: ApplicationState) => state.folder);

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const { parentId } = useSelector(
    (state: ApplicationState) => state.currentFolder
  );

  useEffect(() => {
    dispatch(setCurrentFolderId(viewFolder.id));
  }, [dispatch, viewFolder]);

  useEffect(() => {
    dispatch(fetchViewFolderRequest(0));
  }, [dispatch]);

  const handleAddAudioPressed = () => {
    dispatch(
      openDrawer(<AddAudioSidebar />, {
        title: 'Adicionar novos áudios'
      })
    );
  };

  const handleAddFolderPressed = () => {
    dispatch(setSelectedFolder(FOLDER_INITIAL_STATE.folder));
    dispatch(
      openDrawer(<FolderActionSidebar />, {
        title: 'Adicionar pasta'
      })
    );
  };

  const handleSelectedItemsRemoveClick = () => {
    const getTitle =
      selectedItems.length > 1
        ? 'Remover itens selecionados'
        : 'Remover item selecionado';
    const getMessage = `Tem certeza que deseja remover ${
      selectedItems.length > 1
        ? `estes ${selectedItems.length} itens`
        : 'este item'
    }?`;

    const onAudioRemove = (audio: Audio) =>
      dispatch(removeAudioRequest({ ...audio, parentId }));
    const onFolderRemove = (folder: Folder) =>
      dispatch(removeFolderRequest({ ...folder, parentId }));

    dispatch(
      showDialog({
        title: getTitle,
        message: getMessage,
        visible: true,
        mode: 'CONFIRM',
        onConfirmPress: () =>
          utils.onRemoveMultipleItems(
            selectedItems,
            onAudioRemove,
            onFolderRemove
          )
      })
    );

    setSelectedItems([]);
  };

  const folderNavigator = (folder: Folder, back = false) => {
    if (back) {
      if (folder.parentId) {
        dispatch(fetchViewFolderRequest(folder.parentId));
      } else {
        dispatch(fetchViewFolderRequest(0));
      }

      return;
    }
    dispatch(fetchViewFolderRequest(folder.id));
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={() => folderNavigator(viewFolder, true)}
      disabled={parentId === 1}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  const getActionButtons = () => (
    <S.Row>
      <Button
        type="button"
        severity="danger"
        onClick={handleSelectedItemsRemoveClick}
        disabled={selectedItems.length === 0}
      >
        <FaTrash />
        Remover
      </Button>

      <Button
        type="button"
        severity="primary"
        onClick={handleAddAudioPressed}
        disabled={parentId === 1}
      >
        <i className="pi pi-file" />
        Adicionar Audio
      </Button>

      <Button type="button" severity="primary" onClick={handleAddFolderPressed}>
        <i className="pi pi-folder" />
        Adicionar Pasta
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <S.Row>
        <S.Container>
          <PageHeader
            title="Gerenciador de Arquivos"
            breadcrumbsBottomComponent={getBackButton()}
            actionsComponent={getActionButtons()}
          />

          <FileManagerDataTable
            folderNavigator={folderNavigator}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
          />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { FileManagerPage };
