import { Reducer } from 'redux';
import Subscription from 'models/Subscription';
import { SubscriptionState, SubscriptionTypes } from './types';

export type Action = {
  type: SubscriptionTypes;
  payload: {
    subscriptions: Subscription[]
  };
};

const INITIAL_STATE: SubscriptionState = {
  subscriptions: [],
};

const reducer: Reducer<SubscriptionState, Action> = (
  state: SubscriptionState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case SubscriptionTypes.FETCH_SUBSCRIPTION_SUCCESS: {
    return {
      ...state,
      subscriptions: action.payload.subscriptions,
    };
  }

  default: {
    return state;
  }
  }
};

export default reducer;
