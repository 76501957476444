import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { fetchOneQuizQuestionsRequest } from '../quizQuestions/actions';
import { showToast } from '../toast/actions';
import {
  createQuizAlternativeError,
  createQuizAlternativeSuccess,
  deleteQuizAlternativeError,
  deleteQuizAlternativeSuccess,
  updateQuizAlternativeError,
  updateQuizAlternativeSuccess
} from './actions';
import { Action } from './index';

export function* createQuizAlternative(action: Action) {
  try {
    const { one: formData } = action.payload;

    const quizQuestion = { connect: { id: action.payload.one.quizQuestionId } };

    const data = {
      answer: formData.answer,
      isCorrect: formData.isCorrect,
      quizQuestion
    } as any;

    yield call(Connector().post, 'quiz-alternatives', data);

    yield put(
      showToast({
        summary: 'Quiz - Alternativas',
        detail: 'Dados criados com sucesso',
        severity: 'success'
      })
    );
    yield put(createQuizAlternativeSuccess());
    yield put(fetchOneQuizQuestionsRequest(action.payload.one.quizQuestionId));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz - Alternativas',
        detail: 'Falha ao criar dados',
        severity: 'error'
      })
    );
    yield put(createQuizAlternativeError());
  }
}

export function* updateQuizAlternative(action: Action) {
  try {
    const { quizQuestionId, ...serialized } = action.payload.one;
    const quizQuestion = { connect: { id: quizQuestionId } };

    const data = Object.assign(serialized, { quizQuestion }) as any;

    yield call(Connector().put, `quiz-alternatives?id=${serialized.id}`, data);

    yield put(
      showToast({
        summary: 'Quiz - Alternativas',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
    yield put(updateQuizAlternativeSuccess());
    yield put(fetchOneQuizQuestionsRequest(quizQuestionId));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz - Alternativas',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(updateQuizAlternativeError());
  }
}

export function* deleteQuizAlternative(action: Action) {
  try {
    yield call(
      Connector().delete,
      `quiz-alternatives?id=${action.payload.one.id}`
    );

    yield put(
      showToast({
        summary: 'Quiz - Alternativas',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
    yield put(deleteQuizAlternativeSuccess());
    yield put(fetchOneQuizQuestionsRequest(action.payload.one.quizQuestionId));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz - Alternativas',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(deleteQuizAlternativeError());
  }
}
