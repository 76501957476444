import { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Folder from 'models/Folder';
import { closeDrawer } from 'store/ducks/drawer/actions';
import {
  editFolderRequest,
  saveFolderRequest
} from 'store/ducks/folder/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, InputTextarea } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import folderValidate from 'helpers/validation/folder';
import * as S from './FolderActionSidebar.styles';

const FolderActionSidebar = () => {
  const { parentId } = useSelector(
    (state: ApplicationState) => state.currentFolder
  );
  const { folder: stateFolder } = useSelector(
    (state: ApplicationState) => state.folder
  );
  const [folder, setFolder] = useState<Folder>(stateFolder);

  const dispatch = useDispatch();

  useEffect(() => {
    setFolder(stateFolder);
  }, [stateFolder]);

  const handleConfirmPress = () => {
    try {
      folderValidate(folder);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    if (stateFolder.id === 0) {
      dispatch(saveFolderRequest({ ...folder, parentId }));
    } else {
      dispatch(editFolderRequest({ ...folder, parentId }));
    }
    dispatch(closeDrawer());
  };

  const handleCancelPress = () => {
    dispatch(closeDrawer());
  };

  return (
    <S.Form>
      <InputText
        label="Nome"
        name="name"
        type="text"
        value={folder.name}
        onChange={(e) =>
          setFolder({
            ...folder,
            name: e.target.value
          })
        }
      />

      <InputTextarea
        label="Descrição"
        name="description"
        value={folder.description}
        rows={8}
        style={{ resize: 'none' }}
        onChange={(e) =>
          setFolder({
            ...folder,
            description: e.target.value
          })
        }
      />

      <InputText
        label="Nome do Narrador"
        name="narratorName"
        type="text"
        value={folder.narratorName}
        onChange={(e) =>
          setFolder({
            ...folder,
            narratorName: e.target.value
          })
        }
      />

      <S.ButtonGroup>
        <Button
          type="button"
          size="big"
          severity="confirm"
          onClick={handleConfirmPress}
        >
          <FaSave />
          Salvar
        </Button>

        <Button
          type="button"
          size="big"
          severity="danger"
          onClick={handleCancelPress}
        >
          <FaTimes />
          Cancelar
        </Button>
      </S.ButtonGroup>
    </S.Form>
  );
};

export { FolderActionSidebar };
