import Subscription from 'models/Subscription';
import {
  ActiveUsers,
  ActiveUsersByPeriod,
  CanceledAndActiveUsersAfterTest,
  CanceledOrActivePayingUsers,
  CanceledUsersAfterTest,
  FinancialReport,
  PaymentAmount,
  PlaylistsUsage,
  SubscriptionsSummary,
  UsagePerHour,
  UsersInPromotion,
  UsersInTest
} from 'interfaces/Analytics';

export enum AnalyticsTypes {
  USAGE_PER_HOURS_REQUEST = '@analytics/USAGE_PER_HOURS_REQUEST',
  USAGE_PER_HOURS_SUCCESS = '@analytics/USAGE_PER_HOURS_SUCCESS',
  USAGE_PER_HOURS_FAILURE = '@analytics/USAGE_PER_HOURS_FAILURE',

  PLAYLISTS_USAGE_REQUEST = '@analytics/PLAYLISTS_USAGE_REQUEST',
  PLAYLISTS_USAGE_SUCCESS = '@analytics/PLAYLISTS_USAGE_SUCCESS',
  PLAYLISTS_USAGE_FAILURE = '@analytics/PLAYLISTS_USAGE_FAILURE',

  ACTIVE_USERS_BY_PLAN_REQUEST = '@analytics/ACTIVE_USERS_BY_PLAN_REQUEST',
  ACTIVE_USERS_BY_PLAN_SUCCESS = '@analytics/ACTIVE_USERS_BY_PLAN_SUCCESS',
  ACTIVE_USERS_BY_PLAN_FAILURE = '@analytics/ACTIVE_USERS_BY_PLAN_FAILURE',

  ACTIVE_USERS_BY_PERIOD_REQUEST = '@analytics/ACTIVE_USERS_BY_PERIOD_REQUEST',
  ACTIVE_USERS_BY_PERIOD_SUCCESS = '@analytics/ACTIVE_USERS_BY_PERIOD_SUCCESS',
  ACTIVE_USERS_BY_PERIOD_FAILURE = '@analytics/ACTIVE_USERS_BY_PERIOD_FAILURE',

  CANCELED_OR_ACTIVE_PAYING_USERS_REQUEST = '@analytics/CANCELED_OR_ACTIVE_PAYING_USERS_REQUEST',
  CANCELED_OR_ACTIVE_PAYING_USERS_SUCCESS = '@analytics/CANCELED_OR_ACTIVE_PAYING_USERS_SUCCESS',
  CANCELED_OR_ACTIVE_PAYING_USERS_FAILURE = '@analytics/CANCELED_OR_ACTIVE_PAYING_USERS_FAILURE',

  CANCELED_AND_ACTIVE_USERS_AFTER_TEST_REQUEST = '@analytics/CANCELED_AND_ACTIVE_USERS_AFTER_TEST_REQUEST',
  CANCELED_AND_ACTIVE_USERS_AFTER_TEST_SUCCESS = '@analytics/CANCELED_AND_ACTIVE_USERS_AFTER_TEST_SUCCESS',
  CANCELED_AND_ACTIVE_USERS_AFTER_TEST_FAILURE = '@analytics/CANCELED_AND_ACTIVE_USERS_AFTER_TEST_FAILURE',

  CANCELED_USERS_AFTER_TEST_REQUEST = '@analytics/CANCELED_USERS_AFTER_TEST_REQUEST',
  CANCELED_USERS_AFTER_TEST_SUCCESS = '@analytics/CANCELED_USERS_AFTER_TEST_SUCCESS',
  CANCELED_USERS_AFTER_TEST_FAILURE = '@analytics/CANCELED_USERS_AFTER_TEST_FAILURE',

  USERS_IN_PROMOTION_REQUEST = '@analytics/USERS_IN_PROMOTION_REQUEST',
  USERS_IN_PROMOTION_SUCCESS = '@analytics/USERS_IN_PROMOTION_SUCCESS',
  USERS_IN_PROMOTION_FAILURE = '@analytics/USERS_IN_PROMOTION_FAILURE',

  USERS_IN_TEST_REQUEST = '@analytics/USERS_IN_TEST_REQUEST',
  USERS_IN_TEST_SUCCESS = '@analytics/USERS_IN_TEST_SUCCESS',
  USERS_IN_TEST_FAILURE = '@analytics/USERS_IN_TEST_FAILURE',

  PAYMENT_AMOUNT_REQUEST = '@analytics/PAYMENT_AMOUNT_REQUEST',
  PAYMENT_AMOUNT_SUCCESS = '@analytics/PAYMENT_AMOUNT_SUCCESS',
  PAYMENT_AMOUNT_FAILURE = '@analytics/PAYMENT_AMOUNT_FAILURE',

  FINANCIAL_REPORT_REQUEST = '@analytics/FINANCIAL_REPORT_REQUEST',
  FINANCIAL_REPORT_SUCCESS = '@analytics/FINANCIAL_REPORT_SUCCESS',
  FINANCIAL_REPORT_FAILURE = '@analytics/FINANCIAL_REPORT_FAILURE',

  SUBSCRIPTIONS_SUMMARY_REQUEST = '@analytics/SUBSCRIPTIONS_SUMMARY_REQUEST',
  SUBSCRIPTIONS_SUMMARY_SUCCESS = '@analytics/SUBSCRIPTIONS_SUMMARY_SUCCESS',
  SUBSCRIPTIONS_SUMMARY_FAILURE = '@analytics/SUBSCRIPTIONS_SUMMARY_FAILURE',

  ALL_SUBSCRIPTIONS_REQUEST = '@analytics/ALL_SUBSCRIPTIONS_REQUEST',
  ALL_SUBSCRIPTIONS_SUCCESS = '@analytics/ALL_SUBSCRIPTIONS_SUCCESS',
  ALL_SUBSCRIPTIONS_FAILURE = '@analytics/ALL_SUBSCRIPTIONS_FAILURE'
}

export type AnalyticsModules =
  | 'USAGE_PER_HOURS'
  | 'PLAYLISTS_USAGE'
  | 'ACTIVE_USERS_BY_PLAN'
  | 'ACTIVE_USERS_BY_PERIOD'
  | 'CANCELED_OR_ACTIVE_PAYING_USERS'
  | 'CANCELED_AND_ACTIVE_USERS_AFTER_TEST'
  | 'CANCELED_USERS_AFTER_TEST'
  | 'USERS_IN_PROMOTION'
  | 'USERS_IN_TEST'
  | 'PAYMENT_AMOUNT'
  | 'FINANCIAL_REPORT'
  | 'SUBSCRIPTIONS_SUMMARY'
  | 'ALL_SUBSCRIPTIONS';

export interface AnalyticsState {
  readonly usagePerHours: UsagePerHour[];
  readonly playlistsUsage: PlaylistsUsage[];
  readonly activeUsersByPlan: ActiveUsers[];
  readonly activeUsersByPeriod: ActiveUsersByPeriod;
  readonly canceledOrActivePayingUsers: CanceledOrActivePayingUsers;
  readonly canceledAndActiveUsersAfterTest: CanceledAndActiveUsersAfterTest[];
  readonly canceledUsersAfterTest: CanceledUsersAfterTest[];
  readonly usersInPromotion: UsersInPromotion;
  readonly usersInTest: UsersInTest;
  readonly paymentAmount: PaymentAmount;
  readonly financialReport: FinancialReport[];
  readonly subscriptionsSummary: SubscriptionsSummary;
  readonly subscriptions: Subscription[];

  readonly error: AnalyticsModules[];
  readonly success: AnalyticsModules[];
  readonly loading: AnalyticsModules[];

  readonly startsIn: string;
  readonly endsIn: string;
}

export type AnalyticsAction = {
  type: AnalyticsTypes;
  payload: AnalyticsState;
};
