import { action } from 'typesafe-actions';
import Quiz from 'models/Quiz';
import { QuizTypes } from './types';

export const fetchQuizzesRequest = () =>
  action(QuizTypes.FETCH_QUIZZES_REQUEST);

export const fetchQuizzesSuccess = (datas: Quiz[]) =>
  action(QuizTypes.FETCH_QUIZZES_SUCCESS, {
    datas
  });

export const fetchQuizzesError = () => action(QuizTypes.FETCH_QUIZZES_ERROR);

export const fetchOneQuizRequest = (id: number) =>
  action(QuizTypes.FETCH_ONE_QUIZ_REQUEST, { id });

export const fetchOneQuizSuccess = (data: Quiz) =>
  action(QuizTypes.FETCH_ONE_QUIZ_SUCCESS, {
    data
  });

export const fetchOneQuizError = () => action(QuizTypes.FETCH_ONE_QUIZ_ERROR);

export const createQuizRequest = (data: Quiz) =>
  action(QuizTypes.CREATE_QUIZ_REQUEST, { data });

export const createQuizSuccess = () => action(QuizTypes.CREATE_QUIZ_SUCCESS);

export const createQuizError = () => action(QuizTypes.CREATE_QUIZ_ERROR);

export const updateQuizRequest = (data: Quiz) =>
  action(QuizTypes.UPDATE_QUIZ_REQUEST, { data });

export const updateQuizSuccess = () => action(QuizTypes.UPDATE_QUIZ_SUCCESS);

export const updateQuizError = () => action(QuizTypes.UPDATE_QUIZ_ERROR);

export const deleteQuizRequest = (data: Quiz) =>
  action(QuizTypes.DELETE_QUIZ_REQUEST, { data });

export const deleteQuizSuccess = () => action(QuizTypes.DELETE_QUIZ_SUCCESS);

export const deleteQuizError = () => action(QuizTypes.DELETE_QUIZ_ERROR);
