import { Reducer } from 'redux';
import { ToastTypes, ToastState, ToastAction } from './types';
import { TOAST_INITIAL_STATE } from './utils';

const reducer: Reducer<ToastState, ToastAction> = (
  state: ToastState = TOAST_INITIAL_STATE,
  action: ToastAction
) => {
  switch (action.type) {
    case ToastTypes.TOAST_SHOW: {
      return {
        toast: action.payload.toast,
        showToast: true,
        dismissAll: false
      };
    }
    case ToastTypes.TOAST_DISMISS_ALL: {
      return {
        toast: TOAST_INITIAL_STATE.toast,
        showToast: false,
        dismissAll: true
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
