import { FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog as PrimeReactDialog } from 'primereact/dialog';
import { hide } from 'store/ducks/dialog/actions';
import ApplicationState from 'helpers/types/ApplicationState';
import { Button } from '../Button/Button';
import * as S from './Dialog.styles';

const Dialog = () => {
  const dialog = useSelector((state: ApplicationState) => state.dialog.data);
  const dispatch = useDispatch();

  const handleConfirmPress = () => {
    dispatch(hide());

    if (dialog.onConfirmPress) {
      dialog.onConfirmPress();
    }
  };

  const handleCancelPress = () => {
    dispatch(hide());

    if (dialog.onCancelPress) {
      dialog.onCancelPress();
    }
  };

  const Footer =
    dialog.mode === 'CONFIRM' ? (
      <S.ButtonGroup>
        <Button type="button" severity="confirm" onClick={handleConfirmPress}>
          <FaCheck />
          Sim
        </Button>
        <Button type="button" severity="danger" onClick={handleCancelPress}>
          <FaTimes />
          Não
        </Button>
      </S.ButtonGroup>
    ) : (
      <div>
        <Button type="button" onClick={() => dispatch(hide())}>
          Ok
        </Button>
      </div>
    );

  return (
    <S.DialogWrapper>
      <PrimeReactDialog
        header={dialog.title}
        visible={dialog.visible}
        onHide={handleCancelPress}
        footer={Footer}
        closeOnEscape
        focusOnShow
        dismissableMask
        modal
        blockScroll
        draggable={false}
      >
        {dialog.message}
      </PrimeReactDialog>
    </S.DialogWrapper>
  );
};

export { Dialog };
