import { Contact } from 'models/Institutional';

export enum InstitutionalContactTypes {
  FETCH_CONTACTS_REQUEST = 'institutionalContact@FETCH_CONTACTS_REQUEST',
  FETCH_CONTACTS_SUCCESS = 'institutionalContact@FETCH_CONTACTS_SUCCESS',
  FETCH_CONTACTS_ERROR = 'institutionalContact@FETCH_CONTACTS_ERROR',
}

export interface InstitutionalContactState {
  readonly contacts: Contact[];
}

export interface InstitutionalContactAction {
  type: InstitutionalContactTypes;
  payload: InstitutionalContactState;
}