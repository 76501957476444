import classNames from 'classnames';
import {
  MultiSelect as PrimeReactMultiSelect,
  MultiSelectSelectedItemTemplateType
} from 'primereact/multiselect';
import { Tag } from '../Tag/Tag';
import { MultiSelectProps } from './interfaces/MultiSelectProps';
import * as S from './MultiSelect.styles';

const MultiSelect = (props: MultiSelectProps) => {
  const {
    label,
    emptySelection,
    labelDirection,
    optionLabel,
    selectedItemTemplate,
    ...options
  } = props;

  const getSelectedItemTemplate = (
    selected?: MultiSelectSelectedItemTemplateType
  ) => {
    if (selectedItemTemplate) {
      return selectedItemTemplate;
    }

    if (selected) {
      let item = selected;

      if (typeof selected === 'object' && optionLabel) {
        item = (selected as any)[optionLabel];
      }

      return <Tag severity="info" value={item} />;
    }

    return <span>{emptySelection}</span>;
  };

  return (
    <S.MultiSelectContainer
      className={classNames({
        'p-multiselect-container-row': labelDirection === 'side',
        'p-multiselect-container-column': labelDirection === 'top'
      })}
    >
      <S.Label className="p-multiselect-container-label">{label}</S.Label>

      <PrimeReactMultiSelect
        optionLabel={optionLabel}
        selectedItemTemplate={getSelectedItemTemplate}
        {...options}
      />
    </S.MultiSelectContainer>
  );
};

MultiSelect.defaultProps = {
  emptySelection: 'Selecionar itens',
  labelDirection: 'top'
};

export { MultiSelect };
