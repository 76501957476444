import { Filters } from 'models/Filters';

export const filterInitialState: Filters = {
  CreatedAt: {
    enable: false,
    value: ''
  },
  CanceledAt: {
    enable: false,
    value: ''
  },
  status: {
    enable: false,
    value: ''
  },
  planValue: {
    enable: false,
    value: ''
  },
  address: {
    enable: false,
    value: ''
  },
  city: {
    enable: false,
    value: ''
  },
  state: {
    enable: false,
    value: ''
  },
  country: {
    enable: false,
    value: ''
  },
  phone: {
    enable: false,
    value: ''
  },
  teachingInstitutionId: {
    enable: false,
    value: ''
  },
  startsIn: {
    enable: false,
    value: ''
  },
  endsIn: {
    enable: false,
    value: ''
  }
};
