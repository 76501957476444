import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { showToast } from '../toast/actions';
import {
  fetchTrashSuccess,
  fetchTrashError,
  removeTrashAudioSuccess,
  removeTrashAudioError,
  restoreTrashAudioSuccess,
  restoreTrashAudioError,
  fetchTrashRequest
} from './actions';
import { Action } from './index';

export function* fetchTrash() {
  try {
    const folders: ConnectorResponse = yield call(
      Connector().get,
      'folders/in-trash/list'
    );
    const audios: ConnectorResponse = yield call(
      Connector().get,
      'audios/in-trash/list'
    );

    yield put(fetchTrashSuccess([...folders.data, ...audios.data]));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchTrashError());
  }
}

export function* removeTrashAudio(action: Action) {
  try {
    yield call(Connector().delete, `audios?id=${action.payload.old.id}`);

    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Áudio removido com sucesso',
        severity: 'success'
      })
    );
    yield put(removeTrashAudioSuccess());
    yield put(fetchTrashRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Falha ao remover áudio',
        severity: 'error'
      })
    );
    yield put(removeTrashAudioError());
  }
}

export function* restoreTrashAudio(action: Action) {
  try {
    yield call(Connector().put, `audios/in-trash?id=${action.payload.old.id}`);

    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Áudio restaurado com sucesso',
        severity: 'success'
      })
    );
    yield put(restoreTrashAudioSuccess());
    yield put(fetchTrashRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Falha ao restaurar audio',
        severity: 'error'
      })
    );
    yield put(restoreTrashAudioError());
  }
}

export function* removeTrashFolder(action: Action) {
  try {
    yield call(Connector().delete, `folders?id=${action.payload.old.id}`);

    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Pasta removida com sucesso',
        severity: 'success'
      })
    );
    yield put(removeTrashAudioSuccess());
    yield put(fetchTrashRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Falha ao remover pasta',
        severity: 'error'
      })
    );
    yield put(removeTrashAudioError());
  }
}

export function* restoreTrashFolder(action: Action) {
  try {
    yield call(Connector().put, `folders/in-trash?id=${action.payload.old.id}`);

    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Pasta restaurada com sucesso',
        severity: 'success'
      })
    );
    yield put(restoreTrashAudioSuccess());
    yield put(fetchTrashRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Lixeira',
        detail: 'Falha ao restaurar pasta',
        severity: 'error'
      })
    );
    yield put(restoreTrashAudioError());
  }
}
