import classNames from 'classnames';
import * as S from './Button.styles';
import { ButtonProps } from './interface/ButtonProps';

const Button = (props: ButtonProps) => {
  const { severity, size, children, className, ...rest } = props;

  return (
    <S.ButtonWrapper
      className={classNames(`p-button-${severity}`, {
        'p-button-sm': size === 'small',
        'p-button-lg': size === 'big',
        [`${className}`]: className
      })}
      {...rest}
    >
      {children}
    </S.ButtonWrapper>
  );
};

Button.defaultProps = {
  severity: 'primary',
  size: 'small',
  className: undefined,
  type: 'submit'
};

export { Button };
