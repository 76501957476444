import { Reducer } from 'redux';
import Quiz from 'models/Quiz';
import { QuizTypes, QuizState } from './types';

export type Action = {
    type: QuizTypes;
    payload: {
      id: number;
      data: Quiz;
      datas: Quiz[];
    }
};

const INITIAL_STATE: QuizState = {
  id: 0,
  data: {
    id: 0,
    name: '',
    description: '',
    playListId: 0,
    quizQuestions: [],
    createdById: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  datas: [],
};

const reducer: Reducer<QuizState, Action> = (
  state: QuizState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case QuizTypes.FETCH_QUIZZES_SUCCESS: {
    return {
      ...state,
      datas: action.payload.datas,
    };
  }
  case QuizTypes.FETCH_ONE_QUIZ_REQUEST: {
    return {
      ...state,
      id: action.payload.id,
    };
  }
  case QuizTypes.FETCH_ONE_QUIZ_SUCCESS: {
    return {
      ...state,
      data: action.payload.data,
    };
  }
  case QuizTypes.CREATE_QUIZ_REQUEST: {
    return {
      ...state,
      data: action.payload.data,
    };
  }
  case QuizTypes.UPDATE_QUIZ_REQUEST: {
    return {
      ...state,
      data: action.payload.data,
    };
  }
  case QuizTypes.DELETE_QUIZ_REQUEST: {
    return {
      ...state,
      data: action.payload.data,
    };
  }
  default: {
    return state;
  }
  }
};

export default reducer;
