/* eslint-disable no-plusplus */
import moment from 'moment';

const monthLabels = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

const convertStringToDate = (date: string) => moment(date).toDate();

const convertDateToString = (date: Date) => moment(date).toISOString();

const getInitialStartDate = () => {
  const currentDate = moment();

  currentDate.date(1);
  currentDate.month(currentDate.month() - 3);
  currentDate.hours(0);
  currentDate.minutes(0);
  currentDate.seconds(0);
  currentDate.milliseconds(0);

  return currentDate.toDate();
};

const getInitialEndDate = () => {
  const newDate = new Date();

  const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);

  return endDate;
};

const getMonthRangeLabels = (startsIn: string, endsIn: string) => {
  const startDate = convertStringToDate(startsIn);
  const endDate = convertStringToDate(endsIn);

  const range = moment(endDate).diff(startDate, 'months');
  const currentDate = moment(startDate);

  const labels = [];

  for (let index = 0; index <= range; index++) {
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();

    labels.push(`${monthLabels[currentMonth]}/${currentYear}`);

    if (currentMonth === 12) {
      currentDate.month(0);
      currentDate.year(currentDate.year() + 1);
    } else {
      currentDate.month(currentDate.month() + 1);
    }
  }

  return labels;
};

const getTimeLabels = () => [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
];

export default {
  convertDateToString,
  convertStringToDate,
  getInitialStartDate,
  getInitialEndDate,
  getMonthRangeLabels,
  getTimeLabels
};
