import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchHomeData as fetchHomeDataRequest,
  fetchRequestSuccess,
  fetchRequestError,
  homeRequestSuccess,
  homeRequestError
} from './actions';
import { InstitutionalHomeAction } from './types';

export function* fetchHomeData() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { data: banner }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/banners'
    );
    const { data: differentials }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/our-differences'
    );
    const { data: testimonials }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/depositions/cms'
    );

    yield put(fetchRequestSuccess(banner, differentials, testimonials));
  } catch (err: any) {
    yield put(fetchRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* editHomeBanner(action: InstitutionalHomeAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { id, ...data } = action.payload.banner;

    yield call(Connector().put, `/landing-page/banners?id=${id}`, data);

    yield put(homeRequestSuccess());
    yield put(fetchHomeDataRequest());
  } catch (err: any) {
    yield put(homeRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* createHomeDifferential(action: InstitutionalHomeAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const {
      x: { title, description },
      icon
    } = action.payload.differential;

    const differential = new FormData();

    differential.append('title', title);
    differential.append('description', description);

    if (icon) {
      differential.append('icon', icon);
    }

    yield call(Connector().post, '/landing-page/our-differences', differential);

    yield put(homeRequestSuccess());
    yield put(fetchHomeDataRequest());
  } catch (err: any) {
    yield put(homeRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* editHomeDifferential(action: InstitutionalHomeAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const {
      x: { id, title, description },
      icon
    } = action.payload.differential;

    yield call(Connector().put, `/landing-page/our-differences?id=${id}`, {
      title,
      description
    });

    if (icon) {
      const data = new FormData();

      data.append('icon', icon);

      yield call(
        Connector().put,
        `/landing-page/our-differences-icon?id=${id}`,
        data
      );
    }

    yield put(homeRequestSuccess());
    yield put(fetchHomeDataRequest());
  } catch (err: any) {
    yield put(homeRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* removeHomeDifferential(action: InstitutionalHomeAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const {
      x: { id }
    } = action.payload.differential;

    yield call(Connector().delete, `/landing-page/our-differences?id=${id}`);

    yield put(homeRequestSuccess());
    yield put(fetchHomeDataRequest());
    yield put(
      showToast({
        summary: 'Institucional - Homepage',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(homeRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* createHomeTestimonials(action: InstitutionalHomeAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { contact, message, showInHome, avatar } = action.payload.testimonial;

    const testimonial = new FormData();

    testimonial.append('contact', contact);
    testimonial.append('message', message);
    testimonial.append('showInHome', `${showInHome}`);

    if (avatar) {
      testimonial.append('avatar', avatar);
    }

    yield call(Connector().post, '/landing-page/depositions', testimonial);

    yield put(homeRequestSuccess());
    yield put(fetchHomeDataRequest());
  } catch (err: any) {
    yield put(homeRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* editHomeTestimonials(action: InstitutionalHomeAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { avatar, id, contact, message, showInHome } =
      action.payload.testimonial;

    const data = new FormData();

    data.append('contact', contact);
    data.append('message', message);
    data.append('showInHome', `${showInHome}`);

    if (avatar) {
      data.append('avatar', avatar);
    }

    yield call(
      Connector().put,
      `/landing-page/depositions/update?id=${id}`,
      data
    );

    yield put(homeRequestSuccess());
    yield put(fetchHomeDataRequest());
  } catch (err: any) {
    yield put(homeRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* removeHomeTestimonials(action: InstitutionalHomeAction) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { id } = action.payload.testimonial;

    yield call(Connector().delete, `/landing-page/depositions?id=${id}`);

    yield put(homeRequestSuccess());
    yield put(fetchHomeDataRequest());
  } catch (err: any) {
    yield put(homeRequestError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* showSuccessMessage() {
  yield put(
    showToast({
      summary: 'Institucional - Homepage',
      detail: 'Dados salvos com sucesso',
      severity: 'success'
    })
  );
}

export function* showErrorMessage() {
  yield put(
    showToast({
      summary: 'Institucional - Homepage',
      detail: 'Falha ao salvar dados',
      severity: 'error'
    })
  );
}
