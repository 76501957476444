import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Log from 'models/Log';
import { UserApp } from 'models/User';
import { fetchLogByUserSubscriptionRequest } from 'store/ducks/logs/actions';
import { fetchUsersAppRequest } from 'store/ducks/userApp/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import {
  UserLogActionSidebar,
  LogUserDataTable,
  DetailedLogDataTable
} from './components';
import { logInitialState, userInitialState } from './helpers/initialState';
import * as S from './UserLogsPage.styles';

type DTMode = 'USER' | 'LOG';

const UserLogsPage = () => {
  const dispatch = useDispatch();

  const users = useSelector((state: ApplicationState) => state.userApp.all);
  const { logs } = useSelector((state: ApplicationState) => state.logs);

  const [selectedLog, setSelectedLog] = useState<Log>(logInitialState);

  const [displayMode, setDisplayMode] = useState<DTMode>('USER');
  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchUsersAppRequest());
  }, [dispatch]);

  const handleNavigation = () => {
    if (displayMode === 'LOG') {
      setDisplayMode('USER');
    }
  };

  const handleDoubleClick = (data: UserApp) => {
    dispatch(fetchLogByUserSubscriptionRequest(data.id));
    setDisplayMode('LOG');
  };

  const handleViewPressed = (data: Log) => {
    setSelectedLog(data);
    setDrawerMode('OPEN_VIEW');
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={() => handleNavigation()}
      disabled={displayMode === 'USER'}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <UserLogActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedLog={selectedLog}
        />

        <S.Container>
          <PageHeader
            title="Logs de usuários"
            breadcrumbsBottomComponent={getBackButton()}
          />

          {displayMode === 'USER' ? (
            <DetailedLogDataTable
              data={users}
              onDoubleClick={handleDoubleClick}
            />
          ) : (
            <LogUserDataTable data={logs} onPressItem={handleViewPressed} />
          )}
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { UserLogsPage };
