import styled from 'styled-components';

export const Container = styled.div`
  .p-card {
    display: flex;
    min-width: 250px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;

    margin: 10px;
    background-color: ${({ theme }) => theme.background}!important;
    color: ${({ theme }) => theme.text.primary}!important;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;

    .p-card-body {
      .p-card-title {
        font-size: 14px !important;
        font-family: 'Mulish', sans-serif !important;
      }

      .p-card-content {
        padding: 0 !important;
      }
    }
  }
`;

export const Body = styled.div`
  text-align: center;
  font-size: 1.6em;
  font-weight: 300;
  font-family: 'Mulish', sans-serif;
`;

export const VerticalSeparator = styled.div`
  @media screen and (min-width: 1025px) {
    width: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
`;

export const HorizontalSeparator = styled.div`
  width: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px;
`;
