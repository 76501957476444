import { action } from 'typesafe-actions';
import PlaylistCategory from 'models/PlaylistCategory';
import { PlaylistCategoryTypes } from './types';

export const fetchPlaylistCategoryRequest = () =>
  action(PlaylistCategoryTypes.FETCH_PLAYLIST_CATEGORY_REQUEST);

export const fetchPlaylistCategorySuccess = (data: PlaylistCategory[]) =>
  action(PlaylistCategoryTypes.FETCH_PLAYLIST_CATEGORY_SUCCESS, { data });

export const fetchPlaylistCategoryError = () =>
  action(PlaylistCategoryTypes.FETCH_PLAYLIST_CATEGORY_ERROR);

export const editPlaylistCategoryRequest = (old: PlaylistCategory) =>
  action(PlaylistCategoryTypes.EDIT_PLAYLIST_CATEGORY_REQUEST, { old });

export const editPlaylistCategorySuccess = () =>
  action(PlaylistCategoryTypes.EDIT_PLAYLIST_CATEGORY_SUCCESS);

export const editPlaylistCategoryError = () =>
  action(PlaylistCategoryTypes.EDIT_PLAYLIST_CATEGORY_ERROR);

export const removePlaylistCategoryRequest = (old: PlaylistCategory) =>
  action(PlaylistCategoryTypes.REMOVE_PLAYLIST_CATEGORY_REQUEST, { old });

export const removePlaylistCategorySuccess = () =>
  action(PlaylistCategoryTypes.REMOVE_PLAYLIST_CATEGORY_SUCCESS);

export const removePlaylistCategoryError = () =>
  action(PlaylistCategoryTypes.REMOVE_PLAYLIST_CATEGORY_ERROR);

export const savePlaylistCategoryRequest = (old: PlaylistCategory) =>
  action(PlaylistCategoryTypes.SAVE_PLAYLIST_CATEGORY_REQUEST, { old });

export const savePlaylistCategorySuccess = () =>
  action(PlaylistCategoryTypes.SAVE_PLAYLIST_CATEGORY_SUCCESS);

export const savePlaylistCategoryError = () =>
  action(PlaylistCategoryTypes.SAVE_PLAYLIST_CATEGORY_ERROR);
