import styled from 'styled-components';

export const TestimonialsWrapper = styled.div``;

export const TestimonialsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TestimonialItem = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const TestimonialItemAvatar = styled.div`
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 18px;

  img {
    width: 100%;
    position: relative;
    top: -20%;
  }
`;

export const TestimonialItemText = styled.div`
  text-align: left;

  .item__contact {
    font-weight: 600;
  }
`;

export const Form = styled.form``;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const FormGroup = styled.fieldset`
  display: flex;
  flex-direction: column;

  &:last-child {
    width: 100%;
  }

  .input-switch__container {
    margin-top: 18px;
  }
`;

export const TestimonialsAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 12px;

  label {
    height: 160px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 5px;
    transition: all 0.3s;
    margin-bottom: 12px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.background};

    input {
      display: none;
    }

    svg {
      opacity: 0.8;
      font-size: 20px;
      transition: all 0.3s;
      color: ${({ theme }) => theme.text.primary};
    }

    &:hover {
      background-color: rgba(82, 89, 112, 0.8);

      svg {
        opacity: 0.6;
        color: white;
      }
    }
  }

  .has-cover {
    animation: fadeIn 0.5s;

    svg {
      display: none;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;

  button:not(:last-child) {
    margin-right: 12px;
  }
`;
