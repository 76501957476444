import { UserApp } from 'models/User';

export enum UserAppTypes {
  FETCH_USERS_APP_REQUEST = '@user/FETCH_USERS_APP_REQUEST',
  FETCH_USERS_APP_SUCCESS = '@user/FETCH_USERS_APP_SUCCESS',
  FETCH_USERS_APP_ERROR = '@user/FETCH_USERS_APP_ERROR',

  FETCH_ONE_USER_APP_REQUEST = '@user/FETCH_ONE_USER_APP_REQUEST',
  FETCH_ONE_USER_APP_SUCCESS = '@user/FETCH_ONE_USER_APP_SUCCESS',
  FETCH_ONE_USER_APP_ERROR = '@user/FETCH_ONE_USER_APP_ERROR',

  CREATE_USER_APP_REQUEST = '@user/CREATE_USER_APP_REQUEST',
  CREATE_USER_APP_SUCCESS = '@user/CREATE_USER_APP_SUCCESS',
  CREATE_USER_APP_ERROR = '@user/CREATE_USER_APP_ERROR',

  UPDATE_USER_APP_REQUEST = '@user/UPDATE_USER_APP_REQUEST',
  UPDATE_USER_APP_SUCCESS = '@user/UPDATE_USER_APP_SUCCESS',
  UPDATE_USER_APP_ERROR = '@user/UPDATE_USER_APP_ERROR',

  DELETE_USER_APP_REQUEST = '@user/DELETE_USER_APP_REQUEST',
  DELETE_USER_APP_SUCCESS = '@user/DELETE_USER_APP_SUCCESS',
  DELETE_USER_APP_ERROR = '@user/DELETE_USER_APP_ERROR',
}

export interface UserAppState {
  readonly id: number;
  readonly one: UserApp;
  readonly all: UserApp[];
}
