import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Plan from 'models/Plan';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, InputTextarea, InputRadio } from 'components/Form';
import { DrawerMode } from 'helpers/types/DrawerMode';
import planValidate from 'helpers/validation/plan';
import * as S from './PlanActionSidebar.styles';

interface PlanActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedPlan: Plan;
  onConfirmPress: (plan: Plan) => void;
  onCancelPress?: () => void;
}

const PlanActionSidebar = (props: PlanActionSidebarProps) => {
  const { mode, setMode, selectedPlan, onCancelPress, onConfirmPress } = props;

  const dispatch = useDispatch();

  const [plan, setPlan] = useState<Plan>(selectedPlan);

  useEffect(() => {
    setPlan(selectedPlan);
  }, [selectedPlan]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar plano';
        break;
      case 'OPEN_EDIT':
        title = 'Editar plano';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      planValidate(plan);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    if (onConfirmPress) {
      onConfirmPress(plan);
    }
    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Nome"
          name="name"
          type="text"
          value={plan.name}
          onChange={(e) =>
            setPlan({
              ...plan,
              name: e.target.value
            })
          }
        />

        <InputText
          label="Valor"
          name="value"
          type="number"
          step="0.01"
          value={Number(plan.value).toString()}
          onChange={(e) =>
            setPlan({
              ...plan,
              value: e.target.value
                ? Number.parseFloat(parseFloat(e.target.value).toFixed(3))
                : 0
            })
          }
        />

        <InputTextarea
          label="Descrição"
          name="description"
          rows={5}
          cols={30}
          value={plan.description}
          onChange={(e) =>
            setPlan({
              ...plan,
              description: e.currentTarget.value
            })
          }
        />

        <S.InputRadioGroup>
          <span className="radioGroupLabel">Status</span>

          <InputRadio
            label="Ativo"
            name="status"
            value="active"
            onClick={() =>
              setPlan({
                ...plan,
                status: 'ENABLED'
              })
            }
            checked={plan.status === 'ENABLED'}
          />

          <InputRadio
            label="Inativo"
            name="status"
            value="inactive"
            onClick={() =>
              setPlan({
                ...plan,
                status: 'DISABLED'
              })
            }
            checked={plan.status === 'DISABLED'}
          />
        </S.InputRadioGroup>

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

PlanActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { PlanActionSidebar };
