/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useRef, MouseEventHandler, useEffect } from 'react';
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeDown,
  FaVolumeMute
} from 'react-icons/fa';
import classNames from 'classnames';
import { ButtonClickEvent } from 'helpers/types/ButtonEvents';
import { Button } from '../Button/Button';
import * as S from './AudioPlayer.styles';
import { AudioPlayerProps } from './interfaces/AudioPlayerProps';

const AudioPlayer = (props: AudioPlayerProps) => {
  const { src } = props;
  const [playerOn, setPlayerOn] = useState(false);
  const [timer, setTimer] = useState('00:00 / 00:00');
  const [isMuted, setMuted] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    setPlayerOn(false);
  }, [src]);

  const handlePlayback = (event: ButtonClickEvent) => {
    event.preventDefault();

    if (playerOn) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setPlayerOn(!playerOn);
  };

  const handleVolume = (event: ButtonClickEvent, volumeDirection: string) => {
    event.preventDefault();
    setMuted(true);

    if (audioRef.current) {
      if (volumeDirection === 'up' && audioRef.current.volume <= 0.95) {
        audioRef.current.volume += 0.05;
      } else if (
        volumeDirection === 'down' &&
        audioRef.current.volume >= 0.05
      ) {
        audioRef.current.volume -= 0.05;
      } else if (volumeDirection === 'mute') {
        audioRef.current.muted = !audioRef.current.muted;
        setMuted(false);
      }
    }
  };

  const formatSecondsAsTime = (secs: number) => {
    const hr = Math.floor(secs / 3600);
    let min: any = Math.floor((secs - hr * 3600) / 60);
    let sec: any = Math.floor(secs - hr * 3600 - min * 60);
    if (min < 10) {
      min = `0${min}`;
    }
    if (sec < 10) {
      sec = `0${sec}`;
    }
    return `${min}:${sec}`;
  };

  const handleTimer = () => {
    if (!audioRef.current || Number.isNaN(audioRef.current.duration)) {
      setTimer('00:00 / 00:00');
      return;
    }
    const currentTime = formatSecondsAsTime(
      Math.floor(audioRef.current.currentTime)
    );
    const duration = formatSecondsAsTime(Math.floor(audioRef.current.duration));
    setTimer(`${currentTime} / ${duration}`);
  };

  return (
    <S.Container>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimer}
        preload="metadata"
        onLoadedMetadata={handleTimer}
      />
      <S.Timer>
        <span>{timer}</span>
      </S.Timer>
      <S.Buttons>
        <Button
          className="p-button-outlined"
          onClick={(e) => handleVolume(e, 'down')}
          disabled={!src}
          icon="pi pi-volume-down"
          tooltip="Diminuir volume"
          tooltipOptions={{
            position: 'top'
          }}
        />
        <Button
          className="p-button-outlined"
          onClick={handlePlayback}
          disabled={!src}
          icon={playerOn ? 'pi pi-pause' : 'pi pi-play'}
          tooltip={playerOn ? 'Pause' : 'Play'}
          tooltipOptions={{
            position: 'top'
          }}
        />
        <Button
          className="p-button-outlined"
          onClick={(e) => handleVolume(e, 'up')}
          disabled={!src}
          icon="pi pi-volume-up"
          tooltip="Aumentar volume"
          tooltipOptions={{
            position: 'top'
          }}
        />
        <Button
          className="p-button-outlined"
          onClick={(e) => handleVolume(e, 'mute')}
          disabled={!src || !playerOn}
          icon={`pi ${
            audioRef.current?.muted ? 'pi-volume-off' : 'pi-volume-up'
          }`}
          severity={audioRef.current?.muted ? 'danger' : 'warning'}
          tooltip="Silenciar"
          tooltipOptions={{
            position: 'top'
          }}
        >
          <FaVolumeMute />
        </Button>
      </S.Buttons>
    </S.Container>
  );
};

export { AudioPlayer };
