import { PageHeader } from 'components';
import {
  ActiveByPeriodChart,
  ActiveByPlanChart,
  CanceledAndActiveUsersAfter,
  CanceledOrActiveByPeriodChart,
  FinancialReport,
  PaymentAmount,
  SubscriptionsSummary
} from './components';
import * as S from './DashboardPage.styles';

const DashboardPage = () => (
  <S.ContainerFluid>
    <S.Row className="height">
      <S.Container>
        <PageHeader title="Dashboard" />

        <S.ChartContainer>
          <S.Row className="dashboard-page__financial">
            <FinancialReport />
            <PaymentAmount />
          </S.Row>

          <S.Row>
            <SubscriptionsSummary />
          </S.Row>

          <S.Row>
            <ActiveByPeriodChart />
          </S.Row>

          <S.Row>
            <ActiveByPlanChart />
          </S.Row>

          <S.Row>
            <CanceledAndActiveUsersAfter />
          </S.Row>

          <S.Row>
            <CanceledOrActiveByPeriodChart />
          </S.Row>
        </S.ChartContainer>
      </S.Container>
    </S.Row>
  </S.ContainerFluid>
);

export { DashboardPage };
