import { ColumnProps } from 'primereact/column';
import Subscriber from 'models/Subscriber';

const columns = [
  { field: 'cpf', header: 'CPF' },
  { field: 'userEmail', header: 'Email' },
  { field: 'phone', header: 'Telefone' },
  { field: 'status', header: 'Status' }
];

const getSubscriptionStatusBody = (subscriber: Subscriber) => {
  switch (subscriber.status) {
    case 'IN_TEST':
      return <span className="in-test">Em teste</span>;
    case 'ENABLED':
      return <span className="enabled">Ativa</span>;
    case 'OVERDUE':
      return <span className="disabled">Atrasada</span>;
    case 'CANCELED':
      return <span className="cancelled">Cancelada</span>;
    case 'DISABLED':
      return <span className="disabled">Desativada</span>;
    default:
      return null;
  }
};

const getEmailBody = (subscriber: Subscriber, isFullHd?: boolean) => {
  if (subscriber.userEmail) {
    return (
      <span title={subscriber.userEmail}>
        {isFullHd
          ? subscriber.userEmail
          : `${subscriber.userEmail.substring(0, 18)}...`}
      </span>
    );
  }
};

const getProps = (column: ColumnProps, isFullHd?: boolean): ColumnProps => {
  switch (column.field) {
    case 'cpf':
      return {
        filter: true,
        filterPlaceholder: 'Buscar',
        sortable: true
      };
    case 'userEmail':
      return {
        filter: true,
        filterPlaceholder: 'Buscar',
        body: (e) => getEmailBody(e, isFullHd),
        sortable: true
      };
    case 'phone':
      return {
        filter: true,
        filterPlaceholder: 'Buscar'
      };
    case 'status':
      return {
        body: getSubscriptionStatusBody,
        sortable: true,
        style: { width: '100px' }
      };
    default:
      return {};
  }
};

export default {
  columns,
  getProps
};
