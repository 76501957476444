import { Reducer } from 'redux';
import { SubscriberTypes, SubscriberState, SubscriberAction } from './types';
import { SUBSCRIBER_INITIAL_STATE } from './utils';

const reducer: Reducer<SubscriberState, SubscriberAction> = (
  state: SubscriberState = SUBSCRIBER_INITIAL_STATE,
  action: SubscriberAction
) => {
  switch (action.type) {
    case SubscriberTypes.FETCH_SUBSCRIBERS_SUCCESS: {
      return {
        ...state,
        all: action.payload.all
      };
    }
    case SubscriberTypes.FETCH_ONE_SUBSCRIBER_REQUEST: {
      return {
        ...state,
        id: action.payload.id,
        one: SUBSCRIBER_INITIAL_STATE.one
      };
    }
    case SubscriberTypes.FETCH_ONE_SUBSCRIBER_SUCCESS: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case SubscriberTypes.CREATE_SUBSCRIBER_REQUEST: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case SubscriberTypes.UPDATE_SUBSCRIBER_REQUEST: {
      return {
        ...state,
        one: action.payload.one
      };
    }
    case SubscriberTypes.FILTER_SUBSCRIBER_REQUEST: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case SubscriberTypes.FILTER_SUBSCRIBER_SUCCESS: {
      return {
        ...state,
        all: action.payload.all
      };
    }
    case SubscriberTypes.UPDATE_SUBSCRIBER_PLAN_REQUEST: {
      return {
        ...state,
        one: action.payload.one,
        newPlanId: action.payload.newPlanId
      };
    }
    case SubscriberTypes.UPDATE_SUBSCRIBER_PLAN_SUCCESS: {
      return {
        ...state,
        updatePlanError: false
      };
    }
    case SubscriberTypes.UPDATE_SUBSCRIBER_PLAN_ERROR: {
      return {
        ...state,
        updatePlanError: true
      };
    }
    case SubscriberTypes.CANCEL_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
