import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { showToast } from '../toast/actions';
import {
  fetchPlaylistCategorySuccess,
  fetchPlaylistCategoryError,
  editPlaylistCategoryError,
  editPlaylistCategorySuccess,
  savePlaylistCategorySuccess,
  savePlaylistCategoryError,
  fetchPlaylistCategoryRequest
} from './actions';
import { Action } from './index';

export function* fetchCategory() {
  try {
    const response: ConnectorResponse = yield call(
      Connector().get,
      'categories/list'
    );

    yield put(fetchPlaylistCategorySuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Categoria',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchPlaylistCategoryError());
  }
}

export function* editCategory(action: Action) {
  try {
    const { old } = action.payload;

    const data = {
      id: old.id,
      name: old.name,
      description: old.description,
      createdAt: old.createdAt,
      updatedAt: old.updatedAt,
      showInHome: old.showInHome,
      superCategory: {
        connect: {
          id: old.superCategoryId
        }
      }
    };

    yield call(Connector().put, `categories?id=${action.payload.old.id}`, data);

    yield put(
      showToast({
        summary: 'Categoria',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
    yield put(editPlaylistCategorySuccess());
    yield put(fetchPlaylistCategoryRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Categoria',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(editPlaylistCategoryError());
  }
}

export function* removeCategory(action: Action) {
  try {
    yield call(Connector().delete, `categories?id=${action.payload.old.id}`);

    yield put(
      showToast({
        summary: 'Categoria',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
    yield put(editPlaylistCategorySuccess());
    yield put(fetchPlaylistCategoryRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Categoria',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(editPlaylistCategoryError());
  }
}

export function* saveCategory(action: Action) {
  try {
    const { old } = action.payload;

    const data = {
      id: old.id,
      name: old.name,
      description: old.description,
      superCategoryId: old.superCategoryId,
      createdBy: { connect: { id: 1 } },
      superCategory: { connect: { id: old.superCategoryId } },
      showInHome: old.showInHome
    };

    yield call(Connector().post, 'categories', data);

    yield put(
      showToast({
        summary: 'Categoria',
        detail: 'Dado criado com sucesso',
        severity: 'success'
      })
    );
    yield put(savePlaylistCategorySuccess());
    yield put(fetchPlaylistCategoryRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Categoria',
        detail: 'Falha ao criar dado',
        severity: 'error'
      })
    );
    yield put(savePlaylistCategoryError());
  }
}
