/* eslint-disable class-methods-use-this */
/* eslint-disable no-continue */

// import { Filters } from 'models/Filters';
import FilterResponse from './types/FilterResponse';

class QueryBuilder {
  static cleanParam(param: string, key: string, mode: 'qs' | 'qobj' = 'qs') {
    if (param === null) return null;

    if (typeof param === 'string') {
      if (param === '' || param.trim() === '') {
        return null;
      }

      return (
        param
          .trimStart()
          .trimEnd()
          // .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      );
    }
    if (typeof param === 'boolean') {
      if (mode === 'qs') {
        return param ? 'true' : 'false';
      }
      return param;
    }
    if (typeof param === 'number') {
      return param;
    }
    if (Object.prototype.toString.call(param) === '[object Date]') {
      const date = param as Date;
      return date.toISOString();
    }
    // console.log(`[QueryBuilder]: Fazendo skip no campo "${key}" pois seu tipo é desconhecido!`);
    return null;
  }

  qs(params: any) {
    const keys = Object.keys(params);

    let query = '';

    for (let i = 0; i < keys.length; i += 1) {
      const paramCleaned = QueryBuilder.cleanParam(
        params[keys[i]],
        keys[i],
        'qs'
      );

      if (paramCleaned === null) continue;

      if (i === 0) {
        query = `?${keys[i]}=${paramCleaned}`;
      } else {
        query += `&${keys[i]}=${paramCleaned}`;
      }
    }

    return query;
  }

  qobj(params: Record<string, FilterResponse>, objectContainerName?: string) {
    const keys = Object.keys(params);

    const obj: Record<string, any> = {};

    for (let i = 0; i < keys.length; i += 1) {
      if (!params[keys[i]].enable) {
        continue;
      }

      const { value } = params[keys[i]];

      if (keys[i] === 'CreatedAt' && value.length === 2) {
        const paramCleaned1 = QueryBuilder.cleanParam(
          params[keys[i]].value[0],
          keys[i],
          'qobj'
        );

        const paramCleaned2 = QueryBuilder.cleanParam(
          params[keys[i]].value[1],
          keys[i],
          'qobj'
        );

        if (paramCleaned1 === null || paramCleaned2 === null) continue;

        Object.assign(obj, { CreatedAtFrom: paramCleaned1 });
        Object.assign(obj, { CreatedAtTo: paramCleaned2 });
      } else {
        const paramCleaned = QueryBuilder.cleanParam(
          params[keys[i]].value,
          keys[i],
          'qobj'
        );

        if (paramCleaned === null) continue;

        Object.assign(obj, { [keys[i]]: paramCleaned });
      }
    }

    if (objectContainerName) {
      return { [objectContainerName]: obj };
    }
    return obj;
  }
}

export default new QueryBuilder();
