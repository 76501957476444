import 'styled-components';

const globalTheme = {
  yellow: '#DBB200',
  yellowDarker: '#b99200',
  blue: '#272F4C',
  blueLighter: '#525970',
  blueDarker: '#131827',
  blue4: '#1f2d40',
  green: '#00944A',
  red: '#A71D1D',

  navigator: {
    link: '#F3F3F3',
    hover: '#DBB200'
  },

  size: {
    h1: '28px',
    h2: '24px',
    h3: '20px',
    h4: '18px',
    p: {
      small: '12px',
      medium: '14px',
      big: '18px'
    }
  },
  weight: {
    bold: '700',
    regular: '500',
    light: '300'
  },

  progressBar: {
    bg: 'rgba(0,0,0,0.3)',
    status: '#DBB200',
    color: '#F3F3F3'
  },

  chart: {
    bg: {
      blue1: '#42A5F5',
      blue2: '#001eff',
      blue3: '#0095ff',
      blue4: '#a1a8fe',
      blue5: '#dcc2fc',
      blue6: '#ffe2ff',
      green1: '#00e8c5',
      green2: '#45e4a6',
      green3: '#6adf85',
      green4: '#8bd964',
      green5: '#66BB6A',
      red1: '#ff4838',
      red2: '#ff0059',
      red3: '#ff5862',
      orange1: '#ff7e17',
      orange2: '#ff7543',
      orange3: '#ff9024',
      orange4: '#ffa600',
      pink1: '#b400d8',
      pink2: '#ff1aab',
      pink3: '#ff0080',
      pink4: '#ff3785',
      pink5: '#f100ac',
      pink6: '#ff24d4',
      pink7: '#ff43fc',
      yellow1: '#ffa600'
    },
    bghover: {
      blue1: '#64B5F6',
      blue2: '#001299',
      blue3: '#0070bf',
      blue4: '#8a90d5',
      blue5: '#ccb6e8',
      blue6: '#ffe3ff',
      green1: '#00b096',
      green2: '#30b583',
      green3: '#53b86c',
      green4: '#74ba53',
      green5: '#81C784',
      red1: '#ff4c3a',
      red2: '#f20052',
      red3: '#ff4e55',
      orange1: '#ff7c20',
      orange2: '#ff6c3e',
      orange3: '#ff8926',
      orange4: '#FFB74D',
      pink1: '#780090',
      pink2: '#e3207f',
      pink3: '#d7006a',
      pink4: '#f8336a',
      pink5: '#af007f',
      pink6: '#c61f91',
      pink7: '#a12a9f',
      yellow1: '#ffa600'
    }
  }
};

export const darkTheme = {
  ...globalTheme,
  background: '#131827',
  shadow: '#0C0F17',

  userAvatar: '#FFF',

  text: {
    primary: '#F3F3F3',
    secondary: '#B0B4C4'
  },

  section: {
    bg: '#1f2d40'
  },

  btn: {
    primary: {
      color: '#272F4C',
      bg: '#DBB200'
    },
    secondary: {
      color: '#F3F3F3',
      bg: '#343a40'
    },
    info: {
      color: '#F3F3F3',
      bg: '#131827',
      hover: '#272F4C'
    },
    disabled: {
      bg: '#272F4C',
      color: '#131827'
    }
  },

  sidebarTable: {
    color: '#F3F3F3',
    bg: '#343a40'
  },

  scroll: {
    color: '#DBB200',
    hover: '#D88000'
  },

  table: {
    border: '#B0B4C4',
    title: '#AAA'
  },

  dndt: {
    hover: 'rgba(19, 24, 39, 0.4)',
    focus: 'rgba(219, 178, 0, 0.4)'
  },

  toggler: {
    label: {
      regular: '#525970',
      active: '#F3F3F3'
    },
    slider: '#131827'
  },

  radio: {
    border: '#525970',
    bg: {
      checked: '#DBB200',
      hover: '#525970'
    }
  },

  select: {
    arrow: {
      regular: '%23DBB200',
      hover: '%23525970'
    }
  },

  cards: {
    percent: 'rgba(0,0,0,0.3)',
    monetary: 'rgba(0,0,0,0.6)',

    duration: {
      border: '#525970'
    }
  },

  calendar: {
    border: '#272F4C'
  },

  search: {
    border: {
      color: '#B0B4C4',
      hover: '#F3F3F3'
    },
    placeholder: '#B0B4C4',
    bg: '#272F4C'
  },

  dialog: {
    header: {
      bg: '#272F4C',
      color: '#F3F3F3'
    },
    content: {
      bg: '#525970',
      color: '#F3F3F3'
    },
    border: '#131827',
    borderAlt: '#272F4C'
  },

  checkbox: {
    background: '#131827',
    border: '#999',
    hover: {
      bg: '#DBB200',
      border: '#DBB200'
    }
  },

  multiselect: {
    item: {
      hover: 'rgb(255,255,255,0.1)',
      hl: {
        color: '#F3F3F3',
        bg: '#272F4C'
      }
    },
    token: {
      bg: '#DBB200'
    },
    color: '#272F4C',
    hover: '#b99200',
    search: '#131827'
  },

  tree: {
    focus: {
      color: '#F3F3F3',
      bg: '#272F4C'
    }
  },

  dropdown: {
    bg: {
      color: '#272F4C',
      hover: '#525970',
      focus: '#DBB200'
    },
    text: {
      color: '#F3F3F3',
      hover: '#272F4C'
    },
    border: '#525970'
  },

  paginator: {
    bg: {
      color: '#272F4C',
      hover: '#525970',
      focus: '#DBB200'
    },
    text: {
      color: '#F3F3F3',
      hover: '#272F4C'
    }
  },

  chart: {
    ...globalTheme.chart,
    grid: '#4d5573'
  },

  toast: {
    success: 'rgba(0, 112, 56,1)',
    info: '#1d95f5',
    error: 'rgba(167,29,29,1)',
    warning: 'rgba(173, 144, 17,1)',
    text: '#FFFFFF'
  }
};

export const lightTheme = {
  ...globalTheme,
  background: '#F3F3F3',
  shadow: '#e8e8e8',

  userAvatar: '#525970',

  text: {
    primary: '#222',
    secondary: '#525970'
  },

  section: {
    bg: '#fff'
  },

  btn: {
    primary: {
      color: '#FFFFFF',
      bg: '#272F4C'
    },
    secondary: {
      color: '#272F4C',
      bg: '#DBB200'
    },
    info: {
      color: '#272F4C',
      bg: '#F3F3F3',
      hover: '#DBB200'
    },
    disabled: {
      bg: '#B0B4C4',
      color: '#F3F3F3'
    }
  },

  sidebarTable: {
    color: '#FFFFFF',
    bg: '#272F4C'
  },

  scroll: {
    color: '#525970',
    hover: '#272F4C'
  },

  table: {
    border: '#DDD',
    title: '#999'
  },

  dndt: {
    hover: 'rgba(19, 24, 39, 0.1)',
    focus: 'rgba(219, 178, 0, 0.4)'
  },

  toggler: {
    label: {
      regular: '#BBB',
      active: '#222'
    },
    slider: '#F3F3F3'
  },

  radio: {
    border: '#525970',
    bg: {
      checked: '#272F4C',
      hover: '#525970'
    }
  },

  select: {
    arrow: {
      regular: '%23272F4C',
      hover: '%23BBBBBB'
    }
  },

  cards: {
    percent: 'rgba(0,0,0,0.3)',
    monetary: 'rgba(0,0,0,0.6)',

    duration: {
      border: '#DDD'
    }
  },

  calendar: {
    border: '#DDD'
  },

  search: {
    border: {
      color: '#B0B4C4',
      hover: '#DBB200'
    },
    placeholder: '#B0B4C4',
    bg: '#FFF'
  },

  dialog: {
    header: {
      bg: '#F3F3F3',
      color: '#222'
    },
    content: {
      bg: '#FFF',
      color: '#333'
    },
    border: '#CCC',
    borderAlt: '#CCC'
  },

  checkbox: {
    background: '#f3f3f3',
    border: '#AAA',
    hover: {
      bg: '#DBB200',
      border: '#DBB200'
    }
  },

  multiselect: {
    item: {
      hover: 'rgb(0,0,0,0.05)',
      hl: {
        color: '#333',
        bg: 'rgb(0,0,0,0.1)'
      }
    },
    token: {
      bg: '#272F4C'
    },
    color: '#F3F3F3',
    hover: '#525970',
    search: '#F3F3F3'
  },

  tree: {
    focus: {
      color: '#333',
      bg: 'rgb(0,0,0,0.2)'
    }
  },

  dropdown: {
    bg: {
      color: '#F3F3F3',
      hover: '#272F4C',
      focus: '#DBB200'
    },
    text: {
      color: '#272F4C',
      hover: '#F3F3F3'
    },
    border: '#CCC'
  },

  paginator: {
    bg: {
      color: '#F3F3F3',
      hover: '#272F4C',
      focus: '#DBB200'
    },
    text: {
      color: '#272F4C',
      hover: '#F3F3F3'
    }
  },

  chart: {
    ...globalTheme.chart,
    grid: '#CCC'
  },

  toast: {
    success: 'rgba(0,148,74,1)',
    info: '#42A5F5',
    error: 'rgba(240, 17, 17,1)',
    warning: 'rgba(219,178,0,1)',
    text: '#FFFFFF'
  }
};

export type LightThemeType = typeof lightTheme;
export type DarkThemeType = typeof darkTheme;

declare module 'styled-components' {
  export interface DefaultTheme extends LightThemeType, DarkThemeType {}
}
