import { useState, useEffect, ChangeEvent } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';
import FileService from 'services/FileService';
import * as Yup from 'yup';
import Audio from 'models/Audio';
import { FileStatus } from 'models/FileStatus';
import {
  downloadAudioRequest,
  editAudioRequest
} from 'store/ducks/audio/actions';
import { closeDrawer } from 'store/ducks/drawer/actions';
import { showToast } from 'store/ducks/toast/actions';
import { AudioPlayer, Button, InputFile, ProgressUpload } from 'components';
import { InputText, InputTextarea, InputRadio } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import audioValidate from 'helpers/validation/audio';
import * as S from './EditAudioSidebar.styles';

const EditAudioSidebar = () => {
  const { parentId } = useSelector(
    (state: ApplicationState) => state.currentFolder
  );
  const { audio: stateAudio, audioUrl } = useSelector(
    (state: ApplicationState) => state.audio
  );

  const [fileSubstitute, setFileSubstitute] = useState<File | null>(null);
  const [visibleProgressBar, setVisibleProgressBar] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [audio, setAudio] = useState<Audio>(stateAudio);
  const [url, setURL] = useState(audioUrl);
  const [fileLabel, setFileLabel] = useState(audio.fileName);

  const dispatch = useDispatch();

  useEffect(() => {
    setAudio(stateAudio);
    setFileLabel(stateAudio.fileName);
  }, [stateAudio]);

  useEffect(() => {
    setURL(audioUrl);
  }, [audioUrl]);

  useEffect(() => {
    if (audio.id && audio.id > 0) {
      dispatch(downloadAudioRequest(audio.id));
    }
  }, [audio.id]);

  const handleConfirmPress = async () => {
    try {
      audioValidate(audio);

      if (fileSubstitute !== null) {
        setVisibleProgressBar(true);

        await new FileService()
          .uploadAudio(fileSubstitute, audio.id, setProgressValue)
          .then((e) => {
            dispatch(editAudioRequest({ ...audio, parentId }));
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => {
            setVisibleProgressBar(false);
          });
      } else {
        dispatch(editAudioRequest({ ...audio, parentId }));
      }

      dispatch(closeDrawer());
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const handleCancelPress = () => {
    dispatch(closeDrawer());
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFile = e.target.files[0];
      setFileSubstitute(newFile);
      setFileLabel(newFile.name);
      const newURL = URL.createObjectURL(newFile);
      setURL(newURL);
    }
  };

  return (
    <>
      <ProgressUpload
        progressValue={progressValue}
        visibleProgressBar={visibleProgressBar}
      />

      <S.Form>
        <InputText
          label="Título"
          name="title"
          type="text"
          value={audio.title}
          onChange={(e) =>
            setAudio({
              ...audio,
              title: e.target.value
            })
          }
        />

        <InputText
          label="Descrição"
          name="description"
          type="text"
          value={audio.description}
          onChange={(e) =>
            setAudio({
              ...audio,
              description: e.target.value
            })
          }
        />

        <InputTextarea
          label="Transcrição"
          name="transcription"
          rows={5}
          cols={30}
          value={audio.transcription}
          onChange={(e) =>
            setAudio({
              ...audio,
              transcription: e.currentTarget.value
            })
          }
        />

        <S.InputRadioGroup>
          <span className="radioGroupLabel">Status</span>

          <InputRadio
            label="Ativo"
            name="status"
            value="active"
            onClick={() =>
              setAudio({
                ...audio,
                status: FileStatus.ENABLED
              })
            }
            checked={audio.status === FileStatus.ENABLED}
          />

          <InputRadio
            label="Inativo"
            name="status"
            value="inactive"
            onClick={() =>
              setAudio({
                ...audio,
                status: FileStatus.DISABLED
              })
            }
            checked={audio.status === FileStatus.DISABLED}
          />
        </S.InputRadioGroup>

        <S.FileUploadWrapper>
          <InputFile
            label={fileLabel}
            name="FileUpload"
            onFileChange={onFileChange}
            accept="audio/*"
          />
        </S.FileUploadWrapper>

        <AudioPlayer src={url} />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </>
  );
};

export { EditAudioSidebar };
