import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuizAlternative, QuizQuestion } from 'models/Quiz';
import { fetchOneQuizQuestionsRequest } from 'store/ducks/quizQuestions/actions';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './QuizAlternativeCardTable.styles';

interface QuizAlternativeCardTableProps {
  data: QuizQuestion;
  onClick: (data: QuizAlternative) => void;
}
const QuizAlternativeCardTable = (props: QuizAlternativeCardTableProps) => {
  const { data, onClick } = props;

  const dispatch = useDispatch();

  const question = useSelector(
    (state: ApplicationState) => state.quizQuestions.one
  );

  const [alternatives, setAlternatives] = useState<QuizAlternative[]>(
    question.quizAlternative
  );

  useEffect(() => {
    dispatch(fetchOneQuizQuestionsRequest(data.id));
  }, [dispatch]);

  useEffect(() => {
    setAlternatives(question.quizAlternative);
  }, [question]);

  const handleRowClick = (alternative: QuizAlternative) => {
    onClick(alternative);
  };

  return (
    <S.TableContainer>
      {alternatives &&
        alternatives.map((alt) => (
          <div
            key={alt.id}
            tabIndex={0}
            role="button"
            className="qa-card"
            onClick={() => handleRowClick(alt)}
            onKeyDown={() => handleRowClick(alt)}
          >
            <div className="qa-card__id">
              <span className="qa-card__label">ID</span>
              <p className="qa-card__content">{alt.id}</p>
            </div>
            <div className="qa-card__answer">
              <span className="qa-card__label">Resposta</span>
              <p className="qa-card__content">{alt.answer}</p>
            </div>
            <div className="qa-card__isCorrect">
              <span className="qa-card__label">É correta?</span>
              <p className="qa-card__content">
                {alt.isCorrect ? 'Sim' : 'Não'}
              </p>
            </div>
          </div>
        ))}
    </S.TableContainer>
  );
};

export { QuizAlternativeCardTable };
