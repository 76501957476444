import styled from 'styled-components';
import { ContentLayout } from 'components';
import { H1 } from 'components/Styled/Text';
import * as Breakpoint from 'styles/breakpoints';

export const ContainerFluid = styled.div`
  ${Breakpoint.Fluid};
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  height: 100%;

  &.headerRow {
    margin-bottom: 32px;
  }

  &.noHeight {
    height: auto;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &.addButtonColumn {
    align-items: flex-end;
  }

  &.buttonGroup {
    align-items: flex-end;
    flex-grow: 1;

    button:disabled {
      display: none;
    }

    button:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const FormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.buttonGroup {
    align-items: flex-end;
    flex-grow: 1;

    button:disabled {
      display: none;
    }

    button:not(:last-child) {
      margin-right: 8px;
    }
  }

  .institutional__form {
    padding: 16px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.section.bg};
    box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    h2 {
      font-family: 'Mulish', sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    textarea {
      resize: none;
    }
  }
`;

export const Container = styled(ContentLayout)``;

export const Title = styled(H1)``;
