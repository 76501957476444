import { Reducer } from 'typesafe-actions';
import { AnalyticsAction, AnalyticsState, AnalyticsTypes } from './types';
import { ANALYTICS_INITIAL_STATE } from './utils';

export const reducer: Reducer<AnalyticsState, AnalyticsAction> = (
  state: AnalyticsState = ANALYTICS_INITIAL_STATE,
  action: AnalyticsAction
) => {
  switch (action.type) {
    case AnalyticsTypes.USAGE_PER_HOURS_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'USAGE_PER_HOURS']
      };
    }
    case AnalyticsTypes.USAGE_PER_HOURS_SUCCESS: {
      return {
        ...state,
        usagePerHours: action.payload.usagePerHours,
        loading: state.loading.filter((item) => item !== 'USAGE_PER_HOURS')
      };
    }
    case AnalyticsTypes.USAGE_PER_HOURS_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'USAGE_PER_HOURS')
      };
    }
    case AnalyticsTypes.PLAYLISTS_USAGE_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'PLAYLISTS_USAGE']
      };
    }
    case AnalyticsTypes.PLAYLISTS_USAGE_SUCCESS: {
      return {
        ...state,
        playlistsUsage: action.payload.playlistsUsage,
        loading: state.loading.filter((item) => item !== 'PLAYLISTS_USAGE')
      };
    }
    case AnalyticsTypes.PLAYLISTS_USAGE_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'PLAYLISTS_USAGE')
      };
    }
    case AnalyticsTypes.ACTIVE_USERS_BY_PLAN_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'ACTIVE_USERS_BY_PLAN']
      };
    }
    case AnalyticsTypes.ACTIVE_USERS_BY_PLAN_SUCCESS: {
      return {
        ...state,
        activeUsersByPlan: action.payload.activeUsersByPlan,
        loading: state.loading.filter((item) => item !== 'ACTIVE_USERS_BY_PLAN')
      };
    }
    case AnalyticsTypes.ACTIVE_USERS_BY_PLAN_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'ACTIVE_USERS_BY_PLAN')
      };
    }
    case AnalyticsTypes.ACTIVE_USERS_BY_PERIOD_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'ACTIVE_USERS_BY_PERIOD']
      };
    }
    case AnalyticsTypes.ACTIVE_USERS_BY_PERIOD_SUCCESS: {
      return {
        ...state,
        activeUsersByPeriod: action.payload.activeUsersByPeriod,
        loading: state.loading.filter(
          (item) => item !== 'ACTIVE_USERS_BY_PERIOD'
        )
      };
    }
    case AnalyticsTypes.ACTIVE_USERS_BY_PERIOD_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter(
          (item) => item !== 'ACTIVE_USERS_BY_PERIOD'
        )
      };
    }
    case AnalyticsTypes.CANCELED_OR_ACTIVE_PAYING_USERS_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'CANCELED_OR_ACTIVE_PAYING_USERS']
      };
    }
    case AnalyticsTypes.CANCELED_OR_ACTIVE_PAYING_USERS_SUCCESS: {
      return {
        ...state,
        canceledOrActivePayingUsers: action.payload.canceledOrActivePayingUsers,
        loading: state.loading.filter(
          (item) => item !== 'CANCELED_OR_ACTIVE_PAYING_USERS'
        )
      };
    }
    case AnalyticsTypes.CANCELED_OR_ACTIVE_PAYING_USERS_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter(
          (item) => item !== 'CANCELED_OR_ACTIVE_PAYING_USERS'
        )
      };
    }
    case AnalyticsTypes.CANCELED_AND_ACTIVE_USERS_AFTER_TEST_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'CANCELED_AND_ACTIVE_USERS_AFTER_TEST']
      };
    }
    case AnalyticsTypes.CANCELED_AND_ACTIVE_USERS_AFTER_TEST_SUCCESS: {
      return {
        ...state,
        canceledAndActiveUsersAfterTest:
          action.payload.canceledAndActiveUsersAfterTest,
        loading: state.loading.filter(
          (item) => item !== 'CANCELED_AND_ACTIVE_USERS_AFTER_TEST'
        )
      };
    }
    case AnalyticsTypes.CANCELED_AND_ACTIVE_USERS_AFTER_TEST_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter(
          (item) => item !== 'CANCELED_AND_ACTIVE_USERS_AFTER_TEST'
        )
      };
    }
    case AnalyticsTypes.CANCELED_USERS_AFTER_TEST_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'CANCELED_USERS_AFTER_TEST']
      };
    }
    case AnalyticsTypes.CANCELED_USERS_AFTER_TEST_SUCCESS: {
      return {
        ...state,
        canceledUsersAfterTest: action.payload.canceledUsersAfterTest,
        loading: state.loading.filter(
          (item) => item !== 'CANCELED_USERS_AFTER_TEST'
        )
      };
    }
    case AnalyticsTypes.CANCELED_USERS_AFTER_TEST_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter(
          (item) => item !== 'CANCELED_USERS_AFTER_TEST'
        )
      };
    }
    case AnalyticsTypes.USERS_IN_PROMOTION_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'USERS_IN_PROMOTION']
      };
    }
    case AnalyticsTypes.USERS_IN_PROMOTION_SUCCESS: {
      return {
        ...state,
        usersInPromotion: action.payload.usersInPromotion,
        loading: state.loading.filter((item) => item !== 'USERS_IN_PROMOTION')
      };
    }
    case AnalyticsTypes.USERS_IN_PROMOTION_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'USERS_IN_PROMOTION')
      };
    }
    case AnalyticsTypes.USERS_IN_TEST_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'USERS_IN_TEST']
      };
    }
    case AnalyticsTypes.USERS_IN_TEST_SUCCESS: {
      return {
        ...state,
        usersInTest: action.payload.usersInTest,
        loading: state.loading.filter((item) => item !== 'USERS_IN_TEST')
      };
    }
    case AnalyticsTypes.USERS_IN_TEST_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'USERS_IN_TEST')
      };
    }
    case AnalyticsTypes.PAYMENT_AMOUNT_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'PAYMENT_AMOUNT']
      };
    }
    case AnalyticsTypes.PAYMENT_AMOUNT_SUCCESS: {
      return {
        ...state,
        paymentAmount: action.payload.paymentAmount,
        loading: state.loading.filter((item) => item !== 'PAYMENT_AMOUNT')
      };
    }
    case AnalyticsTypes.PAYMENT_AMOUNT_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'PAYMENT_AMOUNT')
      };
    }
    case AnalyticsTypes.FINANCIAL_REPORT_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'FINANCIAL_REPORT']
      };
    }
    case AnalyticsTypes.FINANCIAL_REPORT_SUCCESS: {
      return {
        ...state,
        financialReport: action.payload.financialReport,
        loading: state.loading.filter((item) => item !== 'FINANCIAL_REPORT')
      };
    }
    case AnalyticsTypes.FINANCIAL_REPORT_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'FINANCIAL_REPORT')
      };
    }
    case AnalyticsTypes.SUBSCRIPTIONS_SUMMARY_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'SUBSCRIPTIONS_SUMMARY']
      };
    }
    case AnalyticsTypes.SUBSCRIPTIONS_SUMMARY_SUCCESS: {
      return {
        ...state,
        subscriptionsSummary: action.payload.subscriptionsSummary,
        loading: state.loading.filter(
          (item) => item !== 'SUBSCRIPTIONS_SUMMARY'
        )
      };
    }
    case AnalyticsTypes.SUBSCRIPTIONS_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter(
          (item) => item !== 'SUBSCRIPTIONS_SUMMARY'
        )
      };
    }
    case AnalyticsTypes.ALL_SUBSCRIPTIONS_REQUEST: {
      return {
        ...state,
        loading: [...state.loading, 'ALL_SUBSCRIPTIONS']
      };
    }
    case AnalyticsTypes.ALL_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
        loading: state.loading.filter((item) => item !== 'ALL_SUBSCRIPTIONS')
      };
    }
    case AnalyticsTypes.ALL_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        loading: state.loading.filter((item) => item !== 'ALL_SUBSCRIPTIONS')
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
