import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Promotion from 'models/Promotion';
import { fetchPlanRequest } from 'store/ducks/plan/actions';
import {
  createPromotionRequest,
  editPromotionRequest,
  fetchPromotionsRequest
} from 'store/ducks/promotion/actions';
import { PROMOTION_PLAN_INITIAL_STATE } from 'store/ducks/promotion/utils';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { PromotionActionSidebar, PromotionDataTable } from './components';
import * as S from './styled';

const PromotionPage = () => {
  const dispatch = useDispatch();
  const { promotions } = useSelector(
    (state: ApplicationState) => state.promotions
  );

  const [promotionSelected, setPromotionSelected] = useState<Promotion>(
    PROMOTION_PLAN_INITIAL_STATE.promotion
  );
  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchPlanRequest());
    dispatch(fetchPromotionsRequest());
  }, [dispatch]);

  const handleItemPressed = (promotion: Promotion) => {
    setPromotionSelected(promotion);
    setDrawerMode('OPEN_EDIT');
  };

  const handleAddPressed = () => {
    setPromotionSelected(PROMOTION_PLAN_INITIAL_STATE.promotion);
    setDrawerMode('OPEN_ADD');
  };

  const handleSavePressed = (promotion: Promotion, planId: number) => {
    if (promotion.id && promotion.id !== 0) {
      dispatch(editPromotionRequest(promotion));
    } else {
      dispatch(createPromotionRequest(promotion, planId));
    }
    setPromotionSelected(PROMOTION_PLAN_INITIAL_STATE.promotion);
  };

  const getAddButton = () => (
    <Button
      type="button"
      severity="primary"
      size="big"
      onClick={handleAddPressed}
    >
      <FaPlus />
      Adicionar
    </Button>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <PromotionActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedPromotion={promotionSelected}
          onConfirmPress={handleSavePressed}
        />

        <S.Container>
          <PageHeader title="Promoções" actionsComponent={getAddButton()} />

          <PromotionDataTable
            promotions={promotions}
            onPressItem={handleItemPressed}
          />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { PromotionPage };
