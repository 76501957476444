import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Plan from 'models/Plan';
import {
  createPlanRequest,
  fetchPlanRequest,
  updatePlanRequest
} from 'store/ducks/plan/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { PlanActionSidebar, PlanDataTable } from './components';
import * as S from './PlanPage.styles';

const planInitialState: Plan = {
  id: 0,
  name: '',
  value: 0,
  description: '',
  status: 'ENABLED'
};

const PlanPage = () => {
  const dispatch = useDispatch();

  const plans = useSelector((state: ApplicationState) => state.plan.plans);

  const [selectedPlan, setSelectedPlan] = useState<Plan>(planInitialState);

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  useEffect(() => {
    dispatch(fetchPlanRequest());
  }, [dispatch]);

  // Handlers de estado
  const handleSavePressed = (plan: Plan) => {
    if (plan.id && plan.id !== 0) {
      dispatch(updatePlanRequest(plan));
    } else {
      dispatch(createPlanRequest(plan));
    }
    setSelectedPlan(planInitialState);
  };

  const handleEditPressed = (data: Plan) => {
    setSelectedPlan(data);
    setDrawerMode('OPEN_EDIT');
  };

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <PlanActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          selectedPlan={selectedPlan}
          onConfirmPress={handleSavePressed}
        />

        <S.Container>
          <PageHeader title="Planos" />

          <PlanDataTable data={plans} onPressItem={handleEditPressed} />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { PlanPage };
