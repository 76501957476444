import classNames from 'classnames';
import { TagProps } from './interfaces/TagProps';
import * as S from './Tag.styles';

const Tag = (props: TagProps) => {
  const { size, ...options } = props;

  return (
    <S.TagWrapper
      className={classNames({
        'p-tag-sm': size === 'small',
        'p-tag-bg': size === 'big'
      })}
      {...options}
    />
  );
};

Tag.defaultProps = {
  size: 'small'
};

export { Tag };
