import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Testimonial } from 'models/Institutional';
import ApplicationState from 'helpers/types/ApplicationState';
import { INITIAL_STATE } from '../../HomePage.utils';
import * as S from './HomeTestimonials.styles';
import { HomeTestimonialsForm } from './HomeTestimonialsForm';

const HomeTestimonials = () => {
  const { testimonials } = useSelector(
    (state: ApplicationState) => state.institutionalHome
  );

  const [testimonial, setTestimonial] = useState<Testimonial>(
    INITIAL_STATE.testimonial
  );

  const [action, setAction] = useState<'create' | 'update'>('create');

  const handleItemDoubleClick = (item: Testimonial) => {
    setTestimonial(item);
    setAction('update');
  };

  return (
    <S.TestimonialsWrapper className="institutional__form">
      <h2>Depoimentos</h2>

      <S.TestimonialsList>
        {testimonials
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <S.TestimonialItem
              key={item.id}
              title="Clique duas vezes para editar"
              onDoubleClick={() => handleItemDoubleClick(item)}
            >
              <S.TestimonialItemAvatar>
                <img src={item.avatarUrl} alt="Ícone do item" />
              </S.TestimonialItemAvatar>
              <S.TestimonialItemText>
                <p className="item__contact">{item.contact}</p>
                <p className="item__message">{item.message}</p>
              </S.TestimonialItemText>
            </S.TestimonialItem>
          ))}
      </S.TestimonialsList>

      <HomeTestimonialsForm
        action={action}
        onActionChange={setAction}
        selectedTestimonial={testimonial}
        onSelectedTestimonialChange={setTestimonial}
      />
    </S.TestimonialsWrapper>
  );
};

export { HomeTestimonials };
