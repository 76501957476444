import styled from 'styled-components';

export const MultiSelectContainer = styled.div`
  display: flex;
  width: 100%;

  &.p-multiselect-container-row {
    flex-direction: row;
    align-items: center;

    .p-multiselect {
      flex-grow: 1;
    }

    .p-multiselect-container-label {
      margin-right: 12px;
    }
  }

  &.p-multiselect-container-column {
    flex-direction: column;

    .p-multiselect {
      width: 100%;
    }

    .p-multiselect-container-label {
      margin-bottom: 12px;
    }
  }
`;

export const Label = styled.span`
  font-weight: 600;
  font-family: 'Mulish';
  font-size: 14px;
  text-transform: uppercase;
`;
