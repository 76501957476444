/* eslint-disable radix */
import * as yup from 'yup';
import { UserApp, UserAdmin } from 'models/User';
import PasswordChecklistRules from 'helpers/types/PasswordChecklistRules';
import { validateCpf } from 'utils/validateCpf';

const userSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required('O campo nome é obrigatório'),
  email: yup.string().required('O campo email é obrigatório'),
  isAdmin: yup.boolean().required('O campo nível de acesso é obrigatório')
});

export const userValidation = (
  user: UserApp | UserAdmin,
  shouldValidatePassword: boolean,
  passwordAgain: string,
  passwordRules: PasswordChecklistRules,
  shouldValidateCpf = false
) => {
  const { ValidationError } = yup;
  const errors = [];

  try {
    userSchema.validateSync(user, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (shouldValidateCpf) {
    if (!user.cpf) {
      errors.push(
        new ValidationError('O campo CPF é obrigatório', '', 'password')
      );
    } else if (!validateCpf(user.cpf)) {
      errors.push(new ValidationError('O CPF digitado é inválido', '', 'cpf'));
    }
  }

  if (shouldValidatePassword) {
    if (!user.password) {
      errors.push(
        new ValidationError('O campo nova senha é obrigatório', '', 'password')
      );
    }

    if (!passwordAgain) {
      errors.push(
        new ValidationError(
          'O campo confirmar a nova senha é obrigatório',
          '',
          'passwordAgain'
        )
      );
    }

    if (user.password && passwordAgain && !passwordRules.match) {
      errors.push(
        new ValidationError('As senhas não conferem', '', 'passwordsDontMatch')
      );
    }

    if (!passwordRules.minLength) {
      errors.push(
        new ValidationError(
          'A senha deve conter ao menos 8 caracteres',
          '',
          'passwordValidation'
        )
      );
    }

    if (!passwordRules.hasNumber) {
      errors.push(
        new ValidationError(
          'A senha deve conter ao menos 1 número',
          '',
          'passwordValidation'
        )
      );
    }
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};
