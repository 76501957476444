import { useState, useEffect } from 'react';
import Playlist from 'models/Playlist';
import { DragAndDrop } from 'components';
import * as S from './PlaylistDragrable.styles';

interface PlaylistDragrableProps {
  playlist: Playlist;
  onChangeMultiAudiosSelecteds: (audios: number[]) => void;
}

const PlaylistDragrable = (props: PlaylistDragrableProps) => {
  const { playlist: dtPlaylist, onChangeMultiAudiosSelecteds: onChange } =
    props;
  const [playlist, setPlaylist] = useState<Playlist>(dtPlaylist);

  useEffect(() => {
    setPlaylist(dtPlaylist);
  }, [dtPlaylist]);

  return (
    <S.Container>
      <DragAndDrop
        playlist={playlist}
        onChangeMultiAudiosSelecteds={onChange}
      />
    </S.Container>
  );
};

export { PlaylistDragrable };
