import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { closeDrawer } from 'store/ducks/drawer/actions';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './Drawer.styles';

const Drawer = () => {
  const { children, isOpen, title, onHide, className } = useSelector(
    (state: ApplicationState) => state.drawer
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDrawer());
  };

  const handleDrawerHide = () => {
    if (onHide) {
      onHide();
    }
    handleClose();
  };

  return (
    <Sidebar
      className={classNames(className, 'p-drawer')}
      position="right"
      visible={isOpen}
      onHide={handleDrawerHide}
      blockScroll
      closeOnEscape
      showCloseIcon={false}
    >
      <S.DrawerHeader>
        <h2>{title}</h2>

        <Button
          className="p-button-rounded p-button-danger p-button-outlined"
          icon="pi pi-times"
          onClick={handleClose}
        />
      </S.DrawerHeader>

      {children}
    </Sidebar>
  );
};

Drawer.defaultProps = {
  className: ''
};

export { Drawer };
