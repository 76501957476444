import styled from 'styled-components';
// import { DropdownBase } from 'components/DataTableLayout/DataTableLayout.styles.dropdown';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.buttonGroup {
    align-items: flex-end;
    flex-grow: 1;

    button:disabled {
      display: none;
    }

    button:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  label {
    margin-right: 10px;
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

/* ${DropdownBase}; */
export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  span {
    margin-right: 10px;
  }
`;

export const FilterElementsContainer = styled.div`
  display: flex;
`;
