import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Toast as PrToast } from 'primereact/toast';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './Toast.styles';

const Toast = () => {
  const toastRef = useRef<PrToast>(null);
  const { toast, showToast, dismissAll } = useSelector(
    (state: ApplicationState) => state.toast
  );

  useEffect(() => {
    if (showToast) {
      toastRef.current?.show({ ...toast });
    }
  }, [showToast, toast]);

  useEffect(() => {
    if (dismissAll) {
      toastRef.current?.clear();
    }
  }, [dismissAll]);

  return (
    <S.ToastWrapper>
      <PrToast ref={toastRef} position="bottom-right" />
    </S.ToastWrapper>
  );
};

export { Toast };
