import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { removeLoadingModule, addLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchPlaylistSuccess,
  fetchPlaylistError,
  editPlaylistSuccess,
  fetchPlaylistRequest,
  editPlaylistError,
  savePlaylistSuccess,
  savePlaylistError,
  removePlaylistSuccess,
  removePlaylistError,
  fetchOnePlaylistSuccess,
  fetchOnePlaylistError,
  editPlaylistOrdererError,
  editPlaylistOrdererSuccess,
  fetchAllFoldersSuccess,
  fetchAllFoldersError,
  addAudioToPlaylistSuccess,
  addAudioToPlaylistError,
  fetchOnePlaylistRequest,
  removeAudioFromPlaylistSuccess,
  removeAudioFromPlaylistError
} from './actions';
import { Action } from './index';

export function* fetchPlaylist() {
  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));

    const response: ConnectorResponse = yield call(
      Connector().get,
      'playlists/list'
    );

    const obj = response.data.map((dt: any) =>
      Object.assign(dt, { audios: [] })
    );

    yield put(fetchPlaylistSuccess(obj));
  } catch (err: any) {
    yield put(fetchPlaylistError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao carregar playlists',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* fetchOnePlaylist(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));

    const response: ConnectorResponse = yield call(
      Connector().get,
      `playlists?id=${action.payload.old.id}`
    );

    yield put(fetchOnePlaylistSuccess(response.data));
  } catch (err: any) {
    yield put(fetchOnePlaylistError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao carregar playlist',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* editPlaylist(action: Action) {
  const { id } = action.payload.old;

  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));

    const obj = action.payload.old as any;

    const conn = obj.categories.map((cat: { id: any }) => ({ id: cat.id }));

    Object.assign(obj, { categories: { connect: conn } });

    delete obj.id;
    delete obj.coverFilename;
    delete obj.coverUrl;
    delete obj.coverUrlFormat;
    delete obj.createdAt;
    delete obj.updatedAt;
    delete obj.removedAt;
    delete obj.removed;
    delete obj.download;
    delete obj.favorite;
    delete obj.quiz;
    delete obj.quiz;
    delete obj.status;
    delete obj.audios;

    Object.assign(obj, { audios: { connect: [] } });

    yield call(Connector().put, `playlists?id=${id}`, action.payload.old);

    yield put(editPlaylistSuccess());
    yield put(fetchPlaylistRequest());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Playlist atualizada com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(editPlaylistError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao atualizar playlist',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* editPlaylistOrderer(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));

    yield call(Connector().put, 'audio-playlist-order', action.payload.orderer);

    yield put(editPlaylistOrdererSuccess());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Ordem dos áudios atualizada com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(editPlaylistOrdererError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao editar ordem dos áudios',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* savePlaylist(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));

    const { old, cover } = action.payload;

    let categories = '';

    old.categories.forEach((category: any) => {
      categories = categories ? `${categories},${category.id}` : category.id;
    });

    const playlist = new FormData();

    playlist.append('name', old.name);
    playlist.append('description', old.description);
    playlist.append('categories', categories);
    playlist.append('cover', cover);

    const { data }: ConnectorResponse = yield call(
      Connector().post,
      'playlists',
      playlist
    );

    yield put(savePlaylistSuccess(data));
    yield put(fetchPlaylistRequest());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Playlist criada com sucesso',
        severity: 'success'
      })
    );
    yield put(fetchPlaylistRequest());
  } catch (err: any) {
    yield put(savePlaylistError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao criar playlist',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* removePlaylist(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));
    yield call(Connector().delete, `playlists?id=${action.payload.old.id}`);

    yield put(removePlaylistSuccess());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Playlist removida com sucesso',
        severity: 'success'
      })
    );
    yield put(fetchPlaylistRequest());
  } catch (err: any) {
    yield put(removePlaylistError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao remover playlist',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* fetchAllFolders() {
  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));
    const response: ConnectorResponse = yield call(
      Connector().get,
      'folders/list-to-playlist'
    );

    yield put(fetchAllFoldersSuccess(response.data));
  } catch (err: any) {
    yield put(fetchAllFoldersError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao carregar pastas',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* addAudioToPlaylist(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.PLAYLIST));
    const { playlist, audios } = action.payload.audioToPlaylist;

    yield call(
      Connector().put,
      `playlists/add-audio?id=${playlist.id}`,
      audios
    );

    yield put(addAudioToPlaylistSuccess());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Áudios importados com sucesso',
        severity: 'success'
      })
    );
    yield put(fetchOnePlaylistRequest(playlist));
  } catch (err: any) {
    yield put(addAudioToPlaylistError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao importar áudios',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}

export function* removeAudioFromPlaylist(action: Action) {
  try {
    const { playlist, audios } = action.payload.audioToPlaylist;

    yield call(
      Connector().put,
      `playlists/remove-audio?id=${playlist.id}`,
      audios
    );

    yield put(removeAudioFromPlaylistSuccess());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Áudios desvinculados com sucesso',
        severity: 'success'
      })
    );
    yield put(fetchOnePlaylistRequest(action.payload.audioToPlaylist.playlist));
  } catch (err: any) {
    yield put(removeAudioFromPlaylistError());
    yield put(
      showToast({
        summary: 'Playlist',
        detail: 'Falha ao desvincular áudios',
        severity: 'error'
      })
    );
  }
}
