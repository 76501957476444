/* eslint-disable no-new-object */
/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react';
import { FaFolderPlus, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from 'store/ducks/dialog/actions';
import { fetchTrashRequest } from 'store/ducks/trash/actions';
import * as SagasTrash from 'store/ducks/trash/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { TrashDataTable } from './components';
import * as S from './TrashPage.styles';

const TrashPage = () => {
  const dispatch = useDispatch();

  const trash = useSelector((state: ApplicationState) => state.trash.data);
  const [multiSelectionItems, setMultiSelectionItems] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchTrashRequest());
  }, [dispatch]);

  const isAudio = (e: any) => new Object(e).hasOwnProperty('title');

  const handleMultiRemovePressed = () => {
    const titleRemove = 'Remover Selecionados';
    const messageRemove = `Deseja realmente remover definitivamente todos os ${multiSelectionItems.length} itens selecionados?`;

    const remove = () =>
      Promise.all([
        multiSelectionItems.forEach((item) => {
          if (isAudio(item)) {
            return Promise.resolve(
              dispatch(SagasTrash.removeTrashAudioRequest(item))
            );
          }
          return Promise.resolve(
            dispatch(SagasTrash.removeTrashFolderRequest(item))
          );
        })
      ]).finally(() => setMultiSelectionItems([]));

    dispatch(
      showDialog({
        visible: true,
        title: titleRemove,
        message: messageRemove,
        mode: 'CONFIRM',
        onCancelPress: () => '',
        onConfirmPress: remove
      })
    );
  };

  const handleMultiRestorePressed = () => {
    const titleRestore = 'Restaurar Selecionados';
    const messageRestore = `Deseja realmente restaurar todos os ${multiSelectionItems.length} itens selecionados?`;

    const restore = () =>
      Promise.all([
        multiSelectionItems.forEach((item) => {
          if (isAudio(item)) {
            return Promise.resolve(
              dispatch(SagasTrash.restoreTrashAudioRequest(item))
            );
          }
          return Promise.resolve(
            dispatch(SagasTrash.restoreTrashFolderRequest(item))
          );
        })
      ]).finally(() => setMultiSelectionItems([]));

    dispatch(
      showDialog({
        visible: true,
        title: titleRestore,
        message: messageRestore,
        mode: 'CONFIRM',
        onCancelPress: () => '',
        onConfirmPress: restore
      })
    );
  };

  const getActionButtons = () => (
    <S.Row>
      <Button
        type="button"
        severity="primary"
        onClick={handleMultiRestorePressed}
        disabled={!multiSelectionItems.length}
      >
        <FaFolderPlus />
        Restaurar Selecionados
      </Button>

      <Button
        type="button"
        severity="danger"
        onClick={handleMultiRemovePressed}
        disabled={!multiSelectionItems.length}
      >
        <FaTrash />
        Remover Selecionados
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <S.Container>
          <PageHeader title="Lixeira" actionsComponent={getActionButtons()} />

          <TrashDataTable
            data={trash}
            onChangeMultipleSelecteds={setMultiSelectionItems}
            multipleSelecteds={multiSelectionItems}
          />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { TrashPage };
