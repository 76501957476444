/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

const getObjectPropertyViaPath = (item, path) => {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  const a = path.split('.');

  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in item) {
      item = item[k];
    } else {
      return;
    }
  }

  return item;
};

export default {
  getObjectPropertyViaPath
};
