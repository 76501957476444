import { NavigatorState } from './types';

const navigatorCollapsed = localStorage.getItem('navigatorCollapsed')
  ? localStorage.getItem('navigatorCollapsed') === 'true'
  : true;

export const NAVIGATOR_INITIAL_STATE: NavigatorState = {
  isCollapsed: navigatorCollapsed,
  navigatorWidth: navigatorCollapsed ? 0 : 277,
  permissions: []
};
