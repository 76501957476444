import styled from 'styled-components';
import { ContentLayout } from 'components';
import { DatePickerBase } from 'components/Form/DatePicker/DatePicker.styles';
import { H1 } from 'components/Styled/Text';
import * as Breakpoint from 'styles/breakpoints';

export const ContainerFluid = styled.div`
  ${Breakpoint.Fluid};
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  height: 100%;

  &.headerRow {
    margin-bottom: 32px;
  }

  &.noHeight {
    height: auto;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.buttonGroup {
    align-items: flex-end;
    flex-grow: 1;

    a:disabled {
      display: none;
    }

    a:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;

  ${DatePickerBase};

  input {
    padding: 4px;
    border: 1px solid ${({ theme }) => theme.table.border};

    &:first-of-type {
      margin-left: 10px;
    }

    &:last-of-type {
      margin-right: 10px;
    }
  }

  input,
  select {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text.primary};
    outline: 0;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    &:focus {
      box-shadow: 0;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }
`;

/* ${DropdownBase}; */
export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
`;

export const Container = styled(ContentLayout)``;

export const Title = styled(H1)``;
