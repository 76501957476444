import classNames from 'classnames';
import { Card } from 'primereact/card';
import { MiniCardProps } from './interfaces/MiniCardProps';
import * as S from './MiniCard.styles';

const MiniCard = (props: MiniCardProps) => {
  const {
    title,
    body,
    className,
    leftSeparator,
    rightSeparator,
    bottomSeparator
  } = props;

  return (
    <>
      {leftSeparator && <S.VerticalSeparator />}
      <S.Container>
        <Card title={title} className={classNames(className)}>
          <S.Body>{body}</S.Body>
        </Card>
      </S.Container>
      {rightSeparator && <S.VerticalSeparator />}
      {bottomSeparator && <S.HorizontalSeparator />}
    </>
  );
};

MiniCard.defaultProps = {
  className: undefined,
  leftSeparator: false,
  rightSeparator: false,
  bottomSeparator: false
};

export { MiniCard };
