import { useEffect, useState } from 'react';
import { FaArrowLeft, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Quiz, { QuizAlternative, QuizQuestion } from 'models/Quiz';
import { showDialog } from 'store/ducks/dialog/actions';
import {
  createQuizAlternativeRequest,
  deleteQuizAlternativeRequest,
  updateQuizAlternativeRequest
} from 'store/ducks/quizAlternatives/actions';
import {
  createQuizQuestionRequest,
  updateQuizQuestionRequest
} from 'store/ducks/quizQuestions/actions';
import {
  fetchQuizzesRequest,
  createQuizRequest,
  updateQuizRequest
} from 'store/ducks/quizzes/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import { QuizDisplayMode, QuizTitleMode } from 'helpers/types/QuizDisplayMode';
import {
  QuizAlternativeActionSidebar,
  QuizAlternativeCardTable,
  QuizPlaylistActionSidebar,
  QuizPlaylistDataTable,
  QuizQuestionActionSidebar,
  QuizQuestionDataTable
} from './components';
import {
  alternativeInitialState,
  quizInitialState,
  questionInitialState
} from './helpers/initialState';
import * as S from './QuizPage.styles';

const QuizPage = () => {
  const dispatch = useDispatch();

  const quizzes = useSelector((state: ApplicationState) => state.quizzes.datas);

  const [selectedQuiz, setSelectedQuiz] = useState<Quiz>(quizInitialState);
  const [selectedQuestion, setSelectedQuestion] =
    useState<QuizQuestion>(questionInitialState);
  const [selectedAlternative, setSelectedAlternative] =
    useState<QuizAlternative>(alternativeInitialState);

  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');
  const [displayMode, setDisplayMode] = useState<QuizDisplayMode>('QUIZZES');
  const [titleMode, setTitleMode] = useState<QuizTitleMode>('Quizzes');

  useEffect(() => {
    dispatch(fetchQuizzesRequest());
  }, [dispatch]);

  const handleQuizSavePressed = (quiz: Quiz) => {
    if (quiz.id && quiz.id !== 0) {
      dispatch(updateQuizRequest(quiz));
    } else {
      dispatch(createQuizRequest(quiz));
    }
    setSelectedQuiz(quizInitialState);
  };

  const handleQuestionSavePressed = (question: QuizQuestion) => {
    if (question.id && question.id !== 0) {
      dispatch(updateQuizQuestionRequest(question));
    } else {
      const data = { ...question, quizId: selectedQuiz.id };
      dispatch(createQuizQuestionRequest(data));
    }
    setSelectedQuestion(questionInitialState);
  };

  const handleAlternativeSavePressed = (alternative: QuizAlternative) => {
    if (alternative.id && alternative.id !== 0) {
      dispatch(updateQuizAlternativeRequest(alternative));
    } else {
      const data = { ...alternative, quizQuestionId: selectedQuestion.id };
      dispatch(createQuizAlternativeRequest(data));
    }
    setSelectedAlternative(alternativeInitialState);
  };

  const handleAlternativeRemovePressed = (alternative: QuizAlternative) => {
    const data = { ...alternative, quizQuestionId: selectedQuestion.id };

    dispatch(
      showDialog({
        visible: true,
        title: 'Remover quiz',
        message: `Deseja reamente remover a alternativa "${data.answer}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () => dispatch(deleteQuizAlternativeRequest(data))
      })
    );
  };

  const handleNavigation = () => {
    switch (displayMode) {
      case 'ALTERNATIVES':
        setDisplayMode('QUESTIONS');
        setTitleMode('Questões');
        break;
      case 'QUESTIONS':
        setDisplayMode('QUIZZES');
        setTitleMode('Quizzes');
        break;
      default:
        break;
    }
  };

  const handleAddPressed = () => {
    switch (displayMode) {
      case 'QUIZZES':
        setSelectedQuiz(quizInitialState);
        break;
      case 'QUESTIONS':
        setSelectedQuestion(questionInitialState);
        break;
      case 'ALTERNATIVES':
        setSelectedAlternative(alternativeInitialState);
        break;
      default:
        break;
    }
    setDrawerMode('OPEN_ADD');
  };

  const handleEditPressed = (data: Quiz | QuizQuestion) => {
    switch (displayMode) {
      case 'QUIZZES':
        setSelectedQuiz(data as Quiz);
        break;
      case 'QUESTIONS':
        setSelectedQuestion(data as QuizQuestion);
        break;
      default:
        break;
    }
    setDrawerMode('OPEN_EDIT');
  };

  const handleDoubleClick = (data: any) => {
    switch (displayMode) {
      case 'QUIZZES':
        setSelectedQuiz(data);
        setDisplayMode('QUESTIONS');
        setTitleMode('Questões');
        break;
      case 'QUESTIONS':
        setSelectedQuestion(data);
        setDisplayMode('ALTERNATIVES');
        setTitleMode('Alternativas');
        break;
      case 'ALTERNATIVES':
        setSelectedAlternative(data);
        setDrawerMode('OPEN_EDIT');
        break;
      default:
        break;
    }
  };

  const getBackButton = () => (
    <Button
      type="button"
      severity="back"
      onClick={() => handleNavigation()}
      disabled={displayMode === 'QUIZZES'}
    >
      <FaArrowLeft />
      Voltar
    </Button>
  );

  const getAddButton = () => (
    <S.Row>
      <Button
        type="button"
        severity="primary"
        size="big"
        onClick={handleAddPressed}
      >
        <FaPlus />
        Adicionar
      </Button>
    </S.Row>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        {displayMode === 'QUIZZES' && (
          <QuizPlaylistActionSidebar
            mode={drawerMode}
            setMode={setDrawerMode}
            selectedQuiz={selectedQuiz}
            onConfirmPress={handleQuizSavePressed}
          />
        )}
        {displayMode === 'QUESTIONS' && (
          <QuizQuestionActionSidebar
            mode={drawerMode}
            setMode={setDrawerMode}
            selectedQuestion={selectedQuestion}
            onConfirmPress={handleQuestionSavePressed}
          />
        )}
        {displayMode === 'ALTERNATIVES' && (
          <QuizAlternativeActionSidebar
            mode={drawerMode}
            setMode={setDrawerMode}
            selectedAlternative={selectedAlternative}
            onConfirmPress={handleAlternativeSavePressed}
            onDeletePress={handleAlternativeRemovePressed}
          />
        )}

        <S.Container>
          <PageHeader
            title={titleMode}
            breadcrumbsBottomComponent={getBackButton()}
            actionsComponent={getAddButton()}
          />

          {displayMode === 'QUIZZES' && (
            <QuizPlaylistDataTable
              data={quizzes}
              onPressItem={handleEditPressed}
              onDoubleClick={handleDoubleClick}
            />
          )}
          {displayMode === 'QUESTIONS' && (
            <QuizQuestionDataTable
              data={selectedQuiz}
              onPressItem={handleEditPressed}
              onDoubleClick={handleDoubleClick}
            />
          )}
          {displayMode === 'ALTERNATIVES' && (
            <QuizAlternativeCardTable
              data={selectedQuestion}
              onClick={handleDoubleClick}
            />
          )}
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { QuizPage };
