import styled from 'styled-components';
import { ChartStyles } from '../Chart.styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .p-chart {
      width: 70%;
      margin-right: 32px;
      margin-bottom: 32px;
    }
  }

  @media screen and (min-width: 1025px) {
    flex-direction: row;

    .p-chart {
      width: 30%;
      margin-right: 32px;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .p-card:not(:last-child) {
      margin-right: 12px;
    }
  }

  @media screen and (min-width: 1025px) {
    flex-direction: column;

    .p-card:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;
