import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchHomeData } from 'store/ducks/institutionalHome/actions';
import { PageHeader } from 'components';
import {
  HomeBannerForm,
  HomeDifferentialsForm,
  HomeTestimonials
} from './components';
import * as S from './HomePage.styles';

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHomeData());
  }, [dispatch]);

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <S.Container>
          <PageHeader title="Institucional - Página Inicial" />

          <S.FormsWrapper>
            <HomeBannerForm />
            <HomeDifferentialsForm />
            <HomeTestimonials />
          </S.FormsWrapper>
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { HomePage };
