import { Reducer } from 'redux';
import { NavigatorTypes, NavigatorState, NavigatorAction } from './types';
import { NAVIGATOR_INITIAL_STATE } from './utils';

const reducer: Reducer<NavigatorState, NavigatorAction> = (
  state: NavigatorState = NAVIGATOR_INITIAL_STATE,
  action: NavigatorAction
) => {
  switch (action.type) {
    case NavigatorTypes.SET_COLLAPSED: {
      return {
        ...state,
        isCollapsed: action.payload.isCollapsed
      };
    }
    case NavigatorTypes.SET_WIDTH: {
      return {
        ...state,
        navigatorWidth: action.payload.navigatorWidth
      };
    }
    case NavigatorTypes.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload.permissions
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
