import * as yup from 'yup';
import Promotion from 'models/Promotion';

const promotionSchema = yup.object().shape({
  description: yup
    .string()
    .required('O campo descrição é obrigatório')
    .nullable(),
  discount: yup.number().required('O campo desconto é obrigatório'),
  discountType: yup.number().required('O campo tipo de desconto é obrigatório'),
  promoPeriod: yup
    .number()
    .min(1, 'A validade do desconto deve ser de no minímo 1 dia')
    .required('O campo validade do desconto é obrigatório'),
  totalQuantity: yup.number().nullable()
});

export default (promotion: Promotion) =>
  promotionSchema.validateSync(promotion, { abortEarly: false });

export const promotionValidation = (
  promotion: Promotion,
  selectedPlanId: number | null,
  dates?: Date | Date[]
) => {
  const { ValidationError } = yup;
  const errors = [];

  if (selectedPlanId === 0) {
    errors.push(
      new ValidationError(
        'Selecione um plano para vincular a promoção',
        '',
        'promotionPlanId'
      )
    );
  }

  try {
    promotionSchema.validateSync(promotion, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (!Array.isArray(dates)) {
    errors.push(
      new ValidationError(
        'Selecione uma duração válida para a promoção',
        '',
        'duration'
      )
    );
  } else if (dates[0] && dates[1] === null) {
    errors.push(
      new ValidationError(
        'Selecione a data final para duração da promoção',
        '',
        'duration'
      )
    );
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};
