/* eslint-disable camelcase */
import { FormEvent, useEffect, useState } from 'react';
import { FaKey, FaSignInAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { LoginInfo } from 'models/Login';
import { loginRequest, logoutRequest } from 'store/ducks/login/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button } from 'components';
import { InputText } from 'components/Form';
import logo from 'assets/playlegis-logo-yellow.svg';
import ApplicationState from 'helpers/types/ApplicationState';
import loginValidate from 'helpers/validation/login';
import * as S from './LoginPage.styles';

type LoginPageProps = {
  type?: 'login' | 'logout';
};

const LoginPage = (props: LoginPageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = props;

  const modules = useSelector(
    (state: ApplicationState) => state.loading.modules
  );

  const [isLoading, setLoading] = useState(modules.length > 0);

  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    email: '',
    password: ''
  });

  useEffect(() => {
    setLoading(modules.length > 0);
  }, [modules]);

  useEffect(() => {
    if (type === 'logout') {
      dispatch(logoutRequest());
    }
  }, [dispatch, type]);

  const handleLoginPress = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    try {
      loginValidate(loginInfo);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    dispatch(loginRequest(loginInfo, navigate));
  };

  return (
    <S.Container>
      <S.FormWrapper method="post" onSubmit={(e) => handleLoginPress(e)}>
        <S.PlaylegisLogo>
          <img src={logo} alt="Logo da Playlegis" />
        </S.PlaylegisLogo>

        {type === 'logout' && (
          <S.LogoutMessage>
            Você finalizou sua sessão.
            <br />
            Para continuar, entre com seu usuário e senha.
          </S.LogoutMessage>
        )}

        <InputText
          label="Email"
          name="email"
          type="text"
          value={loginInfo.email}
          onChange={(e) =>
            setLoginInfo({
              ...loginInfo,
              email: e.target.value
            })
          }
          disabled={isLoading}
        />
        <InputText
          label="Senha"
          name="password"
          type="password"
          value={loginInfo.password}
          onChange={(e) =>
            setLoginInfo({
              ...loginInfo,
              password: e.target.value
            })
          }
          disabled={isLoading}
        />

        <S.ButtonGroup>
          <Button type="submit" severity="primary" size="big">
            <FaSignInAlt />
            Login
          </Button>

          <Button type="submit" severity="info" size="big" disabled>
            <FaKey />
            Esqueci a senha
          </Button>
        </S.ButtonGroup>
      </S.FormWrapper>
    </S.Container>
  );
};

LoginPage.defaultProps = {
  type: 'login'
};

export { LoginPage };
