import styled from 'styled-components';

export const PasswordChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 18px;
`;

export const PasswordChecklistDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.text.primary};

  &.description--has-error {
    color: ${({ theme }) => theme.red};
  }
`;
