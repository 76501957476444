import { Reducer } from 'redux';
import {
  InstitutionalNewsTypes,
  InstitutionalNewsState,
  InstitutionalNewsAction
} from './types';
import { NEWS_INITIAL_STATE } from './utils';

const reducer: Reducer<InstitutionalNewsState, InstitutionalNewsAction> = (
  state: InstitutionalNewsState = NEWS_INITIAL_STATE,
  action: InstitutionalNewsAction
) => {
  switch (action.type) {
    case InstitutionalNewsTypes.FETCH_NEWS_SUCCESS: {
      return {
        ...state,
        list: action.payload.list
      };
    }
    case InstitutionalNewsTypes.CREATE_NEWS_REQUEST: {
      return {
        ...state,
        article: action.payload.article
      };
    }
    case InstitutionalNewsTypes.UPDATE_NEWS_REQUEST: {
      return {
        ...state,
        article: action.payload.article
      };
    }
    case InstitutionalNewsTypes.DELETE_NEWS_REQUEST: {
      return {
        ...state,
        article: action.payload.article
      };
    }
    case InstitutionalNewsTypes.NEWS_REQUEST_SUCCESS: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    case InstitutionalNewsTypes.NEWS_REQUEST_ERROR: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
