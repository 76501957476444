import { Button } from 'primereact/button';
import styled from 'styled-components';

export const ButtonWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-items: center;
  color: #fff;
  font-size: 12px !important;
  font-family: 'Mulish', sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.2s, color 0.2s;

  svg,
  i {
    margin-right: 4px;
    line-height: initial;
  }

  &.p-button-back {
    color: ${({ theme }) => theme.btn.info.color};
    background-color: ${({ theme }) => theme.btn.info.bg};
    padding: 10px;
    margin: 10px 0 20px;
    width: fit-content;
    border-color: ${({ theme }) => theme.btn.info.color};

    &:hover {
      color: ${({ theme }) => theme.btn.info.hover}!important;
      background-color: transparent !important;
      border-color: ${({ theme }) => theme.btn.info.hover}!important;
    }

    &:disabled,
    &:disabled:hover {
      color: ${({ theme }) => theme.btn.disabled.bg};
      background-color: ${({ theme }) => theme.btn.info.bg};
      border-color: ${({ theme }) => theme.btn.disabled.bg}!important;
    }
  }

  &.p-button-close {
    color: ${({ theme }) => theme.btn.primary.bg};
    padding: 0px;
    font-size: 24px !important;

    &:hover {
      color: ${({ theme }) => theme.blueLighter};
    }
  }

  &.big {
    padding: 12px 24px;
    font-size: 14px !important;

    svg,
    i {
      font-size: 16px !important;
      margin-right: 8px;
    }
  }

  &.noMargin {
    svg,
    i {
      margin: 0px;
    }
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.4 !important;
  }

  & + .p-button {
    margin-left: 8px;
  }
`;
