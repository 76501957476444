import styled from 'styled-components';

export const InputMonetaryContainer = styled.div`
  display: flex;

  .input-text {
    width: 100%;
  }

  label {
    margin-left: 12px;
  }

  & + * {
    margin-top: 18px;
  }
`;
