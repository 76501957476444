import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import PlaylistCategory from 'models/PlaylistCategory';
import {
  fetchPlaylistCategoryRequest,
  editPlaylistCategoryRequest,
  savePlaylistCategoryRequest
} from 'store/ducks/playlistCategory/actions';
import { PageHeader, Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './CategoryPage.styles';
import { CategoryActionSidebar } from './components/ActionSidebar/CategoryActionSidebar';
import { CategoryDataTable } from './components/DataTable/CategoryDataTable';

const newCategory: PlaylistCategory = {
  id: 0,
  name: '',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  superCategoryId: 0,
  showInHome: false
};

const CategoryPage = () => {
  const dispatch = useDispatch();

  const [categorySelected, setCategorySelected] =
    useState<PlaylistCategory>(newCategory);
  const [drawerMode, setDrawerMode] = useState<DrawerMode>('CLOSE');

  const categories = useSelector(
    (state: ApplicationState) => state.playlistCategory.data
  );

  useEffect(() => {
    dispatch(fetchPlaylistCategoryRequest());
  }, [dispatch]);

  const handleItemPressed = (item: any) => {
    const category = item as PlaylistCategory;
    setCategorySelected(category);
    setDrawerMode('OPEN_EDIT');
  };

  const handleAddPressed = () => {
    setCategorySelected(newCategory);
    setDrawerMode('OPEN_ADD');
  };

  const handleSavePressed = (c: PlaylistCategory) => {
    if (c.id && c.id !== 0) {
      dispatch(editPlaylistCategoryRequest(c));
    } else {
      dispatch(savePlaylistCategoryRequest(c));
    }
    setCategorySelected({ ...newCategory });
  };

  const getAddButton = () => (
    <Button
      type="button"
      severity="primary"
      size="big"
      onClick={handleAddPressed}
    >
      <FaPlus />
      Adicionar
    </Button>
  );

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <CategoryActionSidebar
          mode={drawerMode}
          setMode={setDrawerMode}
          category={categorySelected}
          onConfirmPress={handleSavePressed}
        />

        <S.ContainerColumn>
          <PageHeader title="Categorias" actionsComponent={getAddButton()} />

          <CategoryDataTable
            data={categories}
            onPressItem={handleItemPressed}
          />
        </S.ContainerColumn>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { CategoryPage };
