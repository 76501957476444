import { Reducer } from 'redux';
import PlaylistCategory from 'models/PlaylistCategory';
import { PlaylistCategoryState, PlaylistCategoryTypes } from './types';

export type Action = {
  type: PlaylistCategoryTypes;
  payload: {
    data: PlaylistCategory[];
    old: PlaylistCategory;
  };
};

const INITIAL_STATE: PlaylistCategoryState = {
  data: [],
  old: {
    id: 0,
    name: '',
    description: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    superCategoryId: 0,
    showInHome: false
  }
};

const reducer: Reducer<PlaylistCategoryState, Action> = (
  state: PlaylistCategoryState = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case PlaylistCategoryTypes.FETCH_PLAYLIST_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case PlaylistCategoryTypes.EDIT_PLAYLIST_CATEGORY_REQUEST: {
      return {
        ...state,
        old: action.payload.old
      };
    }
    case PlaylistCategoryTypes.REMOVE_PLAYLIST_CATEGORY_REQUEST: {
      return {
        ...state,
        old: action.payload.old
      };
    }
    case PlaylistCategoryTypes.SAVE_PLAYLIST_CATEGORY_REQUEST: {
      return {
        ...state,
        old: action.payload.old
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
