import { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { ThemeContext } from 'styled-components';
import { playlistsUsageRequest } from 'store/ducks/analytics/actions';
import { Card, DateInterval, LoadingIndicator } from 'components';
import dateUtils from 'helpers/date';
import ApplicationState from 'helpers/types/ApplicationState';
import { CustomChartOptions } from 'helpers/types/ChartOptionsType';
import { ChartConfig } from 'helpers/types/ChartTypes';
import * as S from './PlaylistUsage.styles';

const { convertDateToString, getInitialEndDate, getInitialStartDate } =
  dateUtils;

const PlaylistUsage = () => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { playlistsUsage, loading } = useSelector(
    (state: ApplicationState) => state.analytics
  );

  const isModuleLoading = () => loading.includes('PLAYLISTS_USAGE');

  const [startsIn, setStartsIn] = useState(
    convertDateToString(getInitialStartDate())
  );

  const [endsIn, setEndsIn] = useState(
    convertDateToString(getInitialEndDate())
  );

  useEffect(() => {
    dispatch(playlistsUsageRequest(startsIn, endsIn));
  }, [dispatch, startsIn, endsIn]);

  const chartConfig = useMemo(
    () => ({
      labels: playlistsUsage.map((item) => item.playlist.name),
      datasets: [
        {
          label: 'Quantidade de acessos',
          data: playlistsUsage.map((item) => item.accessCount),
          backgroundColor: themeContext.chart.bg.orange3,
          hoverBackgroundColor: themeContext.chart.bg.orange3,
          borderColor: themeContext.chart.bg.orange3,
          fill: false
        }
      ]
    }),
    [playlistsUsage]
  );

  const chartOptions = useMemo<CustomChartOptions>(
    () => ({
      indexAxis: 'y',
      plugins: {
        legend: {
          labels: {
            color: themeContext.text.primary
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: themeContext.text.primary
          },
          grid: {
            color: themeContext.chart.grid
          },
          title: {
            display: true,
            text: 'Acessos',
            color: themeContext.text.secondary,
            font: {
              family: 'Mulish , sans-serif',
              weight: 'bold',
              size: 20
            },
            padding: {
              top: 24
            }
          }
        },
        y: {
          ticks: {
            color: themeContext.text.primary
          },
          grid: {
            color: themeContext.chart.grid
          },
          title: {
            display: true,
            text: 'Playlists',
            color: themeContext.text.secondary,
            font: {
              family: 'Mulish , sans-serif',
              weight: 'bold',
              size: 20
            },
            padding: {
              bottom: 24
            }
          }
        }
      }
    }),
    [themeContext]
  );

  const chartMemo = useMemo(
    () => (
      <Chart
        type="bar"
        data={chartConfig}
        options={chartOptions}
        className="chart"
      />
    ),
    [chartConfig, chartOptions]
  );

  const handleRefresh = () => {
    dispatch(playlistsUsageRequest(startsIn, endsIn));
  };

  return (
    <Card title="Playlists mais acessadas">
      <LoadingIndicator
        isLoading={isModuleLoading()}
        position="absolute"
        showRefreshButton
        onRefresh={handleRefresh}
      />

      <S.Container>
        <DateInterval
          startsIn={startsIn}
          onStartsInChange={setStartsIn}
          endsIn={endsIn}
          onEndsInChange={setEndsIn}
          disable={isModuleLoading()}
        />

        {chartMemo}
      </S.Container>
    </Card>
  );
};

export { PlaylistUsage };
