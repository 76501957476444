import { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { ThemeContext } from 'styled-components';
import {
  canceledAndActiveUsersAfterTestRequest,
  canceledOrActivePayingUsersRequest
} from 'store/ducks/analytics/actions';
import { Card, DateInterval, LoadingIndicator, MiniCard } from 'components';
import dateUtils from 'helpers/date';
import ApplicationState from 'helpers/types/ApplicationState';
import { CustomChartOptions } from 'helpers/types/ChartOptionsType';
import { ChartConfig } from 'helpers/types/ChartTypes';
import * as S from './CanceledOrActiveByPeriodChart.styles';

const {
  convertDateToString,
  getInitialEndDate,
  getInitialStartDate,
  getMonthRangeLabels
} = dateUtils;

const CanceledOrActiveByPeriodChart = () => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { canceledOrActivePayingUsers, loading } = useSelector(
    (state: ApplicationState) => state.analytics
  );
  const isModuleLoading = () =>
    loading.includes('CANCELED_OR_ACTIVE_PAYING_USERS');

  const [startsIn, setStartsIn] = useState(
    convertDateToString(getInitialStartDate())
  );

  const [endsIn, setEndsIn] = useState(
    convertDateToString(getInitialEndDate())
  );

  useEffect(() => {
    dispatch(canceledOrActivePayingUsersRequest(startsIn, endsIn));
  }, [dispatch, startsIn, endsIn]);

  const chartConfig = useMemo<ChartConfig>(
    () => ({
      labels: getMonthRangeLabels(startsIn, endsIn),
      datasets: canceledOrActivePayingUsers.data.map((periodData) => ({
        label: periodData.key === 'Cancelados' ? 'Canceladas' : 'Novas',
        data: periodData.data,
        backgroundColor: themeContext.background,
        borderColor:
          periodData.key === 'Cancelados'
            ? themeContext.chart.bg.red1
            : themeContext.chart.bg.green1,
        fill: false,
        tension: 0.4
      }))
    }),
    [canceledOrActivePayingUsers, themeContext]
  );

  const chartOptions = useMemo<CustomChartOptions>(
    () => ({
      plugins: {
        legend: {
          labels: {
            color: themeContext.text.primary
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: themeContext.text.primary
          },
          grid: {
            color: themeContext.chart.grid
          }
        },
        y: {
          title: {
            display: true,
            text: 'Assinaturas',
            color: themeContext.text.secondary,
            font: {
              family: 'Mulish , sans-serif',
              weight: 'bold',
              size: 20
            },
            padding: {
              bottom: 24
            }
          },
          ticks: {
            stepSize: 1,
            color: themeContext.text.primary
          },
          grid: {
            color: themeContext.chart.grid
          }
        }
      }
    }),
    [themeContext]
  );

  const chartMemo = useMemo(
    () => (
      <Chart
        type="line"
        data={chartConfig}
        options={chartOptions}
        className="chart"
      />
    ),
    [chartConfig, chartOptions]
  );

  return (
    <Card title="Assinaturas novas e canceladas por período">
      <LoadingIndicator isLoading={isModuleLoading()} position="absolute" />

      <S.Container>
        <DateInterval
          startsIn={startsIn}
          onStartsInChange={setStartsIn}
          endsIn={endsIn}
          onEndsInChange={setEndsIn}
          disable={isModuleLoading()}
        />

        {chartMemo}
      </S.Container>
    </Card>
  );
};

export { CanceledOrActiveByPeriodChart };
