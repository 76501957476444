import styled from 'styled-components';
import { DataTableLayout } from 'components';

export const DataTableWrapper = styled(DataTableLayout)`
  table {
    thead.p-datatable-thead {
      tr:first-of-type > th {
        border-bottom: 0px;
      }

      tr:last-of-type {
        border-bottom: 0px;
      }

      th {
        &.p-filter-column {
          &:first-of-type {
            padding-left: 7px;
            padding-right: 7px;
          }
        }

        &.filterInput {
          input {
            background: ${({ theme }) => theme.section.bg};
            border: 1px solid ${({ theme }) => theme.search.border.color};
            border-radius: 5px;
            padding: 4px 5px;
            transition: all 0.3s;
            color: ${({ theme }) => theme.text.primary};

            &::placeholder {
              transition: all 0.3s;
              color: ${({ theme }) => theme.search.placeholder};
            }

            &:focus,
            &:hover {
              border-color: ${({ theme }) => theme.search.border.hover};

              &::placeholder {
                color: ${({ theme }) => theme.search.border.hover};
              }
            }
          }
        }
      }
    }

    tbody.p-datatable-tbody {
      tr:first-of-type {
        border-top: 1px solid ${({ theme }) => theme.table.border};
      }
    }
  }
`;

export { ActionButtons } from 'components/DataTableLayout/DataTableLayout.styles';
