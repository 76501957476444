import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { financialReportRequest } from 'store/ducks/analytics/actions';
import {
  ActivatableDateInterval,
  Card,
  ExportCsvButton,
  ExportPdfButton,
  LoadingIndicator
} from 'components';
import dateUtils from 'helpers/date';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './FinancialReport.styles';
import { translateFields } from './FinancialReport.utils';
import { FinancialReportPdfDocument } from './FinancialReportPdfDocument/FinancialReportPdfDocument';

const { convertDateToString, getInitialStartDate, getInitialEndDate } =
  dateUtils;

const FinancialReport = () => {
  const { financialReport, loading } = useSelector(
    (state: ApplicationState) => state.analytics
  );
  const dispatch = useDispatch();

  const isModuleLoading = () => loading.includes('FINANCIAL_REPORT');

  const [startsIn, setStartsIn] = useState(
    convertDateToString(getInitialStartDate())
  );

  const [endsIn, setEndsIn] = useState(
    convertDateToString(getInitialEndDate())
  );

  const [useFilterDate, setUseDateFilter] = useState(false);

  useEffect(() => {
    if (useFilterDate) {
      dispatch(financialReportRequest(startsIn, endsIn));
    } else {
      dispatch(
        financialReportRequest(
          '2020-01-01T03:00:00.000Z',
          convertDateToString(new Date())
        )
      );
    }
  }, [startsIn, endsIn, useFilterDate]);

  return (
    <Card title="Balanço Financeiro">
      <LoadingIndicator
        isLoading={isModuleLoading()}
        position="absolute"
        size="small"
      />

      <S.Row className="buttonGroup">
        <ExportCsvButton
          data={translateFields(financialReport)}
          disabled={isModuleLoading()}
          fileName="balanco_financeiro"
        />
        <ExportPdfButton
          document={<FinancialReportPdfDocument data={financialReport} />}
          disabled={isModuleLoading()}
          fileName="balanco_financeiro"
        />
      </S.Row>

      <ActivatableDateInterval
        startsIn={startsIn}
        onStartsInChange={setStartsIn}
        endsIn={endsIn}
        onEndsInChange={setEndsIn}
        isActive={useFilterDate}
        onActiveChange={setUseDateFilter}
        disable={isModuleLoading()}
        label="Filtrar por meses:"
        containerDirection="column"
      />
    </Card>
  );
};

export { FinancialReport };
