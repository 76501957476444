import styled from 'styled-components';
import { DataTableLayout } from 'components';

export const DataTableWrapper = styled(DataTableLayout)`
  table {
    thead.p-datatable-thead {
      tr:first-of-type > th {
        border-bottom: 0px;
      }

      tr:last-of-type {
        border-bottom: 0px;
      }

      th {
        &.p-filter-column {
          &:first-of-type {
            padding-left: 7px;
            padding-right: 7px;
          }
        }

        &.filterInput {
          input {
            background: ${({ theme }) => theme.section.bg};
            border: 1px solid ${({ theme }) => theme.search.border.color};
            border-radius: 5px;
            padding: 4px 5px;
            transition: all 0.3s;
            color: ${({ theme }) => theme.text.primary};

            &::placeholder {
              transition: all 0.3s;
              color: ${({ theme }) => theme.search.placeholder};
            }

            &:focus,
            &:hover {
              border-color: ${({ theme }) => theme.search.border.hover};

              &::placeholder {
                color: ${({ theme }) => theme.search.border.hover};
              }
            }
          }
        }
      }
    }

    tbody.p-datatable-tbody {
      tr:first-of-type {
        border-top: 1px solid ${({ theme }) => theme.table.border};
      }

      td {
        &.userAccessLevel {
          .admin,
          .regular {
            border-radius: 25px;
            padding: 6px 0;
            width: 80%;
            margin: 0 auto;
            display: block;
            font-weight: 500;
            font-size: 12px;
            text-transform: uppercase;
          }

          .admin {
            color: ${({ theme }) => theme.btn.primary.color};
            background-color: ${({ theme }) => theme.btn.primary.bg};
          }

          .regular {
            color: ${({ theme }) => theme.btn.secondary.color};
            background-color: ${({ theme }) => theme.btn.secondary.bg};
          }
        }
      }
    }

    tr.p-datatable-row > td.fileNameColumn {
      span.audioName,
      span.folderName {
        display: flex;
        align-items: center;

        svg {
          font-size: 20px;
          margin-right: 8px;
        }
      }
    }
  }
`;

export { ActionButtons } from 'components/DataTableLayout/DataTableLayout.styles';
