import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CrumbLinkProps } from './interfaces/CrumbLinkProps';

const size = '14px';

export const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: ${size};
  margin-bottom: 12px;
  font-family: 'Mulish';
  text-transform: uppercase;

  span.crumbLabel {
    display: none;
    margin-right: 8px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const CrumbLink = styled(Link)<CrumbLinkProps>`
  font-weight: 700;
  color: ${({ theme }) => theme.text.secondary};

  ${({ $isModule, theme }) =>
    $isModule
      ? `
    transition: color 0.2s;

    &:hover {
      color: ${theme.yellow};
    }
    `
      : `
        cursor: auto
    `}
`;

export const Divider = styled.i`
  text-align: center;
  margin: 0 8px;

  &.isIcon {
    height: ${size};
    width: ${size};
    font-size: '85px';
  }
`;
