import { AboutUs } from 'models/Institutional';

export enum InstitutionalAboutUsTypes {
  FETCH_ABOUT_US_REQUEST = '@INSTITUTIONAL/FETCH_ABOUT_US_REQUEST',
  FETCH_ABOUT_US_SUCCESS = '@INSTITUTIONAL/FETCH_ABOUT_US_SUCCESS',

  UPDATE_ABOUT_US_REQUEST = '@INSTITUTIONAL/UPDATE_ABOUT_US_REQUEST',
  
  ABOUT_US_REQUEST_SUCCESS = '@INSTITUTIONAL/ABOUT_US_REQUEST_SUCCESS',
  ABOUT_US_REQUEST_ERROR = '@INSTITUTIONAL/ABOUT_US_REQUEST_ERROR',
}

export interface InstitutionalAboutUsState {
  readonly aboutUs: AboutUs;
  readonly message: string;
}

export type InstitutionalAboutUsAction = {
  type: InstitutionalAboutUsTypes;
  payload: InstitutionalAboutUsState;
};
