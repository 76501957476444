import { useMemo } from 'react';
import classNames from 'classnames';
import { Calendar as PRCalendar } from 'primereact/calendar';
import { iCalendarProps } from './interfaces/iCalendarProps';
import * as S from './styled';

export const Calendar = ({ className, ...props }: iCalendarProps) => {
  const { name, label, value, placeholder } = props;

  const valueMemo = useMemo(
    () => !!value || !!placeholder,
    [value, placeholder]
  );

  return (
    <S.CalendarWrapper>
      <PRCalendar
        className={classNames({
          className,
          filled: valueMemo
        })}
        {...props}
      />
      <label htmlFor={name}>{label}</label>
    </S.CalendarWrapper>
  );
};

Calendar.defaultProps = {
  type: 'text',
  placeholder: ''
};
