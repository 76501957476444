import { Tag } from 'primereact/tag';
import styled from 'styled-components';

export const TagWrapper = styled(Tag)`
  &:not(:last-child) {
    margin-right: 8px;
  }

  .p-tag-value {
    font-weight: 400 !important;
    font-family: 'Noto Sans' !important;
  }

  .p-tag-sm {
    .p-tag-value {
      font-size: 12px !important;
    }
  }

  .p-tag-bg {
    .p-tag-value {
      font-size: 14px !important;
    }
  }
`;
