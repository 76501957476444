import { Reducer } from 'redux';
import TeachingInstitution from 'models/TeachingInstitution';
import { TeachingInstitutionTypes, TeachingInstitutionState } from './types';

export type Action = {
    type: TeachingInstitutionTypes;
    payload: {
      id: number;
      one: TeachingInstitution;
      all: TeachingInstitution[];
    }
};

const INITIAL_STATE: TeachingInstitutionState = {
  id: 0,
  one: {
    id: 0,
    name: '',
    cnpj: '',
    code: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  all: [],
};

const reducer: Reducer<TeachingInstitutionState, Action> = (
  state: TeachingInstitutionState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
  case TeachingInstitutionTypes.FETCH_TEACHING_INSTITUTIONS_SUCCESS: {
    return {
      ...state,
      all: action.payload.all,
    };
  }
  case TeachingInstitutionTypes.FETCH_ONE_TEACHING_INSTITUTION_REQUEST: {
    return {
      ...state,
      id: action.payload.id,
    };
  }
  case TeachingInstitutionTypes.FETCH_ONE_TEACHING_INSTITUTION_SUCCESS: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  case TeachingInstitutionTypes.CREATE_TEACHING_INSTITUTION_REQUEST: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  case TeachingInstitutionTypes.UPDATE_TEACHING_INSTITUTION_REQUEST: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  case TeachingInstitutionTypes.DELETE_TEACHING_INSTITUTION_REQUEST: {
    return {
      ...state,
      one: action.payload.one,
    };
  }
  default: {
    return state;
  }
  }
};

export default reducer;
