import {
  FormEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FaCamera } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Testimonial } from 'models/Institutional';
import {
  createTestimonial,
  editTestimonial,
  removeTestimonial
} from 'store/ducks/institutionalHome/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Switch, Button } from 'components';
import { InputText, InputTextarea } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { INITIAL_STATE } from '../../HomePage.utils';
import * as S from './HomeTestimonials.styles';

interface HomeTestimonialsForm {
  action: 'create' | 'update';
  onActionChange: (action: 'create' | 'update') => void;
  selectedTestimonial: Testimonial;
  onSelectedTestimonialChange: (selectedTestimonial: Testimonial) => void;
}

const HomeTestimonialsForm: FunctionComponent<HomeTestimonialsForm> = (
  props
) => {
  const {
    action,
    onActionChange,
    selectedTestimonial,
    onSelectedTestimonialChange
  } = props;

  const dispatch = useDispatch();

  const [preview, setPreview] = useState<string>(selectedTestimonial.avatarUrl);

  useEffect(() => {
    setPreview(selectedTestimonial.avatarUrl);
  }, [selectedTestimonial.avatarUrl]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onUploadSingleFile = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const selectedTestimonialAvatar = e.currentTarget.files[0];

      setPreview(URL.createObjectURL(selectedTestimonialAvatar));
      onSelectedTestimonialChange({
        ...selectedTestimonial,
        avatar: selectedTestimonialAvatar
      });
    }
  };

  const handleReset = () => {
    onSelectedTestimonialChange(INITIAL_STATE.testimonial);
    setPreview('');
    onActionChange('create');

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleTestimonialSubmit = (formAction: string) => {
    try {
      Yup.object()
        .shape({
          contact: Yup.string().required('O campo nome é obrigatório!'),
          message: Yup.string().required('O campo mensagem é obrigatório!')
        })
        .validateSync(selectedTestimonial, { abortEarly: false });

      if (formAction === 'create') {
        if (!selectedTestimonial.avatar) {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: 'É necessário selecionar um ícone',
                severity: 'error'
              })
            );
          }, 800);
          return;
        }

        dispatch(createTestimonial(selectedTestimonial));
      }

      if (formAction === 'update') {
        dispatch(editTestimonial(selectedTestimonial));
      }

      if (formAction === 'remove') {
        dispatch(removeTestimonial(selectedTestimonial));
      }

      handleReset();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const onShowInHomeSwitch = (showInHome: boolean) => {
    onSelectedTestimonialChange({
      ...selectedTestimonial,
      showInHome
    });
  };

  const showInHomeSwitchLabel = useMemo(() => {
    if (selectedTestimonial.showInHome) {
      return 'Mostrar na página inicial';
    }

    return 'Não mostrar na página inicial';
  }, [selectedTestimonial.showInHome]);

  return (
    <S.Form as="div">
      <S.FormRow>
        <S.FormGroup>
          <S.TestimonialsAvatarWrapper title="Selecione uma imagem para ser utilizada como ícone">
            <label
              style={{
                backgroundImage: `url(${preview})`
              }}
              className={classNames({
                'has-cover': preview !== ''
              })}
              htmlFor="selectedTestimonialAvatar"
            >
              <FaCamera />
              <input
                id="selectedTestimonialAvatar"
                type="file"
                accept="image/*"
                onChange={onUploadSingleFile}
                ref={fileInputRef}
                required
              />
            </label>
          </S.TestimonialsAvatarWrapper>
        </S.FormGroup>

        <S.FormGroup>
          <InputText
            name="message"
            type="text"
            placeholder="Nome"
            value={selectedTestimonial.contact}
            onChange={(ev) =>
              onSelectedTestimonialChange({
                ...selectedTestimonial,
                contact: ev.target.value
              })
            }
          />

          <InputTextarea
            name="description"
            placeholder="Mensagem"
            value={selectedTestimonial.message}
            onChange={(ev) =>
              onSelectedTestimonialChange({
                ...selectedTestimonial,
                message: ev.target.value
              })
            }
          />

          <Switch
            id="showInHomeToggler"
            rightSideLabel={showInHomeSwitchLabel}
            checked={selectedTestimonial.showInHome}
            onCheck={onShowInHomeSwitch}
          />
        </S.FormGroup>
      </S.FormRow>

      <S.ButtonGroup>
        <Button type="button" onClick={() => handleTestimonialSubmit(action)}>
          Salvar
        </Button>

        <Button
          type="button"
          severity="secondary"
          onClick={handleReset}
          disabled={
            !selectedTestimonial.contact &&
            !selectedTestimonial.message &&
            !selectedTestimonial.avatar
          }
        >
          Descartar alterações
        </Button>

        <Button
          type="button"
          severity="danger"
          onClick={() => handleTestimonialSubmit('remove')}
          disabled={!selectedTestimonial.id}
        >
          Remover item
        </Button>
      </S.ButtonGroup>
    </S.Form>
  );
};

export { HomeTestimonialsForm };
