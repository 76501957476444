import { action } from 'typesafe-actions';
import { Terms } from 'models/Institutional';
import { InstitutionalTermsTypes } from './types';

export const fetchTermsRequest = () =>
  action(InstitutionalTermsTypes.FETCH_TERMS_REQUEST);

export const fetchTermsSuccess = (terms: Terms) =>
  action(InstitutionalTermsTypes.FETCH_TERMS_SUCCESS, { terms });

export const fetchTermsError = () =>
  action(InstitutionalTermsTypes.FETCH_TERMS_ERROR);

export const editTermsRequest = (terms: Terms) =>
  action(InstitutionalTermsTypes.EDIT_TERMS_REQUEST, { terms });

export const editTermsSuccess = () =>
  action(InstitutionalTermsTypes.EDIT_TERMS_SUCCESS);

export const editTermsError = () =>
  action(InstitutionalTermsTypes.EDIT_TERMS_ERROR);
