import { iNumberFormatterConfig } from './interfaces/iNumberFormatterConfig';

export const formatValue = (formatterConfig: iNumberFormatterConfig) => {
  const { oldValue } = formatterConfig;
  const { newValue } = formatterConfig;
  const { withNegative } = formatterConfig;
  const { withDecimals } = formatterConfig;
  const { minNumber } = formatterConfig;
  const { maxNumber } = formatterConfig;
  const decimalDigits = formatterConfig.decimalDigits || 1;

  const notValidRegex = /-\./.exec(newValue);

  if (notValidRegex) {
    return oldValue;
  }

  if (minNumber && Number(newValue) < minNumber) {
    return minNumber;
  }

  if (maxNumber && Number(newValue) > maxNumber) {
    return maxNumber;
  }

  let regex = /([1-9][0-9]*)/;
  const decimalRegex = /\.[0-9]{0}/;

  if (!withNegative && withDecimals) {
    regex = new RegExp(`([1-9][0-9]*)(\\.{0,1})([0-9]{0,${decimalDigits}})`);
  }

  if (withNegative && !withDecimals) {
    regex = /(-{0,1})([1-9][0-9]*)/;
  }

  if (withNegative && withDecimals) {
    regex = new RegExp(
      `(-{0,1})([1-9][0-9]*)(\\.{0,1})([0-9]{0,${decimalDigits}})`
    );
  }

  const value: string | RegExpExecArray | null = regex.exec(newValue);

  const applyNumberFormat = (v: string) => {
    let val;

    if (withDecimals && decimalRegex.test(v)) {
      val = Number(parseFloat(v).toFixed(decimalDigits));
    } else {
      val = Number(v);
    }

    return val;
  };

  return value ? (value[0] as unknown as number) : 0;
};
