/* eslint-disable camelcase */
import { call, put } from 'redux-saga/effects';
import Subscription from 'models/Subscription';
import Connector from 'helpers/connector/Connector';
import { SagaGenerator } from 'helpers/types/SagasGenerator';
import {
  ActiveUsers,
  ActiveUsersByPeriod,
  CanceledOrActivePayingUsers,
  CanceledUsersAfterTest,
  PaymentAmount,
  PlaylistsUsage,
  SubscriptionsSummary,
  UsagePerHour,
  UsersInPromotion,
  UsersInTest
} from 'interfaces/Analytics';
import { showToast } from '../toast/actions';
import {
  activeUsersByPeriodFailure,
  activeUsersByPeriodSuccess,
  activeUsersByPlanFailure,
  activeUsersByPlanSuccess,
  allSubscriptionsFailure,
  allSubscriptionsSuccess,
  canceledAndActiveUsersAfterTestFailure,
  canceledAndActiveUsersAfterTestSuccess,
  canceledOrActivePayingUsersFailure,
  canceledOrActivePayingUsersSuccess,
  canceledUsersAfterTestFailure,
  canceledUsersAfterTestSuccess,
  financialReportFailure,
  financialReportSuccess,
  paymentAmountFailure,
  paymentAmountSuccess,
  playlistsUsageFailure,
  playlistsUsageSuccess,
  subscriptionsSummaryFailure,
  subscriptionsSummarySuccess,
  usagePerHoursFailure,
  usagePerHoursSuccess,
  usersInPromotionFailure,
  usersInPromotionSuccess,
  usersInTestFailure,
  usersInTestSuccess
} from './actions';
import { AnalyticsAction } from './types';

export function* usagePerHours(action: AnalyticsAction): SagaGenerator<any[]> {
  try {
    const { startsIn, endsIn } = action.payload;
    const url = `/analytics/analitics-logs?startsIn=${startsIn}&endsIn=${endsIn}`;

    const { data } = yield call(Connector().get, url);

    const serializedData: UsagePerHour[] = data.map((item: any) => ({
      hour: item.hora,
      accessCount: item.recorrencia
    }));

    yield put(usagePerHoursSuccess(serializedData));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Uso Por Horas',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(usagePerHoursFailure());
  }
}

export function* playlistsUsage(action: AnalyticsAction): SagaGenerator<any[]> {
  try {
    const { startsIn, endsIn } = action.payload;
    const url = `/analytics/playlist-most-popular?startsIn=${startsIn}&endsIn=${endsIn}`;

    const { data } = yield call(Connector().get, url);

    const serializedData: PlaylistsUsage[] = data.map(
      ({ access, ...item }: any) => ({
        ...item,
        accessCount: access
      })
    );

    yield put(playlistsUsageSuccess(serializedData));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Playslists Mais Acessadas',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(playlistsUsageFailure());
  }
}

export function* activeUsersByPlan(): SagaGenerator<ActiveUsers[]> {
  try {
    const url = '/analytics/actives/by-plan';

    const { data } = yield call(Connector().get, url);

    yield put(activeUsersByPlanSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Usuários ativos por plano',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(activeUsersByPlanFailure());
  }
}

export function* activeUsersByPeriod(
  action: AnalyticsAction
): SagaGenerator<ActiveUsersByPeriod> {
  try {
    const { startsIn, endsIn } = action.payload;
    const url = `/analytics/actives/by-period?startsIn=${startsIn}&endsIn=${endsIn}`;

    const response = yield call(Connector().get, url);

    yield put(activeUsersByPeriodSuccess(response.data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Usuários ativos por período',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(activeUsersByPeriodFailure());
  }
}

export function* canceledOrActivePayingUsers(
  action: AnalyticsAction
): SagaGenerator<CanceledOrActivePayingUsers> {
  try {
    const { startsIn, endsIn } = action.payload;
    const url = `/analytics/list/canceled-or-active-with-pay?startsIn=${startsIn}&endsIn=${endsIn}`;

    const { data } = yield call(Connector().get, url);

    yield put(canceledOrActivePayingUsersSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Usuários cancelados ou ativos após pagamento',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(canceledOrActivePayingUsersFailure());
  }
}

export function* canceledAndActiveUsersAfterTest(
  action: AnalyticsAction
): SagaGenerator<any[]> {
  try {
    const { startsIn, endsIn } = action.payload;
    const url = `/analytics/list/canceled-and-active-after-test?startsIn=${startsIn}&endsIn=${endsIn}`;

    const { data } = yield call(Connector().get, url);

    yield put(canceledAndActiveUsersAfterTestSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Usuários cancelados e ativos após o teste',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(canceledAndActiveUsersAfterTestFailure());
  }
}

export function* canceledUsersAfterTest(
  action: AnalyticsAction
): SagaGenerator<CanceledUsersAfterTest[]> {
  try {
    const { startsIn, endsIn } = action.payload;
    const url = `/analytics/list/canceled-after-test-by-period?startsIn=${startsIn}&endsIn=${endsIn}`;

    const { data } = yield call(Connector().get, url);

    yield put(canceledUsersAfterTestSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Usuários cancelados após o teste',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(canceledUsersAfterTestFailure());
  }
}

export function* usersInPromotion(): SagaGenerator<UsersInPromotion> {
  try {
    const url = '/analytics/actives/in-promotion';

    const { data } = yield call(Connector().get, url);

    yield put(usersInPromotionSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Usuários em promoção',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(usersInPromotionFailure());
  }
}

export function* usersInTest(): SagaGenerator<UsersInTest> {
  try {
    const url = '/analytics/list/in-test';

    const { data } = yield call(Connector().get, url);

    yield put(usersInTestSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Usuários em teste',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(usersInTestFailure());
  }
}

export function* paymentAmount(): SagaGenerator<PaymentAmount> {
  try {
    const url = '/analytics/amount-payments';

    const { data } = yield call(Connector().get, url);

    yield put(paymentAmountSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Receita',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(paymentAmountFailure());
  }
}

export function* financialReport(
  action: AnalyticsAction
): SagaGenerator<any[]> {
  try {
    const { startsIn, endsIn } = action.payload;
    const query =
      startsIn && endsIn ? `?startsIn=${startsIn}&endsIn=${endsIn}` : '';
    const url = `/analytics/financial-statements${query}`;

    const { data } = yield call(Connector().get, url);

    yield put(financialReportSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Relatório financeiro',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(financialReportFailure());
  }
}

export function* subscriptionsSummary(): SagaGenerator<SubscriptionsSummary> {
  try {
    const url = '/analytics/summary';

    const { data } = yield call(Connector().get, url);

    yield put(subscriptionsSummarySuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Resumo de assinaturas',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(subscriptionsSummaryFailure());
  }
}

export function* allSubscriptions(): SagaGenerator<Subscription[]> {
  try {
    const url = '/analytics/all-subscription';

    const { data } = yield call(Connector().get, url);

    yield put(allSubscriptionsSuccess(data));
  } catch (error) {
    yield put(
      showToast({
        summary: 'Analytics - Assinaturas',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(allSubscriptionsFailure());
  }
}
