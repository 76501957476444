import Quiz, { QuizAlternative, QuizQuestion } from 'models/Quiz';

export const quizInitialState: Quiz = {
  id: 0,
  name: '',
  description: '',
  createdAt: new Date(''),
  updatedAt: new Date(''),
  playListId: 0,
  createdById: 0,
  quizQuestions: [
    {
      id: 0,
      question: '',
      createdAt: new Date(''),
      updatedAt: new Date(''),
      isMultiple: false,
      quizId: 0,
      quizAlternative: [],
    },
  ],
};

export const questionInitialState: QuizQuestion = {
  id: 0,
  question: '',
  createdAt: new Date(''),
  updatedAt: new Date(''),
  isMultiple: false,
  quizId: 0,
  quizAlternative: [{
    id: 0,
    answer: '',
    isCorrect: false,
    createdAt: new Date(''),
    updatedAt: new Date(''),
    quizQuestionId: 0,
  }],
};

export const alternativeInitialState: QuizAlternative = {
  id: 0,
  answer: '',
  isCorrect: false,
  createdAt: new Date(''),
  updatedAt: new Date(''),
  quizQuestionId: 0,
};
