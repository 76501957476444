/* eslint-disable @typescript-eslint/no-empty-function */
import { Reducer } from 'redux';
import AudioPlaylistOrderer from 'models/AudioPlaylistOrderer';
import AudioToPlaylist from 'models/AudioToPlaylist';
import { FileStatus } from 'models/FileStatus';
import FolderTree from 'models/FolderTree';
import Playlist from 'models/Playlist';
import { PlaylistTypes, PlaylistState } from './types';

export type Action = {
  type: PlaylistTypes;
  payload: {
    old: Playlist;
    data: Playlist[];
    orderer: AudioPlaylistOrderer[];
    folderTree: FolderTree[];
    folderTreeLoading: boolean;
    audioToPlaylist: AudioToPlaylist;
    cover: File;
  };
};

const INITIAL_STATE: PlaylistState = {
  old: {
    id: 0,
    name: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    removedAt: new Date(),
    audios: [],
    categories: [],
    description: '',
    removed: false,
    status: FileStatus.ENABLED,
    userId: 0,
    coverUrl: ''
  },
  data: [],
  orderer: [],
  folderTree: [],
  folderTreeLoading: true,
  audioToPlaylist: {
    playlist: {
      id: 0,
      name: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      removedAt: new Date(),
      audios: [],
      categories: [],
      description: '',
      removed: false,
      status: FileStatus.ENABLED,
      userId: 0,
      coverUrl: ''
    },
    audios: []
  },
  cover: new File([''], 'cover')
};

const reducer: Reducer<PlaylistState, Action> = (
  state: PlaylistState = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case PlaylistTypes.FETCH_PLAYLIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case PlaylistTypes.FETCH_ONE_PLAYLIST_REQUEST: {
      return {
        ...state,
        old: action.payload.old
      };
    }
    case PlaylistTypes.FETCH_ONE_PLAYLIST_SUCCESS: {
      return {
        ...state,
        old: action.payload.old
      };
    }
    case PlaylistTypes.SAVE_ORDER_REQUEST: {
      return {
        ...state,
        old: action.payload.old
      };
    }

    case PlaylistTypes.EDIT_PLAYLIST_REQUEST: {
      return {
        ...state,
        old: action.payload.old
      };
    }

    case PlaylistTypes.EDIT_PLAYLIST_ORDERER_REQUEST: {
      return {
        ...state,
        orderer: action.payload.orderer
      };
    }

    case PlaylistTypes.SAVE_PLAYLIST_REQUEST: {
      return {
        ...state,
        old: action.payload.old,
        cover: action.payload.cover
      };
    }

    case PlaylistTypes.SAVE_PLAYLIST_SUCESS: {
      return {
        ...state,
        old: action.payload.old
      };
    }

    case PlaylistTypes.REMOVE_PLAYLIST_REQUEST: {
      return {
        ...state,
        old: action.payload.old
      };
    }

    case PlaylistTypes.FETCH_ALL_FOLDERS_SUCESS: {
      return {
        ...state,
        folderTree: action.payload.folderTree,
        folderTreeLoading: false
      };
    }
    case PlaylistTypes.FETCH_ALL_FOLDERS_ERROR: {
      return {
        ...state,
        folderTreeLoading: false
      };
    }
    case PlaylistTypes.FETCH_ALL_FOLDERS_REQUEST: {
      return {
        ...state,
        folderTreeLoading: true
      };
    }

    case PlaylistTypes.ADD_AUDIO_TO_PLAYLIST_REQUEST: {
      return {
        ...state,
        audioToPlaylist: action.payload.audioToPlaylist
      };
    }

    case PlaylistTypes.REMOVE_AUDIO_FROM_PLAYLIST_REQUEST: {
      return {
        ...state,
        audioToPlaylist: action.payload.audioToPlaylist
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
