import { action } from 'typesafe-actions';
import { Filters } from 'models/Filters';
import Subscriber from 'models/Subscriber';
import { SubscriberTypes } from './types';

export const fetchSubscribersRequest = (filters?: string) =>
  action(SubscriberTypes.FETCH_SUBSCRIBERS_REQUEST, { filters });

export const fetchSubscribersSuccess = (all: Subscriber[]) =>
  action(SubscriberTypes.FETCH_SUBSCRIBERS_SUCCESS, { all });

export const fetchSubscribersError = () =>
  action(SubscriberTypes.FETCH_SUBSCRIBERS_ERROR);

export const fetchOneSubscriberRequest = (id: string | number) =>
  action(SubscriberTypes.FETCH_ONE_SUBSCRIBER_REQUEST, { id });

export const fetchOneSubscriberSuccess = (one: Subscriber[]) =>
  action(SubscriberTypes.FETCH_ONE_SUBSCRIBER_SUCCESS, { one });

export const fetchOneSubscriberError = () =>
  action(SubscriberTypes.FETCH_ONE_SUBSCRIBER_ERROR);

export const createSubscriberRequest = (one: Subscriber) =>
  action(SubscriberTypes.CREATE_SUBSCRIBER_REQUEST, { one });

export const createSubscriberSuccess = () =>
  action(SubscriberTypes.CREATE_SUBSCRIBER_SUCCESS);

export const createSubscriberError = () =>
  action(SubscriberTypes.CREATE_SUBSCRIBER_ERROR);

export const updateSubscriberRequest = (one: Subscriber) =>
  action(SubscriberTypes.UPDATE_SUBSCRIBER_REQUEST, { one });

export const updateSubscriberSuccess = () =>
  action(SubscriberTypes.UPDATE_SUBSCRIBER_SUCCESS);

export const updateSubscriberError = () =>
  action(SubscriberTypes.UPDATE_SUBSCRIBER_ERROR);

export const updateSubscriberPlanRequest = (
  one: Subscriber,
  newPlanId: number
) => action(SubscriberTypes.UPDATE_SUBSCRIBER_PLAN_REQUEST, { one, newPlanId });

export const updateSubscriberPlanSuccess = () =>
  action(SubscriberTypes.UPDATE_SUBSCRIBER_PLAN_SUCCESS);

export const updateSubscriberPlanError = () =>
  action(SubscriberTypes.UPDATE_SUBSCRIBER_PLAN_ERROR);

export const unsubscribeRequest = (id: string) =>
  action(SubscriberTypes.CANCEL_SUBSCRIPTION_REQUEST, { id });

export const unsubscribeSuccess = () =>
  action(SubscriberTypes.CANCEL_SUBSCRIPTION_SUCCESS);

export const unsubscribeError = () =>
  action(SubscriberTypes.CANCEL_SUBSCRIPTION_ERROR);
