import styled from 'styled-components';

export const ExamplesContainer = styled.div`
  background-color: #999;
  color: black;
  margin: 0 80px;
  height: 100%;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.size.h1};
  text-align: center;
  padding: 40px 20px;
`;
