import StudentRequest from 'models/StudentRequest';

export enum StudentRequestTypes {
  FETCH_STUDENTS_REQ_REQUEST = '@subscriber/FETCH_STUDENTS_REQ_REQUEST',
  FETCH_STUDENTS_REQ_SUCCESS = '@subscriber/FETCH_STUDENTS_REQ_SUCCESS',
  FETCH_STUDENTS_REQ_ERROR = '@subscriber/FETCH_STUDENTS_REQ_ERROR',

  CREATE_STUDENT_REQ_REQUEST = '@subscriber/CREATE_STUDENT_REQ_REQUEST',
  CREATE_STUDENT_REQ_SUCCESS = '@subscriber/CREATE_STUDENT_REQ_SUCCESS',
  CREATE_STUDENT_REQ_ERROR = '@subscriber/CREATE_STUDENT_REQ_ERROR',

  UPDATE_STUDENT_REQ_REQUEST = '@subscriber/UPDATE_STUDENT_REQ_REQUEST',
  UPDATE_STUDENT_REQ_SUCCESS = '@subscriber/UPDATE_STUDENT_REQ_SUCCESS',
  UPDATE_STUDENT_REQ_ERROR = '@subscriber/UPDATE_STUDENT_REQ_ERROR',

  SEND_STUDENT_MESSAGE_REQUEST = '@subscriber/SEND_STUDENT_MESSAGE_REQUEST',
  SEND_STUDENT_MESSAGE_SUCCESS = '@subscriber/SEND_STUDENT_MESSAGE_SUCCESS',
  SEND_STUDENT_MESSAGE_ERROR = '@subscriber/SEND_STUDENT_MESSAGE_ERROR'
}

export interface Filters {
  startsIn: string;
  endsIn: string;
  status: string;
}

export interface StudentRequestState {
  readonly id: number;
  readonly one: StudentRequest;
  readonly message: string;
  readonly all: StudentRequest[];
  readonly filters: Filters;
}
