import { AxiosError, AxiosResponse } from 'axios';
import { PUBLIC_ROUTES } from 'helpers/constants';

const responseInterceptor = (response: AxiosResponse) => response;

const responseInterceptorError = (error: AxiosError) => {
  const status = error.response?.status;
  const currentPath = window.location.pathname;
  const reloadBlockList = [PUBLIC_ROUTES.LOGIN, PUBLIC_ROUTES.LOGOUT];
  const pathOnBlockList = reloadBlockList.includes(currentPath);

  if (status === 403 && !pathOnBlockList) {
    window.location.replace(PUBLIC_ROUTES.LOGIN);
  }

  if (status === 401 && !pathOnBlockList) {
    window.location.reload();
  }

  return Promise.reject(error);
};

export default { responseInterceptor, responseInterceptorError };
