import { ReactNode } from 'react';

export enum DrawerTypes {
  OPEN = '@Drawer/OPEN',
  CLOSE = '@Drawer/CLOSE'
}

export interface DrawerState {
  readonly isOpen: boolean;
  readonly children: ReactNode | null;
  readonly onHide?: () => void;
  readonly title: string;
  readonly className?: string;
}

export interface DrawerProps {
  title: string;
  onHide?: () => void;
  className?: string;
}

export type DrawerAction = {
  type: DrawerTypes;
  payload: Omit<DrawerState, 'isOpen'>;
};
