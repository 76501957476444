import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 0;

  th,
  td {
    padding: 16px 8px;
  }

  thead {
    tr {
      th {
        font-family: 'Mulish';
        text-align: left;
        color: ${({ theme }) => theme.table.title};

        &.audioOrder,
        &.audioId {
          text-align: center;
        }

        &.audioTitle {
          width: 60%;
        }
      }
    }
  }

  tbody {
    tr {
      transition: background-color 0.4s;

      &:hover {
        background-color: ${({ theme }) => theme.dndt.hover};
      }

      &.isDragging {
        background-color: ${({ theme }) => theme.dndt.focus};
      }

      td {
        &.audioSelect {
          &.isChecked {
            background-color: ${({ theme }) => theme.dndt.hover};
          }
        }

        &.audioOrder,
        &.audioId {
          text-align: center;
        }

        &.audioActions {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;
