import { AboutUs } from 'models/Institutional';
import { RichTextEditor } from 'components';
import * as S from './AboutUsEditor.styles';

interface AboutUsEditorProps {
  aboutUs: AboutUs;
  setAboutUs: React.Dispatch<React.SetStateAction<AboutUs>>;
}

const AboutUsEditor = (props: AboutUsEditorProps) => {
  const { aboutUs, setAboutUs } = props;

  return (
    <S.EditorWrapper>
      <S.FormGroup>
        <S.Fieldset>
          <label htmlFor="aboutUsDescription">Descrição</label>
          <RichTextEditor
            id="aboutUsDescription"
            value={aboutUs.description}
            onTextChange={(ev) =>
              setAboutUs({
                ...aboutUs,
                description: ev.htmlValue || ''
              })
            }
            style={{ height: '400px' }}
          />
        </S.Fieldset>
      </S.FormGroup>
    </S.EditorWrapper>
  );
};

export { AboutUsEditor };
