import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from '../../Button/Button';
import * as S from './InputText.styles';
import { InputTextProps } from './interfaces/InputTextProps';

export const InputText = ({
  label,
  className,
  type: originalType,
  ...props
}: InputTextProps) => {
  const { value, name, placeholder } = props;

  const [passwordShown, setPasswordShown] = useState(false);
  const [type, setType] = useState(originalType);

  useEffect(() => {
    if (originalType === 'password') {
      if (!passwordShown) {
        setType(originalType);
      } else {
        setType('text');
      }
    }
  }, [passwordShown]);

  const isFilled = useMemo(
    () => (value !== undefined && value !== '') || !!placeholder,
    [value, placeholder]
  );

  return (
    <S.InputWrapper
      className="input-text"
      onMouseLeave={() =>
        originalType === 'password' && setPasswordShown(false)
      }
    >
      <input
        className={classNames({
          className,
          filled: isFilled,
          'is--disabled': props.disabled,
          'is--password-input': originalType === 'password'
        })}
        type={type}
        {...props}
      />
      {!!label && <label htmlFor={name}>{label}</label>}
      {originalType === 'password' && (
        <Button
          type="button"
          onClick={() => setPasswordShown(!passwordShown)}
          title="Mostrar senha"
          tabIndex={-1}
          className="p-button-rounded p-button-outlined p-button-secondary p-float-button"
          icon={passwordShown ? 'pi pi-eye' : 'pi pi-eye-slash'}
        />
      )}
    </S.InputWrapper>
  );
};

InputText.defaultProps = {
  type: 'text',
  placeholder: '',
  label: ''
};
