import { Reducer } from 'redux';
import { FolderTypes, FolderState, FolderAction } from './types';
import { FOLDER_INITIAL_STATE } from './utils';

const reducer: Reducer<FolderState, FolderAction> = (
  state: FolderState = FOLDER_INITIAL_STATE,
  action: FolderAction
) => {
  switch (action.type) {
    case FolderTypes.FETCH_ONE_FOLDER_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    case FolderTypes.FETCH_ONE_FOLDER_SUCCESS: {
      return {
        ...state,
        folder: action.payload.folder
      };
    }
    case FolderTypes.FETCH_VIEW_FOLDER_REQUEST: {
      return {
        ...state,
        id: action.payload.id
      };
    }
    case FolderTypes.FETCH_VIEW_FOLDER_SUCCESS: {
      return {
        ...state,
        viewFolder: action.payload.viewFolder
      };
    }

    case FolderTypes.SAVE_FOLDER_REQUEST: {
      return {
        ...state,
        folder: action.payload.folder
      };
    }

    case FolderTypes.EDIT_FOLDER_REQUEST: {
      return {
        ...state,
        folder: action.payload.folder
      };
    }

    case FolderTypes.REMOVE_FOLDER_REQUEST: {
      return {
        ...state,
        folder: action.payload.folder
      };
    }

    case FolderTypes.SET_SELECTED_FOLDER: {
      return {
        ...state,
        folder: action.payload.folder
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
