import Subscription from 'models/Subscription';

export enum SubscriptionTypes {
  FETCH_SUBSCRIPTION_REQUEST = '@dash/FETCH_SUBSCRIPTION_REQUEST',
  FETCH_SUBSCRIPTION_SUCCESS = '@dash/FETCH_SUBSCRIPTION_SUCCESS',
  FETCH_SUBSCRIPTION_ERROR = '@dash/FETCH_SUBSCRIPTION_ERROR'
}

export interface SubscriptionState {
  readonly subscriptions: Subscription[];
}
