import { useMemo, useState } from 'react';
import { FaBan, FaPen } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Subscriber from 'models/Subscriber';
import { showDialog } from 'store/ducks/dialog/actions';
import { Button, DataTableColumnSelector, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './SubscriberDataTable.styles';
import utils from './SubscriberDataTable.utils';

interface SubscriberDataTableProps {
  data: Subscriber[];
  onPressItem: (subscriber: Subscriber) => void;
  onDoubleClick: (subscriber: Subscriber) => void;
  onUnsubscribe: (hubId: string) => void;
}

const SubscriberDataTable = (props: SubscriberDataTableProps) => {
  const { data, onPressItem, onDoubleClick, onUnsubscribe } = props;

  const isNotFullhd = useMediaQuery({ maxWidth: '1368px' });
  const dispatch = useDispatch();

  const handleUnsubscribe = (userId: string) => {
    dispatch(
      showDialog({
        title: 'Confirmação de Cancelamento',
        message: 'Tem certeza que deseja cancelar essa assinatura?',
        mode: 'CONFIRM',
        onConfirmPress: () => onUnsubscribe(userId),
        visible: true
      })
    );
  };

  const getActionButtons = (subscriber: Subscriber) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(subscriber)}
        severity="confirm"
        title="Editar"
        className="p-button-success"
        icon="pi pi-pencil"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleUnsubscribe(subscriber.hubId!)}
        severity="danger"
        title="Cancelar assinatura"
        disabled={subscriber.status === 'CANCELED'}
        className="p-button-danger"
        icon="pi pi-trash"
      >
        <FaBan />
      </Button>
    </S.ActionButtons>
  );

  const updatedAtBody = (subscriber: Subscriber) => (
    <span>{moment(subscriber.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const createdAtBody = (subscriber: Subscriber) => (
    <span>{moment(subscriber.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const handleRowDoubleClick = (subscriber: Subscriber) => {
    onDoubleClick(subscriber);
  };

  const [selectedColumns, setSelectedColumns] = useState<ColumnProps[]>(
    utils.columns
  );

  const columnComponents = useMemo(
    () =>
      selectedColumns.map((column) => (
        <Column
          key={column.field}
          field={column.field}
          header={column.header}
          {...utils.getProps(column, !isNotFullhd)}
        />
      )),
    [selectedColumns, isNotFullhd]
  );

  const dataTableHeader = useMemo(
    () => (
      <DataTableColumnSelector
        options={utils.columns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
    ),
    [selectedColumns]
  );

  return (
    <DataTableLayout>
      <DataTable
        value={data}
        sortField="createdAt"
        sortOrder={-1}
        header={dataTableHeader}
        filterDisplay="row"
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
      >
        <Column
          field="userName"
          header="Nome"
          filter
          filterField="userName"
          filterPlaceholder="Buscar"
          sortable
        />
        {columnComponents}
        <Column
          sortField="createdAt"
          header="Criado em"
          style={{ width: '180px' }}
          body={createdAtBody}
          sortable
        />
        <Column
          sortField="updatedAt"
          header="Atualizado em"
          style={{ width: '180px' }}
          body={updatedAtBody}
          sortable
        />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          body={getActionButtons}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { SubscriberDataTable };
