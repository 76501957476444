import { action } from 'typesafe-actions';
import { CMS_PERMISSION_TYPE } from 'helpers/constants';
import { NavigatorTypes } from './types';

export const setCollapsed = (isCollapsed: boolean) =>
  action(NavigatorTypes.SET_COLLAPSED, { isCollapsed });

export const setWidth = (navigatorWidth: number) =>
  action(NavigatorTypes.SET_WIDTH, { navigatorWidth });

export const setPermissions = (permissions: CMS_PERMISSION_TYPE[]) =>
  action(NavigatorTypes.SET_PERMISSIONS, { permissions });
