import { action } from 'typesafe-actions';
import Log from 'models/Log';
import { LogTypes } from './types';

export const fetchLogsRequest = () => action(LogTypes.FETCH_LOGS_REQUEST);

export const fetchLogsSuccess = (logs: Log[]) =>
  action(LogTypes.FETCH_LOGS_SUCCESS, { logs });

export const fetchLogsError = () => action(LogTypes.FETCH_LOGS_ERROR);

export const fetchLogByIdRequest = (id: number) =>
  action(LogTypes.FETCH_LOG_BY_ID_REQUEST, { id });

export const fetchLogByIdSuccess = (log: Log) =>
  action(LogTypes.FETCH_LOG_BY_ID_SUCCESS, { log });

export const fetchLogByIdError = () => action(LogTypes.FETCH_LOG_BY_ID_ERROR);

export const fetchLogByUserRequest = (id: number) =>
  action(LogTypes.FETCH_LOGS_BY_USER_REQUEST, { id });

export const fetchLogByUserSuccess = (logs: Log[]) =>
  action(LogTypes.FETCH_LOGS_BY_USER_SUCCESS, { logs });

export const fetchLogByUserError = () =>
  action(LogTypes.FETCH_LOGS_BY_USER_ERROR);

export const fetchLogByUserSubscriptionRequest = (id: number) =>
  action(LogTypes.FETCH_LOGS_BY_USER_SUBSCRIPTION_REQUEST, { id });

export const fetchLogByUserSubscriptionSuccess = (logs: Log[]) =>
  action(LogTypes.FETCH_LOGS_BY_USER_SUBSCRIPTION_SUCCESS, { logs });

export const fetchLogByUserSubscriptionError = () =>
  action(LogTypes.FETCH_LOGS_BY_USER_SUBSCRIPTION_ERROR);
