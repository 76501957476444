import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { Tooltip } from 'primereact/tooltip';
import { setCollapsed, setWidth } from 'store/ducks/navigator/action';
import playlegisLogoIcon from 'assets/playlegis-logo-icon.svg';
import playlegisLogoYellow from 'assets/playlegis-logo-yellow.svg';
import ApplicationState from 'helpers/types/ApplicationState';
import { ThemeSwitch } from '../ThemeSwitch/ThemeSwitch';
import * as S from './Navigator.styles';
import { mainMenu, footerMenu, MenuType } from './Navigator.utils';

const Navigator = () => {
  const dispatch = useDispatch();

  const { isCollapsed, navigatorWidth, permissions } = useSelector(
    (state: ApplicationState) => state.navigator
  );

  const navigatorRef = useRef<HTMLDivElement>(null);

  const [logo, setLogo] = useState(playlegisLogoIcon);
  const [menu, setMenu] = useState<MenuType[]>(mainMenu);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    const serializedMenu = mainMenu
      .map((item) => {
        const isOnPage = item.links.find((link) => link.to === path);
        const isOpen = isOnPage ? !isCollapsed : false;

        const newItem = {
          ...item,
          isOpen,
          links: item.links.filter(({ id }) => permissions.includes(id))
        };

        return newItem;
      })
      .filter((a) => a.links.length);

    setMenu(serializedMenu);
  }, [permissions, location]);

  useEffect(() => {
    let newLogo = playlegisLogoIcon;

    if (isCollapsed) {
      setMenu(
        menu.map((sub) => ({
          ...sub,
          isOpen: false
        }))
      );
    } else {
      newLogo = playlegisLogoYellow;

      setMenu(
        menu.map((sub) => ({
          ...sub,
          isOpen: !!sub?.links.find((item) => item.to === location.pathname)
        }))
      );
    }

    if (logo !== newLogo) {
      setLogo(newLogo);
    }
  }, [isCollapsed, location.pathname]);

  useEffect(() => {
    if (isCollapsed) {
      dispatch(setWidth(76));
    } else {
      dispatch(setWidth(277));
    }
  }, [isCollapsed]);

  const handleCollapseChange = () => {
    const newCollapseMode = !isCollapsed;

    dispatch(setCollapsed(newCollapseMode));

    localStorage.setItem('navigatorCollapsed', newCollapseMode.toString());
  };

  const closeAllMenus = (menuId: string) => {
    const serializedMenu = menu.map((sub) =>
      sub.id === menuId
        ? {
            ...sub,
            isOpen: false
          }
        : sub
    );

    setMenu(serializedMenu);
  };

  const handleTabSwitchClick = (menuId: string) => {
    if (!isCollapsed) {
      const serializedMenu = menu.map((sub) =>
        sub.id === menuId
          ? {
              ...sub,
              isOpen: !sub.isOpen
            }
          : {
              ...sub,
              isOpen: false
            }
      );

      setMenu(serializedMenu);
    }
  };

  const handleTabContainerMouseEnter = (menuId: string) => {
    if (isCollapsed) {
      const serializedMenu = menu.map((sub) =>
        sub.id === menuId
          ? {
              ...sub,
              isOpen: true
            }
          : {
              ...sub,
              isOpen: false
            }
      );

      setMenu(serializedMenu);
    }
  };

  const handleTabContainerMouseLeave = (menuId: string) => {
    if (isCollapsed) {
      const serializedMenu = menu.map((sub) => ({
        ...sub,
        isOpen: false
      }));

      setMenu(serializedMenu);
    }
  };

  const getMenuMemo = () =>
    React.useMemo(
      () =>
        menu.map((sub, key) => (
          <S.TabContainer
            className="menu-block"
            onMouseEnter={() => handleTabContainerMouseEnter(sub.id)}
            onMouseLeave={() => handleTabContainerMouseLeave(sub.id)}
            key={`${key.toString()}-${sub.title}`}
          >
            <S.TabSwitch
              $isCollapsed={isCollapsed}
              onClick={() => handleTabSwitchClick(sub.id)}
              type="button"
              className={sub.isOpen ? 'tab--is-open' : 'tab--is-closed'}
              title={
                isCollapsed ? sub.title : 'Clique para expandir/fechar o menu'
              }
            >
              {!isCollapsed ? (
                <>
                  <span>{sub.title}</span>
                  <i>
                    <FaChevronDown />
                  </i>
                </>
              ) : (
                <i>{sub.icon}</i>
              )}
            </S.TabSwitch>

            <S.TabContent
              $isCollapsed={isCollapsed}
              $navigatorWidth={navigatorWidth}
              isOpen={sub.isOpen}
            >
              {isCollapsed && <span className="submenuTitle">{sub.title}</span>}
              {sub?.links.map(({ icon, label, ...rest }) => (
                <S.MainLink
                  key={label}
                  className={location.pathname === rest.to ? 'active' : ''}
                  $isCollapsed={isCollapsed}
                  onClick={() => closeAllMenus(sub.id)}
                  {...rest}
                >
                  <div className="nav-item">
                    <i className="icon">{icon}</i>
                    <span className="label">{label}</span>
                  </div>
                </S.MainLink>
              ))}
            </S.TabContent>
          </S.TabContainer>
        )),
      [menu]
    );

  return (
    <S.ContainerFluid ref={navigatorRef}>
      <Tooltip target=".show-tooltip" />
      <S.Column>
        <S.LogoIcon $isCollapsed={isCollapsed} onClick={handleCollapseChange}>
          <img
            src={logo}
            alt="Logo da Playlegis"
            title={`Clique para exibir o menu ${
              isCollapsed ? 'completo' : 'compactado'
            }`}
          />
        </S.LogoIcon>

        <S.Row className="body" $isCollapsed={isCollapsed}>
          <S.Column className="mainMenu" $isCollapsed={isCollapsed}>
            {getMenuMemo()}
          </S.Column>
        </S.Row>

        <S.Footer $isCollapsed={isCollapsed}>
          <S.FooterMenu>
            {!isCollapsed && <ThemeSwitch />}

            {footerMenu?.map(({ icon, label, ...rest }) => (
              <S.FooterLink
                key={label}
                $isCollapsed={isCollapsed}
                $navigatorWidth={navigatorWidth}
                {...rest}
              >
                {isCollapsed ? (
                  <div
                    className="nav-item show-tooltip"
                    data-pr-tooltip={label}
                  >
                    <span className="icon">{icon}</span>
                  </div>
                ) : (
                  <div className="nav-item" data-pr-tooltip={label}>
                    <span className="icon">{icon}</span>
                    <span className="label">{label}</span>
                  </div>
                )}
              </S.FooterLink>
            ))}
          </S.FooterMenu>
        </S.Footer>
      </S.Column>
    </S.ContainerFluid>
  );
};

export default React.memo(Navigator);
