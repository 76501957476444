import { Checkbox as PRCheckbox, CheckboxProps } from 'primereact/checkbox';
import * as S from './styled';

const Checkbox: React.FC<CheckboxProps> = ({
  ref,
  ...props
}: CheckboxProps) => (
  <S.Container className="p-checkbox-container" htmlFor={props.name}>
    <PRCheckbox inputId={props.name} {...props} />
  </S.Container>
);

export default Checkbox;
