import * as yup from 'yup';
import Quiz from 'models/Quiz';

const quizSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required('O campo "NOME" é obrigatório!'),
  playListId: yup.number().required('A seleção de uma playlist é obrigatória!')
});

export default (quiz: Quiz) =>
  quizSchema.validateSync(quiz, { abortEarly: false });
