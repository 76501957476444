import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  justify-content: center;

  button {
    text-align: right;

    &:first-child {
      margin-right: 8px;
    }
  }
`;

export const Form = styled.form`
  margin-top: 40px;
  width: 95%;
`;

export const InputRadioGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioLabel = styled.span`
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.bold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.secondary};
  padding: 8px 14px;
  flex-grow: 2;
`;

export const PlaylistCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  label {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-position: 50%;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 5px;
    transition: all 0.4s;
    margin-bottom: 12px;
    cursor: pointer;

    input {
      display: none;
    }

    svg {
      opacity: 0.2;
      font-size: 20px;
      transition: all 0.3s;
      color: ${({ theme }) => theme.text.primary};
    }

    &:hover svg {
      opacity: 0.8;
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  .has-cover {
    animation: fadeIn 0.5s;

    svg {
      display: none;
    }
  }

  span {
    text-align: center;
    font-size: 12px;
    color: ${({ theme }) => theme.text.primary};
  }
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  align-items: center;

  .p-multiselect-container {
    flex-grow: 1;
  }

  .p-button {
    width: 50px !important;
    height: 50px;
    margin-left: 10px;
    padding: 19px 20px;
  }
`;
