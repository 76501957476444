import { Reducer } from 'redux';
import { PlanTypes, PlanState, PlanAction } from './types';
import { PLAN_INITIAL_STATE } from './utils';

const reducer: Reducer<PlanState, PlanAction> = (
  state: PlanState = PLAN_INITIAL_STATE,
  action: PlanAction
) => {
  switch (action.type) {
    case PlanTypes.FETCH_PLAN_SUCCESS: {
      return {
        ...state,
        plans: action.payload.plans
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
