/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { UserAdmin, UserPermission } from 'models/User';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer, PasswordChecklist } from 'components';
import { InputText } from 'components/Form';
import { DrawerMode } from 'helpers/types/DrawerMode';
import PasswordChecklistRules from 'helpers/types/PasswordChecklistRules';
import { userValidation } from 'helpers/validation/user';
import { UserAdminPermissionsMultiSelect } from '../PermissionsMultiSelect/UserAdminPermissionsMultiSelect';
import * as S from './UserAdminActionSidebar.styles';

interface UserAdminActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedUser: UserAdmin;
  onConfirmPress: (user: UserAdmin) => void;
  onCancelPress?: () => void;
}

export const passwordChecklistRulesInitialState: PasswordChecklistRules = {
  minLength: false,
  hasNumber: false,
  match: false
};

const UserAdminActionSidebar = (props: UserAdminActionSidebarProps) => {
  const { mode, setMode, selectedUser, onCancelPress, onConfirmPress } = props;

  const dispatch = useDispatch();

  const [user, setUser] = useState<UserAdmin>(selectedUser);
  const [newPassword, setNewPassword] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState<
    UserPermission[]
  >([]);
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordChecklistRules>(passwordChecklistRulesInitialState);

  useEffect(() => {
    setUser(selectedUser);
    setSelectedPermissions(selectedUser.userPermissions);
  }, [selectedUser]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar usuário';
        break;
      case 'OPEN_EDIT':
        title = 'Editar usuário';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    const serializedUser =
      newPassword !== '' ? { ...user, password: newPassword } : user;

    try {
      userValidation(
        serializedUser,
        mode === 'OPEN_ADD' || newPassword !== '',
        newPasswordAgain,
        passwordValidation,
        true
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error',
                life: 10000
              })
            );
          }, index * 200);
        });
      }

      return;
    }

    if (onConfirmPress) onConfirmPress(serializedUser);
    setMode('CLOSE');
    setNewPassword('');
    setNewPasswordAgain('');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
    setNewPassword('');
    setNewPasswordAgain('');
  };

  const handleSelectionChange = (e: any) => {
    setUser({
      ...user,
      userPermissions: e
    });
  };

  const getPasswordLabel = () =>
    mode === 'OPEN_EDIT' ? 'Forçar nova senha' : 'Senha';

  const getPasswordAgainLabel = () =>
    mode === 'OPEN_EDIT' ? 'Confirmar nova senha' : 'Confirmar a senha';

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleChangePasswordAgain = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordAgain(e.target.value);
  };

  const getCpfIsDisabled = useMemo(
    () => mode === 'OPEN_EDIT' && user.cpf !== '',
    [mode, user.cpf]
  );

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Nome"
          name="name"
          type="text"
          value={user.name}
          onChange={(e) =>
            setUser({
              ...user,
              name: e.target.value
            })
          }
        />

        <InputText
          label="Email"
          name="email"
          type="text"
          value={user.email}
          onChange={(e) =>
            setUser({
              ...user,
              email: e.target.value
            })
          }
        />

        <InputText
          label="CPF"
          name="cpf"
          type="text"
          value={user.cpf || ''}
          onChange={(e) =>
            setUser({
              ...user,
              cpf: e.target.value
            })
          }
          disabled={getCpfIsDisabled}
        />

        <PasswordChecklist
          value={newPassword}
          onChange={handleChangePassword}
          valueAgain={newPasswordAgain}
          onChangeAgain={handleChangePasswordAgain}
          onChecklistChange={setPasswordValidation}
          placeholders={{
            password: getPasswordLabel(),
            passwordAgain: getPasswordAgainLabel()
          }}
          inputClasses={{
            password: 'input__user input__user--is-current-password',
            passwordAgain: 'input__user input__user--is-current-password'
          }}
        />

        <UserAdminPermissionsMultiSelect
          onSelectionChange={handleSelectionChange}
          selected={selectedPermissions}
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

UserAdminActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { UserAdminActionSidebar };
