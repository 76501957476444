import styled from 'styled-components';
import { DataTableLayout } from 'components';

export const DataTableWrapper = styled(DataTableLayout)`
  table {
    thead.p-datatable-thead {
      tr:first-of-type > th {
        border-bottom: 0px;
      }

      tr:last-of-type {
        width: 100%;
        display: block;
        margin-bottom: 18px;
      }

      th {
        &.p-filter-column {
          &:first-of-type {
            padding-left: 7px;
            padding-right: 7px;
          }
        }

        &.searchPlaylistName {
          input {
            position: absolute;
            left: 16px;
            top: 51px;
            width: calc(100% - 32px);
            background: ${({ theme }) => theme.section.bg};
            border: 1px solid ${({ theme }) => theme.search.border.color};
            border-radius: 5px;
            padding: 4px 5px;
            transition: all 0.3s;
            color: ${({ theme }) => theme.text.primary};

            &::placeholder {
              transition: all 0.3s;
              color: ${({ theme }) => theme.search.placeholder};
            }

            &:focus,
            &:hover {
              border-color: ${({ theme }) => theme.search.border.hover};

              &::placeholder {
                color: ${({ theme }) => theme.search.border.hover};
              }
            }
          }
        }
      }
    }

    tbody.p-datatable-tbody {
      tr:first-of-type {
        border-top: 1px solid ${({ theme }) => theme.table.border};
      }

      tr {
        cursor: pointer;
      }
    }

    tr.p-datatable-row > td.fileNameColumn {
      span.audioName,
      span.folderName {
        display: flex;
        align-items: center;

        svg {
          font-size: 20px;
          margin-right: 8px;
        }
      }
    }
  }
`;

export { ActionButtons } from 'components/DataTableLayout/DataTableLayout.styles';
