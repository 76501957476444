import { Faq } from 'models/Institutional';
import { RichTextEditor } from 'components';
import { InputText } from 'components/Form';
import * as S from './FaqEditor.styles';

interface FaqEditorProps {
  question: Faq;
  setQuestion: React.Dispatch<React.SetStateAction<Faq>>;
}

const FaqEditor = (props: FaqEditorProps) => {
  const { question, setQuestion } = props;

  return (
    <S.EditorWrapper>
      <S.FormGroup>
        <S.Fieldset>
          <label htmlFor="question">Questão</label>
          <InputText
            id="question"
            name="question"
            title="Questão"
            value={question.title}
            onChange={(e) =>
              setQuestion({ ...question, title: e.target.value })
            }
          />
        </S.Fieldset>
      </S.FormGroup>

      <S.FormGroup>
        <S.Fieldset>
          <label htmlFor="answer">Resposta</label>
          <RichTextEditor
            id="answer"
            value={question.description}
            onTextChange={(ev) =>
              setQuestion({
                ...question,
                description: ev.htmlValue || ''
              })
            }
            style={{ height: '320px' }}
          />
        </S.Fieldset>
      </S.FormGroup>
    </S.EditorWrapper>
  );
};

export { FaqEditor };
