import styled, { css } from 'styled-components';
import { Drawer } from 'components';

export const DrawerWrapper = styled(Drawer)`
  .p-tree {
    width: 98%;
    color: ${({ theme }) => theme.text.primary};
    padding: 0.571em 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  .p-tree .p-tree-container {
    padding: 0.286em;
    margin: 0;
  }
  .p-tree .p-tree-container .p-treenode {
    padding: 0.143em 0;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 0;
    border: 1px solid transparent;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    vertical-align: middle;
    display: inline-block;
    float: none;
    margin: 0 0.286em 0 0;
    color: #848484;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler
    .p-tree-toggler-icon {
    line-height: 1.25em;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    vertical-align: middle;
    display: inline-block;
    margin: 0 0.286em 0 0;
    color: #848484;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    padding: 0.286em 0;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin: 0 0.286em 0 0;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background-color: ${({ theme }) => theme.tree.focus.bg};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler,
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon {
    color: ${({ theme }) => theme.text.primary};
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-checkbox
    .p-checkbox-box {
    border-color: ${({ theme }) => theme.text.primary};
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight-contextmenu {
    border: 1px dashed ${({ theme }) => theme.tree.focus.bg};
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):not(.p-disabled):hover {
    background-color: ${({ theme }) => theme.multiselect.item.hover};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):not(.p-disabled):hover
    .p-treenode-icon {
    color: ${({ theme }) => theme.text.primary};
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content > span {
    line-height: inherit;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-treenode-dragover {
    background-color: ${({ theme }) => theme.multiselect.item.hover};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: ${({ theme }) => theme.tree.focus.bg};
  }
  .p-tree.p-tree-horizontal {
    padding-left: 0;
    padding-right: 0;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    background-color: ${({ theme }) => theme.text.primary};
    color: ${({ theme }) => theme.text.primary};
    padding: 0.571em 1em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: ${({ theme }) => theme.tree.focus.bg};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content
    .p-checkbox
    .p-icon {
    color: ${({ theme }) => theme.tree.focus.bg};
  }
  .p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content
    .p-treenode-label:not(.p-highlight):not(.p-disabled):hover {
    background-color: inherit;
    color: inherit;
  }
  .p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):not(.p-disabled):hover {
    background-color: ${({ theme }) => theme.multiselect.item.hover};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 2em;
  }
  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -0.5em;
    right: 0.8em;
    color: ${({ theme }) => theme.tree.focus.bg};
  }

  .p-inputtext {
    border-radius: 6px;
    color: ${({ theme }) => theme.text.primary};
    background-color: ${({ theme }) => theme.multiselect.search};
    border: 1px solid ${({ theme }) => theme.table.border};
    padding: 0.429em;

    &::placeholder {
      color: ${({ theme }) => theme.search.placeholder};
    }
  }

  .p-multiselect-filter-icon {
    color: ${({ theme }) => theme.text.secondary};
    top: 50%;
    margin-top: -0.5em;
    right: 0.75em;
    left: auto;
  }

  .p-treetable .p-treetable-header,
  .p-treetable .p-treetable-footer {
    color: ${({ theme }) => theme.text.primary};
    padding: 0.571em 1em;
    text-align: center;
  }
  .p-treetable .p-treetable-header {
    border-bottom: 0 none;
    font-weight: 700;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .p-treetable .p-treetable-footer {
    border-top: 0 none;
    font-weight: 700;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-treetable .p-treetable-thead > tr > th {
    padding: 0.571em 0.857em;
    font-weight: 700;
    color: ${({ theme }) => theme.text.primary};

    text-align: center;
  }
  .p-treetable .p-treetable-tfoot > tr > td {
    padding: 0.571em 0.857em;
    font-weight: 700;
    color: ${({ theme }) => theme.text.primary};

    text-align: center;
  }
  .p-treetable .p-sortable-column {
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #848484;
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 18px;
    min-width: 18px;
    line-height: 18px;
    display: inline-block;
    color: ${({ theme }) => theme.tree.focus.bg};
    background-color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable
    .p-sortable-column:not(.p-highlight):hover
    .p-sortable-column-icon {
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-sortable-column:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
    box-shadow: inset 0 0 0 0.2em #8dcdff;
  }
  .p-treetable .p-sortable-column.p-highlight {
    background-color: ${({ theme }) => theme.tree.focus.bg};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-editable-column input {
    font-size: 14px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .p-treetable .p-treetable-tbody > tr {
    background: ${({ theme }) => theme.text.primary};
    color: ${({ theme }) => theme.text.primary};
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
  }
  .p-treetable .p-treetable-tbody > tr > td {
    background: inherit;
    padding: 0.429em 0.857em;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    color: #848484;
    vertical-align: middle;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background-color: ${({ theme }) => theme.tree.focus.bg};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight-contextmenu {
    background-color: #5ab7ff;
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable
    .p-treetable-tbody
    > tr.p-highlight-contextmenu
    .p-treetable-toggler {
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-treetable-tbody > tr:focus {
    outline: none;
  }
  .p-treetable .p-treetable-tbody > tr:focus + tr > td {
    box-shadow: 0 -0.2em 0 0 #8dcdff;
  }
  .p-treetable .p-treetable-tbody > tr:focus > td {
    box-shadow: 0 -0.2em 0 0 #8dcdff, 0 0.2em 0 0 #8dcdff;
  }
  .p-treetable .p-treetable-tbody > tr:focus > td:first-child {
    box-shadow: 0 -0.2em 0 0 #8dcdff, 0 0.2em 0 0 #8dcdff,
      -0.1em -0.1em 0 0.1em #8dcdff;
  }
  .p-treetable .p-treetable-tbody > tr:focus > td:last-child {
    box-shadow: 0 -0.2em 0 0 #8dcdff, 0 0.2em 0 0 #8dcdff,
      0.1em -0.1em 0 0.1em #8dcdff;
  }
  .p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.multiselect.item.hover};
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover
    .p-treetable-toggler {
    color: ${({ theme }) => theme.text.primary};
  }
  .p-treetable .p-column-resizer-helper {
    background-color: ${({ theme }) => theme.tree.focus.bg};
  }
  .p-treetable .p-treetable-scrollable-header,
  .p-treetable .p-treetable-scrollable-footer {
  }

  .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px;
  }
  .p-checkbox .p-checkbox-box {
    border: 1px solid ${({ theme }) => theme.checkbox.border};
    background-color: ${({ theme }) => theme.checkbox.background};
    width: 20px;
    height: 20px;
    text-align: center;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    border-color: #212121;
  }
  .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.2em #8dcdff; */
    border-color: ${({ theme }) => theme.checkbox.hover.bg};
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: ${({ theme }) => theme.checkbox.hover.border};
    background-color: ${({ theme }) => theme.checkbox.hover.bg};
    color: #ffffff;
  }
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: ${({ theme }) => theme.yellowDarker};
    background-color: ${({ theme }) => theme.yellowDarker};
    color: #ffffff;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    overflow: hidden;
    position: relative;
    font-size: 18px;
  }
`;
