import { action } from 'typesafe-actions';
import StudentRequest from 'models/StudentRequest';
import { StudentRequestTypes, Filters } from './types';

export const fetchStudentsReqRequest = (filters: Filters) =>
  action(StudentRequestTypes.FETCH_STUDENTS_REQ_REQUEST, { filters });

export const fetchStudentsReqSuccess = (all: StudentRequest[]) =>
  action(StudentRequestTypes.FETCH_STUDENTS_REQ_SUCCESS, { all });

export const fetchStudentsReqError = () =>
  action(StudentRequestTypes.FETCH_STUDENTS_REQ_ERROR);

export const updateStudentReqRequest = (
  one: StudentRequest,
  filters: Filters
) => action(StudentRequestTypes.UPDATE_STUDENT_REQ_REQUEST, { one, filters });

export const updateStudentReqSuccess = () =>
  action(StudentRequestTypes.UPDATE_STUDENT_REQ_SUCCESS);

export const updateStudentReqError = () =>
  action(StudentRequestTypes.UPDATE_STUDENT_REQ_ERROR);

export const sendStudentMessageRequest = (
  one: StudentRequest,
  message: string
) => action(StudentRequestTypes.SEND_STUDENT_MESSAGE_REQUEST, { one, message });

export const sendStudentMessageSuccess = () =>
  action(StudentRequestTypes.SEND_STUDENT_MESSAGE_SUCCESS);

export const sendStudentMessageError = () =>
  action(StudentRequestTypes.SEND_STUDENT_MESSAGE_ERROR);
