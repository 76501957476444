/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import moment from 'moment';
import Log from 'models/Log';
import { Drawer } from 'components';
import { DrawerMode } from 'helpers/types/DrawerMode';
import * as S from './AdminLogActionSidebar.styles';

interface AdminLogActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedLog: Log;
  onCancelPress?: () => void;
}

const AdminLogActionSidebar = (props: AdminLogActionSidebarProps) => {
  const { mode, setMode, selectedLog, onCancelPress } = props;

  const [log, setLog] = useState<Log>(selectedLog);

  useEffect(() => {
    setLog(selectedLog);
  }, [selectedLog]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_VIEW':
        title = 'LOG DETALHADO';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  const handleUnknown = (item: unknown[]) => {
    switch (item[0]) {
      case 'updatedAt':
        return moment(item[1] as string).format('DD/MM/YYYY HH:mm');
      case 'createdAt':
        return moment(item[1] as string).format('DD/MM/YYYY HH:mm');
      default:
        break;
    }

    if (item[1] === false) {
      return 'false';
    }
    if (item[1] === null) {
      return 'null';
    }

    return item[1] as string;
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.SidebarTable className="table_general">
        <tbody>
          <tr>
            <th>Usuário</th>
            <td>{log.userId}</td>
          </tr>
          {log.userAgent && (
            <tr>
              <th>Agente do usuário: </th>
              <td>{log.userAgent}</td>
            </tr>
          )}
          <tr>
            <th>Nível de acesso</th>
            <td>{log.isAdmin ? 'Administrador' : 'Comum'}</td>
          </tr>
          {log.createdAt && (
            <tr>
              <th>Criado em</th>
              <td>{moment(log.createdAt).format('DD/MM/YYYY HH:mm')}</td>
            </tr>
          )}
          {log.updatedAt && (
            <tr>
              <th>Atualizado em</th>
              <td>{moment(log.updatedAt).format('DD/MM/YYYY HH:mm')}</td>
            </tr>
          )}
          {log.content?.model && (
            <tr>
              <th>Recursado modificado</th>
              <td>{log.content?.model}</td>
            </tr>
          )}
          {log.content?.action && (
            <tr>
              <th>Ação</th>
              <td>{log.content?.action}</td>
            </tr>
          )}
          {log.content?.entityID && (
            <tr>
              <th>Recurso (ID): </th>
              <td>{log.content?.entityID}</td>
            </tr>
          )}
        </tbody>
      </S.SidebarTable>

      {log.content.from && (
        <S.SidebarTable className="table_from">
          <caption>Dados iniciais</caption>
          <tbody>
            <tr style={{ textAlign: 'left' }}>
              <th>Dado</th>
              <th>Valor</th>
            </tr>
            {log.content.from &&
              Object.entries(log.content.from).map((item, key) => (
                <tr key={`${key.toString()}-${item}`} className="table-row">
                  <td>{`${item[0]}`}</td>
                  <td>{handleUnknown(item)}</td>
                </tr>
              ))}
          </tbody>
        </S.SidebarTable>
      )}

      {log.content.to && (
        <S.SidebarTable className="table_to">
          <caption>Dados modificados</caption>
          <tbody>
            <tr>
              <th>Dado</th>
              <th>Valor</th>
            </tr>
            {log.content.to &&
              Object.entries(log.content.to).map((item, key) => (
                <tr key={`${key.toString()}-${item}`} className="table-row">
                  <td>{`${item[0]}`}</td>
                  <td>{handleUnknown(item)}</td>
                </tr>
              ))}
          </tbody>
        </S.SidebarTable>
      )}
    </Drawer>
  );
};

AdminLogActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { AdminLogActionSidebar };
