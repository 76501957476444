/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */

// import { RadioButton, RadioButtonProps } from 'primereact/radiobutton';
import { RadioLabel } from './styled';

type HTMLInput = JSX.IntrinsicElements['input'];

interface Props extends HTMLInput {
  name: string;
  label: string;
  value: string;
}

const InputRadio: React.FC<Props> = (props: Props) => {
  const { name, label, value, onChange } = props;

  return (
    <RadioLabel htmlFor={value}>
      <input
        type="radio"
        onChange={(e) => (onChange ? onChange(e) : {})}
        id={value}
        {...props}
      />
      <span className="radioCheckmark" />
      <span className="radioLabel">{label}</span>
    </RadioLabel>
  );
};

export default InputRadio;
