import { action } from 'typesafe-actions';
import TeachingInstitution from 'models/TeachingInstitution';
import { TeachingInstitutionTypes } from './types';

export const fetchTeachingInstitutionsRequest = () =>
  action(TeachingInstitutionTypes.FETCH_TEACHING_INSTITUTIONS_REQUEST);

export const fetchTeachingInstitutionsSuccess = (all: TeachingInstitution[]) =>
  action(TeachingInstitutionTypes.FETCH_TEACHING_INSTITUTIONS_SUCCESS, { all });

export const fetchTeachingInstitutionsError = () =>
  action(TeachingInstitutionTypes.FETCH_TEACHING_INSTITUTIONS_ERROR);

export const fetchOneTeachingInstitutionRequest = (id: number) =>
  action(TeachingInstitutionTypes.FETCH_ONE_TEACHING_INSTITUTION_REQUEST, {
    id
  });

export const fetchOneTeachingInstitutionSuccess = (
  one: TeachingInstitution[]
) =>
  action(TeachingInstitutionTypes.FETCH_ONE_TEACHING_INSTITUTION_SUCCESS, {
    one
  });

export const fetchOneTeachingInstitutionError = () =>
  action(TeachingInstitutionTypes.FETCH_ONE_TEACHING_INSTITUTION_ERROR);

export const createTeachingInstitutionRequest = (one: TeachingInstitution) =>
  action(TeachingInstitutionTypes.CREATE_TEACHING_INSTITUTION_REQUEST, { one });

export const createTeachingInstitutionSuccess = () =>
  action(TeachingInstitutionTypes.CREATE_TEACHING_INSTITUTION_SUCCESS);

export const createTeachingInstitutionError = () =>
  action(TeachingInstitutionTypes.CREATE_TEACHING_INSTITUTION_ERROR);

export const updateTeachingInstitutionRequest = (one: TeachingInstitution) =>
  action(TeachingInstitutionTypes.UPDATE_TEACHING_INSTITUTION_REQUEST, { one });

export const updateTeachingInstitutionSuccess = () =>
  action(TeachingInstitutionTypes.UPDATE_TEACHING_INSTITUTION_SUCCESS);

export const updateTeachingInstitutionError = () =>
  action(TeachingInstitutionTypes.UPDATE_TEACHING_INSTITUTION_ERROR);

export const deleteTeachingInstitutionRequest = (one: TeachingInstitution) =>
  action(TeachingInstitutionTypes.DELETE_TEACHING_INSTITUTION_REQUEST, { one });

export const deleteTeachingInstitutionSuccess = () =>
  action(TeachingInstitutionTypes.DELETE_TEACHING_INSTITUTION_SUCCESS);

export const deleteTeachingInstitutionError = () =>
  action(TeachingInstitutionTypes.DELETE_TEACHING_INSTITUTION_ERROR);
