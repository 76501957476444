import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchUsersAppRequest,
  fetchUsersAppSuccess,
  fetchUsersAppError,
  fetchOneUserAppSuccess,
  fetchOneUserAppError,
  createUserAppSuccess,
  createUserAppError,
  updateUserAppSuccess,
  updateUserAppError,
  deleteUserAppSuccess,
  deleteUserAppError
} from './actions';
import { Action } from './index';

export function* fetchUsersApp() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const response: ConnectorResponse = yield call(
      Connector().get,
      'users/list?isAdmin=false'
    );

    yield put(fetchUsersAppSuccess(response.data));
  } catch (err: any) {
    yield put(fetchUsersAppError());
    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* fetchOneUserApp(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { data: user }: ConnectorResponse = yield call(
      Connector().get,
      `users?id=${action.payload.id}`
    );

    yield put(fetchOneUserAppSuccess(user));
  } catch (err: any) {
    yield put(fetchOneUserAppError());
    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* createUserApp(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const user = {
      name: action.payload.one.name,
      email: action.payload.one.email,
      password: action.payload.one.password,
      isAdmin: false
    } as any;

    yield call(Connector().post, 'users/create/app', user);

    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Dados criados com sucesso',
        severity: 'success'
      })
    );
    yield put(createUserAppSuccess());
    yield put(fetchUsersAppRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Falha ao criar dados',
        severity: 'error'
      })
    );
    yield put(createUserAppError());

    if (err.response.status === 409) {
      yield put(
        showToast({
          summary: 'Usuários - App/Web',
          detail: 'Email ou CPF já cadastrados',
          severity: 'error'
        })
      );
    } else {
      yield put(
        showToast({
          summary: 'Usuários - App/Web',
          detail: 'Falha ao criar dados',
          severity: 'error'
        })
      );
    }
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* updateUserApp(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const user = action.payload.one;

    yield call(Connector().put, `users?id=${user.id}`, user);

    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
    yield put(updateUserAppSuccess());
    yield put(fetchUsersAppRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(updateUserAppError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* deleteUserApp(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const user = action.payload.one;

    yield call(Connector().delete, `users?id=${user.id}`);

    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
    yield put(deleteUserAppSuccess());
    yield put(fetchUsersAppRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Usuários - App/Web',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(deleteUserAppError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}
