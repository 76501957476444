import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment';
import { mainMenu } from 'components/Navigator/Navigator.utils';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';

interface AuthProps {
  type?: string;
}

const AuthRoute: React.FC<AuthProps> = (props: AuthProps) => {
  const { type } = props;
  const { pathname } = useLocation();

  const token = Cookies.get('AuthToken');
  const tokenExpires = localStorage.getItem('@tokenExpiresIn');
  const isTokenValid =
    tokenExpires && moment().diff(moment(tokenExpires), 'days') < 0;

  const isAuthUser = token && isTokenValid;

  // TODO: verificar todos os lugares que usam os métodos atob e btoa e trocar por Buffer.from(str, 'base64') e buf.toString('base64') (estudar isso antes)
  const cookies = Cookies.get(btoa('AuthAccess')) || '';

  if (cookies) {
    const activePathId =
      mainMenu
        .map(({ links }) => links.find(({ to }) => to === pathname))
        .filter((item) => item !== undefined)[0]?.id || '';

    const permissions: string[] = JSON.parse(cookies).map((p: string) =>
      atob(p)
    );

    if (pathname === '/') {
      const pageToRedirect =
        mainMenu
          .map(({ links }) => links.find(({ id }) => id === permissions[0]))
          .filter((item) => item !== undefined)[0]?.to || '';

      if (!permissions.includes('DASH')) {
        return <Navigate to={pageToRedirect} />;
      }

      return <Outlet />;
    }

    const allowed = ['/403', '/login', '/sair', '/lixeira'];

    if (!allowed.includes(pathname) && !permissions.includes(activePathId)) {
      return <Navigate to={PUBLIC_ROUTES.FORBIDDEN} />;
    }
  }

  if (type === 'private' && !isAuthUser) {
    return <Navigate to={PUBLIC_ROUTES.LOGIN} />;
  }

  if (isAuthUser && pathname.includes('/login')) {
    return <Navigate to={PRIVATE_ROUTES.HOME} />;
  }

  return <Outlet />;
};

AuthRoute.defaultProps = {
  type: 'private'
};

export default AuthRoute;
