import * as S from './Card.styles';
import { CardProps } from './interfaces/CardProps';

const Card = (props: CardProps) => {
  const { title, children } = props;

  return (
    <S.CardColumn className="card__card-column">
      <S.CardTitle>{title}</S.CardTitle>

      {children}
    </S.CardColumn>
  );
};

Card.defaultProps = {
  onRow: false
};

export { Card };
