import * as Yup from 'yup';
import { showToast } from 'store/ducks/toast/actions';

const triggerErrorToasts = (dispatch: any, err: Yup.ValidationError) => {
  err.inner.forEach((err_, index) => {
    setTimeout(() => {
      dispatch(
        showToast({
          summary: 'Falha na validação',
          detail: err_.message,
          severity: 'error',
          closable: true,
          life: 10000
        })
      );
    }, index);
  });
};

export default { triggerErrorToasts };
