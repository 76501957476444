import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import TeachingInstitution from 'models/TeachingInstitution';
import { showDialog } from 'store/ducks/dialog/actions';
import * as TeachingInstitutionSagas from 'store/ducks/teachingInstitution/actions';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './TeachingInstitutionsDataTable.styles';

interface TeachingInstitutionsDataTableProps {
  data: TeachingInstitution[];
  onPressItem: (quiz: TeachingInstitution) => void;
}

const TeachingInstitutionsDataTable = (
  props: TeachingInstitutionsDataTableProps
) => {
  const { data, onPressItem } = props;

  const dispatch = useDispatch();

  const handleRemovePressed = (teachingInstitution: TeachingInstitution) => {
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover usuário',
        message: `Deseja reamente remover a instituição "${teachingInstitution.name}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () =>
          dispatch(
            TeachingInstitutionSagas.deleteTeachingInstitutionRequest(
              teachingInstitution
            )
          )
      })
    );
  };

  const getActionButtons = (teachingInstitution: TeachingInstitution) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(teachingInstitution)}
        severity="confirm"
        className="p-button-success"
        icon="pi pi-pencil"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleRemovePressed(teachingInstitution)}
        severity="danger"
        className="p-button-danger"
        icon="pi pi-trash"
      >
        <FaTrash />
      </Button>
    </S.ActionButtons>
  );

  const updatedAtBody = (teachingInstitution: TeachingInstitution) => (
    <span>
      {moment(teachingInstitution.updatedAt).format('DD/MM/YYYY HH:mm')}
    </span>
  );
  const createdAtBody = (teachingInstitution: TeachingInstitution) => (
    <span>
      {moment(teachingInstitution.createdAt).format('DD/MM/YYYY HH:mm')}
    </span>
  );

  const handleRowDoubleClick = (data: TeachingInstitution) => {
    onPressItem(data);
  };

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        value={data}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        filterDisplay="row"
      >
        <Column
          field="id"
          header="ID"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          style={{ width: '60px' }}
          sortable
        />
        <Column
          field="name"
          header="Nome"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="cnpj"
          header="CNPJ"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="code"
          header="Código"
          style={{ width: '145px' }}
          sortable
        />
        <Column
          sortField="createdAt"
          header="Criado em"
          style={{ width: '145px' }}
          body={createdAtBody}
          sortable
        />
        <Column
          sortField="updatedAt"
          header="Atualizado em"
          style={{ width: '160px' }}
          body={updatedAtBody}
          sortable
        />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          body={getActionButtons}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { TeachingInstitutionsDataTable };
