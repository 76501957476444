import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { fetchViewFolderRequest } from '../folder/actions';
import { showToast } from '../toast/actions';
import {
  downloadAudioSuccess,
  downloadAudioError,
  editAudioSuccess,
  editAudioError,
  removeAudioSuccess,
  removeAudioError
} from './actions';
import { AudioAction } from './types';

export function* editAudio(action: AudioAction) {
  try {
    const obj = action.payload.audio;

    const { id } = action.payload.audio;
    const { parentId } = action.payload.audio;

    const data = {
      title: obj.title,
      description: obj.description,
      transcription: obj.transcription,
      status: obj.status
    };

    yield call(Connector().put, `audios?id=${id}`, data);

    yield put(
      showToast({
        summary: 'Áudio',
        detail: 'Dados editados com sucesso',
        severity: 'success'
      })
    );
    yield put(editAudioSuccess());
    yield put(fetchViewFolderRequest(parentId || 0));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Áudio',
        detail: 'Falha ao editar dados',
        severity: 'error'
      })
    );
    yield put(editAudioError());
  }
}

export function* removeAudio(action: AudioAction) {
  try {
    const { parentId } = action.payload.audio;
    yield call(
      Connector().delete,
      `audios/in-trash?id=${action.payload.audio.id}`
    );

    yield put(
      showToast({
        summary: 'Áudio',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
    yield put(removeAudioSuccess());
    yield put(fetchViewFolderRequest(parentId));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Áudio',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(removeAudioError());
  }
}

export function* downloadAudio(action: AudioAction) {
  try {
    const { id } = action.payload;

    const { data }: ConnectorResponse = yield call(
      Connector().get,
      `audios/url-download?id=${id}`
    );

    yield put(downloadAudioSuccess(data.url));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Áudio',
        detail: 'Falha ao buscar áudio',
        severity: 'error'
      })
    );
    yield put(downloadAudioError());
  }
}
