import { BiRefresh } from 'react-icons/bi';
import Lottie from 'react-lottie-player';
import RoundCircleAnimation from 'assets/lottie/round-circle.json';
import { LoadingIndicatorProps } from './interfaces/LoadingIndicatorProps';
import * as S from './LoadingIndicator.styles';

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const { isLoading, position, showRefreshButton, onRefresh, size } = props;

  const getLoadingSize = () =>
    size === 'default'
      ? { width: 100, height: 100 }
      : { width: 80, height: 80 };

  const getRefreshSize = () =>
    size === 'default'
      ? { width: 100, height: 100, padding: 32 }
      : { width: 80, height: 80, padding: 20 };

  const handleNotLoading = () =>
    showRefreshButton ? (
      <S.RefreshIndicator onClick={onRefresh} title="Recarregar informações">
        <BiRefresh style={getRefreshSize()} />
      </S.RefreshIndicator>
    ) : null;

  return (
    <S.LoadingIndicatorContainer $position={position || 'relative'}>
      {isLoading ? (
        <Lottie
          loop
          play
          animationData={RoundCircleAnimation}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice'
          }}
          style={getLoadingSize()}
        />
      ) : (
        handleNotLoading()
      )}
    </S.LoadingIndicatorContainer>
  );
};

LoadingIndicator.defaultProps = {
  position: 'relative',
  showRefreshButton: false,
  onRefresh: () => {},
  size: 'default'
};

export { LoadingIndicator };
