import { useEffect, useState } from 'react';
import { FaFilePdf, FaFileImage, FaFile, FaTh, FaList } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import StudentRequest from 'models/StudentRequest';
import { fetchOneSubscriberRequest } from 'store/ducks/subscriber/actions';
import { Button } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './StudentRequestInformationTable.styles';

interface StudentRequestInformationTableProps {
  data: StudentRequest;
}

const StudentRequestInformationTable = (
  props: StudentRequestInformationTableProps
) => {
  const { data } = props;

  const [displayMode, toggleDisplayMode] = useState('list');

  const subscriber = useSelector(
    (state: ApplicationState) => state.subscriber.one
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOneSubscriberRequest(data.hubId));
  }, []);

  const formatDate = (date: Date | undefined, addYear?: boolean) => {
    if (date && addYear) {
      return moment(date).add('years', 1).format('DD/MM/YYYY HH:mm');
    }

    return date && moment(date).format('DD/MM/YYYY HH:mm');
  };

  const formatSubscriptionStatus = (status: string | undefined) => {
    switch (status) {
      case 'ENABLED':
        return 'Ativa';
      case 'DISABLED':
        return 'Desativada';
      case 'OVERDUE':
        return 'Atrasada';
      case 'CANCELED':
        return 'Cancelada';
      case 'IN_TEST':
        return 'Em teste';
      default:
        return '';
    }
  };

  const formatRequestStatus = (status: string) => {
    switch (status) {
      case 'APPROVED':
        return 'Ativa';
      case 'DISAPPROVED':
        return 'Negada';
      case 'PENDING':
        return 'Pendente';
      default:
        return '';
    }
  };

  const handleFileIcon = (fileName: string) => {
    const fileExtension = fileName.split('.').pop();

    if (
      fileExtension === 'JPG' ||
      fileExtension === 'jpg' ||
      fileExtension === 'PNG' ||
      fileExtension === 'png'
    ) {
      return <FaFileImage />;
    }
    if (fileExtension === 'PDF' || fileExtension === 'pdf') {
      return <FaFilePdf />;
    }
    return <FaFile />;
  };

  return (
    <S.TableWrapper>
      <S.Table>
        <tbody>
          <tr>
            <th>Nome</th>
            <td>{subscriber.userName}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{subscriber.userEmail}</td>
          </tr>
          <tr>
            <th>CPF</th>
            <td>{subscriber.cpf}</td>
          </tr>
          <tr>
            <th>Status da assinatura</th>
            <td>{formatSubscriptionStatus(subscriber.status)}</td>
          </tr>
          <tr>
            <th>Status da solicitação</th>
            <td>{formatRequestStatus(data.status)}</td>
          </tr>
          <tr>
            <th>Cadastro em</th>
            <td>{formatDate(subscriber.createdAt)}</td>
          </tr>
          <tr>
            <th>Renovação em</th>
            <td>{formatDate(subscriber.updatedAt, true)}</td>
          </tr>
        </tbody>
      </S.Table>

      <S.StudentInfo>
        {data.status === 'DISAPPROVED' && (
          <S.Section>
            <S.SectionTitle>Motivo de reprovação</S.SectionTitle>

            <S.Blockquote>{data.reasonRepprove}</S.Blockquote>
          </S.Section>
        )}

        <S.Section>
          <S.DownloadSectionHeader>
            <S.SectionTitle>Anexos</S.SectionTitle>

            <S.TogglerGroup>
              <Button
                type="button"
                title="Visualização em grade"
                onClick={() => toggleDisplayMode('grid')}
                className="noMargin"
              >
                <FaTh />
              </Button>
              <Button
                type="button"
                title="Visualização em lista"
                onClick={() => toggleDisplayMode('list')}
                className="noMargin"
              >
                <FaList />
              </Button>
            </S.TogglerGroup>
          </S.DownloadSectionHeader>

          <S.ButtonGroup mode={displayMode}>
            {data.files.length > 0 &&
              data.files.map(({ url, fileName }) => (
                <S.DownloadButton
                  key={fileName}
                  title={fileName}
                  mode={displayMode}
                  onClick={() => window.open(url)}
                >
                  {handleFileIcon(fileName)}
                  {displayMode === 'list'
                    ? fileName
                    : `${fileName.substring(0, 24)}...`}
                </S.DownloadButton>
              ))}
          </S.ButtonGroup>
        </S.Section>
      </S.StudentInfo>
    </S.TableWrapper>
  );
};

export { StudentRequestInformationTable };
