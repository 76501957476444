import React from 'react';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { UserApp } from 'models/User';
import { DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import * as S from './DetailedLogDataTable.styles';

interface DetailedLogDataTableProps {
  data: UserApp[];
  onDoubleClick: (user: UserApp) => void;
}

const DetailedLogDataTable = (props: DetailedLogDataTableProps) => {
  const { data, onDoubleClick } = props;

  const handleRowDoubleClick = (user: UserApp) => {
    onDoubleClick(user);
  };

  const getUpdatedAtBody = (user: UserApp) => (
    <span>{moment(user.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
  );
  const getCreatedAtBody = (user: UserApp) => (
    <span>{moment(user.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const getAccessLevel = (user: UserApp) =>
    user.isAdmin ? (
      <span className="admin">Administrador</span>
    ) : (
      <span className="regular">Comum</span>
    );

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        paginator
        rows={10}
        value={data}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        filterDisplay="row"
      >
        <Column field="id" header="ID" style={{ width: '60px' }} sortable />
        <Column
          field="name"
          header="Nome"
          className="fileNameColumn"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          sortField="isAdmin"
          header="Nível de acesso"
          style={{ width: '165px' }}
          body={getAccessLevel}
          sortable
        />
        <Column
          sortField="createdAt"
          header="Criado em"
          style={{ width: '145px' }}
          body={getCreatedAtBody}
          sortable
        />
        <Column
          sortField="updatedAt"
          header="Atualizado em"
          style={{ width: '160px' }}
          body={getUpdatedAtBody}
          sortable
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { DetailedLogDataTable };
