import styled from 'styled-components';

export const ImportWrapper = styled.form``;

export const ButtonGroup = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  justify-content: flex-end;

  button {
    text-align: right;

    &:first-child {
      margin-right: 8px;
    }
  }
`;

export const FileUploadWrapper = styled.div`
  .p-button {
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.text.secondary};
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 4px;
    padding: 15px;
    transition: all 0.3s !important;

    &:hover,
    &:focus {
      .p-button-label,
      .p-button-icon {
        color: white;
      }
    }

    .p-button-label {
      font-family: 'Mulish', sans-serif;
      font-size: 14px;
      font-weight: ${({ theme }) => theme.weight.bold};
      text-align: left;
      transition: all 0.3s !important;
    }
  }
`;

export const DownloadButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.btn.info.color};
  border-radius: 10px;
  flex-grow: 1;
  color: ${({ theme }) => theme.btn.info.color};
  transition: opacity 0.3s;

  svg {
    font-size: 48px;
    margin-bottom: 12px;
  }

  &:hover {
    opacity: 0.5;
  }
`;
