import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { showToast } from 'store/ducks/toast/actions';
import { Button, RichTextEditor } from 'components';
import * as S from './TermsEditor.styles';

interface TermsEditorProps {
  openTab: boolean;
  onOpenTabChange: () => void;
  title: string;
  textValue: string;
  onTextValueChange: (event: { htmlValue: string | null }) => void;
  onSubmit: () => void;
}

const TermsEditor = (props: TermsEditorProps) => {
  const {
    openTab,
    onOpenTabChange,
    title,
    textValue,
    onTextValueChange,
    onSubmit
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = () => {
    try {
      Yup.object()
        .shape({
          textValue: Yup.string().required('O campo título é obrigatório!')
        })
        .validateSync({ textValue }, { abortEarly: false });

      onSubmit();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  return (
    <S.Form as="div" className="institutional__form">
      <S.EditorHeader>
        <h2>{title}</h2>

        <S.ButtonGroup>
          <Button type="button" onClick={handleSubmit} disabled={!openTab}>
            Salvar
          </Button>

          <Button type="button" onClick={onOpenTabChange} severity="secondary">
            {openTab ? 'Fechar editor' : 'Abrir editor'}
          </Button>
        </S.ButtonGroup>
      </S.EditorHeader>

      <S.EditorWrapper $visible={openTab}>
        <RichTextEditor value={textValue} onTextChange={onTextValueChange} />
      </S.EditorWrapper>
    </S.Form>
  );
};

export { TermsEditor };
