import { FileStatus } from 'models/FileStatus';
import { FolderState } from './types';

export const FOLDER_INITIAL_STATE: FolderState = {
  id: 0,
  parentId: 0,
  folder: {
    id: 0,
    name: '',
    audios: [],
    Folder: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    removedAt: new Date(),
    description: '',
    narratorName: '',
    parentId: 0,
    removed: false,
    status: FileStatus.DISABLED,
    userId: 0
  },
  viewFolder: {
    id: 0,
    name: '',
    audios: [],
    Folder: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    removedAt: new Date(),
    description: '',
    narratorName: '',
    parentId: 0,
    removed: false,
    status: FileStatus.DISABLED,
    userId: 0
  }
};
