import styled from 'styled-components';

export const Container = styled.div`
height: 100%;
padding: 16px;
background-color: ${({ theme }) => theme.section.bg};
box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
border-radius: 6px;
font-size: 14px;

.p-datatable-thead > tr > th {
    padding-bottom: 16px;
}

table {
  color: ${({ theme }) => theme.text.primary};

  thead, td {
    border-bottom: 1px solid ${({ theme }) => theme.table.border};
  }

  thead {
    text-transform: uppercase;

    .p-sortable-column {
      .p-column-title {
        margin-right: 8px;
      }
      .p-column-title, .p-sortable-column-icon {
      }
    }
  }

  tr.p-datatable-row {
    padding-left: 20px;

    td {
      padding: 8px 0px;
      text-align: center;

      &.discountColumn {
        color: #FFF;

        .discount-percent, .discount-monetary {
          border-radius: 25px;
          padding: 6px 24px;
          width: 60%;
          margin: 0 auto;
          display: block;
          font-weight: 700px;
        }

        .discount-monetary {
          background-color: ${({ theme }) => theme.cards.monetary}
        }

        .discount-percent {
          background-color: ${({ theme }) => theme.cards.percent}
        }
      }

      &.statusColumn {
        color: #FFF;

        .active, .notActive {
          border-radius: 25px;
          padding: 6px 24px;
          width: 60%;
          margin: 0 auto;
          display: block;
          font-weight: 700px;
        }

        .active {
          background-color: ${({ theme }) => theme.green};
        }

        .notActive {
          background-color: ${({ theme }) => theme.red};
        }
      }
    }

    .duration {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      /* font-size: 12px; */

      svg {
        margin-right: 4px;
      }

      .startDate {
        margin-bottom: 8px;
      }

      .startDate, .endDate {
        display: flex;
        align-items: flex-start;
      }
    }

    .btn-group {
      display: flex;
      justify-content:  space-around;
    }
  }
}
`;
