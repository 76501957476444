import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { removeLoadingModule, addLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchNewsRequest,
  fetchNewsSuccess,
  newsRequestSuccess,
  newsRequestError
} from './actions';
import { InstitutionalNewsAction } from './types';

export function* fetchNewsArticles() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALNEWS));

    const { data }: ConnectorResponse = yield call(
      Connector().get,
      '/landing-page/news/cms'
    );

    yield put(fetchNewsSuccess(data));
  } catch (err: any) {
    yield put(newsRequestError('Falha ao carregar notícias'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALNEWS));
  }
}

export function* createArticle(action: InstitutionalNewsAction) {
  yield put(addLoadingModule(StoreModule.INSTITUTIONALNEWS));

  try {
    const { title, description, publish, cover } = action.payload.article;

    const data = new FormData();
    data.append('title', title);
    data.append('description', description);
    data.append('publish', `${publish}`);

    if (cover) {
      data.append('cover', cover);
    }

    yield call(Connector().post, '/landing-page/news', data);

    yield put(newsRequestSuccess('Artigo criado com sucesso'));
    yield put(fetchNewsRequest());
  } catch (err: any) {
    yield put(newsRequestError('Falha ao criar artigo'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALNEWS));
  }
}

export function* updateArticle(action: InstitutionalNewsAction) {
  yield put(addLoadingModule(StoreModule.INSTITUTIONALNEWS));

  try {
    const { id, title, description, publish, cover } = action.payload.article;

    yield call(Connector().put, `/landing-page/news?id=${id}`, {
      title,
      description,
      publish
    });

    if (cover) {
      const data = new FormData();
      data.append('cover', cover);
      yield call(Connector().put, `/landing-page/news-cover?id=${id}`, data);
    }

    yield put(newsRequestSuccess('Artigo atualizado com sucesso'));
    yield put(fetchNewsRequest());
  } catch (err: any) {
    yield put(newsRequestError('Falha ao atualizar artigo'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALNEWS));
  }
}

export function* deleteArticle(action: InstitutionalNewsAction) {
  yield put(addLoadingModule(StoreModule.INSTITUTIONALNEWS));

  try {
    const { id } = action.payload.article;

    yield call(Connector().delete, `/landing-page/news?id=${id}`);

    yield put(newsRequestSuccess('Artigo removido com sucesso'));
    yield put(fetchNewsRequest());
  } catch (err: any) {
    yield put(newsRequestError('Falha ao remover artigo'));
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALNEWS));
  }
}

export function* showSuccessMessage(action: InstitutionalNewsAction) {
  const { message } = action.payload;
  yield put(
    showToast({
      summary: 'Institucional - Notícias',
      detail: message,
      severity: 'success'
    })
  );
}

export function* showErrorMessage(action: InstitutionalNewsAction) {
  const { message } = action.payload;
  yield put(
    showToast({
      summary: 'Institucional - Notícias',
      detail: message,
      severity: 'error'
    })
  );
}
