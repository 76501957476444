import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { fetchOneQuizRequest } from '../quizzes/actions';
import { showToast } from '../toast/actions';
import {
  fetchOneQuizQuestionsSuccess,
  fetchOneQuizQuestionsError,
  createQuizQuestionSuccess,
  createQuizQuestionError,
  updateQuizQuestionSuccess,
  updateQuizQuestionError,
  deleteQuizQuestionSuccess,
  deleteQuizQuestionError
} from './actions';
import { Action } from './index';

export function* fetchOneQuizQuestions(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.QUIZQUESTIONS));
    const response: ConnectorResponse = yield call(
      Connector().get,
      `quiz-questions?id=${action.payload.id}`
    );

    yield put(fetchOneQuizQuestionsSuccess(response.data));
  } catch (err: any) {
    yield put(fetchOneQuizQuestionsError());
    yield put(
      showToast({
        summary: 'Quiz - Questões',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.QUIZQUESTIONS));
  }
}

export function* createQuizQuestion(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.QUIZQUESTIONS));
    const { one: formData } = action.payload;

    const quiz = { connect: { id: action.payload.one.quizId } };
    const data = {
      question: formData.question,
      isMultiple: formData.isMultiple,
      quiz
    } as any;

    yield call(Connector().post, 'quiz-questions', data);

    yield put(createQuizQuestionSuccess());
    yield put(fetchOneQuizRequest(action.payload.one.quizId));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz - Questões',
        detail: 'Falha ao criar dado',
        severity: 'error'
      })
    );
    yield put(createQuizQuestionError());
    yield put(removeLoadingModule(StoreModule.QUIZQUESTIONS));
  }
}

export function* updateQuizQuestion(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.QUIZQUESTIONS));
    const { quizId, ...serialized } = action.payload.one;
    const quiz = { connect: { id: quizId } };

    const data = Object.assign(serialized, { quiz }) as any;

    yield call(Connector().put, `quiz-questions?id=${serialized.id}`, data);

    yield put(updateQuizQuestionSuccess());
    yield put(fetchOneQuizRequest(quizId));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz - Questões',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(updateQuizQuestionError());
    yield put(removeLoadingModule(StoreModule.QUIZQUESTIONS));
  }
}

export function* deleteQuizQuestion(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.QUIZQUESTIONS));
    yield call(
      Connector().delete,
      `quiz-questions?id=${action.payload.one.id}`
    );

    yield put(deleteQuizQuestionSuccess());
    yield put(fetchOneQuizRequest(action.payload.one.quizId));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz - Questões',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(deleteQuizQuestionError());
    yield put(removeLoadingModule(StoreModule.QUIZQUESTIONS));
  }
}
