import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Banner } from 'models/Institutional';
import { editBanner } from 'store/ducks/institutionalHome/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button } from 'components';
import { InputText, InputTextarea } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { INITIAL_STATE } from '../../HomePage.utils';
import * as S from './HomeBannerForm.styles';

const HomeBannerForm = () => {
  const dispatch = useDispatch();
  const { banner: stateBanner } = useSelector(
    (state: ApplicationState) => state.institutionalHome
  );

  const [banner, setBanner] = useState<Banner>(INITIAL_STATE.banner);

  useEffect(() => {
    setBanner(stateBanner);
  }, [stateBanner]);

  const handleBannerSubmit = () => {
    try {
      Yup.object()
        .shape({
          title: Yup.string().required('O campo título é obrigatório'),
          subtitle: Yup.string().required('O campo subtítulo é obrigatório'),
          description: Yup.string().required('O campo descrição é obrigatório')
        })
        .validateSync(banner, { abortEarly: false });

      dispatch(editBanner(banner));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  return (
    <S.Form as="div" className="institutional__form">
      <h2>Cabeçalho</h2>

      <InputText
        label="Título"
        name="title"
        type="text"
        placeholder="Título do cabeçalho"
        value={banner.title}
        onChange={(ev) =>
          setBanner({
            ...banner,
            title: ev.target.value
          })
        }
      />

      <InputText
        label="Subtítulo"
        name="subtitle"
        type="text"
        placeholder="Subtítulo do cabeçalho"
        value={banner.subtitle}
        onChange={(ev) =>
          setBanner({
            ...banner,
            subtitle: ev.target.value
          })
        }
      />

      <InputTextarea
        label="Descrição"
        name="description"
        placeholder="Descrição do cabeçalho"
        value={banner.description}
        onChange={(ev) =>
          setBanner({
            ...banner,
            description: ev.target.value
          })
        }
      />

      <Button type="button" onClick={handleBannerSubmit}>
        Salvar
      </Button>
    </S.Form>
  );
};

export { HomeBannerForm };
