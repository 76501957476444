import AudioPlaylistOrderer from 'models/AudioPlaylistOrderer';
import AudioToPlaylist from 'models/AudioToPlaylist';
import FolderTree from 'models/FolderTree';
import Playlist from 'models/Playlist';

export enum PlaylistTypes {
    FETCH_PLAYLIST_REQUEST = '@playlist/FETCH_PLAYLIST_REQUEST',
    FETCH_PLAYLIST_SUCCESS = '@playlist/FETCH_PLAYLIST_SUCCESS',
    FETCH_PLAYLIST_ERROR = '@playlist/FETCH_PLAYLIST_ERROR',

    FETCH_ONE_PLAYLIST_REQUEST = '@playlist/FETCH_ONE_PLAYLIST_REQUEST',
    FETCH_ONE_PLAYLIST_SUCCESS = '@playlist/FETCH_ONE_PLAYLIST_SUCCESS',
    FETCH_ONE_PLAYLIST_ERROR = '@playlist/FETCH_ONE_PLAYLIST_ERROR',

    EDIT_PLAYLIST_REQUEST = '@playlist/EDIT_PLAYLIST_REQUEST',
    EDIT_PLAYLIST_SUCESS = '@playlist/EDIT_PLAYLIST_SUCESS',
    EDIT_PLAYLIST_ERROR = '@playlist/EDIT_PLAYLIST_ERROR',

    EDIT_PLAYLIST_ORDERER_REQUEST = '@playlist/EDIT_PLAYLIST_ORDERER_REQUEST',
    EDIT_PLAYLIST_ORDERER_SUCESS = '@playlist/EDIT_PLAYLIST_ORDERER_SUCESS',
    EDIT_PLAYLIST_ORDERER_ERROR = '@playlist/EDIT_PLAYLIST_ORDERER_ERROR',

    SAVE_PLAYLIST_REQUEST = '@playlist/SAVE_PLAYLIST_REQUEST',
    SAVE_PLAYLIST_SUCESS = '@playlist/SAVE_PLAYLIST_SUCESS',
    SAVE_PLAYLIST_ERROR = '@playlist/SAVE_PLAYLIST_ERROR',

    REMOVE_PLAYLIST_REQUEST = '@playlist/REMOVE_PLAYLIST_REQUEST',
    REMOVE_PLAYLIST_SUCESS = '@playlist/REMOVE_PLAYLIST_SUCESS',
    REMOVE_PLAYLIST_ERROR = '@playlist/REMOVE_PLAYLIST_ERROR',

    SAVE_ORDER_REQUEST = '@playlist/SAVE_ORDER_REQUEST',
    SAVE_ORDER_SUCESS = '@playlist/SAVE_ORDER_SUCCESS',
    SAVE_ORDER_ERROR = '@playlist/SAVE_ORDER_ERROR',

    FETCH_ALL_FOLDERS_REQUEST = '@playlist/FETCH_ALL_FOLDERS_REQUEST',
    FETCH_ALL_FOLDERS_SUCESS = '@playlist/FETCH_ALL_FOLDERS_SUCESS',
    FETCH_ALL_FOLDERS_ERROR = '@playlist/FETCH_ALL_FOLDERS_ERROR',

    ADD_AUDIO_TO_PLAYLIST_REQUEST = '@playlist/ADD_AUDIO_TO_PLAYLIST_REQUEST',
    ADD_AUDIO_TO_PLAYLIST_SUCESS = '@playlist/ADD_AUDIO_TO_PLAYLIST_SUCESS',
    ADD_AUDIO_TO_PLAYLIST_ERROR = '@playlist/ADD_AUDIO_TO_PLAYLIST_ERROR',

    REMOVE_AUDIO_FROM_PLAYLIST_REQUEST = '@playlist/REMOVE_AUDIO_FROM_PLAYLIST_REQUEST',
    REMOVE_AUDIO_FROM_PLAYLIST_SUCESS = '@playlist/REMOVE_AUDIO_FROM_PLAYLIST_SUCESS',
    REMOVE_AUDIO_FROM_PLAYLIST_ERROR = '@playlist/REMOVE_AUDIO_FROM_PLAYLIST_ERROR',

  }

export interface PlaylistState {
  readonly old: Playlist;
  readonly data: Playlist[];
  readonly cover: File;
  readonly orderer: AudioPlaylistOrderer [];
  readonly folderTree: FolderTree [];
  readonly folderTreeLoading: boolean
  readonly audioToPlaylist: AudioToPlaylist
  // readonly handleCoverUpload: (playlistId: number) => void;
}
