import { CMS_PERMISSION_TYPE } from 'helpers/constants';

export enum NavigatorTypes {
  SET_COLLAPSED = '@Navigator/SET_COLLAPSED',
  SET_WIDTH = '@Navigator/SET_WIDTH',
  SET_PERMISSIONS = '@Navigator/SET_PERMISSIONS'
}

export interface NavigatorState {
  readonly isCollapsed: boolean;
  readonly navigatorWidth: number;
  readonly permissions: CMS_PERMISSION_TYPE[];
}

export type NavigatorAction = {
  type: NavigatorTypes;
  payload: NavigatorState;
};
