import styled from 'styled-components';
import { ContentLayoutContainerProps } from './interfaces/ContentLayoutContainerProps';

export const ContentLayoutContainer = styled.div<ContentLayoutContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 26px;
  transition: margin-left 0.3s;

  ${({ $marginLeft }) => $marginLeft && `margin-left: ${$marginLeft}px`}
`;
