import { ToastMessage } from 'primereact/toast';

export enum ToastTypes {
  TOAST_SHOW = '@toast/TOAST_SHOW',
  TOAST_DISMISS_ALL = '@toast/TOAST_DISMISS_ALL'
}

export interface ToastState {
  readonly toast: ToastMessage;
  readonly showToast: boolean;
  readonly dismissAll: boolean;
}

export type ToastAction = {
  type: ToastTypes;
  payload: {
    toast: ToastMessage;
    showToast: boolean;
    dismissAll: boolean;
  };
};
