import classNames from 'classnames';
import { InputTextareaProps } from './interfaces/InputTextareaProps';
import { TextareaWrapper } from './styled';

const InputTextarea = (props: InputTextareaProps) => {
  const { name, label, value, className, ...rest } = props;

  return (
    <TextareaWrapper
      className={classNames({
        'is--disabled': props.disabled
      })}
    >
      <textarea
        className={value || name === 'discount' ? 'filled' : ''}
        name={name}
        value={value}
        {...rest}
      />
      {!!label && <label htmlFor={name}>{label}</label>}
    </TextareaWrapper>
  );
};

InputTextarea.defaultProps = {
  label: ''
};

export default InputTextarea;
