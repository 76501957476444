import { action } from 'typesafe-actions';
import { Contact } from 'models/Institutional';
import { InstitutionalContactTypes } from './types';

export const fetchContactsRequest = () =>
  action(InstitutionalContactTypes.FETCH_CONTACTS_REQUEST);

export const fetchContactsSuccess = (contacts: Contact[]) =>
  action(InstitutionalContactTypes.FETCH_CONTACTS_SUCCESS, { contacts });

export const fetchContactsError = () =>
  action(InstitutionalContactTypes.FETCH_CONTACTS_ERROR);
