import { useState, useCallback } from 'react';
import { DropTargetMonitor } from 'react-dnd';
import { FaSave } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import FileService from 'services/FileService';
import { showDialog } from 'store/ducks/dialog/actions';
import { alphaNumericSort } from 'helpers/alphaNumericSort';
import { Button } from '../../../Button/Button';
import { FileDragAndDropConsumerProps } from '../../interfaces/FileDragAndDropConsumerProps';
import { FileDragAndDropList } from '../FileDragAndDropList/FileDragAndDropList';
import { FileDragAndDropTargetBox } from '../FileDragAndDropTargetBox/FileDragAndDropTargetBox';
import * as S from './FileDragAndDropConsumer.styles';

const FileDragAndDropConsumer = (props: FileDragAndDropConsumerProps) => {
  const { folderId, progress, changeProgressBarState, onFinish } = props;
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  const dispatch = useDispatch();

  const handleFileDrop = useCallback(
    (_: any, monitor: DropTargetMonitor<File>) => {
      if (monitor) {
        const { files } = monitor.getItem<File[]>() as any;

        if (droppedFiles.length + files.length > 120) {
          dispatch(
            showDialog({
              title: 'Número máximo de arquivos atingido!',
              message: `O número máximo de arquivos permitido por vez é 120. Você enviou ${
                droppedFiles.length + files.length
              }, remova o restante e tente novamente!`,
              mode: 'OK',
              visible: true
            })
          );
        }

        const newFiles = [...droppedFiles, ...files];

        const sorted = alphaNumericSort(newFiles, 'name');

        setDroppedFiles(sorted);
      }
    },
    [dispatch, droppedFiles]
  );

  const handleRemove = (file: File) => {
    const newAudios = droppedFiles.filter((_file) => _file !== file);
    setDroppedFiles(newAudios);
  };

  const handleSave = () => {
    changeProgressBarState(true);
    new FileService()
      .uploadMultipleAudio(droppedFiles, folderId, progress)
      .then(() => {
        onFinish();
      })
      .finally(() => {
        changeProgressBarState(false);
        setDroppedFiles([]);
      });
  };

  return (
    <S.FileDragAndDropConsumerContainer>
      <FileDragAndDropTargetBox onDrop={handleFileDrop} />
      <FileDragAndDropList audios={droppedFiles} onRemovePress={handleRemove} />

      <Button
        type="button"
        size="big"
        severity="confirm"
        onClick={handleSave}
        disabled={droppedFiles.length > 120}
      >
        <FaSave />
        Salvar
      </Button>
    </S.FileDragAndDropConsumerContainer>
  );
};

export { FileDragAndDropConsumer };
