import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import useOutsideClickCapture from 'hooks/useOutsideClickCapture';
import { iSelectProps } from './interfaces/iSelectProps';
import Option from './Option';
import * as S from './styled';
import utils from './utils';

export const Select = (props: iSelectProps) => {
  const {
    dataId,
    dataValue,
    searchable,
    data,
    onSelection,
    selected,
    autoClose
  } = props;

  const searchRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showOptionList, toggleOptionList] = useState(false);
  const [value, setValue] = useState(selected);
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState<any>([]);
  const [timer, setTimer] = useState<number>();

  const { ref, clickOutside } = useOutsideClickCapture();

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (searchValue !== '') {
      const serialized = data.filter((item) =>
        utils
          .getObjectPropertyViaPath(item, dataValue)
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
      setItems(serialized);
    } else {
      setItems(data);
    }
  }, [searchValue]); // eslint-disable-line

  useEffect(() => {
    if (!showOptionList && searchValue) {
      setSearchValue('');
    }

    if (searchable && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showOptionList]); // eslint-disable-line

  useEffect(() => {
    if (clickOutside) {
      toggleOptionList(false);

      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [clickOutside]); // eslint-disable-line

  const updateValue = () => {
    if (selected) {
      const item = data.find(
        (item: any) => utils.getObjectPropertyViaPath(item, dataId) === selected
      );

      if (item) {
        setValue(utils.getObjectPropertyViaPath(item, dataValue));
      }
    } else {
      setValue('');
    }
  };

  useEffect(() => {
    updateValue();
  }, [selected]); // eslint-disable-line

  useEffect(() => {
    updateValue();
  }, []); // eslint-disable-line

  const handleMouseEnter = () => {
    if (autoClose && timer) {
      clearTimeout(timer);
    }
  };

  const handleMouseLeave = () => {
    if (autoClose && showOptionList) {
      const closeTime = props.closeTime || 3000;

      const timer = window.setTimeout(() => {
        toggleOptionList(false);
      }, closeTime);

      setTimer(timer);
    }
  };

  const handleOptionClick = (item: any) => {
    onSelection(utils.getObjectPropertyViaPath(item, dataId));
    setValue(utils.getObjectPropertyViaPath(item, dataValue));
    toggleOptionList(false);
  };

  const getOptions = () =>
    items.length < 1 ? (
      <S.NoOptions>Sem dados</S.NoOptions>
    ) : (
      items.map((item: any) => (
        <Option
          key={utils.getObjectPropertyViaPath(item, dataId)}
          value={utils.getObjectPropertyViaPath(item, dataValue)}
          onClick={() => handleOptionClick(item)}
        />
      ))
    );

  const handleOptionListVisibility = () => {
    toggleOptionList(!showOptionList);

    if (!showOptionList && dropdownRef.current) {
      dropdownRef.current.scrollTop = 0;
    }
  };

  return (
    <S.SelectContainer
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames({
        'is--disabled': props.disabled
      })}
    >
      <S.SelectInput
        name={props.name}
        value={value}
        placeholder={props.placeholder}
        onClick={handleOptionListVisibility}
        className={classNames('select__input', {
          'select__input--is-option-list-open': showOptionList
        })}
        autoComplete="off"
        disabled={props.disabled}
        readOnly
      />
      <label htmlFor={props.name}>{props.label}</label>

      <S.SelectDropdown
        ref={dropdownRef}
        className={classNames('select__dropdown-list', {
          'select__dropdown-list--is-open': showOptionList
        })}
      >
        <S.SelectOptionList>
          {searchable && (
            <S.SelectSearch>
              <input
                ref={searchRef}
                type="text"
                name="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={props.searchPlaceholder}
                autoComplete="new-password"
              />
            </S.SelectSearch>
          )}

          {getOptions()}
        </S.SelectOptionList>
      </S.SelectDropdown>
    </S.SelectContainer>
  );
};

Select.defaultProps = {
  searchable: false,
  searchPlaceholder: '',
  disabled: false,
  autoClose: true,
  closeTime: 3000
};
