import { ValidationError } from 'yup';
import { Filters } from 'models/Filters';
import FilterResponse from 'helpers/types/FilterResponse';

const filtersKeyTranslated: Record<string, string> = {
  createdAt: 'Criado Em',
  canceledAt: 'Cancelado Em',
  status: 'Status',
  planValue: 'Valor do Plano',
  address: 'Endereço',
  city: 'Cidade',
  state: 'Estado',
  country: 'País',
  phone: 'Telefone',
  teachingInstitutionId: 'Instituição de Ensino',
  startsIn: 'Data de Cadastro',
  endsIn: 'Data de Cancelamento'
};

export const validateSubscribersFilters = (filters: Filters) => {
  const errors: ValidationError[] = [];
  let error;

  Object.entries(filters).forEach((pair) => {
    const filterKey: string = pair[0];
    const filter: FilterResponse = pair[1];
    const filterTranslatedKey = filtersKeyTranslated[filterKey];

    if (filter.enable === undefined && filter.enable === undefined) {
      Object.entries(filter).forEach((subPair) => {
        const subFilterKey: string = subPair[0];
        const subFilter: FilterResponse = subPair[1];
        const subFilterTranslatedKey = filtersKeyTranslated[subFilterKey];

        if (subFilter.enable && !subFilter.value) {
          error = new ValidationError(
            `O campo ${subFilterTranslatedKey} foi selecionado mas não foi preenchido`,
            '',
            filterKey
          );

          errors.push(error);
        }
      });
    }

    if (filter.enable && !filter.value) {
      error = new ValidationError(
        `O campo ${filterTranslatedKey} foi selecionado mas não foi preenchido`,
        '',
        filterKey
      );

      errors.push(error);
    }
  });

  if (errors.length) {
    throw new ValidationError(errors);
  }
};
