import { action } from 'typesafe-actions';
import Folder from 'models/Folder';
import { FolderTypes } from './types';

export const fetchOneFolderRequest = (id: number) =>
  action(FolderTypes.FETCH_ONE_FOLDER_REQUEST, { id });

export const fetchOneFolderSuccess = (folder: Folder) =>
  action(FolderTypes.FETCH_ONE_FOLDER_SUCCESS, { folder });

export const fetchOneFolderError = () =>
  action(FolderTypes.FETCH_ONE_FOLDER_ERROR);

export const fetchViewFolderRequest = (id: number) =>
  action(FolderTypes.FETCH_VIEW_FOLDER_REQUEST, { id });

export const fetchViewFolderSuccess = (viewFolder: Folder) =>
  action(FolderTypes.FETCH_VIEW_FOLDER_SUCCESS, { viewFolder });

export const fetchViewFolderError = () =>
  action(FolderTypes.FETCH_VIEW_FOLDER_ERROR);

export const saveFolderRequest = (folder: Folder) =>
  action(FolderTypes.SAVE_FOLDER_REQUEST, { folder });

export const saveFolderSuccess = () => action(FolderTypes.SAVE_FOLDER_SUCCESS);

export const saveFolderError = () => action(FolderTypes.SAVE_FOLDER_ERROR);

export const editFolderRequest = (folder: Folder) =>
  action(FolderTypes.EDIT_FOLDER_REQUEST, { folder });

export const editFolderSuccess = () => action(FolderTypes.EDIT_FOLDER_SUCCESS);

export const editFolderError = () => action(FolderTypes.EDIT_FOLDER_ERROR);

export const removeFolderRequest = (folder: Folder) =>
  action(FolderTypes.REMOVE_FOLDER_REQUEST, { folder });

export const removeFolderSuccess = () =>
  action(FolderTypes.REMOVE_FOLDER_SUCCESS);

export const removeFolderError = () => action(FolderTypes.REMOVE_FOLDER_ERROR);

export const setSelectedFolder = (folder: Folder) =>
  action(FolderTypes.SET_SELECTED_FOLDER, { folder });
