import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionsSummaryRequest } from 'store/ducks/analytics/actions';
import { Card, LoadingIndicator, MiniCard } from 'components';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './SubscriptionsSummary.styles';

const SubscriptionsSummary = () => {
  const dispatch = useDispatch();

  const { loading, subscriptionsSummary } = useSelector(
    (state: ApplicationState) => state.analytics
  );
  const { active, inTest, overDue, suspended, total } = subscriptionsSummary;

  const isModuleLoading = () => loading.includes('SUBSCRIPTIONS_SUMMARY');

  const handleSubscriptionSummaryRequest = () => {
    dispatch(subscriptionsSummaryRequest());
  };

  useEffect(() => {
    handleSubscriptionSummaryRequest();
  }, []);

  return (
    <Card title="Resumo de assinaturas">
      <LoadingIndicator
        isLoading={isModuleLoading()}
        position="absolute"
        size="small"
        showRefreshButton
        onRefresh={handleSubscriptionSummaryRequest}
      />

      <S.SubscriptionsSummaryContainer>
        <MiniCard title="Ativas" body={active} />
        <MiniCard title="Em teste" body={inTest} />
        <MiniCard title="Pagamento atrasado" body={overDue} />
        <MiniCard title="Total" body={total} rightSeparator />
        <MiniCard title="Suspensas" body={suspended} />
      </S.SubscriptionsSummaryContainer>
    </Card>
  );
};

export { SubscriptionsSummary };
