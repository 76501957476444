import * as yup from 'yup';
import { QuizQuestion } from 'models/Quiz';

const quizQuestionSchema = yup.object().shape({
  id: yup.number().required(),
  question: yup.string().required('O campo "QUESTÃO" é obrigatório!'),
  isMultiple: yup
    .boolean()
    .required('O campo "MÚLTIPLAS RESPOSTAS" é obrigatório!')
});

export default (quizQuestion: QuizQuestion) =>
  quizQuestionSchema.validateSync(quizQuestion, { abortEarly: false });
