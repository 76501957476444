/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import TeachingInstitution from 'models/TeachingInstitution';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText } from 'components/Form';
import { DrawerMode } from 'helpers/types/DrawerMode';
import teachingInstitutionValidate from 'helpers/validation/teachingInstitution';
import * as S from './TeachingInstitutionsActionSidebar.styles';

interface TeachingInstitutionsActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedTeachingInstitution: TeachingInstitution;
  onConfirmPress: (teachingInstitution: TeachingInstitution) => void;
  onCancelPress?: () => void;
}

const TeachingInstitutionsActionSidebar = (
  props: TeachingInstitutionsActionSidebarProps
) => {
  const {
    mode,
    setMode,
    selectedTeachingInstitution,
    onCancelPress,
    onConfirmPress
  } = props;

  const dispatch = useDispatch();

  const [teachingInstitution, setTeachingInstitution] =
    useState<TeachingInstitution>(selectedTeachingInstitution);

  useEffect(() => {
    setTeachingInstitution(selectedTeachingInstitution);
  }, [selectedTeachingInstitution]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar usuário';
        break;
      case 'OPEN_EDIT':
        title = 'Editar usuário';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      teachingInstitutionValidate(teachingInstitution);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    if (onConfirmPress) {
      onConfirmPress(teachingInstitution);
    }
    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setMode('CLOSE');
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Nome"
          name="name"
          type="text"
          value={teachingInstitution.name}
          onChange={(e) =>
            setTeachingInstitution({
              ...teachingInstitution,
              name: e.target.value
            })
          }
        />

        <InputText
          label="CNPJ"
          name="cnpj"
          type="text"
          value={teachingInstitution.cnpj}
          onChange={(e) =>
            setTeachingInstitution({
              ...teachingInstitution,
              cnpj: e.target.value
            })
          }
        />

        <InputText
          label="Código"
          name="code"
          type="text"
          value={teachingInstitution.code}
          onChange={(e) =>
            setTeachingInstitution({
              ...teachingInstitution,
              code: e.target.value
            })
          }
        />

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

TeachingInstitutionsActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { TeachingInstitutionsActionSidebar };
