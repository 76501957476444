import { CSVLink } from 'react-csv';
import styled from 'styled-components';

export const CsvLink = styled(CSVLink)`
  display: flex;
  align-items: center;
  justify-items: center;
  color: #fff;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 5px;
  transition: background-color 0.2s, color 0.2s;
  color: ${({ theme }) => theme.btn.primary.color};
  background-color: ${({ theme }) => theme.btn.primary.bg};

  &.btn-disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.btn.disabled.color}!important;
    background-color: ${({ theme }) => theme.blueLighter}!important;
  }

  &.btn-small {
    padding: 8px 12px;
    font-size: 12px;

    i {
      margin-right: 4px;
    }
  }

  &.btn-big {
    padding: 12px 24px;
    font-size: 14px;

    i {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.blueLighter};
  }
`;
