import styled from 'styled-components';
import { H3 } from 'components/Styled/Text';

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.section.bg};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
  border-radius: 6px;
  position: relative;

  flex-grow: 1;
  align-items: center;
`;

export const CardTitle = styled(H3)`
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 8px;

  &.noMarginBottom {
    margin-bottom: 0px;
  }

  &:after {
    content: '';
    display: block;
    width: 25%;
    border-bottom: 2px solid ${({ theme }) => theme.table.border};
    text-align: center;
    margin: 15px auto;
  }
`;
