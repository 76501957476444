import { useEffect } from 'react';
import {
  FaPen,
  FaTrash,
  FaHourglassStart,
  FaHourglassEnd
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import Promotion from 'models/Promotion';
import { showDialog } from 'store/ducks/dialog/actions';
import { deletePromotionRequest } from 'store/ducks/promotion/actions';
import { Button } from 'components';
import { DiscountValueTypeEnum } from 'helpers/enum/DiscountValueTypeEnum';
import * as S from './PromotionDataTable.styles';

interface PromotionDataTableProps {
  promotions: Promotion[];
  onPressItem: (promotionPlan: Promotion) => void;
}

const PromotionDataTable = (props: PromotionDataTableProps) => {
  const { promotions } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    promotions.map((promotion) => {
      const totalSort = promotion.usedQuantity / promotion.totalQuantity;

      return Object.assign(promotion, { totalSort });
    });
  }, [promotions]);

  const handleRemovePressed = (promotion: Promotion) => {
    const remove = () => dispatch(deletePromotionRequest(promotion.id));

    dispatch(
      showDialog({
        visible: true,
        title: 'Remover promoção',
        message: `Deseja realmente remover a promoção vinculada ao plano "${promotion.plan?.name}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: remove
      })
    );
  };

  const getPlanValue = (promotion: Promotion) => {
    if (promotion.plan?.value) {
      return `R$ ${promotion.plan?.value.toFixed(2).replace('.', ',')}`;
    }

    return 'R$ 0,00';
  };

  const getDiscount = (promotion: Promotion) => {
    const isPercent = promotion.discountType === DiscountValueTypeEnum.PERCENT;

    return (
      <span>
        {isPercent ? `${promotion.discount}%` : `R$${promotion.discount}`}
      </span>
    );
  };

  const getDuration = (promotion: Promotion) => (
    <span className="p-column-duration">
      <Tag
        value={`${moment(promotion.startsIn).format('DD/MM/YYYY')}`}
        icon={<FaHourglassStart />}
        severity="success"
        className="p-column-text-with-icon"
      />
      <Tag
        value={moment(promotion.endsIn).format('DD/MM/YYYY')}
        icon={<FaHourglassEnd />}
        severity="success"
        className="p-column-text-with-icon"
      />
    </span>
  );

  const getPromoPeriod = (promotion: Promotion) =>
    `${promotion.promoPeriod} ${promotion.promoPeriod > 1 ? 'meses' : 'mês'}`;

  const getTotalQuantity = (promotion: Promotion) => {
    const isUnlimited = promotion.totalQuantity === null;

    return isUnlimited ? 'Ilimitados' : promotion.totalQuantity;
  };

  const getUsedQuantity = (promotion: Promotion) => promotion.usedQuantity;

  const getStatus = (promotion: Promotion) =>
    promotion.status ? (
      <Tag
        value="Ativa"
        icon="pi pi-check-circle"
        severity="info"
        rounded
        className="p-column-text-with-icon"
      />
    ) : (
      <Tag
        value="Inativa"
        icon="pi pi-check-circle"
        severity="danger"
        rounded
        className="p-column-text-with-icon"
      />
    );

  const getActions = (promotion: Promotion) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => props.onPressItem(promotion)}
        severity="confirm"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleRemovePressed(promotion)}
        severity="danger"
      >
        <FaTrash />
      </Button>
    </S.ActionButtons>
  );

  return (
    <S.DataTableWrapper>
      <DataTable
        sortField="name"
        sortOrder={1}
        value={promotions}
        selectionMode="single"
        onRowDoubleClick={(e) => props.onPressItem(e.data)}
        filterDisplay="row"
      >
        <Column field="id" header="ID" sortable />
        <Column
          field="name"
          header="Nome"
          filter
          filterField="name"
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="plan.name"
          header="Plano vinculado"
          filter
          filterField="plan.name"
          filterPlaceholder="Buscar"
          sortable
        />
        <Column header="Valor do plano" body={getPlanValue} />
        <Column header="Desconto" body={getDiscount} />
        <Column header="Vigência da promoção" body={getDuration} />
        <Column
          header="Validade do desconto"
          body={getPromoPeriod}
          sortable
          sortField="promoPeriod"
        />
        <Column
          header="Limite de assinaturas"
          body={getTotalQuantity}
          sortable
          sortField="totalSort"
        />
        <Column
          header="Quantidade de assinantes"
          body={getUsedQuantity}
          sortable
          sortField="usedQuantity"
        />
        <Column header="Status" sortField="status" sortable body={getStatus} />
        <Column header="Ações" body={getActions} />
      </DataTable>
    </S.DataTableWrapper>
  );
};

export { PromotionDataTable };
