import styled from 'styled-components';

export const FileDragAndDropTargetBoxContainer = styled.div`
  height: 250px;
  width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 1px dashed gray;
  transition: all 0.4s;
  margin-bottom: 8px;

  .dndIcon {
    font-size: 84px;
    margin-bottom: 8px;
    color: #0095f3;
    transition: color 0.4s;
  }

  .dndLabel {
    font-family: 'Mulish';
    font-size: 18px;
    font-weight: 700;
  }

  &.isActive {
    border-color: ${({ theme }) => theme.btn.secondary.bg};
    color: ${({ theme }) => theme.btn.secondary.bg};

    .dndIcon {
      color: ${({ theme }) => theme.btn.secondary.bg};
    }
  }
`;
