import { useSelector } from 'react-redux';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './ContentLayout.styles';
import { ContentLayoutProps } from './interfaces/ContentLayoutProps';

const ContentLayout = (props: ContentLayoutProps) => {
  const { children, className } = props;
  const { navigatorWidth } = useSelector(
    (state: ApplicationState) => state.navigator
  );

  return (
    <S.ContentLayoutContainer
      className={className}
      $marginLeft={navigatorWidth}
    >
      {children}
    </S.ContentLayoutContainer>
  );
};

ContentLayout.defaultProps = {
  className: ''
};

export { ContentLayout };
