import Folder from 'models/Folder';

export enum FolderTypes {
  FETCH_FOLDER_REQUEST = '@folder/FETCH_FOLDER_REQUEST',
  FETCH_FOLDER_SUCCESS = '@folder/FETCH_FOLDER_SUCCESS',
  FETCH_FOLDER_ERROR = '@folder/FETCH_FOLDER_ERROR',

  FETCH_ONE_FOLDER_REQUEST = '@folder/FETCH_ONE_FOLDER_REQUEST',
  FETCH_ONE_FOLDER_SUCCESS = '@folder/FETCH_ONE_FOLDER_SUCCESS',
  FETCH_ONE_FOLDER_ERROR = '@folder/FETCH_ONE_FOLDER_ERROR',

  FETCH_VIEW_FOLDER_REQUEST = '@folder/FETCH_VIEW_FOLDER_REQUEST',
  FETCH_VIEW_FOLDER_SUCCESS = '@folder/FETCH_VIEW_FOLDER_SUCCESS',
  FETCH_VIEW_FOLDER_ERROR = '@folder/FETCH_VIEW_FOLDER_ERROR',

  SAVE_FOLDER_REQUEST = '@folder/SAVE_FOLDER_REQUEST',
  SAVE_FOLDER_SUCCESS = '@folder/SAVE_FOLDER_SUCCESS',
  SAVE_FOLDER_ERROR = '@folder/SAVE_FOLDER_ERROR',

  EDIT_FOLDER_REQUEST = '@folder/EDIT_FOLDER_REQUEST',
  EDIT_FOLDER_SUCCESS = '@folder/EDIT_FOLDER_SUCCESS',
  EDIT_FOLDER_ERROR = '@folder/EDIT_FOLDER_ERROR',

  REMOVE_FOLDER_REQUEST = '@folder/REMOVE_FOLDER_REQUEST',
  REMOVE_FOLDER_SUCCESS = '@folder/REMOVE_FOLDER_SUCCESS',
  REMOVE_FOLDER_ERROR = '@folder/REMOVE_FOLDER_ERROR',

  SET_SELECTED_FOLDER = '@folder/SET_SELECTED_FOLDER'
}

export interface FolderState {
  readonly id: number;
  readonly parentId: number;
  readonly folder: Folder;
  readonly viewFolder: Folder;
}

export type FolderAction = {
  type: FolderTypes;
  payload: FolderState;
};
