import styled from 'styled-components';
import { LoadingIndicatorContainerProps } from './interfaces/LoadingIndicatorStyles';

export const LoadingIndicatorContainer = styled.div<LoadingIndicatorContainerProps>`
  ${({ $position }) =>
    $position === 'absolute'
      ? `
    position: absolute;
    top: 0;
    right: 0;
  `
      : `
    position: relative;
  `}
`;

export const RefreshIndicator = styled.div`
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.yellow};
    transition: fill 0.3s;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.yellowDarker};
    }
  }
`;
