import Log from 'models/Log';
import { UserApp } from 'models/User';

export const logInitialState: Log = {
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  content: JSON.parse('{}')
};

export const userInitialState: UserApp = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  password: '',
  isAdmin: false,
  removed: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date(),
  customerId: '',
  firstLogin: true,
  hubId: ''
};
