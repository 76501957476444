import { useState, useEffect, FormEvent, useRef } from 'react';
import { FaSave, FaTimes, FaCamera, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import FileService from 'services/FileService';
import * as Yup from 'yup';
import Playlist from 'models/Playlist';
import { fetchPlaylistRequest } from 'store/ducks/playlist/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button, ProgressUpload, Drawer } from 'components';
import { InputText } from 'components/Form';
import MultiSelector from 'components/Form/MultiSelector';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import playlistValidate from 'helpers/validation/playlist';
import playlistInitialState from '../../helpers/initialState';
import * as S from './PlaylistActionSidebar.styles';

interface PlaylistActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  playlist: Playlist;
  onConfirmPress?: (playlist: Playlist, file?: File) => void;
  onCancelPress?: () => void;
  handleClear: () => void;
  setVisibleAddNewCategory: (visibility: boolean) => any;
}

const PlaylistActionSidebar = (props: PlaylistActionSidebarProps) => {
  const {
    mode,
    setMode,
    onConfirmPress,
    onCancelPress,
    playlist: dtPlaylist,
    handleClear
  } = props;
  const [playlist, setPlaylist] = useState<Playlist>(dtPlaylist);

  const [itemsSelecteds, setItemsSelecteds] = useState<any[]>([]);
  const [visibleProgressBar, setVisibleProgressBar] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [preview, setPreview] = useState<string>('');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const categories = useSelector(
    (state: ApplicationState) => state.playlistCategory.data
  );

  const [playlistCover, setPlaylistCover] = useState<File>();

  useEffect(() => {
    if (playlistCover) {
      setPreview(URL.createObjectURL(playlistCover));
    }
  }, [playlistCover]);

  const dispatch = useDispatch();

  const onUploadSingleFile = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setPlaylistCover(e.currentTarget.files[0]);
    }
  };

  const clearFields = () => {
    handleClear();
    setPlaylist(playlistInitialState);
    setItemsSelecteds([]);
    setPlaylistCover(undefined);
    setPreview('');

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    dispatch(fetchPlaylistRequest());
  };

  useEffect(() => {
    if (mode === 'CLOSE' || mode === 'OPEN_ADD') {
      setPlaylist(playlistInitialState);
    } else {
      setPlaylist(dtPlaylist);
      setItemsSelecteds(dtPlaylist.categories);
    }
  }, [dtPlaylist, mode]);

  // useEffect(() => {
  //   setPlaylist(statePlaylist);
  //   setItemsSelecteds(statePlaylist.categories);
  // }, [statePlaylist]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar nova playlist';
        break;
      case 'OPEN_EDIT':
        title = 'Editar playlist';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleSelectionChange = (e: any) => {
    setPlaylist({
      ...playlist,
      categories: e
    });
  };

  const handleUploadFile = (playlistId: number) => {
    if (playlistCover) {
      new FileService()
        .uploadImagePlaylist(playlistCover, playlistId, setProgressValue)
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setVisibleProgressBar(false);
          dispatch(fetchPlaylistRequest());
        });
    }
  };

  const handleConfirmPress = async () => {
    try {
      playlistValidate(playlist);

      if (mode === 'OPEN_ADD' && !playlistCover) {
        dispatch(
          showToast({
            summary: 'Falha na validação',
            detail: 'O campo CAPA é obrigatório',
            severity: 'error'
          })
        );
        return;
      }

      if (playlistCover) {
        if (mode === 'OPEN_ADD' && onConfirmPress) {
          onConfirmPress(playlist, playlistCover);
        } else if (mode === 'OPEN_EDIT' && onConfirmPress) {
          const { id } = { ...playlist };
          onConfirmPress(playlist);
          handleUploadFile(id);
          setVisibleProgressBar(true);
        }
      } else if (onConfirmPress) {
        onConfirmPress(playlist);
      }

      clearFields();
      setMode('CLOSE');
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        errors.inner.forEach((err, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: err.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }
    }
  };

  const handleCancelPress = () => {
    if (onCancelPress) {
      onCancelPress();
    }
    clearFields();
    setMode('CLOSE');
  };

  useEffect(() => {
    if (mode === 'OPEN_EDIT' && !playlistCover) {
      setPreview(playlist.coverUrl);
    }
  }, [mode, playlistCover, playlist]);

  return (
    <>
      <ProgressUpload
        progressValue={progressValue}
        visibleProgressBar={visibleProgressBar}
      />

      <Drawer
        title={handleTitle()}
        drawerMode={mode}
        onBackDropPress={handleCancelPress}
      >
        <S.Form>
          <InputText
            label="NOME"
            name="name"
            type="text"
            value={playlist.name}
            onChange={(e) =>
              setPlaylist({
                ...playlist,
                name: e.target.value
              })
            }
          />

          <InputText
            label="DESCRIÇÃO"
            name="description"
            type="text"
            value={playlist.description}
            onChange={(e) =>
              setPlaylist({
                ...playlist,
                description: e.target.value
              })
            }
          />

          <S.CategoriesWrapper>
            <MultiSelector
              onSelectionChange={handleSelectionChange}
              data={categories}
              selecteds={itemsSelecteds}
            />
            <Button
              type="button"
              size="small"
              severity="warning"
              onClick={() => props.setVisibleAddNewCategory(true)}
              icon="pi pi-plus"
            />
          </S.CategoriesWrapper>

          <S.PlaylistCover>
            <label
              id="label"
              style={{
                backgroundImage: `url(${preview})`
              }}
              className={classNames({
                'has-cover': playlistCover
              })}
              htmlFor="playlistCover"
            >
              <FaCamera />
              <input
                id="playlistCover"
                type="file"
                accept="image/*"
                onChange={onUploadSingleFile}
                ref={fileInputRef}
                required
              />
            </label>

            <span>Selecione uma imagem para usar como capa da playlist</span>
          </S.PlaylistCover>

          <S.ButtonGroup>
            <Button
              type="button"
              size="big"
              severity="confirm"
              onClick={handleConfirmPress}
            >
              <FaSave />
              Salvar
            </Button>

            <Button
              type="button"
              size="big"
              severity="danger"
              onClick={handleCancelPress}
            >
              <FaTimes />
              Cancelar
            </Button>
          </S.ButtonGroup>
        </S.Form>
      </Drawer>
    </>
  );
};

PlaylistActionSidebar.defaultProps = {
  onConfirmPress: () => {},
  onCancelPress: () => {}
};

export { PlaylistActionSidebar };
