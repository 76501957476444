import styled from 'styled-components';

export const MultiSelectWrapper = styled.div`
  .p-multiselect {
    font-size: 14px;
    color: ${({ theme }) => theme.text.primary};
    background: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 4px;
    transition: border-color 0.3s, box-shadow 0.3s;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 8px;

    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;

      .p-multiselect-label {
        color: ${({ theme }) => theme.text.primary};
      }
    }

    .p-multiselect-label {
      font-family: 'Mulish', sans-serif;
      font-weight: ${({ theme }) => theme.weight.bold};
      text-transform: uppercase;
      color: ${({ theme }) => theme.text.secondary};
      transition: color 0.3s;
      display: flex;

      .multiselect-audio-token {
        background-color: ${({ theme }) => theme.multiselect.token.bg};
        color: ${({ theme }) => theme.multiselect.color};
        font-weight: 700;
        font-family: 'Mulish';
        padding: 6px;
        margin-right: 4px;
      }
    }

    .p-multiselect-trigger {
      background: ${({ theme }) => theme.background};
      width: 40px;
      line-height: 2em;
      text-align: center;
      padding: 0;
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  .p-multiselect-panel {
    padding: 0;
    border: 1px solid ${({ theme }) => theme.table.border};
    background-color: ${({ theme }) => theme.background};
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .p-multiselect-header {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 12px;
      color: ${({ theme }) => theme.text.primary};
      background-color: ${({ theme }) => theme.background};
      border-bottom: 1px solid ${({ theme }) => theme.table.border};

      .p-checkbox {
      }

      .p-multiselect-filter-container {
        flex-grow: 1;
        margin: 0 12px;

        .p-inputtext {
          border-radius: 6px;
          color: ${({ theme }) => theme.text.primary};
          background-color: ${({ theme }) => theme.multiselect.search};
          border: 1px solid ${({ theme }) => theme.table.border};
          padding: 0.429em;

          &::placeholder {
            color: ${({ theme }) => theme.search.placeholder};
          }
        }

        .p-multiselect-filter-icon {
          color: ${({ theme }) => theme.text.secondary};
          top: 50%;
          margin-top: -0.5em;
          right: 0.75em;
          left: auto;
        }
      }

      .p-multiselect-close {
        color: ${({ theme }) => theme.text.secondary};
        position: initial;
        display: initial;
      }
    }

    .p-multiselect-items {
      padding: 0;

      .p-multiselect-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: ${({ theme }) => theme.text.primary};
        background-color: transparent;
        transition: background-color 0.3s, box-shadow 0.3s;
        border-radius: 0;

        &:not(.p-highlight):not(.p-disabled):hover {
          color: ${({ theme }) => theme.text.primary};
          background-color: ${({ theme }) => theme.multiselect.item.hover};
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
        }

        &.p-highlight {
          color: ${({ theme }) => theme.multiselect.item.hl.color};
          background-color: ${({ theme }) => theme.multiselect.item.hl.bg};
        }

        .p-checkbox {
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.5em 0 0;
        }
      }

      .p-multiselect-item label {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  /* Checkbox */
  .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px;
  }
  .p-checkbox .p-checkbox-box {
    border: 1px solid ${({ theme }) => theme.checkbox.border};
    background-color: ${({ theme }) => theme.checkbox.background};
    width: 20px;
    height: 20px;
    text-align: center;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    -o-transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    -webkit-transition: background-color 0.3s, border-color 0.3s,
      box-shadow 0.3s;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  }
  .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    border-color: #212121;
  }
  .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 6px ${({ theme }) => theme.shadow}; */
    border-color: ${({ theme }) => theme.checkbox.hover.bg};
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: ${({ theme }) => theme.checkbox.hover.border};
    background-color: ${({ theme }) => theme.checkbox.hover.bg};
    color: ${({ theme }) => theme.background};
  }
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: ${({ theme }) => theme.yellowDarker};
    background-color: ${({ theme }) => theme.yellowDarker};
    color: ${({ theme }) => theme.background};
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    overflow: hidden;
    position: relative;
    font-size: 18px;
  }
`;
