import Plan from 'models/Plan';
import Promotion from 'models/Promotion';

export enum PlanTypes {
  FETCH_PLAN_REQUEST = '@plan/FETCH_PLAN_REQUEST',
  FETCH_PLAN_SUCCESS = '@plan/FETCH_PLAN_SUCCESS',
  FETCH_PLAN_ERROR = '@plan/FETCH_PLAN_ERROR',

  CREATE_PLAN_REQUEST = '@plan/CREATE_PLAN_REQUEST',
  CREATE_PLAN_SUCCESS = '@plan/CREATE_PLAN_SUCCESS',
  CREATE_PLAN_ERROR = '@plan/CREATE_PLAN_ERROR',

  UPDATE_PLAN_REQUEST = '@plan/UPDATE_PLAN_REQUEST',
  UPDATE_PLAN_SUCCESS = '@plan/UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_ERROR = '@plan/UPDATE_PLAN_ERROR',

  DELETE_PLAN_REQUEST = '@plan/DELETE_PLAN_REQUEST',
  DELETE_PLAN_SUCCESS = '@plan/DELETE_PLAN_SUCCESS',
  DELETE_PLAN_ERROR = '@plan/DELETE_PLAN_ERROR'
}

export interface PlanState {
  readonly plans: Plan[];
}

export type PlanAction = {
  type: PlanTypes;
  payload: {
    plans: Plan[];
    plan: Plan;
  };
};
