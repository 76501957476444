import { NewsArticle } from 'models/Institutional';

export enum InstitutionalNewsTypes {
  FETCH_NEWS_REQUEST = '@institutionalNews/FETCH_NEWS_REQUEST',
  FETCH_NEWS_SUCCESS = '@institutionalNews/FETCH_NEWS_SUCCESS',

  CREATE_NEWS_REQUEST = '@institutionalNews/CREATE_NEWS_REQUEST',
  UPDATE_NEWS_REQUEST = '@institutionalNews/UPDATE_NEWS_REQUEST',
  DELETE_NEWS_REQUEST = '@institutionalNews/DELETE_NEWS_REQUEST',

  NEWS_REQUEST_SUCCESS = '@institutionalNews/NEWS_SUCCESS',
  NEWS_REQUEST_ERROR = '@institutionalNews/NEWS_ERROR',
}

export interface InstitutionalNewsState {
  readonly article: NewsArticle;
  readonly list: NewsArticle[];
  readonly message: string; // error/success mesage
}

export type InstitutionalNewsAction = {
  type: InstitutionalNewsTypes;
  payload: InstitutionalNewsState,
}