import { useEffect } from 'react';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import RoundCircle from 'assets/lottie/round-circle.json';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './LoadingOverlay.styles';

const LoadingOverlay = () => {
  const modules = useSelector(
    (state: ApplicationState) => state.loading.modules
  );

  return modules.length > 0 ? (
    <S.Container>
      <Lottie
        loop
        play
        animationData={RoundCircle}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice'
        }}
        style={{ width: 400 }}
      />
    </S.Container>
  ) : null;
};

export { LoadingOverlay };
