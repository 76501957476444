import { action } from 'typesafe-actions';
import { AboutUs } from 'models/Institutional';
import { InstitutionalAboutUsTypes } from './types';

export const fetchAboutUsRequest = () =>
  action(InstitutionalAboutUsTypes.FETCH_ABOUT_US_REQUEST);

export const fetchAboutUsSuccess = (aboutUs: AboutUs) =>
  action(InstitutionalAboutUsTypes.FETCH_ABOUT_US_SUCCESS, { aboutUs });

export const updateAboutUsRequest = (aboutUs: AboutUs) =>
  action(InstitutionalAboutUsTypes.UPDATE_ABOUT_US_REQUEST, { aboutUs });

export const aboutUsRequestSuccess = (message: string) =>
  action(InstitutionalAboutUsTypes.ABOUT_US_REQUEST_SUCCESS, { message });

export const aboutUsRequestError = (message: string) =>
  action(InstitutionalAboutUsTypes.ABOUT_US_REQUEST_ERROR, { message });
