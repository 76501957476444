/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { QuizQuestion } from 'models/Quiz';
import { fetchPlaylistRequest } from 'store/ducks/playlist/actions';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, InputRadio } from 'components/Form';
import { DrawerMode } from 'helpers/types/DrawerMode';
import quizQuestionSchema from 'helpers/validation/quizQuestion';
import { questionInitialState } from '../../helpers/initialState';
import * as S from './QuizQuestionActionSidebar.styles';

interface QuizQuestionActionSidebar {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedQuestion: QuizQuestion;
  onConfirmPress: (question: QuizQuestion) => void;
  onCancelPress?: () => void;
}

const QuizQuestionActionSidebar = (props: QuizQuestionActionSidebar) => {
  const { mode, setMode, selectedQuestion, onCancelPress, onConfirmPress } =
    props;

  const dispatch = useDispatch();

  const [question, setQuestion] = useState<QuizQuestion>(selectedQuestion);

  useEffect(() => {
    dispatch(fetchPlaylistRequest());
  }, [dispatch]);

  useEffect(() => {
    setQuestion(selectedQuestion);
  }, [selectedQuestion]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar questão';
        break;
      case 'OPEN_EDIT':
        title = 'Editar questão';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    try {
      quizQuestionSchema(question);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    if (onConfirmPress) {
      onConfirmPress(question);
    }
    setQuestion(questionInitialState);
    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setQuestion(questionInitialState);
    setMode('CLOSE');
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Questão"
          name="question"
          type="text"
          value={question.question}
          onChange={(e) =>
            setQuestion({
              ...question,
              question: e.target.value
            })
          }
        />

        <S.InputRadioGroup>
          <span className="radioGroupLabel">Alternativas</span>

          <InputRadio
            label="Múltiplas"
            name="isMultiple"
            value="true"
            onChange={() =>
              setQuestion({
                ...question,
                isMultiple: true
              })
            }
            checked={question.isMultiple}
          />

          <InputRadio
            label="Única"
            name="isMultiple"
            value="false"
            onChange={() =>
              setQuestion({
                ...question,
                isMultiple: false
              })
            }
            checked={!question.isMultiple}
          />
        </S.InputRadioGroup>

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

QuizQuestionActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { QuizQuestionActionSidebar };
