import { Calendar, Checkbox } from 'components/Form';
import { iCalendarProps } from 'components/Form/Calendar/interfaces/iCalendarProps';
import { iActivatableInputProps } from './interfaces/iActivatableInput';
import * as S from './styled';

type iActivatableCalendarProps = iActivatableInputProps & iCalendarProps;

export const ActivatableCalendar = ({
  onChangeValue,
  customValue,
  ...props
}: iActivatableCalendarProps) => {
  const handleChangeValue = (e: any) => {
    onChangeValue({
      value: e.target.value,
      enable: customValue.enable
    });
  };

  const handleCheckChange = () => {
    onChangeValue({
      enable: !customValue.enable,
      value: customValue.value
    });
  };

  return (
    <S.ActivatableInputWrapper>
      <Checkbox checked={customValue.enable} onChange={handleCheckChange} />
      <Calendar
        disabled={!customValue.enable}
        onChange={handleChangeValue}
        value={customValue.value}
        {...props}
      />
    </S.ActivatableInputWrapper>
  );
};
