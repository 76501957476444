import styled from 'styled-components';

export const DataTableWrapper = styled.div`
  padding: 4px;
  background-color: ${({ theme }) => theme.section.bg};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
  border-radius: 6px;
  font-size: 14px;

  .p-table-center {
    text-align: center !important;

    .p-column-header-content {
      justify-content: center;
    }
  }

  .p-datatable-item-clickable {
    cursor: pointer;
  }

  .p-datatable-thead {
    tr {
      height: 67px;

      th {
        &.p-filter-single {
          position: absolute;
          width: 100%;
          left: 0;
        }

        &.p-filter-single-with-selection {
          position: absolute;
          width: 97%;
          right: 0;
        }

        .p-column-title {
          text-transform: uppercase;
        }
      }
    }
  }

  .p-datatable-tbody {
    tr {
      td {
        i.p-column-text-with-icon {
          margin-right: 8px;
        }

        .p-column-text-with-icon {
          display: flex;
          align-items: baseline;

          .p-tag-value {
            font-weight: 400;
            line-height: initial;
          }

          svg,
          .p-tag-icon {
            margin-right: 8px;
          }

          .p-tag-icon {
            line-height: initial;
          }
        }
      }
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-around;
`;
