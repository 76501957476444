import Quiz from 'models/Quiz';

export enum QuizTypes {
  FETCH_QUIZZES_REQUEST = '@quiz/FETCH_QUIZZES_REQUEST',
  FETCH_QUIZZES_SUCCESS = '@quiz/FETCH_QUIZZES_SUCCESS',
  FETCH_QUIZZES_ERROR = '@quiz/FETCH_QUIZZES_ERROR',

  FETCH_ONE_QUIZ_REQUEST = '@quiz/FETCH_ONE_QUIZ_REQUEST',
  FETCH_ONE_QUIZ_SUCCESS = '@quiz/FETCH_ONE_QUIZ_SUCCESS',
  FETCH_ONE_QUIZ_ERROR = '@quiz/FETCH_ONE_QUIZ_ERROR',

  CREATE_QUIZ_REQUEST = '@quiz/CREATE_QUIZ_REQUEST',
  CREATE_QUIZ_SUCCESS = '@quiz/CREATE_QUIZ_SUCCESS',
  CREATE_QUIZ_ERROR = '@quiz/CREATE_QUIZ_ERROR',

  UPDATE_QUIZ_REQUEST = '@quiz/UPDATE_QUIZ_REQUEST',
  UPDATE_QUIZ_SUCCESS = '@quiz/UPDATE_QUIZ_SUCCESS',
  UPDATE_QUIZ_ERROR = '@quiz/UPDATE_QUIZ_ERROR',

  DELETE_QUIZ_REQUEST = '@quiz/DELETE_QUIZ_REQUEST',
  DELETE_QUIZ_SUCCESS = '@quiz/DELETE_QUIZ_SUCCESS',
  DELETE_QUIZ_ERROR = '@quiz/DELETE_QUIZ_ERROR',
}

export interface QuizState {
  readonly id: number;
  readonly data: Quiz;
  readonly datas: Quiz[];
}
