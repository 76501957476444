import { InstitutionalFaqState } from './types';

export const FAQ_INITIAL_STATE: InstitutionalFaqState = {
  question: {
    id: 0,
    description: '',
    title: '',
  },
  list: [],
  message: '',
}