import { useEffect } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import PlaylistCategory from 'models/PlaylistCategory';
import { showDialog } from 'store/ducks/dialog/actions';
import * as CategorySagas from 'store/ducks/playlistCategory/actions';
import { fetchSuperCategoryRequest } from 'store/ducks/superCategory/actions';
import { Button, DataTableLayout } from 'components';
import {
  currentPageReportTemplate,
  paginatorTemplate
} from 'helpers/dataTable';
import ApplicationState from 'helpers/types/ApplicationState';
import * as S from './CategoryDataTable.styles';

interface CategoryDataTableProps {
  data: PlaylistCategory[];
  onPressItem: (data: any) => void;
}

const CategoryDataTable = (props: CategoryDataTableProps) => {
  const { data } = props;

  const dispatch = useDispatch();

  const superCategory = useSelector(
    (state: ApplicationState) => state.superCategory.data
  );

  useEffect(() => {
    dispatch(fetchSuperCategoryRequest());
  }, [dispatch]);

  const handleRemovePressed = (category: PlaylistCategory) => {
    const remove = () =>
      dispatch(CategorySagas.removePlaylistCategoryRequest(category));

    dispatch(
      showDialog({
        visible: true,
        title: 'Remover categoria',
        message: `Deseja realmente remover a categoria "${category.name}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: remove
      })
    );
  };

  const actions = (dtAc: any) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => props.onPressItem(dtAc)}
        className="p-button-success"
        icon="pi pi-pencil"
      />
      <Button
        type="button"
        onClick={() => handleRemovePressed(dtAc)}
        className="p-button-danger"
        icon="pi pi-trash"
      />
    </S.ActionButtons>
  );

  const createdAtBody = (dtCr: any) => (
    <span>{moment(dtCr.createdAt).format('DD/MM/YYYY HH:mm')}</span>
  );

  const superCategoryBody = (dtSc: any) => (
    <span>
      {superCategory.find((item) => item.id === dtSc.superCategoryId)?.name ||
        '-'}
    </span>
  );

  return (
    <DataTableLayout>
      <DataTable
        value={data}
        sortField="name"
        sortOrder={1}
        selectionMode="single"
        filterDisplay="row"
        rows={10}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 15, 20]}
        onRowDoubleClick={(e) => props.onPressItem(e.data)}
      >
        <Column
          field="id"
          header="ID"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          style={{ width: '100px' }}
          sortable
        />
        <Column
          field="name"
          header="NOME"
          filter
          filterField="name"
          filterPlaceholder="Buscar"
          filterHeaderClassName="p-filter-single"
          sortable
        />
        <Column
          field="superCategoryId"
          header="S. Categoria"
          body={superCategoryBody}
          sortable
        />
        <Column
          field="createdAt"
          header="Criado em"
          style={{ width: '180px' }}
          body={createdAtBody}
          sortable
        />
        <Column
          field="updatedAt"
          header="Atualizado em"
          style={{ width: '180px' }}
          body={createdAtBody}
          sortable
        />
        <Column
          bodyClassName="actionsColumn"
          header="Ações"
          style={{ width: '100px' }}
          body={actions}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { CategoryDataTable };
