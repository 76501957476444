import LoginResponse, { LoginInfo } from 'models/Login';

export enum LoginTypes {
  LOGIN_REQUEST = '@login/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@login/LOGIN_SUCCESS',
  LOGIN_ERROR = '@login/LOGIN_ERROR',

  LOGOUT_REQUEST = '@login/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = '@login/LOGOUT_SUCCESS',
  LOGOUT_ERROR = '@login/LOGOUT_ERROR',
}

export interface LoginState {
  readonly info: LoginInfo;
  readonly data: LoginResponse;
}
