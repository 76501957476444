import AlertDialog from 'models/AlertDialog';

export enum DialogTypes {
  DIALOG_SHOW = '@dialog/DIALOG_SHOW',
  DIALOG_HIDE = '@dialog/DIALOG_HIDE',
}

export interface DialogState {
  readonly data: AlertDialog;
}
