import Audio from 'models/Audio';

export enum AudioTypes {
  SAVE_AUDIO_REQUEST = '@audio/SAVE_AUDIO_REQUEST',
  SAVE_AUDIO_SUCCESS = '@audio/SAVE_AUDIO_SUCCESS',
  SAVE_AUDIO_ERROR = '@audio/SAVE_AUDIO_ERROR',

  EDIT_AUDIO_REQUEST = '@audio/EDIT_AUDIO_REQUEST',
  EDIT_AUDIO_SUCCESS = '@audio/EDIT_AUDIO_SUCCESS',
  EDIT_AUDIO_ERROR = '@audio/EDIT_AUDIO_ERROR',

  REMOVE_AUDIO_REQUEST = '@audio/REMOVE_AUDIO_REQUEST',
  REMOVE_AUDIO_SUCCESS = '@audio/REMOVE_AUDIO_SUCCESS',
  REMOVE_AUDIO_ERROR = '@audio/REMOVE_AUDIO_ERROR',

  DOWNLOAD_AUDIO_REQUEST = '@audio/DOWNLOAD_AUDIO_REQUEST',
  DOWNLOAD_AUDIO_SUCCESS = '@audio/DOWNLOAD_AUDIO_SUCCESS',
  DOWNLOAD_AUDIO_ERROR = '@audio/DOWNLOAD_AUDIO_ERROR',

  SET_SELECTED_AUDIO = '@audio/SET_SELECTED_AUDIO'
}

export interface AudioState {
  readonly id: number;
  readonly audio: Audio;
  readonly audioUrl: string;
}

export type AudioAction = {
  type: AudioTypes;
  payload: AudioState;
};
