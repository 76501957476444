import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  position: relative;

  &:hover {
    label {
      transform: translateY(-95%);
      font-size: 12px;
    }
  }

  span {
    width: 100%;

    &.filled {
      + label {
        transform: translateY(-95%);
        font-size: 12px;
      }
    }
  }

  & + div {
    margin-top: 18px;
  }

  label {
    padding: 6px 4px;
    position: absolute;
    left: 8px;
    top: 10px;
    z-index: 10;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.weight.bold};
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text.secondary};
    transition: transform 150ms ease-out, font-size 150ms ease-out;
  }

  input {
    color: ${({ theme }) => theme.text.primary};
    position: relative;
    width: 100%;
    outline: 0;
    border: 0;
    font-size: 14px;
    background-color: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.table.border};
    border-radius: 4px;
    padding: 12px 8px;
    cursor: text;
  }
`;
