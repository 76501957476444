import { combineReducers } from 'redux';
import { analytics } from './ducks/analytics';
import { audio } from './ducks/audio';
import currentFolder from './ducks/currentFolder';
import dialog from './ducks/dialog';
import { drawer } from './ducks/drawer';
import { folder } from './ducks/folder';
import institutionalAboutUs from './ducks/institutionalAboutUs';
import institutionalContact from './ducks/institutionalContact';
import institutionalFaq from './ducks/institutionalFaq';
import institutionalHome from './ducks/institutionalHome';
import institutionalNews from './ducks/institutionalNews';
import institutionalTerms from './ducks/institutionalTerms';
import { loading } from './ducks/loading';
import login from './ducks/login';
import logs from './ducks/logs';
import navigator from './ducks/navigator';
import plan from './ducks/plan';
import playlist from './ducks/playlist';
import playlistCategory from './ducks/playlistCategory';
import promotions from './ducks/promotion';
import quizQuestions from './ducks/quizQuestions';
import quizzes from './ducks/quizzes';
import studentRequest from './ducks/studentRequest';
import subscriber from './ducks/subscriber';
import subscriptions from './ducks/subscriptions';
import superCategory from './ducks/superCategory';
import teachingInstitution from './ducks/teachingInstitution';
import { theme } from './ducks/theme';
import toast from './ducks/toast';
import trash from './ducks/trash';
import userAdmin from './ducks/userAdmin';
import userApp from './ducks/userApp';

export default combineReducers({
  analytics,
  drawer,
  toast,
  playlist,
  audio,
  theme,
  navigator,
  promotions,
  dialog,
  plan,
  folder,
  currentFolder,
  playlistCategory,
  trash,
  loading,
  subscriptions,
  superCategory,
  quizzes,
  quizQuestions,
  userApp,
  userAdmin,
  logs,
  subscriber,
  teachingInstitution,
  login,
  studentRequest,
  institutionalHome,
  institutionalAboutUs,
  institutionalTerms,
  institutionalNews,
  institutionalFaq,
  institutionalContact
});
