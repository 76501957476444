import Axios from 'axios';
import Cookies from 'js-cookie';
import forbidden from './response';

const token = Cookies.get('AuthToken') || '';

export const BASE_URL = process.env.REACT_APP_API_URL;

const Connector = (useV2 = false) => {
  const conn = Axios.create({
    baseURL: useV2 ? `${BASE_URL}/v2` : `${BASE_URL}/v1`,
    headers: {
      Authorization: token
    }
  });

  conn.interceptors.response.use(
    forbidden.responseInterceptor,
    forbidden.responseInterceptorError
  );

  return conn;
};

export default Connector;
