import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { removeLoadingModule, addLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchQuizzesRequest,
  fetchQuizzesSuccess,
  fetchQuizzesError,
  fetchOneQuizSuccess,
  fetchOneQuizError,
  createQuizSuccess,
  createQuizError,
  updateQuizSuccess,
  updateQuizError,
  deleteQuizSuccess,
  deleteQuizError
} from './actions';
import { Action } from './index';

export function* fetchQuizzes() {
  try {
    yield put(addLoadingModule(StoreModule.QUIZZES));

    const response: ConnectorResponse = yield call(
      Connector().get,
      'quizzes/list'
    );

    yield put(fetchQuizzesSuccess(response.data));
  } catch (err: any) {
    yield put(fetchQuizzesError());
    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.QUIZZES));
  }
}

export function* fetchOneQuiz(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.QUIZZES));

    const response: ConnectorResponse = yield call(
      Connector().get,
      `quizzes?id=${action.payload.id}`
    );

    yield put(fetchOneQuizSuccess(response.data));
  } catch (err: any) {
    yield put(fetchOneQuizError());
    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.QUIZZES));
  }
}

export function* createQuiz(action: Action) {
  try {
    const { data: formData } = action.payload;

    const playList = { connect: { id: formData.playListId } };
    const createdBy = { connect: { id: 1 } };

    const data = {
      name: formData.name,
      description: formData.description,
      playList,
      createdBy
    } as any;

    yield call(Connector().post, 'quizzes', data);

    yield put(createQuizSuccess());
    yield put(fetchQuizzesRequest());
    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Dados criados com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(createQuizError());
    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Falha ao criar dados',
        severity: 'error'
      })
    );
  }
}

export function* updateQuiz(action: Action) {
  try {
    const playList = { connect: { id: action.payload.data.playListId } };

    const obj = Object.assign(action.payload.data, { playList }) as any;
    delete obj.playListId;

    yield call(Connector().put, `quizzes?id=${action.payload.data.id}`, obj);

    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
    yield put(updateQuizSuccess());
    yield put(fetchQuizzesRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Falha ao editar dados',
        severity: 'error'
      })
    );
    yield put(updateQuizError());
  }
}

export function* deleteQuiz(action: Action) {
  try {
    yield call(Connector().delete, `quizzes?id=${action.payload.data.id}`);

    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
    yield put(deleteQuizSuccess());
    yield put(fetchQuizzesRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Quiz',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(deleteQuizError());
  }
}
