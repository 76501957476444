import { InstitutionalTermsState } from './types';

export const INITIAL_STATE: InstitutionalTermsState = {
  terms: {
    id: 0,
    cookiesPolicy: '',
    privacyPolicies: '',
    termsOfUse: '',
  },
};
