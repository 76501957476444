/* eslint-disable radix */
import * as yup from 'yup';
import { LoginInfo } from 'models/Login';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('O formato digitado é inválido')
    .required('O campo "EMAIL" é obrigatório'),
  password: yup.string().required('O campo "SENHA" é obrigatório')
});

export default (login: LoginInfo) =>
  loginSchema.validateSync(login, { abortEarly: false });
