import { action } from 'typesafe-actions';
import Audio from 'models/Audio';
import { AudioTypes } from './types';

export const editAudioRequest = (audio: Audio) =>
  action(AudioTypes.EDIT_AUDIO_REQUEST, { audio });

export const editAudioSuccess = () => action(AudioTypes.EDIT_AUDIO_SUCCESS);

export const editAudioError = () => action(AudioTypes.EDIT_AUDIO_ERROR);

export const removeAudioRequest = (audio: Audio) =>
  action(AudioTypes.REMOVE_AUDIO_REQUEST, { audio });

export const removeAudioSuccess = () => action(AudioTypes.REMOVE_AUDIO_SUCCESS);

export const removeAudioError = () => action(AudioTypes.REMOVE_AUDIO_ERROR);

export const downloadAudioRequest = (id: number) =>
  action(AudioTypes.DOWNLOAD_AUDIO_REQUEST, { id });

export const downloadAudioSuccess = (audioUrl: string) =>
  action(AudioTypes.DOWNLOAD_AUDIO_SUCCESS, { audioUrl });

export const downloadAudioError = () => action(AudioTypes.DOWNLOAD_AUDIO_ERROR);

export const setSelectedAudio = (audio: Audio) =>
  action(AudioTypes.SET_SELECTED_AUDIO, { audio });
