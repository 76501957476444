import * as yup from 'yup';
import { QuizAlternative } from 'models/Quiz';

const quizAlternativeSchema = yup.object().shape({
  id: yup.number().required(),
  answer: yup.string().required('O campo "RESPOSTA" é obrigatório!'),
  isCorrect: yup
    .boolean()
    .required('O campo "TIPO DE ALTERNATIVA" é obrigatório!')
});

export default (quizAlternative: QuizAlternative) =>
  quizAlternativeSchema.validateSync(quizAlternative, { abortEarly: false });
