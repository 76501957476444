import classNames from 'classnames';
import * as S from './DataTableLayout.styles';
import { DataTableLayoutProps } from './interfaces/DataTableLayoutProps';

const DataTableLayout = (props: DataTableLayoutProps) => {
  const { children, className } = props;

  return (
    <S.DataTableWrapper className={classNames(className)}>
      {children}
    </S.DataTableWrapper>
  );
};

export { DataTableLayout };
