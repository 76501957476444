// import { FaArrowLeft } from 'react-icons/fa';
import playlegisLogoIcon from 'assets/playlegis-logo-icon.svg';
import * as S from './HomePage.styles';

const HomePage = () => (
  <S.ContainerFluid>
    <S.Row className="container-row">
      <S.Container>
        {/* <FaArrowLeft size={80} /> */}
        <S.LogoIcon>
          <img src={playlegisLogoIcon} alt="Logo da Playlegis" />
        </S.LogoIcon>
        <S.Title>Bem vindo!</S.Title>
        <S.Description>
          Use o menu lateral para acessar as páginas da aplicação
        </S.Description>
      </S.Container>
    </S.Row>
  </S.ContainerFluid>
);

export { HomePage };
