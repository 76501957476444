import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Plan from 'models/Plan';
import { showDialog } from 'store/ducks/dialog/actions';
import * as SagasPlan from 'store/ducks/plan/actions';
import { Button, DataTableLayout } from 'components';
import * as S from './PlanDataTable.styles';

interface PlanDataTableProps {
  data: Plan[];
  onPressItem: (plan: Plan) => void;
}

const PlanDataTable = (props: PlanDataTableProps) => {
  const { data, onPressItem } = props;

  const dispatch = useDispatch();
  const isNotFullhd = useMediaQuery({ maxWidth: '1368px' });

  const handleRemovePressed = (selectedPlan: Plan) => {
    dispatch(
      showDialog({
        visible: true,
        title: 'Remover plano',
        message: `Deseja reamente remover o plano "${selectedPlan.name}"?`,
        mode: 'CONFIRM',
        onCancelPress: () => false,
        onConfirmPress: () =>
          dispatch(SagasPlan.deletePlanRequest(selectedPlan))
      })
    );
  };

  const statusBody = (plan: Plan) => (
    <div>
      {plan.status === 'ENABLED' && 'Ativo'}
      {plan.status === 'DISABLED' && 'Inativo'}
    </div>
  );

  const getActionButtons = (selectedPlan: Plan) => (
    <S.ActionButtons>
      <Button
        type="button"
        onClick={() => onPressItem(selectedPlan)}
        severity="confirm"
        className=" p-button-success"
        icon="pi pi-pencil"
      >
        <FaPen />
      </Button>
      <Button
        type="button"
        onClick={() => handleRemovePressed(selectedPlan)}
        severity="danger"
        className="p-button-danger"
        icon="pi pi-trash"
      >
        <FaTrash />
      </Button>
    </S.ActionButtons>
  );

  const handleRowDoubleClick = (data: Plan) => {
    onPressItem(data);
  };

  const getDescriptionBody = (plan: Plan) => {
    if (!plan.description) return;

    return isNotFullhd
      ? `${plan.description.substring(0, 42)}...`
      : plan.description;
  };

  return (
    <DataTableLayout>
      <DataTable
        sortField="name"
        sortOrder={1}
        value={data}
        onRowDoubleClick={(e) => handleRowDoubleClick(e.data)}
        filterDisplay="row"
      >
        <Column
          field="id"
          header="ID"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          style={{ width: '60px' }}
          sortable
        />
        <Column
          field="name"
          header="Nome"
          filter
          filterField="name"
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="value"
          header="Valor"
          filter
          filterField="value"
          filterPlaceholder="Buscar"
          sortable
        />
        <Column
          field="description"
          header="Descrição"
          style={{ minWidth: '300px' }}
          body={getDescriptionBody}
        />
        <Column
          field="status"
          header="Status"
          headerClassName="isCentered"
          bodyClassName="isCentered"
          body={statusBody}
          sortable
        />
        <Column
          header="Ações"
          style={{ width: '100px' }}
          headerClassName="isCentered"
          bodyClassName="actionsColumn isCentered"
          body={getActionButtons}
        />
      </DataTable>
    </DataTableLayout>
  );
};

export { PlanDataTable };
