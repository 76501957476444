/* eslint-disable import/no-cycle */
import Subscriber from './Subscriber';

export type UserPermission = { id: string; label: string };

export const UserPermissions: UserPermission[] = [
  { id: 'DASH', label: 'Dashboard' },
  { id: 'USING_DATA', label: 'Dados de uso' },
  { id: 'DASH_SUBSCRIPTION', label: 'Assinatura' },
  { id: 'SUBSCRIPTIONS', label: 'Assinantes' },
  { id: 'PLAYLISTS', label: 'Playlists' },
  { id: 'FILES', label: 'Arquivos' },
  { id: 'PLANS', label: 'Planos' },
  { id: 'PROMOTIONS', label: 'Promoções' },
  { id: 'CATEGORIES', label: 'Categorias' },
  { id: 'QUIZZES', label: 'Quizzes' },
  { id: 'USERS', label: 'Instituições' },
  { id: 'USERS_ADMIN', label: 'Usuários Administradores' },
  { id: 'INSTITUTIONS', label: 'Instituições' },
  { id: 'STUDENTS', label: 'Estudantes' },
  { id: 'LOGS', label: 'Logs' },
  { id: 'INSTITUTIONAL_CONTENT', label: 'Conteúdo Institucional' }
];

interface UserBasic {
  id: number;
  name: string;
  email: string;
  cpf: string;
  avatar?: File;
  recoverPassCode?: string;
  password: string;
  isAdmin: boolean;
  removed: boolean;
  createdAt: Date;
  updatedAt: Date;
  removedAt?: Date;
  firstLogin: boolean;
  hubId: string;
}

export interface UserApp extends UserBasic {
  subscription?: Subscriber;
  customerId: string;
}
export interface UserAdmin extends UserBasic {
  userPermissions: UserPermission[];
}
