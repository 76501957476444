import { ToastMessage } from 'primereact/toast';
import { action } from 'typesafe-actions';
import { ToastTypes } from './types';

export const showToast = (toast: ToastMessage) =>
  action(ToastTypes.TOAST_SHOW, {
    toast
  });

export const dismissAllToasts = () => action(ToastTypes.TOAST_DISMISS_ALL);
