import { call, put } from 'redux-saga/effects';
import Plan from 'models/Plan';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchPlanRequest,
  fetchPlanSuccess,
  fetchPlanError,
  createPlanSuccess,
  createPlanError,
  updatePlanError,
  updatePlanSuccess,
  deletePlanSuccess,
  deletePlanError
} from './actions';
import { PlanAction } from './types';

export function* fetchPlan() {
  try {
    yield put(addLoadingModule(StoreModule.PLAN));

    const response: ConnectorResponse<Plan[]> = yield call(
      Connector(true).get,
      'plans/list'
    );

    yield put(fetchPlanSuccess(response.data));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Plano',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
    yield put(fetchPlanError());
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAN));
  }
}

export function* createPlan(action: PlanAction) {
  try {
    yield put(addLoadingModule(StoreModule.PLAN));

    const { plan } = action.payload;

    const data = {
      name: plan.name,
      value: plan.value
    };

    yield call(Connector().post, 'plans', data);

    yield put(createPlanSuccess());
    yield put(
      showToast({
        summary: 'Plano',
        detail: 'Plano criado com sucesso',
        severity: 'success'
      })
    );
    yield put(fetchPlanRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Plano',
        detail: 'Falha ao criar plano',
        severity: 'error'
      })
    );
    yield put(createPlanError());
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAN));
  }
}

export function* updatePlan(action: PlanAction) {
  try {
    yield put(addLoadingModule(StoreModule.PLAN));

    const { plan } = action.payload;

    yield call(Connector().put, `plans?id=${plan.id}`, plan);

    yield put(updatePlanSuccess());
    yield put(
      showToast({
        summary: 'Plano',
        detail: 'Plano atualizado com sucesso',
        severity: 'success'
      })
    );
    yield put(fetchPlanRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Plano',
        detail: 'Falha ao atualizar plano',
        severity: 'error'
      })
    );
    yield put(updatePlanError());
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAN));
  }
}

export function* deletePlan(action: PlanAction) {
  try {
    yield put(addLoadingModule(StoreModule.PLAN));

    const { plan } = action.payload;

    yield call(Connector().delete, `plans?id=${plan.id}`);

    yield put(deletePlanSuccess());
    yield put(
      showToast({
        summary: 'Plano',
        detail: 'Plano removido com sucesso',
        severity: 'success'
      })
    );
    yield put(fetchPlanRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Plano',
        detail: 'Falha ao remover plano',
        severity: 'error'
      })
    );
    yield put(deletePlanError());
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAN));
  }
}
