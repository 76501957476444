import { Link as Link_ } from 'react-router-dom';
import styled from 'styled-components';
import { NavigatorStyleProps } from './interfaces/NavigatorStyleProps';

export const ContainerFluid = styled.div<NavigatorStyleProps>`
  display: flex;
  width: fit-content;
  height: 100%;
  position: fixed;
  z-index: 11;
  background-color: ${({ theme }) => theme.blue4};
  color: ${({ theme }) => theme.navigator.link};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.shadow};
`;

export const Column = styled.div<NavigatorStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.mainMenu {
    ${(props) =>
      !props.$isCollapsed &&
      `
      height: 100%;
    `}

    ${(props) =>
      props.$isCollapsed &&
      `
      justify-content: center;
    `}
  }
`;

export const Row = styled.div<NavigatorStyleProps>`
  display: flex;

  &.body {
    ${(props) =>
      props.$isCollapsed &&
      `
        flex-grow: 1;
    `}
  }
`;

export const LogoIcon = styled.button<NavigatorStyleProps>`
  display: flex;
  justify-content: center;

  img {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  ${(props) =>
    props.$isCollapsed
      ? `
    padding: 24px 0;

    img {
      width: 50px;
    }
  `
      : `
    padding: 16px 0;

    img {
      width: 175px;
    }
  `};
`;

export const TabContainer = styled(Column)`
  margin-bottom: 8px;
  position: relative;
`;

export const TabSwitch = styled.button<NavigatorStyleProps>`
  display: flex;

  &:focus {
    outline: 0px transparent !important;
  }

  ${(props) =>
    !props.$isCollapsed &&
    `
    margin: 0 32px;
    padding: 8px 0;
    justify-content: space-between;
    text-align: left;
    text-transform: uppercase;
    align-items: baseline;

    span {
      font-size: 10px;
    }
  
    i {
      font-size: 10px;
  
      svg {
        transition: 0.35s;
      }
    }

    &.tab {
      &--is-open {
        svg {
          transform: rotate(180deg);
        }
      };
      &--is-closed {
        svg {
          transform: rotate(0deg);
        }
      };
    }
  `}

  ${(props) =>
    props.$isCollapsed &&
    `
    justify-content: center;
    width: 100%;
    padding: 12px 0;
    cursor: default;

    i {
      font-size: 20px;
    }

    &.tab {
      &--is-open {
        background: ${props.theme.navigator.hover};
        
        svg {
          fill: ${props.theme.blue};
        }
      };
    }
  `}
`;

export const TabContent = styled(Column)<NavigatorStyleProps>`
  overflow: hidden;

  // collapsed menu
  ${(props) =>
    props.$isCollapsed &&
    `
    display none;
    position: absolute;
    height: initial;
    width: max-content;
    left: ${`${props.$navigatorWidth}px`};
    top: -30px;
    border-radius: 0px 5px 5px 0px;
    box-shadow: 0px 0px 6px #131827;
    opacity: 0;
    transition: opacity 2s ease-out;

    span.submenuTitle {
      font-size: 10px;
      padding: 18px 32px 12px;
      text-transform: uppercase;
      text-align: center;
    }

    ${
      props.isOpen &&
      `
      display: flex;
      opacity: 1;
      background-color: ${props.theme.blue};
    `
    }
  `}

  // full menu 
  ${(props) =>
    !props.$isCollapsed &&
    `
    max-height: ${props.isOpen ? '100%' : '0px'};
    transition: max-height 0.35s ease-out;
  `}
`;

export const MainLink = styled(Link_)<NavigatorStyleProps>`
  width: 100%;
  color: ${({ theme }) => theme.navigator.link};
  text-transform: uppercase;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 12px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.navigator.hover};
    color: ${({ theme }) => theme.blue};
  }

  .nav-item {
    .icon {
      margin-right: 25px;
    }
  }

  ${(props) =>
    props.$isCollapsed &&
    `
    padding: 12px 32px;

    &.active {
      color: ${props.theme.navigator.hover};

      &:hover {
        color: ${props.theme.blue};
      }
    }
  `}

  ${(props) =>
    !props.$isCollapsed &&
    `
    padding: 8px 32px;

    &.active {
      padding-left: 26px;
      border-left: 6px solid ${props.theme.navigator.hover};
    }
  `}
`;

export const Footer = styled.div<NavigatorStyleProps>`
  display: flex;
  align-items: flex-end;

  ${(props) =>
    !props.$isCollapsed &&
    `
    flex-grow: 1;
    padding: 32px;
  `};

  ${(props) =>
    props.$isCollapsed &&
    `
    padding: 32px 0;
  `};
`;

export const FooterMenu = styled.section`
  flex-direction: column;
  width: 100%;

  & > label {
    margin-bottom: 12px;
  }
`;

export const FooterLink = styled(Link_)<NavigatorStyleProps>`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.navigator.link};
  text-transform: uppercase;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 12px;
  transition: color 0.2s;

  ${(props) =>
    props.$isCollapsed &&
    `
    &:hover {
      color: ${props.theme.yellow};

      .nav-item {
        .label {
          display: block;
          width: max-content;
        }
      }
    }

    .nav-item {
      display: flex;

      .icon {
        padding: 12px 32px;
      }
    }
  `};

  ${(props) =>
    !props.$isCollapsed &&
    `
    margin-top: 14px;
    
    &:hover {
      color: ${props.theme.yellow};
    }
  
    .nav-item {
      .icon {
        margin-right: 25px;
      }
    }
  `};
`;
