/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { QuizAlternative } from 'models/Quiz';
import { showToast } from 'store/ducks/toast/actions';
import { Button, Drawer } from 'components';
import { InputText, InputRadio } from 'components/Form';
import ApplicationState from 'helpers/types/ApplicationState';
import { DrawerMode } from 'helpers/types/DrawerMode';
import quizAlternativeSchema from 'helpers/validation/quizAlternative';
import { alternativeInitialState } from '../../helpers/initialState';
import * as S from './QuizAlternativeActionSidebar.styles';

interface QuizAlternativeActionSidebarProps {
  mode: DrawerMode;
  setMode: React.Dispatch<React.SetStateAction<DrawerMode>>;
  selectedAlternative: QuizAlternative;
  onDeletePress: (alternative: QuizAlternative) => void;
  onConfirmPress: (alternative: QuizAlternative) => void;
  onCancelPress?: () => void;
}

const QuizAlternativeActionSidebar = (
  props: QuizAlternativeActionSidebarProps
) => {
  const {
    mode,
    setMode,
    selectedAlternative,
    onCancelPress,
    onConfirmPress,
    onDeletePress
  } = props;

  const dispatch = useDispatch();

  const question = useSelector(
    (state: ApplicationState) => state.quizQuestions.one
  );

  const [alternative, setAlternative] =
    useState<QuizAlternative>(selectedAlternative);

  useEffect(() => {
    setAlternative(selectedAlternative);
  }, [selectedAlternative]);

  const handleTitle = () => {
    let title = '';
    switch (mode) {
      case 'OPEN_ADD':
        title = 'Adicionar alternativa';
        break;
      case 'OPEN_EDIT':
        title = 'Editar alternativa';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const handleConfirmPress = () => {
    let hasError = false;

    if (!question.isMultiple) {
      question.quizAlternative.forEach((qa) => {
        if (qa.isCorrect && alternative.isCorrect && qa.id !== alternative.id) {
          dispatch(
            showToast({
              summary: 'Falha ao salvar',
              detail: 'Essa questão aceita somente uma resposta correta',
              severity: 'error'
            })
          );
          hasError = true;
        }
      });
    }

    if (hasError) {
      return;
    }

    try {
      quizAlternativeSchema(alternative);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e, index) => {
          setTimeout(() => {
            dispatch(
              showToast({
                summary: 'Falha na validação',
                detail: e.message,
                severity: 'error'
              })
            );
          }, index * 800);
        });
      }

      return;
    }

    if (onConfirmPress) {
      onConfirmPress(alternative);
    }
    setAlternative(alternativeInitialState);
    setMode('CLOSE');
  };

  const handleCancelPress = () => {
    if (onCancelPress) onCancelPress();
    setAlternative(alternativeInitialState);
    setMode('CLOSE');
  };

  const handleDeletePress = () => {
    if (onDeletePress) onDeletePress(alternative);
    setMode('CLOSE');
  };

  return (
    <Drawer
      title={handleTitle()}
      drawerMode={mode}
      onBackDropPress={handleCancelPress}
    >
      <S.Form>
        <InputText
          label="Resposta"
          name="answer"
          type="text"
          value={alternative.answer}
          onChange={(e) =>
            setAlternative({
              ...alternative,
              answer: e.target.value
            })
          }
        />

        <S.InputRadioGroup>
          <span className="radioGroupLabel">Resposta correta</span>

          <InputRadio
            label="Sim"
            name="isCorrect"
            value="isCorrect"
            onChange={(e) =>
              setAlternative({
                ...alternative,
                isCorrect: e.target.checked
              })
            }
            checked={alternative.isCorrect}
          />

          <InputRadio
            label="Não"
            name="isCorrect"
            value="isNotCorrect"
            onChange={(e) =>
              setAlternative({
                ...alternative,
                isCorrect: !e.target.checked
              })
            }
            checked={!alternative.isCorrect}
          />
        </S.InputRadioGroup>

        <S.ButtonGroup>
          <Button
            type="button"
            size="big"
            severity="confirm"
            onClick={handleConfirmPress}
          >
            <FaSave />
            Salvar
          </Button>

          <Button
            type="button"
            size="big"
            severity="primary"
            onClick={handleCancelPress}
          >
            <FaTimes />
            Cancelar
          </Button>

          <Button
            type="button"
            size="big"
            severity="danger"
            onClick={handleDeletePress}
          >
            <FaTimes />
            Remover
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </Drawer>
  );
};

QuizAlternativeActionSidebar.defaultProps = {
  onCancelPress: () => {}
};

export { QuizAlternativeActionSidebar };
