import { QuizAlternative } from 'models/Quiz';

export enum QuizAlternativesTypes {
  CREATE_QUESTION_ALTERNATIVE_REQUEST = '@quizAlternative/CREATE_QUESTION_ALTERNATIVE_REQUEST',
  CREATE_QUESTION_ALTERNATIVE_SUCCESS = '@quizAlternative/CREATE_QUESTION_ALTERNATIVE_SUCCESS',
  CREATE_QUESTION_ALTERNATIVE_ERROR = '@quizAlternative/CREATE_QUESTION_ALTERNATIVE_ERROR',

  UPDATE_QUESTION_ALTERNATIVE_REQUEST = '@quizAlternative/UPDATE_QUESTION_ALTERNATIVE_REQUEST',
  UPDATE_QUESTION_ALTERNATIVE_SUCCESS = '@quizAlternative/UPDATE_QUESTION_ALTERNATIVE_SUCCESS',
  UPDATE_QUESTION_ALTERNATIVE_ERROR = '@quizAlternative/UPDATE_QUESTION_ALTERNATIVE_ERROR',

  DELETE_QUESTION_ALTERNATIVE_REQUEST = '@quizAlternative/DELETE_QUESTION_ALTERNATIVE_REQUEST',
  DELETE_QUESTION_ALTERNATIVE_SUCCESS = '@quizAlternative/DELETE_QUESTION_ALTERNATIVE_SUCCESS',
  DELETE_QUESTION_ALTERNATIVE_ERROR = '@quizAlternative/DELETE_QUESTION_ALTERNATIVE_ERROR',

}

export interface QuizAlternativesState {
  readonly id: number;
  readonly one: QuizAlternative;
}
