import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPlanRequest } from 'store/ducks/plan/actions';
import { fetchSubscriptionsRequest } from 'store/ducks/subscriptions/actions';
import { fetchTeachingInstitutionsRequest } from 'store/ducks/teachingInstitution/actions';
import { PageHeader } from 'components';
import { SubscriptionDataTable, SubscriptionFilterBar } from './components';
import { SubscriptionsReportExportButtons } from './components/ExportButtons/SubscriptionsReportExportButtons';
import * as S from './SubscriptionsReportPage.styles';

const SubscriptionsReportPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlanRequest());
  }, []);

  useEffect(() => {
    dispatch(fetchSubscriptionsRequest());
  }, []);

  useEffect(() => {
    dispatch(fetchTeachingInstitutionsRequest());
  }, []);

  return (
    <S.ContainerFluid>
      <S.Row className="height">
        <S.Container>
          <PageHeader
            title="Relatório de Assinaturas"
            actionsComponent={<SubscriptionsReportExportButtons />}
          />

          <SubscriptionFilterBar />
          <SubscriptionDataTable />
        </S.Container>
      </S.Row>
    </S.ContainerFluid>
  );
};

export { SubscriptionsReportPage };
