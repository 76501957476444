/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { ThemeContext } from 'styled-components';
import { activeUsersByPlanRequest } from 'store/ducks/analytics/actions';
import { Card, LoadingIndicator, MiniCard } from 'components';
import money from 'helpers/money';
import ApplicationState from 'helpers/types/ApplicationState';
import { CustomChartOptions } from 'helpers/types/ChartOptionsType';
import { ChartConfig } from 'helpers/types/ChartTypes';
import * as S from './ActiveByPlanChart.styles';

const ActiveByPlanChart = () => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { activeUsersByPlan, loading } = useSelector(
    (state: ApplicationState) => state.analytics
  );

  const isModuleLoading = () => loading.includes('ACTIVE_USERS_BY_PLAN');

  const [chartConfig, setChartConfig] = useState<ChartConfig>({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    dispatch(activeUsersByPlanRequest());
  }, [dispatch]);

  useEffect(() => {
    const config: ChartConfig = {
      labels: activeUsersByPlan.map((item) => item.planName),
      datasets: [
        {
          data: activeUsersByPlan.map((item) => item.numbersubscriptions),
          backgroundColor: [
            themeContext.chart.bg.blue1,
            themeContext.chart.bg.orange1
          ],
          hoverBackgroundColor: [
            themeContext.chart.bghover.blue1,
            themeContext.chart.bghover.orange1
          ],
          borderColor: '#ffffff',
          borderWidth: 3
        }
      ]
    };

    setChartConfig(config);
  }, [activeUsersByPlan]);

  const handleRefresh = () => {
    dispatch(activeUsersByPlanRequest());
  };

  const getIncome = () =>
    money(
      activeUsersByPlan.reduce((prev, current) => current.amount + prev, 0)
    );

  const chartOptions = useMemo<CustomChartOptions>(
    () => ({
      plugins: {
        legend: {
          labels: {
            color: themeContext.text.primary
          }
        }
      }
    }),
    [themeContext]
  );

  const chartMemo = useMemo(
    () => (
      <Chart
        type="doughnut"
        data={chartConfig}
        options={chartOptions}
        className="chart"
      />
    ),
    [chartConfig, chartOptions]
  );

  return (
    <Card title="Assinantes por plano">
      <LoadingIndicator
        isLoading={isModuleLoading()}
        position="absolute"
        showRefreshButton
        onRefresh={handleRefresh}
      />

      <S.Container>
        {chartMemo}

        <S.Column>
          {activeUsersByPlan.map((item) => (
            <MiniCard
              key={item.planId}
              title={item.planName}
              body={item.numbersubscriptions}
            />
          ))}

          <MiniCard title="Receita Prevista" body={getIncome()} />
        </S.Column>
      </S.Container>
    </Card>
  );
};

export { ActiveByPlanChart };
