import styled from 'styled-components';
import { ChartStyles } from '../Chart.styles';

export const Container = styled(ChartStyles)`
  .p-chart {
    margin: 30px 0;
  }
`;

export const Row = styled.div`
  display: flex;
`;
