/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchUsersAdminRequest,
  fetchUsersAdminSuccess,
  fetchUsersAdminError,
  fetchOneUserAdminSuccess,
  fetchOneUserAdminError,
  createUserAdminSuccess,
  createUserAdminError,
  updateUserAdminSuccess,
  updateUserAdminError,
  deleteUserAdminSuccess,
  deleteUserAdminError
} from './actions';
import { Action } from './index';

export function* fetchUsersAdmin() {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const response: ConnectorResponse = yield call(
      Connector().get,
      'users/list?isAdmin=true'
    );

    yield put(fetchUsersAdminSuccess(response.data));
  } catch (err: any) {
    yield put(fetchUsersAdminError());
    yield put(
      showToast({
        summary: 'Usuários - Administradores',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* fetchOneUserAdmin(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { data: user }: ConnectorResponse = yield call(
      Connector().get,
      `users?id=${action.payload.id}`
    );

    yield put(fetchOneUserAdminSuccess(user));
  } catch (err: any) {
    yield put(fetchOneUserAdminError());
    yield put(
      showToast({
        summary: 'Usuários - Administradores',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* createUserAdmin(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { userPermissions } = action.payload.one;

    const serializedPermissions = userPermissions.map((item) => item.id);

    const user = {
      name: action.payload.one.name,
      email: action.payload.one.email,
      password: action.payload.one.password,
      isAdmin: true,
      cpf: action.payload.one.cpf,
      userPermissions: serializedPermissions
    } as any;

    yield call(Connector().post, 'users', user);

    yield put(createUserAdminSuccess());
    yield put(fetchUsersAdminRequest());
    yield put(
      showToast({
        summary: 'Usuários - Administradores',
        detail: 'Dados criados com sucesso',
        severity: 'success'
      })
    );
  } catch (err: any) {
    yield put(createUserAdminError());

    if (err.response.status === 409) {
      yield put(
        showToast({
          summary: 'Usuários - Administradores',
          detail: 'Email ou CPF já cadastrados',
          severity: 'error'
        })
      );
    } else {
      yield put(
        showToast({
          summary: 'Usuários - Administradores',
          detail: 'Falha ao criar dados',
          severity: 'error'
        })
      );
    }
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* updateUserAdmin(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const { userPermissions, ...one } = action.payload.one;
    const serializedPermissions = userPermissions.map((item) => item.id);

    const user = {
      ...one,
      userPermissions: serializedPermissions
    };

    yield call(Connector().put, `users?id=${user.id}`, user);

    yield put(
      showToast({
        summary: 'Usuários - Administradores',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
    yield put(updateUserAdminSuccess());
    yield put(fetchUsersAdminRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Usuários - Administradores',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(updateUserAdminError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}

export function* deleteUserAdmin(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.INSTITUTIONALHOME));

    const user = action.payload.one;

    yield call(Connector().delete, `users?id=${user.id}`);

    yield put(
      showToast({
        summary: 'Usuários - Administradores',
        detail: 'Dados removidos com sucesso',
        severity: 'success'
      })
    );
    yield put(deleteUserAdminSuccess());
    yield put(fetchUsersAdminRequest());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Usuários - Administradores',
        detail: 'Falha ao remover dados',
        severity: 'error'
      })
    );
    yield put(deleteUserAdminError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONALHOME));
  }
}
