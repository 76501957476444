import styled from 'styled-components';

export const FileDragAndropListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
  transition: all 0.5s;

  &.isFilled {
    border-top: 1px solid ${({ theme }) => theme.table.border};
    border-bottom: 1px solid ${({ theme }) => theme.table.border};
  }

  li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 0;

    i {
      font-size: 24px;
      height: 24px;
      width: 24px;
      margin-right: 4px;
    }

    div {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      .title {
        font-size: 14px;
        color: ${({ theme }) => theme.text.primary};
      }

      .size {
        font-size: 12px;
        color: ${({ theme }) => theme.text.secondary};
      }
    }

    button {
      flex-grow: 0;
      align-items: right;
      margin-left: 0px;
      font-size: 20px;
    }
  }
`;
