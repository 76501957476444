import { call, put } from 'redux-saga/effects';
import Connector from 'helpers/connector/Connector';
import { StoreModule } from 'helpers/enum/StoreModule';
import { ConnectorResponse } from 'helpers/types/ConnectorResponse';
import { removeLoadingModule, addLoadingModule } from '../loading/actions';
import { showToast } from '../toast/actions';
import {
  fetchStudentsReqRequest,
  fetchStudentsReqSuccess,
  fetchStudentsReqError,
  updateStudentReqSuccess,
  updateStudentReqError,
  sendStudentMessageSuccess,
  sendStudentMessageError
} from './actions';
import { Action } from './index';

export function* fetchStudentsRequest(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.STUDENTREQUEST));

    const { startsIn, endsIn } = action.payload.filters;

    const serializedStartsIn =
      startsIn || new Date(Date.now() - 3.154e10).toISOString();
    const serializedEndsIn = endsIn || new Date(Date.now()).toISOString();
    const query = `?startsIn=${serializedStartsIn}&endsIn=${serializedEndsIn}&status=ALL`;

    const response: ConnectorResponse = yield call(
      Connector().get,
      `student-request-services/list${query}`
    );

    yield put(fetchStudentsReqSuccess(response.data));
  } catch (err: any) {
    yield put(fetchStudentsReqError());
    yield put(
      showToast({
        summary: 'Requisição de estudante',
        detail: 'Falha ao carregar dados',
        severity: 'error'
      })
    );
  } finally {
    yield put(removeLoadingModule(StoreModule.STUDENTREQUEST));
  }
}

export function* updateStudentRequest(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.STUDENTREQUEST));

    const data = action.payload.one;
    const { filters } = action.payload;

    const query = `/student-request-services/approved?id=${data.id}&status=${data.status}&reason=${data.reasonRepprove}`;

    yield call(Connector(true).put, query);

    yield put(
      showToast({
        summary: 'Requisição de estudante',
        detail: 'Dados atualizados com sucesso',
        severity: 'success'
      })
    );
    yield put(updateStudentReqSuccess());
    yield put(fetchStudentsReqRequest(filters));
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Requisição de estudante',
        detail: 'Falha ao atualizar dados',
        severity: 'error'
      })
    );
    yield put(updateStudentReqError());
  } finally {
    yield put(removeLoadingModule(StoreModule.STUDENTREQUEST));
  }
}

export function* sendStudentMessage(action: Action) {
  try {
    yield put(addLoadingModule(StoreModule.STUDENTREQUEST));

    const { one: data, message } = action.payload;
    const query = `student-request-documents?subscriptionId=${data.hubId}`;

    yield call(Connector().post, query, { message });

    yield put(
      showToast({
        summary: 'Estudantes',
        detail: 'Mensagem enviada com sucesso',
        severity: 'success'
      })
    );
    yield put(sendStudentMessageSuccess());
  } catch (err: any) {
    yield put(
      showToast({
        summary: 'Estudantes',
        detail: 'Falha ao enviar mensagem',
        severity: 'error'
      })
    );
    yield put(sendStudentMessageError());
  } finally {
    yield put(removeLoadingModule(StoreModule.STUDENTREQUEST));
  }
}
