import { QuizQuestion } from 'models/Quiz';

export enum QuizQuestionsTypes {
  FETCH_ONE_QUIZ_QUESTIONS_REQUEST = '@quizQuestions/FETCH_ONE_QUIZ_REQUEST',
  FETCH_ONE_QUIZ_QUESTIONS_SUCCESS = '@quizQuestions/FETCH_ONE_QUIZ_QUESTIONS_SUCCESS',
  FETCH_ONE_QUIZ_QUESTIONS_ERROR = '@quizQuestions/FETCH_ONE_QUIZ_QUESTIONS_ERROR',

  CREATE_QUIZ_QUESTION_REQUEST = '@quizQuestions/CREATE_QUIZ_QUESTION_REQUEST',
  CREATE_QUIZ_QUESTION_SUCCESS = '@quizQuestions/CREATE_QUIZ_QUESTION_SUCCESS',
  CREATE_QUIZ_QUESTION_ERROR = '@quizQuestions/CREATE_QUIZ_QUESTION_ERROR',

  UPDATE_QUIZ_QUESTION_REQUEST = '@quizQuestions/UPDATE_QUIZ_QUESTION_REQUEST',
  UPDATE_QUIZ_QUESTION_SUCCESS = '@quizQuestions/UPDATE_QUIZ_QUESTION_SUCCESS',
  UPDATE_QUIZ_QUESTION_ERROR = '@quizQuestions/UPDATE_QUIZ_QUESTION_ERROR',

  DELETE_QUIZ_QUESTION_REQUEST = '@quizQuestions/DELETE_QUIZ_QUESTION_REQUEST',
  DELETE_QUIZ_QUESTION_SUCCESS = '@quizQuestions/DELETE_QUIZ_QUESTION_SUCCESS',
  DELETE_QUIZ_QUESTION_ERROR = '@quizQuestions/DELETE_QUIZ_QUESTION_ERROR',

}

export interface QuizQuestionsState {
  readonly id: number;
  readonly one: QuizQuestion;
}
