import { Reducer } from 'redux';
import {
  InstitutionalAboutUsTypes,
  InstitutionalAboutUsState,
  InstitutionalAboutUsAction
} from './types';
import { ABOUT_US_INITIAL_STATE } from './utils';

const reducer: Reducer<
  InstitutionalAboutUsState,
  InstitutionalAboutUsAction
> = (
  state: InstitutionalAboutUsState = ABOUT_US_INITIAL_STATE,
  action: InstitutionalAboutUsAction
) => {
  switch (action.type) {
    case InstitutionalAboutUsTypes.FETCH_ABOUT_US_SUCCESS: {
      return {
        ...state,
        aboutUs: action.payload.aboutUs
      };
    }
    case InstitutionalAboutUsTypes.UPDATE_ABOUT_US_REQUEST: {
      return {
        ...state,
        aboutUs: action.payload.aboutUs
      };
    }
    case InstitutionalAboutUsTypes.ABOUT_US_REQUEST_SUCCESS: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    case InstitutionalAboutUsTypes.ABOUT_US_REQUEST_ERROR: {
      return {
        ...state,
        message: action.payload.message
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
